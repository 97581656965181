import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useFormStyles } from '../../styles/useFormStyles';
import { route, SESSION_DETAILS_TABS, sessionDetailsTabParameters, sessionDetailsTabRoute } from '../../utils/routing';
import SessionDetailsOverview from './SessionDetailsOverview';
import SessionDetailsEvents from './SessionDetailsEvents';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import dashboardStore from '../../state/dashboardStore';
import { PAGES } from '../../components/main/MainComponent';

const SessionDetails = observer(() => {
  const formClasses = useFormStyles();

  const { sessionid } = useParams<{ sessionid: string }>();

  const history = useHistory();

  useEffect(() => {
    dashboardStore.appBarLabel = 'Session Details';
  }, [history.location]);

  return (
    <div style={{ width: '100%' }}>
      <div className={formClasses.root}>
        <div className={formClasses.verticalRoot}>
          <ViziTabs tabs={Object.values(SESSION_DETAILS_TABS)} basePath={sessionDetailsTabParameters(sessionid)}>
            <ViziTab
              label={SESSION_DETAILS_TABS.OVERVIEW}
              linkTo={sessionDetailsTabParameters(sessionid, SESSION_DETAILS_TABS.OVERVIEW)}
            />
            <ViziTab
              label={SESSION_DETAILS_TABS.EVENTS}
              linkTo={sessionDetailsTabParameters(sessionid, SESSION_DETAILS_TABS.EVENTS)}
            />
          </ViziTabs>
          <Switch>
            <Route path={sessionDetailsTabRoute(SESSION_DETAILS_TABS.OVERVIEW)}>
              <SessionDetailsOverview />
            </Route>
            <Route path={sessionDetailsTabRoute(SESSION_DETAILS_TABS.EVENTS)}>
              <SessionDetailsEvents />
            </Route>
            <Route exact path={route(PAGES.Sessions) + '/details/:' + sessionid}>
              <Redirect to={sessionDetailsTabParameters(sessionid, SESSION_DETAILS_TABS.OVERVIEW)} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
});

export default SessionDetails;
