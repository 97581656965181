import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import _ from 'lodash';
import { CircularProgress } from '@mui/material';
import { useStyles } from '../../styles/styles';
import StyledCard from '../../components/cards/StyledCard';
import StyledCardContent from '../../components/cards/StyledCardContent';
import StyledCardHeader, { HeaderButtonIcon } from '../../components/cards/StyledCardHeader';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';
import { NameValueRowList } from '../../components/cards/CardRows/NameValueRowList';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import { TitleSubtitleRow } from '../../components/cards/CardRows/TitleSubtitleRow';
import ActionsList from '../../components/other/ActionsList';
import { formatToSloDate } from '../Sessions/SessionsPage';
import credentialStore from '../../state/credentialStore';
import { greenColor } from '../../components/main/react/App';
import ImagesGrid from '../../components/cards/ImageGridCard';

const detailsRows = [{ name: 'type' }, { name: 'name' }, { name: 'createdAt' }, { name: 'isActive' }, { name: 'id' }];
const contactRows = ['phone', 'email'];
const addressRows = ['address', 'zip', 'city', 'state', 'country'];
const assignUserRows = ['name', 'familyName', 'email', 'password', 'confirmPassword'];
const settingsRows = [
  { name: 'canViewWallets', label: 'View wallets' },
  { name: 'canManageLocations', label: 'Manage locations' },
  { name: 'canViewUnassignedChargers', label: 'Unassigned chargers' },
  { name: 'ocppPrefix' },
];

const detailsEditInputs = [
  { name: 'id', type: FormInputTypes.disabledTextField },
  { name: 'type', label: 'Account type', type: FormInputTypes.select, options: ['private', 'company'] },
  { name: 'name', type: FormInputTypes.textField },
  { name: 'createdAt', type: FormInputTypes.textField },
  { name: 'isActive', type: FormInputTypes.switch },
];

const addressEditInputs = [
  { name: 'address', type: FormInputTypes.textField },
  { name: 'zip', type: FormInputTypes.textField },
  { name: 'city', type: FormInputTypes.textField },
  { name: 'state', type: FormInputTypes.textField },
  { name: 'country', type: FormInputTypes.textField },
];

const contactEditInputs = [
  { name: 'phone', type: FormInputTypes.textField },
  { name: 'email', type: FormInputTypes.textField },
];

const assignUserInputs = () => [
  { name: 'name', type: FormInputTypes.textField },
  { name: 'familyName', type: FormInputTypes.textField },
  { name: 'email', type: FormInputTypes.textField },
  { name: 'password', type: FormInputTypes.password },
  { name: 'confirmPassword', type: FormInputTypes.password },
];

const settingsInputs = [
  { name: 'canViewWallets', label: 'View wallets', type: FormInputTypes.switch },
  { name: 'canManageLocations', label: 'Manage locations', type: FormInputTypes.switch },
  { name: 'canViewUnassignedChargers', label: 'Unassigned chargers', type: FormInputTypes.switch },
  { name: 'ocppPrefix', type: FormInputTypes.textField },
];

const CompanyDetailsOverview = observer(({ companyid }: { companyid: string }) => {
  const classes = useStyles();

  const accountProvider = useProvider(undefined, undefined, false);
  const accountUsersProvider = useProvider(() => Api.business.getUsersForAccount(companyid), undefined, false);

  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      accountProvider.setRequest(() => Api.business.getAccount(companyid));
      accountUsersProvider.reloadResource();
    } else {
      accountProvider.setRequest(() => Api.business.getAccount(credentialStore.getAccountId()));
    }

    accountProvider.reloadResource();
  }, [companyid]);

  const [owners, setOwners] = useState([]);
  let userFormValues = null;

  const fetchUserData = async (user) => {
    const resp = await Api.user.getUser(user.userId);
    return resp.data.data;
  };

  const fetchAllUsers = async () => {
    const promises = accountUsersProvider.data.map(fetchUserData);
    const ownerUsers = await Promise.all(promises);
    setOwners(ownerUsers);
  };

  useEffect(() => {
    if (accountProvider.data) {
      if (credentialStore.isUserAdmin) {
        accountUsersProvider.reloadResource();
      }
    }
  }, [accountProvider.data]);

  useEffect(() => {
    if (accountUsersProvider.data !== undefined) {
      if (credentialStore.isUserAdmin) {
        fetchAllUsers();
      }
    }
  }, [accountUsersProvider.data]);

  const confirmEditDetails = (values) => {
    Api.business.patchAccount(companyid, values).then(accountProvider.reloadResource);
  };

  const onClickEditDetails = () => {
    drawerStore.openDrawer({
      label: 'Edit Details',
      inputs: detailsEditInputs,
      clickCallback: () => confirmEditDetails(toJS(drawerStore.values)),
    });

    drawerStore.setValues(accountProvider.data);
  };

  const confirmEditAddress = () => {
    const body = { ...accountProvider.data, ...toJS(drawerStore.values) };

    Api.business.patchAccount(companyid, body).then(accountProvider.reloadResource);
  };

  const handleUserExists = (user: any) => {
    console.log('handleUserExists user:', user);
    drawerStore.openDrawer({
      inputs: [
        {
          name: 'Oops!',
          type: FormInputTypes.textView,
          value:
            'It looks like an account with this email address already exists in our system. \n' +
            'Do you want us to make this account an owner for this company?',
        },
      ],
      label: 'Assign owner',
      buttonLabel: 'Make owner',
      clickCallback: () => {
        Api.business.assignUserToAccount(companyid, user.id, 'owner').then(accountProvider.reloadResource);
      },
    });
  };

  const handleUserDoesNotExist = (values: any) => {
    console.log('handleUserDoesNotExist values:', values);
    Api.user.createUser(values).then((resp) => {
      const data = resp?.data?.data;
      if (data) {
        Api.business.assignUserToAccount(companyid, data.id, 'owner').then(accountProvider.reloadResource);
      }
    });
  };

  const handleUserResponse = (resp: any) => {
    const users = resp?.data?.data?.users;
    console.log('handleUserResponse users:', users);
    if (users && users.length > 0) {
      handleUserExists(users[0]);
    } else {
      console.log(userFormValues);
      handleUserDoesNotExist(userFormValues);
    }
  };

  const confirmAssignUser = () => {
    let values = toJS(drawerStore.values);
    values = _.pick(values, assignUserRows);
    userFormValues = values;
    console.log('setUserFormValues', userFormValues);
    Api.user.listUsers({ email: values.email }).then(handleUserResponse);
  };

  const onClickEditAddress = () => {
    drawerStore.openDrawer({
      label: 'Edit Address',
      inputs: addressEditInputs,
      clickCallback: confirmEditAddress,
    });

    drawerStore.setValues(accountProvider.data);
  };

  const onClickAssignUser = () => {
    drawerStore.openDrawer({
      label: 'Assign User',
      inputs: assignUserInputs(),
      clickCallback: confirmAssignUser,
      buttonLabel: 'Add owner',
    });
  };

  const confirmEditContact = () => {
    let values = toJS(drawerStore.values);
    values = _.pick(values, contactRows);

    Api.business.patchAccount(companyid, values).then(accountProvider.reloadResource);
  };

  const onClickEditContact = () => {
    drawerStore.openDrawer({
      label: 'Edit Contact',
      inputs: contactEditInputs,
      clickCallback: confirmEditContact,
    });

    drawerStore.setValues(accountProvider.data);
  };

  const updateSettings = () => {
    const body = _.pick(
      toJS(drawerStore.values),
      settingsRows.map((row) => row.name),
    );
    Api.business.setAccountSettings(companyid, body).then(accountProvider.reloadResource);
  };

  const onClickEditSettings = () => {
    drawerStore.setValues(accountProvider.data?.settings);
    drawerStore.openDrawer({
      label: 'Update Settings',
      inputs: settingsInputs,
      clickCallback: updateSettings,
    });
  };

  const removeOwner = (userId) => {
    Api.business.removeUserFromAccount(companyid, userId).then(accountProvider.reloadResource);
  };

  if (accountProvider.loading || accountUsersProvider.loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh' }}>
        <CircularProgress style={{ color: greenColor }} />
      </div>
    );
  }

  if (accountProvider.data !== undefined) {
    return (
      <div className={classes.cardRows}>
        <div className={classes.leftCardRow}>
          <StyledCard>
            <StyledCardHeader title="Details" onClick={onClickEditDetails} headerButtonIcon={HeaderButtonIcon.edit} />
            <StyledCardContent>
              <NameValueRowList
                rows={detailsRows}
                values={{ ...accountProvider.data, createdAt: formatToSloDate(accountProvider.data?.createdAt) }}
              />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="Address" onClick={onClickEditAddress} />
            <StyledCardContent>
              <NameValueRowList rows={addressRows} values={accountProvider.data} />
            </StyledCardContent>
          </StyledCard>
          <ImagesGrid entityId={companyid} type="account" />
        </div>

        <div className={classes.rightCardRow}>
          <StyledCard>
            <StyledCardHeader title="Contact" onClick={onClickEditContact} />
            <StyledCardContent>
              <NameValueRowList rows={contactRows} values={accountProvider.data} />
            </StyledCardContent>
          </StyledCard>

          {credentialStore.isUserAdmin && (
            <StyledCard>
              <StyledCardHeader title="Owners" onClick={onClickAssignUser} headerButtonIcon={HeaderButtonIcon.add} />
              <StyledCardContent>
                {owners?.map((owner) => (
                  <TitleSubtitleRow
                    key={owner.id}
                    title={`${owner.name} ${owner.familyName}`}
                    subtitle={owner.email}
                    actions={<ActionsList actions={[{ label: 'Remove', callback: () => removeOwner(owner.id) }]} />}
                  />
                ))}
              </StyledCardContent>
            </StyledCard>
          )}

          {credentialStore.isUserAdmin && (
            <StyledCard>
              <StyledCardHeader title="Settings" onClick={onClickEditSettings} />
              <StyledCardContent>
                <NameValueRowList rows={settingsRows} values={accountProvider.data?.settings} />
              </StyledCardContent>
            </StyledCard>
          )}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
});

export default CompanyDetailsOverview;
