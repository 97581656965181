import { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useFormStyles } from '../../styles/useFormStyles';
import { catalogDetailsTabParameters, catalogDetailsTabRoute, priceListDetailsRoute } from '../../utils/routing';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import dashboardStore from '../../state/dashboardStore';
import CatalogDetailsOverview from './CatalogDetailsOverview';
import { drawerStore } from '../../state/drawerStore';
import { Api } from '../../api';
import { FormInputTypes } from '../../utils/constants';

export const CatalogDetails = observer(() => {
  const formClasses = useFormStyles();
  const { catalogid } = useParams<{ catalogid: string }>();
  const history = useHistory();

  const [priceLists, setPriceLists] = useState([]);
  const [parentCatalog, setParentCatalog] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchPriceLists = () => {
    setIsLoaded(false);
    return Api.catalog.listCatalogs([catalogid]).then((r) => {
      const data = r?.data?.data;
      if (data) {
        setParentCatalog(data?.[0]?.name);
      }
      const childrenData = data?.[0]?.children;
      if (childrenData) {
        setPriceLists(childrenData);
      }
      setIsLoaded(true);
    });
  };

  const createPriceListTabs = (catalogId, childPriceLists) => [
    { id: catalogId, name: parentCatalog },
    ...childPriceLists.map((child) => {
      const childName = child.name;
      const tabName = childName.toUpperCase();
      return { id: child.id, name: tabName };
    }),
  ];

  const priceListTabs = createPriceListTabs(catalogid, priceLists);

  const priceListAddInputs = [
    { name: 'name', type: FormInputTypes.textField },
    { name: 'description', type: FormInputTypes.textField },
  ];
  const confirmAddSubPriceList = (values) => {
    const valuesWithParentId = { ...values, parentId: catalogid };
    Api.catalog.createCatalog(valuesWithParentId).then(() => fetchPriceLists());
  };

  const onClickAddSubPriceList = () => {
    drawerStore.openDrawer({
      label: 'Add price list',
      inputs: priceListAddInputs,
      clickCallback: () => confirmAddSubPriceList(toJS(drawerStore.values)),
    });
  };

  useEffect(() => {
    dashboardStore.appBarLabel = 'Price list details';
  }, [history.location]);

  useEffect(() => {
    Api.catalog.getCatalog(catalogid);
    fetchPriceLists();
  }, [catalogid]);

  const removeChildCatalogAndDependencies = (childPriceId) => {
    Api.catalog
      .removeCatalog(childPriceId)
      .then(() => history.replace(priceListDetailsRoute(catalogid)))
      .then(() => fetchPriceLists());
    Api.charge.deleteSoftCatalogEntries(childPriceId);
    Api.catalog.removeAllCatalogProducts(childPriceId);
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={formClasses.root}>
        <div className={formClasses.verticalRoot}>
          {isLoaded && (
            <ViziTabs
              basePath={catalogDetailsTabParameters(catalogid)}
              tabs={Object.values(priceListTabs)}
              onClickAdd={onClickAddSubPriceList}
            >
              {priceListTabs.map((tab, index) => (
                <ViziTab
                  key={tab.id}
                  label={tab.name}
                  linkTo={
                    index === 0
                      ? catalogDetailsTabParameters(catalogid)
                      : catalogDetailsTabParameters(catalogid, tab.id)
                  }
                  onRemove={() => {
                    removeChildCatalogAndDependencies(tab.id);
                  }}
                  isParentTab={index === 0}
                />
              ))}
            </ViziTabs>
          )}
          <CatalogDetailsOverview fetchPriceLists={fetchPriceLists} />
        </div>
      </div>
    </div>
  );
});
