import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import getCroppedImg from './cropImageUtility';

const ImageCropper = ({ selectedImage, setCroppedImage }) => {
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const aspect = 1;
    const centeredCrop = centerCrop(makeAspectCrop({ unit: '%', width: 90 }, aspect, width, height), width, height);
    setCrop(centeredCrop);
  };

  useDebounceEffect(
    () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);
      }
    },
    100,
    [completedCrop, scale],
  );

  const handleGenerateCroppedImage = async () => {
    if (completedCrop && imgRef.current) {
      try {
        const croppedImageBlob = await getCroppedImg(imgRef.current, completedCrop);
        setCroppedImage(croppedImageBlob);
      } catch (error) {
        console.error('Error generating cropped image: ', `${error}`);
      }
    }
  };

  React.useEffect(() => {
    handleGenerateCroppedImage();
  }, [completedCrop]);

  return (
    <div>
      {!!selectedImage && (
        <ReactCrop
          crop={crop}
          onChange={(_, newCrop) => setCrop(newCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={1}
        >
          <img
            ref={imgRef}
            alt="Crop the img"
            src={selectedImage}
            onLoad={onImageLoad}
            style={{ transform: `scale(${scale})`, maxHeight: '50vh', maxWidth: '100%', objectFit: 'contain' }}
          />
        </ReactCrop>
      )}
      {!!completedCrop && selectedImage && (
        <div>
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: 128,
              height: 128,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageCropper;
