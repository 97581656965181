export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const filterValuesByInputsArray = (valuesMap: any, inputs) => {
  const inputNames = inputs
    .map((inputValue: any) => {
      if (typeof inputValue === 'string') {
        return inputValue;
      }

      if (inputValue.name) {
        return inputValue.name;
      }

      return null;
    })
    .filter((name) => name !== null);

  return Object.keys(valuesMap).reduce((acc, key) => {
    if (inputNames.includes(key)) {
      acc[key] = valuesMap[key];
    }
    return acc;
  }, {});
};

//* * Extracting filename from response headers for csv reports */
export function getFileNameFromResponseHeaders(response): string {
  const headerLine = response.headers['content-disposition'] || response.headers['Content-Disposition'];
  const startFileNameIndex = headerLine.indexOf('"') + 1;
  const endFileNameIndex = headerLine.lastIndexOf('"');
  const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
  return filename;
}

export function downloadCsvFile(data: any, outputFileName: string) {
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', data]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `${outputFileName}`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function copyTextToClipboard(text: string) {
  // @ts-ignore
  if (window.clipboardData !== undefined) {
    // @ts-ignore
    return window.clipboardData.setData('Text', text);
    // @ts-ignore
  } else if (navigator.clipboard !== undefined) {
    // @ts-ignore
    return navigator.clipboard.writeText(text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      return true;
    } catch (e) {
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }

  return false;
}
