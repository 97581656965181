import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useFormStyles } from '../../styles/useFormStyles';
import LocationDetailsOverview from './LocationDetailsOverview';
import LocationDetailsDetails from './LocationDetailsDetails';
import LocationDetailsSessions from './LocationDetailsSessions';
import {
  LOCATION_DETAILS_TABS,
  locationDetailsTabParameters,
  locationDetailsTabRoute,
  route,
} from '../../utils/routing';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import { LocationDetailsPayments } from './LocationDetailsPayments';
import dashboardStore from '../../state/dashboardStore';
import { LocationDetailsAuthorizations } from './LocationDetailsAuthorizations';
import { PAGES } from '../../components/main/MainComponent';
import credentialStore from '../../state/credentialStore';

const LocationDetails = observer(() => {
  const formClasses = useFormStyles();
  const { locationid } = useParams<{ locationid: string }>();
  const history = useHistory();

  useEffect(() => {
    dashboardStore.appBarLabel = 'Location details';
  }, [history.location]);

  return (
    <div style={{ width: '100%' }}>
      <div className={formClasses.root}>
        <div className={formClasses.verticalRoot}>
          <ViziTabs basePath={locationDetailsTabParameters(locationid)} tabs={Object.values(LOCATION_DETAILS_TABS)}>
            <ViziTab
              label={LOCATION_DETAILS_TABS.OVERVIEW}
              linkTo={locationDetailsTabParameters(locationid, LOCATION_DETAILS_TABS.OVERVIEW)}
            />
            <ViziTab
              label={LOCATION_DETAILS_TABS.DETAILS}
              linkTo={locationDetailsTabParameters(locationid, LOCATION_DETAILS_TABS.DETAILS)}
            />
            <ViziTab
              label={LOCATION_DETAILS_TABS.SESSIONS}
              linkTo={locationDetailsTabParameters(locationid, LOCATION_DETAILS_TABS.SESSIONS)}
            />
            {credentialStore.isUserAdmin && (
              <ViziTab
                label={LOCATION_DETAILS_TABS.PAYMENTS}
                linkTo={locationDetailsTabParameters(locationid, LOCATION_DETAILS_TABS.PAYMENTS)}
              />
            )}
            {credentialStore.isUserAdmin && (
              <ViziTab
                label={LOCATION_DETAILS_TABS.AUTHORIZATIONS}
                linkTo={locationDetailsTabParameters(locationid, LOCATION_DETAILS_TABS.AUTHORIZATIONS)}
              />
            )}
          </ViziTabs>

          <Switch>
            <Route path={locationDetailsTabRoute(LOCATION_DETAILS_TABS.OVERVIEW)}>
              <LocationDetailsOverview />
            </Route>
            <Route path={locationDetailsTabRoute(LOCATION_DETAILS_TABS.DETAILS)}>
              <LocationDetailsDetails />
            </Route>
            <Route path={locationDetailsTabRoute(LOCATION_DETAILS_TABS.SESSIONS)}>
              <LocationDetailsSessions />
            </Route>
            <Route path={locationDetailsTabRoute(LOCATION_DETAILS_TABS.PAYMENTS)}>
              <LocationDetailsPayments />
            </Route>
            <Route path={locationDetailsTabRoute(LOCATION_DETAILS_TABS.AUTHORIZATIONS)}>
              <LocationDetailsAuthorizations />
            </Route>
            <Route exact path={route(PAGES.Locations) + '/' + locationid}>
              <Redirect to={locationDetailsTabParameters(locationid, LOCATION_DETAILS_TABS.OVERVIEW)} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
});

export default LocationDetails;
