import _ from 'lodash';

export const gridColumn = (field: string, params: any = {}) => {
  let headerName = params.headerName ? _.startCase(params.headerName) : _.startCase(field);
  if (params.headerName === '' || params.headerName === ' ') {
    headerName = ' ';
  }
  if (params.exactHeaderName) {
    headerName = params.exactHeaderName;
  }

  const isSortable = params.sortableFields ? params.sortableFields.includes(field) : false;

  return {
    field,
    editable: false,
    align: 'left',
    ...params,
    headerName,
    headerAlign: 'left',
    sortable: isSortable,
  };
};
