import { makeAutoObservable, toJS } from 'mobx';
import moment from 'moment';

export class TimeRangeStore {
  private _label?: string;
  private _start?: moment.Moment;
  private _end?: moment.Moment;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.setToLast1Month();
  }

  reset() {
    /* this._label = undefined;
    this._start = undefined;
    this._end = undefined; */

    this.setToLast1Month();
  }

  setToLast1Year() {
    this.setValues({
      label: 'Last 1 year',
      start: moment().subtract(1, 'year').startOf('day'),
      end: moment(),
    });
  }

  setToLast1Month() {
    this.setValues({
      label: 'Last 30 days',
      start: moment().subtract(1, 'month').startOf('day'),
      end: moment(),
    });
  }

  get label() {
    return toJS(this._label);
  }

  get start() {
    return toJS(this._start);
  }

  get end() {
    return toJS(this._end);
  }

  setValues({ label, start, end }: { label: string; start: moment.Moment; end: moment.Moment }) {
    this._label = label;
    this._start = start;
    this._end = end;
  }
}

export const timeRangeStore = new TimeRangeStore();
