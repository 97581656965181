import { PAGES } from '../components/main/MainComponent';

export enum PRICING_TABS {
  PRODUCTS = 'Products',
  PRICE_LISTS = 'Price lists',
  PROMOTIONS = 'Promotions',
}

export enum ACCOUNT_DETAILS_TABS {
  OVERVIEW = 'Overview',
  BILLING = 'Billing',
  VEHICLES = 'Vehicles',
}

export enum LOCATION_DETAILS_TABS {
  OVERVIEW = 'Overview',
  DETAILS = 'Details',
  SESSIONS = 'Sessions',
  PAYMENTS = 'Payments',
  AUTHORIZATIONS = 'Authorizations',
}

export enum USER_DETAILS_TABS {
  OVERVIEW = 'Overview',
  SESSIONS = 'Sessions',
  BILLING = 'Billing',
}

export enum CHARGERS_TABS {
  ALL = 'All',
  UNASSIGNED = 'Unassigned',
  WITHISSUES = 'With Issues',
  UNKNOWN = 'unknown',
  INITIALISATION_CONFIG = 'Initialisation Config',
  OPERATION_CONFIG = 'Operation Config',
}

export enum CHARGER_DETAILS_TABS {
  OVERVIEW = 'Overview',
  SESSIONS = 'Sessions',
}

export enum SESSIONS_TABS {
  ACTIVE = 'Active',
  LAST_24H = 'Last 24h',
  HISTORY = 'History',
}

export enum BILLING_TABS {
  PAYMENTS = 'Payments',
  OPENED_BALANCES = 'Opened balances',
  INVOICES = 'Invoices',
}

export enum SESSION_DETAILS_TABS {
  OVERVIEW = 'overview',
  EVENTS = 'events',
}

export enum COMPANY_DETAILS_TABS {
  OVERVIEW = 'Overview',
  WALLETS = 'Wallets',
}

export enum CATALOG_DETAILS_TABS {
  OVERVIEW = 'Overview',
  RULES = 'Rules',
}

export enum ANALYTICS_TABS {
  BY_LOCATION = 'By Location',
  BY_CHARGER = 'By Charger',
  BY_ACCOUNT = 'By Account',
  BY_USER = 'By User',
  BY_TAG = 'By Tag',
}

export enum ANALYTICS_DETAILS_TABS {
  NO_OF_SESSIONS = 'No of sessions',
  TOTAL_KWH = 'Total kwh',
  NO_OF_USERS = 'No of users',
  TOTAL_TIME = 'Total time',
}

export const strToUrl = (str?: string) => {
  if (!str) return '';
  return str.replaceAll(' ', '-').toLowerCase();
};

export const urlToStr = (url?: string) => {
  if (!url) return '';
  return url.replaceAll('-', ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

export const route = (view: PAGES) => `/${strToUrl(view)}`;

export const pricingTabRoute = (tab?: PRICING_TABS) => {
  switch (tab) {
    case PRICING_TABS.PRODUCTS:
      return strToUrl(`${route(PAGES.Pricing)}/${tab}`);
    case PRICING_TABS.PRICE_LISTS:
      return strToUrl(`${route(PAGES.Pricing)}/${tab}`);
    case PRICING_TABS.PROMOTIONS:
      return strToUrl(`${route(PAGES.Pricing)}/${tab}`);
    default:
      return route(PAGES.Pricing);
  }
};

export const priceListDetailsRoute = (priceListId: string) =>
  strToUrl(`${route(PAGES.Pricing)}/${PRICING_TABS.PRICE_LISTS}/${priceListId}`);

export const sessionTabRoute = (tab?: SESSIONS_TABS) => {
  switch (tab) {
    case SESSIONS_TABS.ACTIVE:
      return strToUrl(`${route(PAGES.Sessions)}/${tab}`);
    case SESSIONS_TABS.LAST_24H:
      return strToUrl(`${route(PAGES.Sessions)}/${tab}`);
    case SESSIONS_TABS.HISTORY:
      return strToUrl(`${route(PAGES.Sessions)}/${tab}`);
    default:
      return route(PAGES.Sessions);
  }
};

export const analyticsTabParameters = (tab?: ANALYTICS_TABS) => {
  switch (tab) {
    case ANALYTICS_TABS.BY_LOCATION:
      return strToUrl(`${route(PAGES.Analytics)}/${tab}`);
    case ANALYTICS_TABS.BY_CHARGER:
      return strToUrl(`${route(PAGES.Analytics)}/${tab}`);
    case ANALYTICS_TABS.BY_ACCOUNT:
      return strToUrl(`${route(PAGES.Analytics)}/${tab}`);
    case ANALYTICS_TABS.BY_USER:
      return strToUrl(`${route(PAGES.Analytics)}/${tab}`);
    case ANALYTICS_TABS.BY_TAG:
      return strToUrl(`${route(PAGES.Analytics)}/${tab}`);
    default:
      return route(PAGES.Analytics);
  }
};

export const billingTabRoute = (tab?: BILLING_TABS) => {
  switch (tab) {
    case BILLING_TABS.PAYMENTS:
      return strToUrl(`${route(PAGES.Billing)}/${tab}`);
    case BILLING_TABS.OPENED_BALANCES:
      return strToUrl(`${route(PAGES.Billing)}/${tab}`);
    case BILLING_TABS.INVOICES:
      return strToUrl(`${route(PAGES.Billing)}/${tab}`);
    default:
      return route(PAGES.Billing);
  }
};

export const chargersTabRoute = (tab?: CHARGERS_TABS) => {
  switch (tab) {
    case CHARGERS_TABS.ALL:
      return strToUrl(`${route(PAGES.Chargers)}/${tab}`);
    case CHARGERS_TABS.UNASSIGNED:
      return strToUrl(`${route(PAGES.Chargers)}/${tab}`);
    case CHARGERS_TABS.WITHISSUES:
      return strToUrl(`${route(PAGES.Chargers)}/${tab}`);
    case CHARGERS_TABS.UNKNOWN:
      return strToUrl(`${route(PAGES.Chargers)}/${tab}`);
    case CHARGERS_TABS.INITIALISATION_CONFIG:
      return strToUrl(`${route(PAGES.Chargers)}/${tab}`);
    case CHARGERS_TABS.OPERATION_CONFIG:
      return strToUrl(`${route(PAGES.Chargers)}/${tab}`);
    default:
      return route(PAGES.Chargers);
  }
};

export const locationDetailsTabRoute = (tab: LOCATION_DETAILS_TABS) => {
  switch (tab) {
    case LOCATION_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Locations)}/:locationid/${tab}`);
    case LOCATION_DETAILS_TABS.DETAILS:
      return strToUrl(`${route(PAGES.Locations)}/:locationid/${tab}`);
    case LOCATION_DETAILS_TABS.SESSIONS:
      return strToUrl(`${route(PAGES.Locations)}/:locationid/${tab}`);
    case LOCATION_DETAILS_TABS.PAYMENTS:
      return strToUrl(`${route(PAGES.Locations)}/:locationid/${tab}`);
    case LOCATION_DETAILS_TABS.AUTHORIZATIONS:
      return strToUrl(`${route(PAGES.Locations)}/:locationid/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Locations)}/:locationid`);
  }
};

export const locationDetailsTabParameters = (locationId: string, tab?: LOCATION_DETAILS_TABS) => {
  switch (tab) {
    case LOCATION_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Locations)}/${locationId}/${tab}`);
    case LOCATION_DETAILS_TABS.DETAILS:
      return strToUrl(`${route(PAGES.Locations)}/${locationId}/${tab}`);
    case LOCATION_DETAILS_TABS.SESSIONS:
      return strToUrl(`${route(PAGES.Locations)}/${locationId}/${tab}`);
    case LOCATION_DETAILS_TABS.PAYMENTS:
      return strToUrl(`${route(PAGES.Locations)}/${locationId}/${tab}`);
    case LOCATION_DETAILS_TABS.AUTHORIZATIONS:
      return strToUrl(`${route(PAGES.Locations)}/${locationId}/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Locations)}/${locationId}`);
  }
};

export const userDetailsTabRoute = (tab?: USER_DETAILS_TABS) => {
  switch (tab) {
    case USER_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Users)}/:userid/${tab}`);
    case USER_DETAILS_TABS.SESSIONS:
      return strToUrl(`${route(PAGES.Users)}/:userid/${tab}`);
    case USER_DETAILS_TABS.BILLING:
      return strToUrl(`${route(PAGES.Users)}/:userid/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Users)}/:userid`);
  }
};

export const companyDetailsRoute = (tab?: COMPANY_DETAILS_TABS) => {
  switch (tab) {
    case COMPANY_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Companies)}/details/:companyid/${tab}`);
    case COMPANY_DETAILS_TABS.WALLETS:
      return strToUrl(`${route(PAGES.Companies)}/details/:companyid/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Companies)}/details/:companyid`);
  }
};

export const companyDetailsParameters = (companyId: string, tab?: COMPANY_DETAILS_TABS) => {
  switch (tab) {
    case COMPANY_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Companies)}/details/${companyId}/${tab}`);
    case COMPANY_DETAILS_TABS.WALLETS:
      return strToUrl(`${route(PAGES.Companies)}/details/${companyId}/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Companies)}/details/${companyId}`);
  }
};

export const companyPageRoute = (tab?: COMPANY_DETAILS_TABS) => {
  switch (tab) {
    case COMPANY_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Company)}/${tab}`);
    case COMPANY_DETAILS_TABS.WALLETS:
      return strToUrl(`${route(PAGES.Company)}/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Company)}`);
  }
};

export const walletDetailsRoute = () => strToUrl(`${route(PAGES.Wallets)}/:walletid`);
export const walletDetailsParameters = (walletId: string) => strToUrl(`${route(PAGES.Wallets)}/${walletId}`);

export const userDetailsTabParameters = (userId: string, tab?: USER_DETAILS_TABS) => {
  switch (tab) {
    case USER_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Users)}/${userId}/${tab}`);
    case USER_DETAILS_TABS.SESSIONS:
      return strToUrl(`${route(PAGES.Users)}/${userId}/${tab}`);
    case USER_DETAILS_TABS.BILLING:
      return strToUrl(`${route(PAGES.Users)}/${userId}/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Users)}/${userId}`);
  }
};

export const chargerDetailsTabRoute = (tab?: CHARGER_DETAILS_TABS) => {
  switch (tab) {
    case CHARGER_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Chargers)}/details/:chargerid/${tab}`);
    case CHARGER_DETAILS_TABS.SESSIONS:
      return strToUrl(`${route(PAGES.Chargers)}/details/:chargerid/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Chargers)}/details/:chargerid`);
  }
};

export const chargerDetailsTabParameters = (chargerId: string, tab?: CHARGER_DETAILS_TABS) => {
  switch (tab) {
    case CHARGER_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Chargers)}/details/${chargerId}/${tab}`);
    case CHARGER_DETAILS_TABS.SESSIONS:
      return strToUrl(`${route(PAGES.Chargers)}/details/${chargerId}/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Chargers)}/details/${chargerId}`);
  }
};

export const sessionDetailsTabRoute = (tab?: SESSION_DETAILS_TABS) => {
  switch (tab) {
    case SESSION_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Sessions)}/details/:sessionid/${tab}`);
    case SESSION_DETAILS_TABS.EVENTS:
      return strToUrl(`${route(PAGES.Sessions)}/details/:sessionid/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Sessions)}/details/:sessionid`);
  }
};

export const sessionDetailsTabParameters = (sessionId: string, tab?: SESSION_DETAILS_TABS) => {
  switch (tab) {
    case SESSION_DETAILS_TABS.OVERVIEW:
      return strToUrl(`${route(PAGES.Sessions)}/details/${sessionId}/${tab}`);
    case SESSION_DETAILS_TABS.EVENTS:
      return strToUrl(`${route(PAGES.Sessions)}/details/${sessionId}/${tab}`);
    default:
      return strToUrl(`${route(PAGES.Sessions)}/details/${sessionId}`);
  }
};

export const catalogDetailsTabRoute = () => `${priceListDetailsRoute(':catalogid')}/:childid?`;

export const catalogDetailsTabParameters = (catalogId: string, childId?: string) => {
  if (childId) {
    return strToUrl(`${priceListDetailsRoute(catalogId)}/${childId}`);
  }
  return strToUrl(`${priceListDetailsRoute(catalogId)}`);
};

export const analyticsPageRoute = () => route(PAGES.Analytics);

export const analyticsPageSubRoute = () => strToUrl(`${route(PAGES.Analytics)}/:tab`);

export const analyticsPageParameters = (tab?: string) => strToUrl(`${route(PAGES.Analytics)}/${tab}`);

export const analyticsDetailsRoute = () => strToUrl(`${route(PAGES.Analytics)}/:tab/:id`);

export const analyticsDetailsSubRoute = () => strToUrl(`${route(PAGES.Analytics)}/:tab/:id/:subtab`);

export const analyticsDetailsParameters = (tab: string, id: string, subtab: string) =>
  strToUrl(`${route(PAGES.Analytics)}/${tab}/${id}/${subtab}`);
