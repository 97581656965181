import { useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useFormStyles } from '../../styles/useFormStyles';
import { CHARGER_DETAILS_TABS, chargerDetailsTabParameters, chargerDetailsTabRoute, route } from '../../utils/routing';
import dashboardStore from '../../state/dashboardStore';
import ChargerDetailsOverview from './ChargerDetailsOverview';
import ChargerDetailsSessions from './ChargerDetailsSessions';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import { PAGES } from '../../components/main/MainComponent';

const ChargerDetails = observer(() => {
  const formClasses = useFormStyles();

  const { chargerid } = useParams<{ chargerid: string }>();

  useEffect(() => {
    dashboardStore.appBarLabel = 'Charge point details';
  });

  return (
    <div style={{ width: '100%' }}>
      <div className={formClasses.root}>
        <div className={formClasses.verticalRoot}>
          <ViziTabs basePath={chargerDetailsTabParameters(chargerid)} tabs={Object.values(CHARGER_DETAILS_TABS)}>
            <ViziTab
              label={CHARGER_DETAILS_TABS.OVERVIEW}
              linkTo={chargerDetailsTabParameters(chargerid, CHARGER_DETAILS_TABS.OVERVIEW)}
            />
            <ViziTab
              label={CHARGER_DETAILS_TABS.SESSIONS}
              linkTo={chargerDetailsTabParameters(chargerid, CHARGER_DETAILS_TABS.SESSIONS)}
            />
          </ViziTabs>

          <Switch>
            <Route path={chargerDetailsTabRoute(CHARGER_DETAILS_TABS.OVERVIEW)}>
              <ChargerDetailsOverview />
            </Route>
            <Route path={chargerDetailsTabRoute(CHARGER_DETAILS_TABS.SESSIONS)}>
              <ChargerDetailsSessions />
            </Route>
            <Route exact path={route(PAGES.Chargers) + '/details/' + chargerid}>
              <Redirect to={chargerDetailsTabParameters(chargerid, CHARGER_DETAILS_TABS.OVERVIEW)} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
});

export default ChargerDetails;
