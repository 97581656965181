export enum PaymentStatus {
    CREATED = 'created',
    VERIFYING = 'verifying',
    CAPTURED = 'captured',
    FAILED = 'failed',
    AUTH_REQUESTED = 'auth_requested',
    CANCELLED = 'cancelled',
    REFUNDED = 'refunded',
    VOID = 'void',
    DUPLICATE = 'duplicate',
    NOT_FOUND = 'not_found',
    RESERVED = 'reserved',
    UNSETTLED = 'unsettled',
}
