import { useEffect, useState } from 'react';
import { ListItem, Typography } from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import ActionsMenu from '../../components/other/ActionsMenu';
import TextLink from '../../components/other/TextLink';
import { ENABLED_STATUS, PriceList } from '../../types/PriceList';
import { catalogDetailsTabParameters } from '../../utils/routing';
import { EnabledStatusBadge } from '../../components/other/CellBadges';
import { drawerStore } from '../../state/drawerStore';
import { gridColumn } from '../../utils/gridUtils';
import { Api } from '../../api';
import { FormInputTypes } from '../../utils/constants';
import { formatToSloDate } from '../Sessions/SessionsPage';

const priceListAddInputs = [
  { name: 'name', type: FormInputTypes.textField },
  { name: 'description', type: FormInputTypes.textField },
  { name: 'isActive', type: FormInputTypes.switch },
];

// Catalog === Price List
export const CatalogsPage = observer(() => {
  const catalogsProvider = usePaginatedProvider(undefined, undefined, false);
  const [catalogData, setCatalogData] = useState([]);
  const [sortableFields, setSortableFields] = useState([]);
  useEffect(() => {
    catalogsProvider.setRequest((offset, sortBy, sortOrder) =>
      Api.catalog.listCatalogs(undefined, { skip: offset, take: 20, sortBy, sortOrder }),
    );

    catalogsProvider.setOnFulfilled((data, _count, sortable) => {
      const parents = data.filter((e) => e.parentId == null);
      setCatalogData(parents);

      if (sortable) {
        setSortableFields(sortable);
      }
    });

    catalogsProvider.reloadResource();
  }, []);

  const confirmAddPriceList = (values) => {
    Api.catalog.createCatalog(values).then(catalogsProvider.reloadResource);
  };

  const onClickAddProduct = () => {
    drawerStore.openDrawer({
      label: 'Add price list',
      inputs: priceListAddInputs,
      clickCallback: () => confirmAddPriceList(toJS(drawerStore.values)),
    });
  };

  const onClickRemoveProduct = (priceList: PriceList) => {
    if (priceList.children && priceList.children.length > 0) {
      console.error('Cannot delete price lists that contain a sub-price list.');
    } else {
      Api.catalog.removeCatalog(priceList.id).then(catalogsProvider.reloadResource);
    }
  };

  const catalogActions = (params: GridRowParams) => (
    <ActionsMenu>
      <ListItem button onClick={() => onClickRemoveProduct(params.row as PriceList)}>
        <Typography style={{ width: '100%' }}>Remove</Typography>
      </ListItem>
    </ActionsMenu>
  );

  const catalogColumns = [
    gridColumn('name', {
      flex: 2,
      sortableFields,
      renderCell: (params: GridRowParams) => {
        const { id, name } = params.row;
        return <TextLink to={catalogDetailsTabParameters(id)} label={name} />;
      },
    }),
    gridColumn('priceListCount', {
      flex: 2,
      headerName: 'Subprice List Count',
      renderCell: (params: GridRowParams) => {
        const { priceListCount } = params.row;
        return <div>{priceListCount === 0 ? '-' : priceListCount}</div>;
      },
      sortableFields,
    }),
    gridColumn('createdAt', {
      flex: 2,
      sortableFields,
      renderCell: (params: GridRowParams) => {
        const { createdAt } = params.row;
        return <div>{formatToSloDate(createdAt)}</div>;
      },
    }),
    gridColumn('status', {
      flex: 2,
      sortableFields,
      renderCell: (params: GridRowParams) => (
        <EnabledStatusBadge status={params.row.isActive ? ENABLED_STATUS.ENABLED : ENABLED_STATUS.DISABLED} />
      ),
    }),
    gridColumn('actions', {
      width: 150,
      renderCell: (params: GridRowParams) => catalogActions(params),
      align: 'center',
      headerAlign: 'center',
    }),
  ];

  return (
    <VMPaginatedGrid
      rows={catalogData}
      columns={catalogColumns}
      count={catalogsProvider.count}
      onPageOffsetChange={catalogsProvider.onPageChange}
      onSortTypeChange={catalogsProvider.setSort}
      label="Price list groups"
      onClickAdd={onClickAddProduct}
    />
  );
});

export default CatalogsPage;
