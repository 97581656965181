import { Divider, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { greenTextColor } from '../../main/react/App';

export const useCardStyles = makeStyles(
  {
    titleSubtitleContainer: {
      alignItems: 'center',
      marginTop: 16,
      marginBottom: 16,
    },
    imageLabelContainer: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 16,
      marginBottom: 16,
    },
    title: {
      fontSize: 16,
    },
    subtitle: {
      fontSize: 12,
      color: 'gray',
    },
  },
  { index: 1 },
);

export interface InfoRowProps {
  title: string;
  titleLink?: string;
  subtitle: string;
  subtitle1?: string;
  actions?: any;
  additionalContent?: React.ReactNode;
  order?: string;
  onClick?: () => void;
}

export const TitleSubtitleRow: FunctionComponent<InfoRowProps> = ({
  title,
  titleLink,
  subtitle,
  subtitle1,
  actions,
  additionalContent,
  order,
  onClick,
}) => {
  const cardClasses = useCardStyles();

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {order && <div style={{ marginRight: '15px' }}> {`${order}.`}</div>}
        <div className={cardClasses.titleSubtitleContainer} style={{ flexGrow: 1 }}>
          {titleLink && (
            <Link to={titleLink} style={{ textDecoration: 'none', color: greenTextColor, fontSize: 16 }}>
              {title}
            </Link>
          )}
          {!titleLink && !onClick && <Typography className={cardClasses.title}>{title}</Typography>}
          {!titleLink && onClick && (
            <Typography
              className={cardClasses.title}
              style={{ color: greenTextColor, cursor: 'pointer' }}
              onClick={onClick}
            >
              {title}
            </Typography>
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography className={cardClasses.subtitle} style={{ paddingRight: '10px' }}>
              {subtitle}
            </Typography>

            <Typography className={cardClasses.subtitle} style={{ textAlign: 'right', paddingLeft: '10px' }}>
              {subtitle1}
            </Typography>
          </div>
        </div>
        {additionalContent && <div style={{ marginLeft: '10px' }}>{additionalContent}</div>}
        <div style={{ marginLeft: '10px' }}> {actions}</div>
      </div>
      <Divider />
    </div>
  );
};
