import { useEffect } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useFormStyles } from '../../styles/useFormStyles';
import { FormInputTypes } from '../../utils/constants';
import TextLink from '../../components/other/TextLink';
import { COMPANY_DETAILS_TABS, companyPageRoute, route } from '../../utils/routing';
import { gridColumn } from '../../utils/gridUtils';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import CompanyDetailsOverview from './CompanyDetailsOverview';
import CompanyDetailsWallets from './CompanyDetailsWallets';
import dashboardStore from '../../state/dashboardStore';
import { PAGES } from '../../components/main/MainComponent';
import credentialStore from '../../state/credentialStore';

const detailsEditInputs = [
  { name: 'Name' },
  { name: 'Address' },
  { name: 'Zip code' },
  { name: 'City' },
  { name: 'Country' },
];
const contactsEditInputs = [
  { name: 'Type', type: FormInputTypes.switch },
  { name: 'Owner' },
  { name: 'Company' },
  { name: 'Responsible person' },
  { name: 'Phone' },
  { name: 'Email' },
];

export const CompanyPage = observer(() => {
  const formClasses = useFormStyles();

  const history = useHistory();
  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      history.push(route(PAGES.Dashboard));
    }
  }, [history.location]);

  useEffect(() => {
    dashboardStore.appBarLabel = 'Company details';
  }, [history.location]);

  const chargersColumns = [
    gridColumn('name', {
      flex: 1,
      renderCell: (params: GridRowParams) => (
        <TextLink to={`/${PAGES.Chargers}/${params.row.id}`} label={params.row.name} />
      ),
    }),
    gridColumn('address', {
      flex: 1,
      headerName: 'Location',
    }),
    gridColumn('points', { flex: 1 }),
    gridColumn('model', { flex: 1 }),
  ];

  if (credentialStore.selectedAccount) {
    if (!credentialStore.canViewWallets()) {
      if (history.location.pathname !== route(PAGES.Company)) {
        return <Redirect to={route(PAGES.Company)} />;
      }
      return <CompanyDetailsOverview companyid={credentialStore.getAccountId()} />;
    }
  }

  console.log('compapy page location ', history.location.pathname);
  console.log('companyPageRoute(COMPANY_DETAILS_TABS.WALLETS) ', companyPageRoute(COMPANY_DETAILS_TABS.WALLETS));
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <div className={formClasses.root}>
          <div className={formClasses.verticalRoot}>
            <ViziTabs basePath={route(PAGES.Company)} tabs={Object.values(COMPANY_DETAILS_TABS)}>
              <ViziTab label={COMPANY_DETAILS_TABS.OVERVIEW} linkTo={companyPageRoute(COMPANY_DETAILS_TABS.OVERVIEW)} />
              <ViziTab label={COMPANY_DETAILS_TABS.WALLETS} linkTo={companyPageRoute(COMPANY_DETAILS_TABS.WALLETS)} />
            </ViziTabs>

            <Switch>
              <Route path={companyPageRoute(COMPANY_DETAILS_TABS.OVERVIEW)}>
                <CompanyDetailsOverview companyid={credentialStore.getAccountId()} />
              </Route>
              <Route path={companyPageRoute(COMPANY_DETAILS_TABS.WALLETS)}>
                <CompanyDetailsWallets companyid={credentialStore.getAccountId()} />
              </Route>
              <Route path={route(PAGES.Company)}>
                <Redirect to={companyPageRoute(COMPANY_DETAILS_TABS.OVERVIEW)} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
});
