/* eslint-disable react/jsx-curly-newline */
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@mui/material';
import { CHARGER_DETAILS_TABS, chargerDetailsTabParameters } from '../../utils/routing';
import TextLink from '../other/TextLink';
import { useFormStyles } from '../../styles/useFormStyles';
import { FormInputTypes } from '../../utils/constants';
import FormDropzone from './FormDropzone';
import GoogleAddressAutocomplete, { LocationAutocompleteResponse } from '../google/GoogleAddressAutocomplete';
import { drawerStore, getInitialFormValues } from '../../state/drawerStore';
import { YearMonthPicker } from './YearMonthPicker';
import { FormDatePicker } from './FormDatePicker';
import { FormTimePicker } from './FormTimePicker';
import { FormJsonView } from './FormJsonView';
import { UserEmailAutocomplete } from './UserEmailAutocomplete';
import { AccountNameAutocomplete } from './AccountNameAutocomplete';
import { MultiAutocomplete } from './MultiAutocomplete';
import { FormTextToQRCodeVisualizer } from './FormTextToQRCodeVisualizer';
import { FormPugCart } from './FormPugCart';
import { FormTimeRangePresets } from './FormTimeRangePresets';
import { EditableJsonView } from './EditableJsonView';

const DrawerContent = observer(() => {
  const formClasses = useFormStyles();

  const updateInputValue = (newVal: any, key: string) => {
    const newValues = { ...drawerStore.values };
    newValues[key] = newVal;
    drawerStore.setValues(newValues);
  };

  const handleTextInputChange = (event: any, inputName: string) => {
    updateInputValue(event.target.value, inputName);
  };

  const handleTextWithPrefixInputChange = (event: any, inputName: string, prefix: string) => {
    const value = event.target.value;
    const valueWithPrefix = prefix + value;
    updateInputValue(value, inputName);
    updateInputValue(valueWithPrefix, inputName + '_with_prefix');
  };

  const handleAutocompleteAddressChange = (address: any, locationResponse: LocationAutocompleteResponse) => {
    const newValues = {
      address: locationResponse.address || '',
      latitude: locationResponse.lat.toString() || '',
      longitude: locationResponse.lng.toString() || '',
      city: locationResponse.city || '',
      zip: locationResponse.zip || '',
      country: locationResponse.country || '',
    };

    drawerStore.setValues({ ...drawerStore.values, ...newValues });
  };

  if (!drawerStore.values) {
    drawerStore.setValues(getInitialFormValues(drawerStore.inputs));
  }

  const displayDate = (dateStr) => {
    const date = new Date(dateStr);
    const monthName = date.toLocaleString('default', { month: 'long' });
    return `${monthName} ${date.getFullYear()}`;
  };

  return (
    <div style={{ width: '100%' }}>
      {drawerStore.inputs?.map((input: any, inputIdx) => {
        const { name, type, options, condition, value, format, inputChangeCallback, defaultOptions, prefix } = input;
        let { label } = input;
        if (label === undefined) {
          label = name;
        }
        label = _.startCase(label);

        const processedValues = drawerStore.values;
        Object.keys(processedValues).forEach((key) => {
          if (processedValues[key] === null) {
            processedValues[key] = undefined;
          }
        });

        if (value !== undefined && !processedValues[name]) {
          processedValues[name] = value;
        }

        if (condition && !condition(drawerStore.values)) {
          return <div key={`${name}_${inputIdx}`} />;
        }

        const key = `${name}_${inputIdx}`;

        const filterListName = `${name}_filterList`;

        const mapOption = (option: any, index) => {
          let _value = '';
          let _label = '';
          if (typeof option === 'string') {
            _value = option;
            _label = option;
          } else if (option.value) {
            _value = option.value;
            _label = option.label;
          }

          return (
            <MenuItem
              value={_value}
              key={`option_${index}`}
              selected={processedValues[name] === _value}
              onClick={() => updateInputValue(_value, name)}
            >
              {_label}
            </MenuItem>
          );
        };

        switch (type) {
          case undefined:
          case FormInputTypes.miniTitle:
            return (
              <div key={key} className={formClasses.formDrawerInput} style={{ marginTop: '2em' }}>
                <Typography style={{ width: '100%' }}>{label}</Typography>
              </div>
            );
          case FormInputTypes.textField:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <TextField
                  label={label}
                  fullWidth
                  autoComplete="new-password"
                  value={processedValues[name] || ''}
                  onChange={(e) => handleTextInputChange(e, name)}
                />
              </div>
            );
          case FormInputTypes.textFieldWithPrefix:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <TextField
                  label={label}
                  fullWidth
                  autoComplete="new-password"
                  value={processedValues[name] || ''}
                  onChange={(e) => handleTextWithPrefixInputChange(e, name, prefix)}
                  InputProps={{
                    startAdornment: (
                      <Typography>
                        <span style={{ whiteSpace: 'pre' }}>{prefix}</span>
                      </Typography>
                    ),
                  }}
                />
              </div>
            );
          case FormInputTypes.textButton:
            return (
              <div
                key={key}
                className={formClasses.formDrawerInput}
                style={{
                  display: 'flex',
                  justifyContent: input.alignment || 'flex-end',
                  marginTop: '1em',
                }}
              >
                <Button
                  onClick={() => {
                    if (input.onClick) {
                      input.onClick();
                    }
                  }}
                  style={{
                    color: '#00BD89',
                    backgroundColor: 'transparent',
                    padding: '0.5em 1em',
                  }}
                >
                  <Typography>{label}</Typography>
                </Button>
              </div>
            );
          case FormInputTypes.number:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <TextField
                  label={label}
                  fullWidth
                  type="number"
                  autoComplete="new-password"
                  value={processedValues[name] || ''}
                  onChange={(e) => handleTextInputChange(e, name)}
                />
              </div>
            );
          case FormInputTypes.textView:
            return (
              <div
                key={key}
                className={formClasses.formDrawerInput}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Typography style={{}}>{label}</Typography>
                <Typography style={{ marginTop: '0.5em', whiteSpace: 'pre' }}>{processedValues[name]}</Typography>
              </div>
            );
          case FormInputTypes.textLinkView:
            return (
              <div key={key} className={formClasses.formDrawerInput} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>{label}</Typography>
                <div style={{ marginTop: '0.5em' }}>
                  {options?.map((option, index) => (
                    <div key={index} style={{ marginBottom: '1em' }}>
                      <TextLink
                        to={chargerDetailsTabParameters(option.bottomRow, CHARGER_DETAILS_TABS.OVERVIEW)}
                        label={option.topRow}
                      />
                      <Typography style={{ color: '#9a9a9a', fontSize: 14, fontWeight: 300 }}>
                        {option.bottomRow}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            );
          case FormInputTypes.titleAndValue:
            return (
              <div key={key} className={formClasses.formDrawerInput} style={{ paddingTop: 8, paddingBottom: 8 }}>
                <Typography style={{ width: '100%', fontWeight: '300', paddingBottom: 4 }}>{label}</Typography>
                <Typography style={{ width: '100%' }}>{processedValues[name] || '/'}</Typography>
                <Divider />
              </div>
            );
          case FormInputTypes.password:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <TextField
                  label={label}
                  fullWidth
                  type="password"
                  autoComplete="off"
                  value={processedValues[name] || ''}
                  onChange={(e) => handleTextInputChange(e, name)}
                />
              </div>
            );
          case FormInputTypes.multilineTextField:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <TextField
                  label={label}
                  fullWidth
                  multiline
                  rows={4}
                  value={processedValues[name] || ''}
                  onChange={(e) => handleTextInputChange(e, name)}
                />
              </div>
            );
          case FormInputTypes.disabledTextField:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <TextField
                  label={label}
                  disabled
                  fullWidth
                  value={processedValues[name] || ''}
                  onChange={(e) => handleTextInputChange(e, name)}
                />
              </div>
            );
          case FormInputTypes.select:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <TextField select label={label} value={processedValues[name] || ''} fullWidth>
                  {options.map(mapOption)}
                </TextField>
              </div>
            );
          case FormInputTypes.customRenderSelect:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <Select
                  labelId={name + '_label'}
                  label={label}
                  value={processedValues[name] || ''}
                  fullWidth
                  renderValue={(_value) => {
                    if (format === 'date') {
                      const dateStr = _value as string;
                      return <MenuItem>{displayDate(dateStr)}</MenuItem>;
                    }

                    return <MenuItem>{_value}</MenuItem>;
                  }}
                >
                  {options.map(mapOption)}
                </Select>
              </div>
            );
          case FormInputTypes.yearMonthPicker:
            return (
              <YearMonthPicker
                key={key}
                processedValues={processedValues}
                name={name}
                updateInputValue={updateInputValue}
                pickerType={input.pickerType}
              />
            );
          case FormInputTypes.datePicker:
            return (
              <FormDatePicker
                key={key}
                processedValues={processedValues}
                name={name}
                updateInputValue={updateInputValue}
                pickerType={input.pickerType}
                label={label}
              />
            );
          case FormInputTypes.datePresets:
            return <FormTimeRangePresets key={key} updateInputValue={updateInputValue} />;
          case FormInputTypes.time:
            return (
              <FormTimePicker
                key={key}
                processedValues={processedValues}
                name={name}
                updateInputValue={updateInputValue}
                label={label}
              />
            );
          case FormInputTypes.jsonView:
            return <FormJsonView key={key} data={processedValues[name] || undefined} />;
          case FormInputTypes.editableJsonView:
            return <EditableJsonView name={name} key={key} data={processedValues[name] || undefined} />;
          case FormInputTypes.multilineSelect:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <TextField label={label} value={processedValues[name] || ''} fullWidth select>
                  {options.map((option: any, index) => (
                    <MenuItem
                      value={option.optionName}
                      key={`option_${index}`}
                      selected={processedValues[name] === option.optionName}
                      onClick={() => updateInputValue(option.optionName, name)}
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                    >
                      <Typography>{option.topRow}</Typography>
                      <Typography style={{ color: '#9a9a9a', fontSize: 14, fontWeight: 300 }}>
                        {option.bottomRow}
                      </Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            );
          case FormInputTypes.filterListSelect:
            if (processedValues[filterListName] === undefined) {
              processedValues[filterListName] = defaultOptions;
            }

            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <Autocomplete
                  fullWidth
                  disableClearable
                  disablePortal
                  options={options || []}
                  getOptionLabel={(option: any) => option.optionName || ''}
                  onChange={(e, option) => {
                    const list = processedValues[filterListName];
                    let newList = [
                      { optionName: option.optionName, topRow: option.topRow, bottomRow: option.bottomRow },
                    ];
                    if (list !== undefined) {
                      newList = [...list, ...newList];
                    }
                    updateInputValue(newList, filterListName);
                  }}
                  inputValue={processedValues[name] || ''}
                  renderOption={(option) => (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                      <Typography>{option.topRow}</Typography>
                      <Typography style={{ color: '#9a9a9a', fontSize: 14, fontWeight: 300 }}>
                        {option.bottomRow}
                      </Typography>
                    </div>
                  )}
                  filterOptions={(allOptions: any[], state) =>
                    allOptions?.filter((option) => {
                      const list = processedValues[filterListName];
                      if (list !== undefined) {
                        const sameEl = list.find((el) => el.optionName === option.optionName);
                        return sameEl === undefined;
                      } else {
                        return true;
                      }
                    })
                  }
                  onInputChange={(e, _value, reason) => {
                    updateInputValue(_value, name);
                    if (reason === 'reset') {
                      updateInputValue('', name);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={input.filter?.label} />}
                />
                <div>
                  {processedValues[filterListName]?.map((option) => (
                    <div
                      key={option.optionName}
                      style={{
                        marginTop: '1em',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingRight: 0,
                        marginRight: 0,
                        width: '100%',
                      }}
                    >
                      <div>
                        <Typography>{option.topRow}</Typography>
                        <Typography style={{ color: '#9a9a9a', fontSize: 14, fontWeight: 300 }}>
                          {option.bottomRow}
                        </Typography>
                      </div>
                      <div>
                        <IconButton
                          style={{ paddingRight: 0, marginRight: 0 }}
                          onClick={() => {
                            const list = processedValues[filterListName];
                            const item = list.find((el) => el.optionName === option.optionName);
                            list.splice(list.indexOf(item), 1);
                            updateInputValue(filterListName, { ...list });
                          }}
                        >
                          <CloseIcon style={{ paddingRight: 0, marginRight: 0 }} />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          case FormInputTypes.userEmailAutocomplete:
            return (
              <UserEmailAutocomplete
                key={key}
                divKey={key}
                options={options}
                updateInputValue={updateInputValue}
                inputChangeCallback={inputChangeCallback}
              />
            );
          case FormInputTypes.accountNameAutocomplete:
            return (
              <AccountNameAutocomplete
                key={key}
                divKey={key}
                options={options}
                updateInputValue={updateInputValue}
                inputChangeCallback={inputChangeCallback}
              />
            );
          case FormInputTypes.image:
            return <FormDropzone key={key} />;
          case FormInputTypes.googleAddressAutocomplete:
            return (
              <GoogleAddressAutocomplete
                key={key}
                onAddressChange={handleAutocompleteAddressChange}
                editInputValue={processedValues[name]}
              />
            );
          case FormInputTypes.switch:
            return (
              <div key={key} className={clsx(formClasses.switchRootContainer, formClasses.formDrawerInput)}>
                <div className={formClasses.switchItemContainer}>
                  <Typography>{label}</Typography>
                </div>
                <div className={formClasses.switchItemContainer}>
                  <Switch
                    checked={processedValues[name] === true}
                    onChange={(event) => updateInputValue(event.target.checked, name)}
                    name="enabled"
                    color="primary"
                  />
                </div>
              </div>
            );
          case FormInputTypes.checkbox:
            return (
              <div key={key} className={clsx(formClasses.switchRootContainer, formClasses.formDrawerInput)}>
                <div className={formClasses.switchItemContainer} style={{ width: '100%' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={processedValues[name] === true}
                        onChange={(event) => updateInputValue(event.target.checked, name)}
                        name="enabled"
                        color="primary"
                      />
                    }
                    label={label}
                  />
                  <Divider />
                </div>
              </div>
            );
          case FormInputTypes.radioButton:
            return (
              <div key={key} className={formClasses.formDrawerInput}>
                <Typography>{label}</Typography>
                <RadioGroup
                  name={name}
                  value={processedValues[name] || ''}
                  onChange={(e) => updateInputValue(e.target.value, name)}
                >
                  {options.map((option, index) => {
                    const optionKey = `radio_${index}`;
                    const formControlLabel = <FormControlLabel
                      key={optionKey}
                      value={option.value}
                      control={<Radio style={{ color: '#00BD89' }} />}
                      label={option.label}
                    />;
                    if (!option.description) {
                      return formControlLabel;
                    } else {
                      return (
                        <div style={{ marginTop: '.5em' }} key={`div_${optionKey}`}>
                          {formControlLabel}
                          <Typography style={{ marginLeft: '2rem', color: '#757575' }}>{option.description}</Typography>
                          <hr style={{ border: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.12)', margin: '10px 0' }} />
                        </div>
                      );
                    }
                  })
                  }
                </RadioGroup>
              </div>
            );
          case FormInputTypes.component:
            return (
              <div key={key} className={clsx(formClasses.formDrawerInput)}>
                {processedValues[name]}
              </div>
            );
          case FormInputTypes.qrCode:
            return (
              <FormTextToQRCodeVisualizer
                key={key}
                processedValues={processedValues}
                name={name}
                handleTextInputChange={handleTextInputChange}
              />
            );
          case FormInputTypes.multiAutocomplete:
            return (
              <div key={key} className={clsx(formClasses.formDrawerInput)}>
                <MultiAutocomplete
                  label={label}
                  initialTags={processedValues?.tags?.split(' ') || []}
                  onTagsChange={(tags) => {
                    console.log('onTagsChange: ', tags);
                    updateInputValue(tags, name);
                  }}
                />
              </div>
            );
          case FormInputTypes.loader:
            return (
              <div
                key={key}
                className={formClasses.formDrawerInput}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <CircularProgress />
              </div>
            );
          case FormInputTypes.pugCart:
            return <FormPugCart key={key} data={processedValues} />;
          case FormInputTypes.button:
            return (
              <Button
                key={key}
                variant="contained"
                color="primary"
                onClick={inputChangeCallback}
                style={{ width: '90%', borderRadius: 32 }}
              >
                {label}
              </Button>
            );
          default:
            console.error('Invalid form input type: ', `"${type}"`);
            return <div />;
        }
      })}
    </div>
  );
});

export default DrawerContent;
