import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import credentialStore from '../state/credentialStore';
import { TOAST_MESSAGE_TYPE, toastStore } from '../state/toastStore';
import { Api } from './index';
import dashboardStore from '../state/dashboardStore';

const required = (key: string): string => {
  const envValue = process.env[key];
  if (!envValue) {
    throw new Error(
      `Missing environment variable: ${key}. Check if your .env file has all the variables defined in sample.env`,
    );
  }
  return envValue.toString();
};

export const baseUrls = {
  business: required('REACT_APP_BASE_URL_BUSINESS'),
  catalog: required('REACT_APP_BASE_URL_CATALOG'),
  charging: required('REACT_APP_BASE_URL_CHARGING'),
  user: required('REACT_APP_BASE_URL_USER'),
  billing: required('REACT_APP_BASE_URL_BILLING'),
  docs: required('REACT_APP_BASE_URL_DOCS'),
};

export const refreshAccessToken = async () => {
  if (credentialStore.refreshingToken) {
    return;
  }

  if (!credentialStore?.credentials?.user?.id) {
    credentialStore.logOut();
    return;
  }

  const body = {
    userId: credentialStore.credentials.user.id,
    refreshToken: credentialStore.credentials.refreshToken,
  };

  credentialStore.credentials.token = undefined;
  credentialStore.refreshingToken = true;

  try {
    const resp = await Api.user.refreshToken(body);
    const data = resp.data.data;

    credentialStore.credentials = {
      ...credentialStore.credentials,
      token: data.token,
    };

    credentialStore.tokenRefreshFlag++;
    credentialStore.tokenExpired = false;
  } catch (e) {
    credentialStore.logOut();
  }

  credentialStore.refreshingToken = false;
};

export function arrayToCommaSeparatedString(arr) {
  let str = '';
  arr?.forEach((val, idx) => {
    if (idx > 0) {
      str += ',';
    }
    str += val;
  });

  return str;
}

const errorHandler = (error) => {
  const madeAnyRequest = dashboardStore.madeAnyRequest;

  console.log('error handler init');

  const { status, data } = error.response;

  if (status === 401) {
    if (madeAnyRequest && !credentialStore.refreshingToken) {
      credentialStore.logOut();
      return Promise.reject(error);
    } else if (credentialStore.credentials?.user) {
      if (!credentialStore.refreshingToken) {
        refreshAccessToken().then();
      }
    }
  } else if (status === 403) {
    console.log('error handler 403: ', data);
    // credentialStore.logOut();
  } else {
    let errorMessage = data;
    if (errorMessage?.error?.message) {
      errorMessage = errorMessage?.error?.message;
    }
    console.log({ ...data });
    toastStore.showMessage(`Api error: ${errorMessage}`, TOAST_MESSAGE_TYPE.ERROR);
  }

  dashboardStore.madeAnyRequest = true;

  return Promise.reject(error);
};

export const createApi = (cfg: AxiosRequestConfig): AxiosInstance => {
  const api = axios.create(cfg);

  api.interceptors.request.use((requestCfg: any) => {
    const token = credentialStore.credentials?.token;
    if (token !== undefined) {
      requestCfg.headers.Authorization = `Bearer ${token}`;
    }
    return requestCfg;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error),
  );
  return api;
};
