export enum PAYMENT_STATUS {
    CREATED = 'created',
    VERIFYING = 'verifying',
    CAPTURED = 'captured',
    FAILED = 'failed',
    CANCELLED = 'cancelled',
    REFUNDED = 'refunded',
    VOID = 'void',
    DUPLICATE = 'duplicate',
    NOT_FOUND = 'not_found',
    RESERVED = 'reserved',
}
