import { makeAutoObservable, toJS } from 'mobx';
import { useEffect, useState } from 'react';

type PaginatedRequest = (skip: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC' | null) => Promise<any> | any;

export class PaginatedProviderStore {
  private mobxData: any;
  private mobxCount?: number;
  private mobxSortable?: any;

  private _request;
  private _onFulfilled;
  private _offset = 0;

  private _loading = false;

  private _refreshInterval?: number;
  private _intervalId?: number;

  private _sortBy?: string | null = null;
  private _sortOrder?: 'ASC' | 'DESC' | null = null;

  constructor(
    request: PaginatedRequest,
    onFulfilled: (data: any, count?: number) => void | undefined,
    autoRequest = true,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this._request = request;
    this._onFulfilled = onFulfilled;

    if (autoRequest) {
      this.reloadResource();
    }
  }

  setRequest(request: PaginatedRequest) {
    this._request = request;
    this._offset = 0;
    this.clearRefreshInterval();
  }

  setOnFulfilled(onFulfilled: (data: any, count?: number, sortable?: any) => void | undefined) {
    this._onFulfilled = onFulfilled;
  }

  reloadResource() {
    try {
      this._loading = true;
      this._request(this._offset, this._sortBy, this._sortOrder).then((resp: any) => {
        this.onProviderSuccess(resp);
        this._loading = false;
      });
    } catch (e) {
      console.log('request error', e);
      this._loading = false;
    }
  }

  onPageChange(offset: number) {
    this._offset = offset;
    const _oldInterval = this._refreshInterval;
    this.clearRefreshInterval();

    this.reloadResource();

    if (_oldInterval) {
      this.setRefreshInterval(_oldInterval);
    }
  }

  setSort(_sortBy: string | null, sortOrder: 'ASC' | 'DESC' | null) {
    if (this._sortBy !== _sortBy || this._sortOrder !== sortOrder) {
      this._sortBy = _sortBy;
      this._sortOrder = sortOrder;
      this._offset = 0;
      this.reloadResource();
    }
  }

  onProviderSuccess(resp) {
    const data = resp?.data?.data;
    const count = resp?.data?.count;
    const sortable = resp?.data?.sortable;

    this.mobxData = data;
    this.mobxCount = count;
    this.mobxSortable = sortable;

    if (this._onFulfilled) {
      this._onFulfilled(data, count, sortable);
    }
  }

  setRefreshInterval(interval: number) {
    this.clearRefreshInterval();
    this._intervalId = window.setInterval(() => {
      this.reloadResource();
    }, interval);
    this._refreshInterval = interval;
  }

  clearRefreshInterval() {
    if (this._intervalId) {
      clearInterval(this._intervalId);
      this._intervalId = undefined;
      this._refreshInterval = undefined;
    }
  }

  get data() {
    return toJS(this.mobxData);
  }

  get count() {
    return toJS(this.mobxCount);
  }
  get sortable() {
    return toJS(this.mobxSortable);
  }

  get loading() {
    return toJS(this._loading);
  }
  get sortBy() {
    return toJS(this._sortBy);
  }
  get sortOrder() {
    return toJS(this._sortOrder);
  }
}

export const usePaginatedProvider = (
  request: PaginatedRequest,
  onFulfilled: (data: any, count?: number, sortable?: any) => void | undefined = undefined,
  autoRequest = true,
) => {
  const provider = useState(() => new PaginatedProviderStore(request, onFulfilled, autoRequest))[0];

  useEffect(
    () => () => {
      provider.clearRefreshInterval();
    },
    [],
  );

  return provider;
};
