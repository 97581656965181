import { useState } from 'react';
import { Button, Card, CardContent, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useHotkeys } from 'react-hotkeys-hook';
import { ReactComponent as DashboardLogo } from '../../images/vmLogo.svg';
import { darkBackgroundColor } from '../../components/main/react/App';
import { Credentials } from '../../types/Credentials';
import credentialStore from '../../state/credentialStore';
import { Api } from '../../api';

const loginData = {
  email: '',
  password: '',
};

export const loginStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
    },
    verticalRoot: {
      display: 'flex',
      width: '100%',
      height: '100%',
      background: darkBackgroundColor,
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    card: {
      width: 400,
      paddingTop: 50,
      paddingLeft: 50,
      paddingRight: 50,
      borderRadius: 32,
    },
    buttonContainer: {
      width: '100%',
      paddingTop: 50,
      paddingBottom: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'space-evenly',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    dashboardLogo: {
      fontSize: 50,
    },
  }),
  { index: 1 },
);

const LoginPage = observer(() => {
  const loginClasses = loginStyles();
  const [errorMessage, setErrorMessage] = useState(undefined);

  const onLoginError = (error: any) => {
    setErrorMessage(error.message);
  };

  const onLoginSuccess = (resp) => {
    const data = resp.data.data;

    credentialStore.clearCredentials();
    credentialStore.credentials = data as Credentials;
  };

  const onClickLogin = () => {
    Api.user.login(loginData).then(onLoginSuccess).catch(onLoginError);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickLogin();
    }
  };

  useHotkeys('Enter', () => {
    if (document.activeElement.tagName !== 'INPUT') {
      onClickLogin();
    }
  });

  if (credentialStore.credentials !== undefined) {
    return <Redirect to="/" />;
  }

  return (
    <div className={loginClasses.root}>
      <div className={loginClasses.verticalRoot}>
        <div className={loginClasses.drawerHeader}>
          <DashboardLogo />
        </div>

        <Card className={loginClasses.card}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography color="textSecondary" gutterBottom variant="h5">
                Login
              </Typography>
            </div>
            <div>
              <TextField
                label="Email"
                fullWidth
                onChange={(event) => {
                  loginData.email = event.target.value;
                }}
                onClick={() => setErrorMessage(undefined)}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div>
              <TextField
                label="Password"
                type="password"
                fullWidth
                onChange={(event) => {
                  loginData.password = event.target.value;
                }}
                onClick={() => setErrorMessage(undefined)}
                onKeyDown={handleKeyPress}
              />
            </div>
          </CardContent>

          <div className={loginClasses.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickLogin}
              style={{ width: '90%', borderRadius: 32 }}
            >
              Login
            </Button>
          </div>

          {errorMessage !== undefined && <Alert severity="error">{errorMessage}</Alert>}
        </Card>
      </div>
    </div>
  );
});

export default LoginPage;
