import { Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import React, { useCallback, useState } from 'react';
import { useFormStyles } from '../../styles/useFormStyles';
import dashboardStore from '../../state/dashboardStore';

const FormDropzone = () => {
  const formClasses = useFormStyles();

  const [images, setImages] = useState([]);
  const addImage = (newImage: any) => {
    const newImages = [...dashboardStore.paragraphFormImages, newImage];
    setImages(newImages);
    dashboardStore.paragraphFormImages = newImages;
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        const blob = new Blob([binaryStr as BlobPart]);
        const url = URL.createObjectURL(blob);

        addImage({
          name: file.path,
          url,
        });
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  const views = [
    <div
      key="dropzone"
      {...getRootProps()}
      className={formClasses.formDrawerInput}
      style={{ marginTop: 32, marginBottom: 32 }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className={formClasses.dropzone}>
          <Typography>Drop the files here ...</Typography>
        </div>
      ) : (
        <div className={formClasses.dropzone}>
          <Typography>Click or drag image to upload</Typography>
        </div>
      )}
    </div>,
  ];

  images.forEach((image, i) => {
    views.push(
      <img src={image.url} key={`${image.name}_${i}`} alt={image.name} style={{ width: '100%' }} />,
    );
  });

  return <div>{views}</div>;
};

export default FormDropzone;
