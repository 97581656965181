import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import { makeAutoObservable, toJS } from 'mobx';
import { GridRowParams } from '@material-ui/data-grid';
import { ListItem, Typography } from '@material-ui/core';
import StyledCard from '../../../components/cards/StyledCard';
import StyledCardHeader, { HeaderButtonIcon } from '../../../components/cards/StyledCardHeader';
import StyledCardContent from '../../../components/cards/StyledCardContent';
import VMDataGrid from '../../../components/main/VMDataGrid';
import { useStyles } from '../../../styles/styles';
import { gridColumn } from '../../../utils/gridUtils';
import { NameValueRowList } from '../../../components/cards/CardRows/NameValueRowList';
import NameValueRow from '../../../components/cards/CardRows/NameValueRow';
import { drawerStore } from '../../../state/drawerStore';
import { FormInputTypes } from '../../../utils/constants';
import { TitleSubtitleRow } from '../../../components/cards/CardRows/TitleSubtitleRow';
import ActionsList from '../../../components/other/ActionsList';
import dashboardStore from '../../../state/dashboardStore';
import { useProvider } from '../../../state/providerStore';
import { Api } from '../../../api';
import TextLink from '../../../components/other/TextLink';
import { route, strToUrl } from '../../../utils/routing';
import { formatToSloDate } from '../../Sessions/SessionsPage';
import { PAGES } from '../../../components/main/MainComponent';
import { getUserAutocompleteInput } from '../../../components/forms/UserEmailAutocomplete';
import credentialStore from '../../../state/credentialStore';
import { filterValuesByInputsArray } from '../../../utils/utils';
import ActionsMenu from '../../../components/other/ActionsMenu';
import { onClickShowCart } from '../../Payments/InvoicesPage';

const detailsRows = [
  'id',
  'name',
  'VAT',
  'address',
  'city',
  'zip',
  'country',
  'ownerName',
  { name: 'accountId', label: 'company' },
  'ownerType',
];

const editWalletInputs = [
  { id: 'id', type: FormInputTypes.disabledTextField },
  { name: 'ownerName', type: FormInputTypes.textField },
  { name: 'address', type: FormInputTypes.googleAddressAutocomplete },
  { name: 'zip', type: FormInputTypes.textField },
  { name: 'city', type: FormInputTypes.textField },
  { name: 'country', type: FormInputTypes.textField },
  { name: 'name', type: FormInputTypes.textField },
  { name: 'VAT', type: FormInputTypes.textField },
];

const onUserAddInputChange = (isAdmin, usersProvider, inputValue, purchaseMethodsProvider) => {
  const urlParams: any = { email: inputValue };

  usersProvider.setOnFulfilled((data) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    drawerStore.setInputs(getUserAddInputs(isAdmin, usersProvider, purchaseMethodsProvider));
    drawerStore.setValues({ ...drawerStore.values });
  });

  usersProvider.setRequest(() => Api.user.listUsers(urlParams));
  usersProvider.reloadResource();
};

function getUserAddInputs(isAdmin, usersProvider, purchaseMethodsProvider) {
  const users = [];
  usersProvider.data?.users?.forEach((user) => {
    const alreadyAddedUser = purchaseMethodsProvider.data?.find((method) => method.userId === user.id);
    if (!alreadyAddedUser) {
      users.push(user);
    }
  });

  if (isAdmin) {
    return [
      getUserAutocompleteInput(users, (inputValue) =>
        onUserAddInputChange(isAdmin, usersProvider, inputValue, purchaseMethodsProvider),
      ),
    ];
  } else {
    return [{ name: 'userId', type: FormInputTypes.textField }];
  }
}

const balanceCorrectionInputs = [
  { name: 'amount', type: FormInputTypes.number },
  { name: 'note', type: FormInputTypes.multilineTextField },
];

class WalletStore {
  // Purchase methods with user data added from separate requests
  methods: any[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  addMethodWithUserData(method, user) {
    this.methods.push({ ...method, user });
  }

  resetMethods() {
    this.methods = [];
  }
}

const transactionActions = (params: GridRowParams, transactionsProvider: any) => {
  const transaction = params.row;
  const cartId = params.row?.cartId;

  if (!cartId) {
    return <div />;
  }

  return (
    <ActionsMenu>
      {cartId && (
        <ListItem button onClick={() => onClickShowCart(cartId)}>
          <Typography style={{ width: '100%' }}>Cart</Typography>
        </ListItem>
      )}
    </ActionsMenu>
  );
};

export const WalletDetails = observer(() => {
  const { walletid } = useParams<{ walletid: string }>();
  const classes = useStyles();

  const [walletStore] = useState(() => new WalletStore());

  const history = useHistory();

  useEffect(() => {
    dashboardStore.appBarLabel = 'Wallet details';
  }, [history.location]);

  const queryUsersFromCognito = (methods: any[]) => {
    walletStore.resetMethods();

    methods.forEach((method) => {
      Api.user.getUser(method.userId).then((resp) => walletStore.addMethodWithUserData(method, resp.data.data));
    });
  };

  const onPurchaseMethodsSuccess = (data) => {
    queryUsersFromCognito(data);
  };

  const walletProvider = useProvider(() => Api.billing.getWallet(walletid));
  const transactionsProvider = useProvider(() => Api.billing.listTransactionsForWallet(walletid));
  const purchaseMethodsProvider = useProvider(
    () => Api.billing.listPurchaseMethodsForWallet(walletid),
    onPurchaseMethodsSuccess,
  );
  const usersProvider = useProvider(Api.user.listUsers, undefined, false);

  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      usersProvider.reloadResource();
    }
  }, [credentialStore.isUserAdmin]);

  const confirmUpdateWallet = () => {
    const values = filterValuesByInputsArray(toJS(drawerStore.values), editWalletInputs);
    Api.billing.updateWallet(walletid, values).then(walletProvider.reloadResource);
  };

  const onClickEditDetails = () => {
    drawerStore.openDrawer({
      label: 'Update wallet',
      inputs: editWalletInputs,
      clickCallback: confirmUpdateWallet,
    });
    drawerStore.setValues(walletProvider.data);
  };

  const confirmAddUserToWallet = (values) => {
    Api.billing
      .createPurchaseMethodForWallet(walletid, { userId: values.userId })
      .then(purchaseMethodsProvider.reloadResource);
  };

  const onClickAddUserToWallet = () => {
    drawerStore.openDrawer({
      label: 'Add user',
      inputs: getUserAddInputs(credentialStore.isUserAdmin, usersProvider, purchaseMethodsProvider),
      clickCallback: () => confirmAddUserToWallet(toJS(drawerStore.values)),
    });
  };

  const confirmCorrectBalance = (values) => {
    Api.billing.correctBalance(walletid, { amount: values.amount, note: values.note }).then(() => {
      walletProvider.reloadResource();
      transactionsProvider.reloadResource();
    });
  };

  const onClickAddBalance = () => {
    drawerStore.openDrawer({
      label: 'Balance correction',
      inputs: balanceCorrectionInputs,
      clickCallback: () => confirmCorrectBalance(toJS(drawerStore.values)),
    });
  };

  const transactionColumns = [
    gridColumn('id', { flex: 1 }),
    gridColumn('createdAt', {
      flex: 1,
      headerName: 'Created at',
      renderCell: (params: GridRowParams) => {
        const { createdAt } = params.row;
        const formattedDate = formatToSloDate(createdAt);
        return <div>{formattedDate}</div>;
      },
    }),
    gridColumn('amount', {
      flex: 1,
      renderCell: (params: GridRowParams) => {
        const { amount } = params.row;
        return <div>{amount.formatted}</div>;
      },
    }),
    gridColumn('balance', {
      flex: 1,
      renderCell: (params: GridRowParams) => {
        const { balance } = params.row;
        return <div>{balance.formatted}</div>;
      },
    }),
    gridColumn('note', { flex: 1 }),
    gridColumn('purchaseId', {
      flex: 1,
      renderCell: (params: GridRowParams) => {
        const { purchaseId } = params.row;
        if (!purchaseId) {
          return <div>N/A</div>;
        }

        if (!credentialStore.isUserAdmin) {
          return <div>{purchaseId}</div>;
        }

        const shorterId = purchaseId.substring(purchaseId.length - 7);
        return <TextLink to={strToUrl(`${route(PAGES.Billing)}/${purchaseId}`)} label={`...${shorterId}`} />;
      },
    }),
    gridColumn('reason', { flex: 1 }),
    gridColumn('actions', {
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => transactionActions(params, transactionsProvider),
    }),
  ];

  const onClickRemoveUserFromWallet = (method) => {
    Api.billing.deletePurchaseMethod(method.id).then(purchaseMethodsProvider.reloadResource);
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.cardRows}>
        <div className={classes.leftCardRow}>
          <StyledCard>
            <StyledCardHeader title="Details" onClick={onClickEditDetails} headerButtonIcon={HeaderButtonIcon.edit} />
            <StyledCardContent>
              <NameValueRowList rows={detailsRows} values={walletProvider.data} hideEmpty />
            </StyledCardContent>
          </StyledCard>
        </div>
        <div className={classes.rightCardRow}>
          <StyledCard>
            <StyledCardHeader
              title="Balance"
              onClick={credentialStore.isUserAdmin ? onClickAddBalance : undefined}
              headerButtonIcon={HeaderButtonIcon.add}
            />
            <StyledCardContent>
              <NameValueRow name="balance" value={walletProvider.data?.balance?.formatted} />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="Users" onClick={onClickAddUserToWallet} headerButtonIcon={HeaderButtonIcon.add} />
            <StyledCardContent>
              {walletStore.methods.map((method) => {
                const fullName = `${method.user?.name} ${method.user?.familyName}`;

                return (
                  <div key={method.id}>
                    <TitleSubtitleRow
                      title={fullName}
                      subtitle={method.user?.email}
                      actions={
                        <ActionsList
                          actions={[{ label: 'Delete', callback: () => onClickRemoveUserFromWallet(method) }]}
                        />
                      }
                    />
                  </div>
                );
              })}
            </StyledCardContent>
          </StyledCard>
        </div>
      </div>

      <VMDataGrid rows={transactionsProvider.data} columns={transactionColumns} label="Transactions" />
    </div>
  );
});
