import { stat } from 'fs';
import { arrayToCommaSeparatedString, baseUrls, createApi } from './apiUtils';

const billingApi = {
  v1: createApi({ baseURL: `${baseUrls.billing}/admin/v1` }),
  v2: createApi({ baseURL: `${baseUrls.billing}/admin/v2` }),
};

const BILLING_URLS = {
  PURCHASES: 'purchases',
  PURCHASE_DETAILS: (billingToken: string) => `purchases/${billingToken}`,
  WALLETS: 'wallets',
  ACCOUNT_WALLETS: (accountId: string) => `accounts/${accountId}/wallets`,
  WALLET: (walletId: string) => `wallets/${walletId}`,
  WALLET_TRANSACTIONS: (walletId: string) => `wallets/${walletId}/transactions`,
  WALLET_PURCHASE_METHODS: (walletId: string) => `wallets/${walletId}/purchase-methods`,
  PURCHASE_METHODS: (purchaseMethodId: string) => `purchase-methods/${purchaseMethodId}`,
  BALANCE_CORRECTION: (walletId: string) => `wallets/${walletId}/balance-correction`,
  BILLING_PLAN_FOR_USER: (userId: string) => `users/${userId}/billing-plan`,
  PURCHASE_METHODS_FOR_USER: (userId: string) => `users/${userId}/purchase-methods`,
  DEBT: 'wallets/debt',
  INVOICES: 'invoices',
  CARTS: 'carts',
};

export const listPayments = (params?: any) => billingApi.v1.get(BILLING_URLS.PURCHASES, { params });

export const listPaymentsForLocation = (locationId: string, params?: any) =>
  billingApi.v1.get(BILLING_URLS.PURCHASES, { params: { locationId, ...params } });

export const listPaymentsForUser = (userId: string, params?: any) =>
  billingApi.v1.get(BILLING_URLS.PURCHASES, { params: { userId, ...params } });

export const getPaymentDetails = (billingToken: string) =>
  billingApi.v1.get(BILLING_URLS.PURCHASE_DETAILS(billingToken));

export const cancelPayment = (billingToken: string) =>
  billingApi.v1.post(`${BILLING_URLS.PURCHASE_DETAILS(billingToken)}/cancel`);

export const listWallets = () => billingApi.v1.get(BILLING_URLS.WALLETS);

export const listOwnerWalletsForAccount = (accountId: string) =>
  billingApi.v1.get(BILLING_URLS.ACCOUNT_WALLETS(accountId));

export const getWallet = (walletId: string) => billingApi.v1.get(BILLING_URLS.WALLET(walletId));

export const createWallet = (walletData) => billingApi.v1.post(BILLING_URLS.WALLETS, walletData);

export const updateWallet = (walletId: string, walletData) =>
  billingApi.v1.patch(BILLING_URLS.WALLET(walletId), walletData);

export const deleteWallet = (walletId: string) => billingApi.v1.delete(BILLING_URLS.WALLET(walletId));

export const listTransactionsForWallet = (walletId: string) =>
  billingApi.v1.get(BILLING_URLS.WALLET_TRANSACTIONS(walletId));

export const listPurchaseMethodsForWallet = (walletId: string) =>
  billingApi.v1.get(BILLING_URLS.WALLET_PURCHASE_METHODS(walletId));

export const getPurchaseMethod = (purchaseMethodId: string) =>
  billingApi.v1.get(BILLING_URLS.PURCHASE_METHODS(purchaseMethodId));

export const createPurchaseMethodForWallet = (walletId: string, purchaseMethodData) =>
  billingApi.v1.post(BILLING_URLS.WALLET_PURCHASE_METHODS(walletId), purchaseMethodData);

export const updatePaymentMethod = (purchaseMethodId: string, purchaseMethodData) =>
  billingApi.v1.patch(BILLING_URLS.PURCHASE_METHODS(purchaseMethodId), purchaseMethodData);

export const deletePurchaseMethod = (purchaseMethodId: string) =>
  billingApi.v1.delete(BILLING_URLS.PURCHASE_METHODS(purchaseMethodId));

export const correctBalance = (walletId: string, data) =>
  billingApi.v1.post(BILLING_URLS.BALANCE_CORRECTION(walletId), data);

export const getPaymentReports = (
  fromDate: string,
  toDate: string,
  locationIds: string[],
  chargerIds: string[],
  statuses: string[],
) =>
  billingApi.v1.get('reports/payment', {
    params: {
      fromDate,
      toDate,
      locationIds: arrayToCommaSeparatedString(locationIds),
      chargePointIds: arrayToCommaSeparatedString(chargerIds),
      statuses: arrayToCommaSeparatedString(statuses),
    },
  });

export const getPaymentReportsForAccount = (
  accountId: string,
  fromDate: string,
  toDate: string,
  locationIds: string[],
  chargerIds: string[],
  statuses: string[],
) =>
  billingApi.v1.get(`admin/v1/users/${accountId}/reports/payment`, {
    params: {
      fromDate,
      toDate,
      accountId,
      locationIds: arrayToCommaSeparatedString(locationIds),
      chargePointIds: arrayToCommaSeparatedString(chargerIds),
      statuses: arrayToCommaSeparatedString(statuses),
    },
  });

export const getBillingPlanForUser = (userId: string) => billingApi.v1.get(BILLING_URLS.BILLING_PLAN_FOR_USER(userId));

export const updateBillingPlanForUser = (userId: string, data) =>
  billingApi.v1.patch(BILLING_URLS.BILLING_PLAN_FOR_USER(userId), data);

export const getPaymentMethodsForUser = (userId: string) =>
  billingApi.v1.get(BILLING_URLS.PURCHASE_METHODS_FOR_USER(userId));

export const getDebt = () => billingApi.v1.get(BILLING_URLS.DEBT);

export const getInvoices = () => billingApi.v1.get(BILLING_URLS.INVOICES);

export const markInvoicePaid = (id: string) => billingApi.v1.post(`${BILLING_URLS.INVOICES}/mark-paid`, { id });

export const markInvoicePaidByReference = (reference: string) =>
  billingApi.v1.post(`${BILLING_URLS.INVOICES}/mark-paid`, { reference });

export const getCart = (cartId: string) => billingApi.v1.get(`${BILLING_URLS.CARTS}/${cartId}`);
