import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, MenuItem, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { observer } from 'mobx-react-lite';
import credentialStore from '../../state/credentialStore';

const AccountMenu = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleToggle}>
        <Typography color="textPrimary">
          {credentialStore.credentials.user.name} {credentialStore.credentials.user.familyName}
        </Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: '20ch' } }}
      >
        <MenuItem onClick={() => credentialStore.logOut()}>Logout</MenuItem>
      </Menu>
    </div>
  );
});

export default AccountMenu;
