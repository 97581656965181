import { useState, useEffect } from 'react';
import ReactJson from 'react-json-view';
import { drawerStore } from '../../state/drawerStore';

export const EditableJsonView = ({ name, data }) => {
  const handleConnectorEdit = (updatedData: any) => {
    updatedData.connectors = updatedData.connectors.map((connector: any, index: number) => {
      if (connector === null) {
        const newConnectorId = `${index + 1}`;
        return {
          type: '',
          cableLength: 0,
          connectorId: newConnectorId,
          ocppExtension: `0${newConnectorId}`,
        };
      }
      return {
        ...connector,
        connectorId: `${index + 1}`,
        ocppExtension: `0${index + 1}`,
      };
    });
  };

  const handleEdit = (edit: any) => {
    const updatedData = edit.updated_src;

    if (edit.name === 'connectors' && Array.isArray(updatedData.connectors)) {
      handleConnectorEdit(updatedData);
    }

    drawerStore.setValues({ ...drawerStore.values, [name]: updatedData });
  };

  return (
    <ReactJson
      src={data}
      iconStyle="triangle"
      displayDataTypes={false}
      displayObjectSize={false}
      indentWidth={4}
      collapsed={false}
      enableClipboard={false}
      collapseStringsAfterLength={100}
      style={{ padding: '10px', backgroundColor: 'white' }}
      onEdit={handleEdit}
      onAdd={handleEdit}
      onDelete={handleEdit}
    />
  );
};
