import { arrayToCommaSeparatedString, baseUrls, createApi } from './apiUtils';

const catalogApi = {
  v1: createApi({ baseURL: `${baseUrls.catalog}/admin/v1` }),
  v2: createApi({ baseURL: `${baseUrls.catalog}/admin/v2` }),
};

const CATALOG_URLS = {
  CATALOGS: 'catalogs',
  CATALOG: (catalogId: string) => `catalogs/${catalogId}`,
  CATALOG_PRODUCTS: (catalogId: string) => `catalogs/${catalogId}/products`,
  PRODUCTS: 'products',
  PRODUCT: (productId: string) => `products/${productId}`,
  REMOVE_PRODUCT: (catalogId: string, productId: string) => `catalogs/${catalogId}/products/${productId}`,
  RULES: 'rules',
  RULE: (ruleId: string) => `rules/${ruleId}`,
  PRODUCT_RULES: (productId: string) => `products/${productId}/rules`,
  USER_RESTRICTIONS: (catalogId: string) => `catalogs/${catalogId}/user-restrictions`,
  USER_RESTRICTION: (catalogId: string, userId: string) => `catalogs/${catalogId}/user-restrictions/users/${userId}`,
  TIME_RESTRICTIONS: (catalogId: string) => `catalogs/${catalogId}/time-restrictions`,
};

export const listCatalogs = (ids?: string[], params?: any) => {
  const queryParams = ids ? { ids: arrayToCommaSeparatedString(ids), ...params } : { ...params };
  return catalogApi.v1.get(CATALOG_URLS.CATALOGS, { params: queryParams });
};

export const getCatalog = (catalogId: string) => catalogApi.v1.get(CATALOG_URLS.CATALOG(catalogId));

export const createCatalog = (catalogData) => catalogApi.v1.post(CATALOG_URLS.CATALOGS, catalogData);

export const patchCatalog = (catalogId: string, catalogData) =>
  catalogApi.v1.patch(CATALOG_URLS.CATALOG(catalogId), catalogData);

export const removeCatalog = (catalogId: string) =>
  catalogApi.v1.delete(CATALOG_URLS.CATALOG(catalogId));

export const listCatalogProducts = (catalogId: string) => catalogApi.v1.get(CATALOG_URLS.CATALOG_PRODUCTS(catalogId));

export const createCatalogProduct = (catalogId: string, productData) =>
  catalogApi.v1.post(CATALOG_URLS.CATALOG_PRODUCTS(catalogId), productData);

export const removeCatalogProduct = (catalogId: string, productId: string) =>
  catalogApi.v1.delete(CATALOG_URLS.REMOVE_PRODUCT(catalogId, productId));

export const removeAllCatalogProducts = (catalogId: string) =>
  catalogApi.v1.delete(CATALOG_URLS.CATALOG_PRODUCTS(catalogId));

export const listProducts = (params: any = {}) => catalogApi.v1.get(CATALOG_URLS.PRODUCTS, { params });

export const createProduct = (productData) => catalogApi.v1.post(CATALOG_URLS.PRODUCTS, productData);

export const getProduct = (productId: string) => catalogApi.v1.get(CATALOG_URLS.PRODUCT(productId));

export const patchProduct = (productId: string, productData) =>
  catalogApi.v1.patch(CATALOG_URLS.PRODUCT(productId), productData);

export const listProductRules = (productId: string) => catalogApi.v1.get(CATALOG_URLS.PRODUCT_RULES(productId));

export const createProductRule = (productId: string, ruleData) =>
  catalogApi.v1.post(CATALOG_URLS.PRODUCT_RULES(productId), ruleData);

export const deleteRule = (ruleId: string) => catalogApi.v1.delete(CATALOG_URLS.RULE(ruleId));

export const setUserRestriction = (catalogId: string, enabled: boolean) =>
  catalogApi.v1.post(CATALOG_URLS.USER_RESTRICTIONS(catalogId), { enabled });

export const getUserRestriction = (catalogId: string) => catalogApi.v1.get(CATALOG_URLS.USER_RESTRICTIONS(catalogId));

export const addUserRestriction = (catalogId: string, userId: string) =>
  catalogApi.v1.post(CATALOG_URLS.USER_RESTRICTION(catalogId, userId));

export const removeUserRestriction = (catalogId: string, userId: string) =>
  catalogApi.v1.delete(CATALOG_URLS.USER_RESTRICTION(catalogId, userId));

export const setTimeRestriction = (catalogId: string, data) =>
  catalogApi.v1.post(CATALOG_URLS.TIME_RESTRICTIONS(catalogId), data);

export const getTimeRestriction = (catalogId: string) => catalogApi.v1.get(CATALOG_URLS.TIME_RESTRICTIONS(catalogId));
