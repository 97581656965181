import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Api } from '../../api';
import { paymentsColumns } from '../Payments/PaymentsPage';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';

export const LocationDetailsPayments = observer(() => {
  const { locationid } = useParams<{ locationid: string }>();

  const paymentsProvider = usePaginatedProvider(
    (offset) => Api.billing.listPaymentsForLocation(locationid, { skip: offset, limit: 20 }),
    undefined,
  );

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        label="Payments"
        rows={paymentsProvider.data}
        columns={paymentsColumns(paymentsProvider)}
        count={paymentsProvider.count}
        onPageOffsetChange={paymentsProvider.onPageChange}
      />
    </div>
  );
});
