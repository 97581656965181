import { makeAutoObservable } from 'mobx';

const TOAST_DURATION = 3000;

export enum TOAST_MESSAGE_TYPE {
  INFO,
  ERROR,
}

export class ToastStore {
  message = '';
  type?: TOAST_MESSAGE_TYPE = undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  showMessage(message: string, type: TOAST_MESSAGE_TYPE = TOAST_MESSAGE_TYPE.INFO) {
    this.message = message;
    this.type = type;
    window.setTimeout(() => { this.message = ''; }, TOAST_DURATION);
  }

  showNotImplemented() {
    this.showMessage('Not implemented yet', TOAST_MESSAGE_TYPE.INFO);
  }
}

export const toastStore = new ToastStore();
