import { useEffect } from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { CircularProgress, Divider, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import QrCodeIcon from '@material-ui/icons/QrCode';
import { useStyles } from '../../styles/styles';
import dashboardStore from '../../state/dashboardStore';
import AccountMenu from '../other/AccountMenu';
import { onRouteChange } from '../../utils/routeListener';
import NotFoundPage from '../../pages/NotFoundPage';
import LocationsPage from '../../pages/Locations/LocationsPage';
import ChargersPage from '../../pages/Chargers/ChargersPage';
import UsersPage from '../../pages/Users/UsersPage';
import {
  analyticsDetailsRoute,
  analyticsDetailsSubRoute,
  analyticsPageRoute,
  analyticsPageSubRoute,
  catalogDetailsTabRoute,
  PRICING_TABS,
  route,
  walletDetailsRoute,
} from '../../utils/routing';
import DrawerContainer from '../forms/DrawerContainer';
import credentialStore from '../../state/credentialStore';
import SessionsPage from '../../pages/Sessions/SessionsPage';
import DashboardPage from '../../pages/Dashboard/DashboardPage';
import CompaniesPage from '../../pages/Companies/CompaniesPage';
import { WalletDetails } from '../../pages/Chargers/Wallets/WalletDetails';
import { ReportsPage } from '../../pages/Reports/ReportsPage';
import { CatalogDetails } from '../../pages/Pricing/CatalogDetails';
import { refreshAccessToken } from '../../api/apiUtils';
import { CompanyPage } from '../../pages/Companies/CompanyPage';
import { MainMenu } from './MainMenu';
import { greenColor } from './react/App';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';
import { BillingContainer } from '../../pages/Payments/BillingContainer';
import { AnalyticsPage } from '../../pages/Analytics/AnalyticsPage';
import { AnalyticsDetails } from '../../pages/Analytics/AnalyticsDetails';
import CatalogsPage from '../../pages/Pricing/CatalogsPage';
import ProductDetails from '../../pages/Pricing/ProductDetails';

export enum PAGES {
  Dashboard = 'Dashboard',
  Users = 'Users',
  Companies = 'Companies',
  Company = 'Company',
  Wallets = 'Wallets',
  Locations = 'Locations',
  Chargers = 'Charge points',
  Pricing = 'Pricing',
  Sessions = 'Sessions',
  Billing = 'Billing',
  Reports = 'Reports',
  Analytics = 'Analytics',
}

export const ADMIN_PAGES = [
  PAGES.Dashboard,
  PAGES.Users,
  PAGES.Companies,
  PAGES.Locations,
  PAGES.Chargers,
  PAGES.Pricing,
  PAGES.Sessions,
  PAGES.Billing,
  PAGES.Reports,
  PAGES.Analytics,
];

export const OWNER_PAGES = [
  PAGES.Dashboard,
  PAGES.Company,
  PAGES.Locations,
  PAGES.Chargers,
  PAGES.Sessions,
  PAGES.Reports,
  PAGES.Analytics,
];

const initializeRouteListener = (history: any) => {
  if (dashboardStore.historySub === undefined) {
    onRouteChange(history); // Call route change on first component load

    dashboardStore.routeChangeCallback = () => onRouteChange(history);
    if (dashboardStore.historySub) {
      dashboardStore.historySub(); // Unsubscribe
    }
    dashboardStore.historySub = history.listen(dashboardStore.routeChangeCallback);
  }
};

export const MainComponent = observer(() => {
  const history = useHistory();
  const classes = useStyles();

  initializeRouteListener(history);

  useEffect(() => {
    if (credentialStore.credentials) {
      if (!credentialStore.isUserAdmin) {
        credentialStore.loadAccounts();
      }
    }
  }, [credentialStore.credentials]);

  const openQRCodeDrawer = () => {
    drawerStore.openDrawer({
      label: 'QR Code',
      inputs: [{ name: 'code', type: FormInputTypes.qrCode }],
      clickCallback: () => {
        console.log('Close QR Code drawer');
      },
      buttonLabel: 'Close',
      showButton: true,
    });
  };

  if (credentialStore.inited) {
    if (credentialStore.tokenExpired || !credentialStore.credentials?.token) {
      if (!credentialStore.refreshingToken) {
        refreshAccessToken();
      }
    }
  }

  if (!credentialStore.inited || credentialStore.tokenExpired || !credentialStore.credentials?.token) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
        <CircularProgress size={70} style={{ color: greenColor }} />
      </div>
    );
  }

  if (credentialStore.credentials === undefined) {
    return <Redirect to="/login" />;
  }

  if (credentialStore.loadingAccounts) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
        <CircularProgress size={70} style={{ color: greenColor }} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap color="textPrimary">
            {dashboardStore.appBarLabel}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton onClick={openQRCodeDrawer}>
              <QrCodeIcon color="primary" />
            </IconButton>

            {/* <IconButton>
              <SearchIcon color="secondary" />
            </IconButton>
            <IconButton color="secondary">
              <Badge variant="dot" color="primary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <Divider orientation="vertical" flexItem color="secondary" style={{ marginLeft: 16, marginRight: 16 }} />
            <AccountMenu />
          </div>
          <div className={classes.sectionMobile}>
            <IconButton color="inherit" />
          </div>
        </Toolbar>
      </AppBar>
      <MainMenu />
      <main className={clsx(classes.content, classes.contentShift)}>
        <div className={classes.drawerHeader} />

        <Switch>
          <Route exact path="/" render={() => <Redirect to={route(PAGES.Dashboard)} />} />
          <Route path={route(PAGES.Dashboard)} component={DashboardPage} />
          <Route path={route(PAGES.Users)} component={UsersPage} />
          <Route path={route(PAGES.Chargers)} component={ChargersPage} />
          <Route path={route(PAGES.Locations)} component={LocationsPage} />
          <Route
            path={`/${PAGES.Pricing.toLowerCase()}/${PRICING_TABS.PRODUCTS.toLowerCase()}/:productid`}
            component={ProductDetails}
          />
          <Route path={catalogDetailsTabRoute()} component={CatalogDetails} />
          <Route path={route(PAGES.Pricing)} component={CatalogsPage} />
          <Route path={route(PAGES.Sessions)} component={SessionsPage} />
          <Route path={route(PAGES.Billing)} component={BillingContainer} />
          <Route path={route(PAGES.Companies)} component={CompaniesPage} />
          <Route path={route(PAGES.Company)} component={CompanyPage} />
          <Route path={walletDetailsRoute()} component={WalletDetails} />
          <Route path={route(PAGES.Reports)} component={ReportsPage} />
          <Route path={analyticsDetailsSubRoute()} component={AnalyticsDetails} />
          <Route path={analyticsDetailsRoute()} component={AnalyticsDetails} />
          <Route path={analyticsPageSubRoute()} component={AnalyticsPage} />
          <Route path={analyticsPageRoute()} component={AnalyticsPage} />
          <Route path="/" component={NotFoundPage} />
        </Switch>

        <DrawerContainer />
      </main>
    </div>
  );
});
