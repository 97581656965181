import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFormStyles } from '../../styles/useFormStyles';

export const FormTimePicker = observer(({ processedValues, name, updateInputValue, label }: any) => {
  const formClasses = useFormStyles();
  const [timeVal, setTimeVal] = useState<Date | null>(new Date());

  useEffect(() => {
    if (processedValues[name]) {
      const [hours, minutes] = processedValues[name].split(':');
      const date = new Date();
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      setTimeVal(date);
    }
  }, [processedValues[name]]);

  const handleTimeChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setTimeVal(newValue);
      const hours = newValue.getHours();
      const minutes = newValue.getMinutes();
      const formattedTime = `${hours}:${minutes}`;
      console.log('formattedTime: ', formattedTime);
      updateInputValue(formattedTime, name);
    }
  };

  return (
    <div className={formClasses.formDrawerInput} style={{ display: 'flex' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          inputFormat="HH:mm"
          onChange={handleTimeChange}
          label={label}
          value={timeVal}
          renderInput={(params) => <TextField {...params} />}
          closeOnSelect
        />
      </LocalizationProvider>
    </div>
  );
});
