import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { GridRowParams } from '@material-ui/data-grid';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { companyDetailsParameters, companyDetailsRoute, route } from '../../utils/routing';
import { gridColumn } from '../../utils/gridUtils';
import TextLink from '../../components/other/TextLink';

import CompanyDetails from './CompanyDetails';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';
import { Api } from '../../api';
import { PAGES } from '../../components/main/MainComponent';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';

const accountCreateInputs = [
  { name: 'type', label: 'Account type', type: FormInputTypes.select, options: ['private', 'company'] },
  { name: 'name', type: FormInputTypes.textField },
  { name: 'description', type: FormInputTypes.textField },
  { name: 'searchAddress', type: FormInputTypes.googleAddressAutocomplete },
  { name: 'address', type: FormInputTypes.textField },
  { name: 'zip', type: FormInputTypes.textField },
  { name: 'city', type: FormInputTypes.textField },
  { name: 'country', type: FormInputTypes.textField },
  { name: 'phone', type: FormInputTypes.textField },
  { name: 'email', type: FormInputTypes.textField },
];

const CompaniesPage = observer(() => {
  const companiesProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);

  useEffect(() => {
    companiesProvider.setRequest((offset, sortBy, sortOrder) =>
      Api.business.listAccounts({ skip: offset, take: 20, sortBy, sortOrder }),
    );

    companiesProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
    });
    companiesProvider.reloadResource();
  }, []);

  const onClickConfirmAdd = () => {
    const data = toJS(drawerStore.values);

    Api.business.createAccount(data).then(companiesProvider.reloadResource);
  };

  const onClickAccountAdd = () => {
    drawerStore.openDrawer({
      label: 'Create account',
      inputs: accountCreateInputs,
      clickCallback: onClickConfirmAdd,
    });
  };

  const companyColumns = [
    gridColumn('name', {
      flex: 3,
      sortableFields,
      renderCell: (params: GridRowParams) => (
        <TextLink label={params.row.name} to={companyDetailsParameters(params.row.id)} />
      ),
    }),
    gridColumn('address', { flex: 3, sortableFields }),
    gridColumn('type', { flex: 2, sortableFields }),
    gridColumn('status', { flex: 2, sortableFields }),
    gridColumn('chargingPoints', { flex: 2, headerName: 'Charging points', sortableFields }),
  ];

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        onClickAdd={onClickAccountAdd}
        label="Companies"
        count={companiesProvider.count}
        rows={companiesProvider.data}
        columns={companyColumns}
        onPageOffsetChange={companiesProvider.onPageChange}
        onSortTypeChange={companiesProvider.setSort}
      />
    </div>
  );
});

export default () => (
  <Switch>
    <Route exact path={route(PAGES.Companies)} component={CompaniesPage} />
    <Route path={companyDetailsRoute()} component={CompanyDetails} />
  </Switch>
);
