import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, ListItem, Typography } from '@material-ui/core';
import { toJS } from 'mobx';
import VMDataGrid from '../../components/main/VMDataGrid';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import { chargerDetailsTabParameters, userDetailsTabParameters } from '../../utils/routing';
import TextLink from '../../components/other/TextLink';
import { gridColumn } from '../../utils/gridUtils';
import { greenColor, lightGreenColor } from '../../components/main/react/App';
import ActionsMenu from '../../components/other/ActionsMenu';
import { formatToSloDate } from '../Sessions/SessionsPage';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';
import { ColumnStatusBadge } from '../../components/other/CellBadges';
import { getUserAutocompleteInput } from '../../components/forms/UserEmailAutocomplete';

const onUserAddInputChange = (usersProvider, inputValue, tag) => {
  const urlParams: any = { email: inputValue };

  usersProvider.setOnFulfilled((data) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    drawerStore.setInputs(getAssignTagInputs(usersProvider, tag));
    drawerStore.setValues({ ...drawerStore.values });
  });

  usersProvider.setRequest(() => Api.user.listUsers(urlParams));
  usersProvider.reloadResource();
};

function getUserAddInputs(usersProvider, tag) {
  const users = [];
  usersProvider.data?.users?.forEach((user) => {
    users.push(user);
  });

  return [getUserAutocompleteInput(users, (inputValue) => onUserAddInputChange(usersProvider, inputValue, tag))];
}

function getAssignTagInputs(usersProvider, tag) {
  return [
    { name: 'name', type: FormInputTypes.textField },
    {
      name: 'type',
      type: FormInputTypes.select,
      options: ['rfid', 'vin'],
    },
    { name: 'tag', label: 'Tag Code', type: FormInputTypes.textView, value: tag },
    ...getUserAddInputs(usersProvider, tag),
  ];
}

export const LocationDetailsAuthorizations = observer(() => {
  const { locationid } = useParams<{ locationid: string }>();

  const authorizationsProvider = useProvider(() => Api.charge.listAuthorizationAttemptsForLocation(locationid));
  const usersProvider = useProvider(Api.user.listUsers);

  const confirmAssignTag = (tagId) => {
    Api.charge
      .createAuthorizationTag(drawerStore.values.userId, drawerStore.values)
      .then(authorizationsProvider.reloadResource);
  };

  const authorizationsColumns = () => [
    gridColumn('date', {
      flex: 1,
      renderCell: (params) => {
        if (!params.row.createdAt) return <div />;

        const date = new Date(params.row.createdAt);
        const formattedDate = formatToSloDate(date);
        return <div>{formattedDate}</div>;
      },
    }),
    gridColumn('tag', { flex: 1 }),
    gridColumn('chargePoint', {
      flex: 1,
      renderCell: (params) => {
        const chargePoint = params.row.chargePoint;
        if (!chargePoint) return <div>N/A</div>;

        return <TextLink to={chargerDetailsTabParameters(chargePoint.id)} label={chargePoint.ocppId} />;
      },
    }),
    gridColumn('user', {
      flex: 1,
      renderCell: (params) => {
        const userId = params.row.userId;
        if (!userId) return <div>N/A</div>;

        return <TextLink to={userDetailsTabParameters(userId)} label={userId} />;
      },
    }),
    gridColumn('status', {
      flex: 1,
      renderCell: (params) => {
        let color = 'white';
        if (params.row.status === 'unknown') color = '#C8C8C8';
        if (params.row.status === 'match') color = lightGreenColor;
        return <ColumnStatusBadge label={params.row.status} color={color} />;
      },
    }),
    gridColumn('response', {
      flex: 1,
      renderCell: (params) => <div>{JSON.stringify(toJS(params.row))}</div>,
    }),
    gridColumn('actions', {
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          <IconButton
            onClick={() => {
              drawerStore.openDrawer({
                label: 'Authorization details',
                inputs: [
                  {
                    type: FormInputTypes.jsonView,
                    value: params.row,
                  },
                ],
                buttonLabel: 'Close',
              });
            }}
          >
            <VisibilityIcon style={{ color: greenColor }} />
          </IconButton>
          {params.row.isAssignable && (
            <ActionsMenu>
              <ListItem
                button
                onClick={() => {
                  drawerStore.openDrawer({
                    label: 'Assign ID Tag',
                    inputs: getAssignTagInputs(usersProvider, params.row.tag),
                    clickCallback: () => confirmAssignTag(params.row.id),
                    buttonLabel: 'Save',
                  });
                }}
              >
                <Typography style={{ width: '100%' }}>Assign tag</Typography>
              </ListItem>
            </ActionsMenu>
          )}
        </div>
      ),
    }),
  ];

  return (
    <div style={{ width: '100%' }}>
      <VMDataGrid label="Authorizations" rows={authorizationsProvider.data} columns={authorizationsColumns()} />
    </div>
  );
});
