import dashboardStore from '../state/dashboardStore';
import { capitalizeFirstLetter } from './utils';
import { route } from './routing';
import { drawerStore } from '../state/drawerStore';
import credentialStore from '../state/credentialStore';
import { PAGES } from '../components/main/MainComponent';
import { timeRangeStore } from '../state/timeRangeStore';

export function onRouteChange(history) {
  if (!credentialStore.inited) {
    return;
  }

  drawerStore.reset();

  if (credentialStore.credentials === undefined && !credentialStore.shouldLogOut) {
    if (!history.location.pathname.includes('login')) {
      credentialStore.logOut();
    }
  }

  if (credentialStore.shouldLogOut) {
    credentialStore.onLogOut();
    credentialStore.credentials = undefined;
    history.push('/login');
  }

  const { pathname } = history.location;

  const pathWords = pathname.substring(1).split('/');

  if (!pathname.startsWith(route(PAGES.Analytics))) {
    dashboardStore.appBarLabel = capitalizeFirstLetter(pathWords[0]);
  }

  dashboardStore.selectedMenuItem = capitalizeFirstLetter(pathWords[0]);

  if (pathname.startsWith(route(PAGES.Pricing))) {
    if (pathWords.length >= 3) {
      dashboardStore.appBarLabel = `${capitalizeFirstLetter(pathWords[1])} details`;
    }
    // const id = pathWords[2];
  } else if (pathWords.length >= 2) {
    // const id = pathWords[1];

    if (!pathname.startsWith(route(PAGES.Analytics))) {
      dashboardStore.appBarLabel = `${capitalizeFirstLetter(pathWords[0])} details`;
    }
  }

  if (!pathname.startsWith(route(PAGES.Analytics))) {
    timeRangeStore.reset();
  }
}
