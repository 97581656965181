import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import { useFormStyles } from '../../styles/useFormStyles';

const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}), { index: 1 });

export interface LocationAutocompleteResponse {
  address?: string;
  lat?: number;
  lng?: number;
  city?: string;
  zip?: string;
  country?: string;
}

interface GoogleAddressAutocompleteProps {
  editInputValue: any;
  onAddressChange: (inputValue: any, locationResponse: LocationAutocompleteResponse) => void;
}

const GoogleAddressAutocomplete = ({ editInputValue, onAddressChange }: GoogleAddressAutocompleteProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(editInputValue);
  const [inputValue, setInputValue] = useState(editInputValue);
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const formClasses = useFormStyles();

  const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const mapsScriptSrc = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places`;

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        encodeURI(mapsScriptSrc),
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const getPlacePredicitions = React.useMemo(
    () =>
      _.throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200, { leading: true }),
    [],
  );

  const getAddressComponentByType = (addressComponents, type: string) => addressComponents?.find((el) => el.types.includes(type));

  const GetLocation = async (addressInput: any): Promise<LocationAutocompleteResponse | void> => {
    const geocoder = new google.maps.Geocoder();
    let resp: LocationAutocompleteResponse;

    await geocoder.geocode(
      {
        address: addressInput,
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const location = results[0]?.geometry?.location;

          const lat = location?.lat();
          const lng = location?.lng();

          const addressComponents = results[0]?.address_components;

          const addressNumber = getAddressComponentByType(addressComponents, 'street_number')?.long_name;
          const addressName = getAddressComponentByType(addressComponents, 'route')?.long_name;

          let address = '';
          if (addressName) {
            address += addressName;
          }
          if (addressNumber) {
            address += addressNumber;
          }

          const city = getAddressComponentByType(addressComponents, 'postal_town')?.long_name;
          const zip = getAddressComponentByType(addressComponents, 'postal_code')?.long_name;
          const country = getAddressComponentByType(addressComponents, 'country')?.long_name;

          resp = {
            address,
            lat,
            lng,
            city,
            zip,
            country,
          };
        }
      },
    );

    return resp;
  };
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    getPlacePredicitions({ input: inputValue }, async (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          const locationResponse = await GetLocation(inputValue);
          if (locationResponse) {
            newOptions = [value];
            onAddressChange(inputValue, locationResponse);
          }
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, getPlacePredicitions]);

  return (
    <Autocomplete
      id="google-map-demo"
      className={formClasses.formDrawerInput}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        // @ts-ignore
        params.inputProps.autoComplete = 'new-password';
        return (
          <TextField
            {...params}
            label="Search address"
            autoComplete="new-password"
          />
        );
      }}
      renderOption={(option) => {
        if (option) {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        return null;
      }}
    />
  );
};

export default GoogleAddressAutocomplete;
