import Drawer from '@material-ui/core/Drawer';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import { Input, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FormControl, MenuItem, Select } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PlaceIcon from '@mui/icons-material/Place';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HistoryIcon from '@mui/icons-material/History';
import PaymentIcon from '@mui/icons-material/Payment';
import EvStationIcon from '@mui/icons-material/EvStation';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import { Api } from '../../api';
import { ReactComponent as DashboardLogo } from '../../images/vmLogo.svg';
import { drawerWidth, useStyles } from '../../styles/styles';
import { route, strToUrl } from '../../utils/routing';
import credentialStore from '../../state/credentialStore';
import { ADMIN_PAGES, OWNER_PAGES, PAGES } from './MainComponent';
import { greenColor } from './react/App';
import CompanyLogo from '../../pages/Companies/CompanyLogo';

const sidebarGrey = '#DDE2FF';

const useSidebarStyles = makeStyles(
  {
    list: {
      paddingLeft: 16,
      paddingRight: 32,
    },
    underline: {
      '&:hover:not(.Mui-disabled):before': {
        borderColor: sidebarGrey, // Your custom color
      },
      '&:before': {
        borderColor: sidebarGrey,
      },
      '&:after': {
        borderColor: sidebarGrey,
      },
      color: sidebarGrey,
    },
    dashboardLogo: {
      fontSize: 50,
    },
    listItemIcon: {
      margin: 0,
      padding: 0,
      paddingRight: 16,
      minWidth: 0,
      color: sidebarGrey,
    },
    listItemText: {
      margin: 0,
      padding: 0,
      color: sidebarGrey,
    },
    divider: {
      backgroundColor: sidebarGrey,
      margin: '0 1em',
    },
    accountSelect: {
      '& .MuiSelect-select': {
        color: sidebarGrey,
      },
      '& .MuiSelect-icon': {
        color: sidebarGrey,
      },
      color: sidebarGrey,
      '&:before': {
        borderColor: sidebarGrey,
      },
      '&:after': {
        borderColor: sidebarGrey,
      },
    },
    accountSelectMenuItem: {},
  },
  { index: 1 },
);

const menuItemColor = '#24262B';
const selectedMenuItemColor = '#3c4148';

const StyledListItem = withStyles({
  root: {
    paddingLeft: 16,
    paddingRight: 0,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 16,
    marginBottom: 4,
    backgroundColor: menuItemColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: drawerWidth - 2 * 16,
    '&.Mui-selected': {
      backgroundColor: selectedMenuItemColor,
    },
  },
  button: {
    '&:hover': {
      backgroundColor: selectedMenuItemColor,
    },
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: selectedMenuItemColor,
      },
    },
  },
})(ListItem);

const getPagesToShow = (isAdmin) => Object.entries(isAdmin ? ADMIN_PAGES : OWNER_PAGES).map((entry) => entry);

const PageMenuItems = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const sidebarClasses = useSidebarStyles();

  const pagesToShow = getPagesToShow(credentialStore.isUserAdmin);

  const [pageWord, setPageWord] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    const pathname = location?.pathname;

    let word = '';
    if (pathname) {
      const words = pathname.split('/');
      if (words && words.length >= 2) {
        word = words[1];
      }
    }

    setPageWord(word);
  }, [location]);

  /* const assignedSessionsOnly = !credentialStore.isAdmin && !credentialStore.canViewUnassignedChargers;
  return (
    <List>
      {pagesToShow?.map(([pageKey, pageVal]) => {
        let label: any = pageVal;
        if (pageKey === PAGES.Sessions) {
          if (assignedSessionsOnly) {
            label = 'Assigned sessions';
          }
        }

        return (
          <StyledListItem
            button
            key={pageKey}
            onClick={() => {
              history.push(route(pageVal as PAGES));
              dashboardStore.selectedMenuItem = pageKey;
            }}
            selected={dashboardStore.selectedMenuItem === pageKey}
          >
            <ListItemIcon className={sidebarClasses.listItemIcon}>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText className={sidebarClasses.listItemText} primary={label} />
          </StyledListItem>
        );
      })}
    </List>
  ); */

  const getIconByPageVal = (pageVal: PAGES) => {
    switch (pageVal) {
      case PAGES.Dashboard:
        return <DashboardIcon />;
      case PAGES.Users:
        return <PeopleIcon />;
      case PAGES.Companies:
      case PAGES.Company:
        return <BusinessIcon />;
      case PAGES.Wallets:
        return <AccountBalanceWalletIcon />;
      case PAGES.Locations:
        return <PlaceIcon />;
      case PAGES.Chargers:
        return <EvStationIcon />;
      case PAGES.Pricing:
        return <AttachMoneyIcon />;
      case PAGES.Sessions:
        return <HistoryIcon />;
      case PAGES.Billing:
        return <PaymentIcon />;
      case PAGES.Reports:
        return <DescriptionIcon />;
      case PAGES.Analytics:
        return <BarChartIcon />;
      default:
        return <DashboardIcon />;
    }
  };

  return (
    <List className={sidebarClasses.list}>
      {pagesToShow?.map(([pageKey, pageVal]) => {
        const isPageSelected = pageWord === strToUrl(pageVal);
        const iconColor = isPageSelected ? greenColor : sidebarGrey;

        return (
          <StyledListItem
            button
            key={pageKey}
            onClick={() => {
              history.push(route(pageVal as PAGES));
            }}
            selected={isPageSelected}
          >
            <ListItemIcon className={sidebarClasses.listItemIcon} style={{ color: iconColor }}>
              {getIconByPageVal(pageVal)}
            </ListItemIcon>
            <ListItemText className={sidebarClasses.listItemText} primary={pageVal} />
          </StyledListItem>
        );
      })}
    </List>
  );
});

export const MainMenu = observer(() => {
  const classes = useStyles();
  const sidebarClasses = useSidebarStyles();
  const [companyLogo, setCompanyLogo] = useState(null);

  const history = useHistory();

  const handleAccountChange = (accountId: string) => {
    credentialStore.selectAccountById(accountId);

    const pathname = history.location.pathname;
    if (pathname.startsWith(`${route(PAGES.Locations)}/`)) {
      history.replace(route(PAGES.Locations));
    }
  };

  useEffect(() => {
    const accId = credentialStore.getAccountId();
    if (accId) {
      Api.business.getAccountLogoUrl(accId).then((res) => {
        setCompanyLogo(res?.data?.data?.[0]?.url);
      });
    }
  }, [credentialStore.selectedAccount]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        {credentialStore.isUserAdmin && <DashboardLogo />}
        {!credentialStore.isUserAdmin && companyLogo && <CompanyLogo logoUrl={companyLogo} />}
      </div>
      {!credentialStore.isUserAdmin && (
        <div style={{ marginBottom: 16, paddingLeft: 32, paddingRight: 32 }}>
          <FormControl fullWidth>
            {/* <InputLabel style={{ color: sidebarGrey }}>Select account</InputLabel> */}
            <Select
              value={credentialStore.getAccountId()}
              onChange={(e) => {
                handleAccountChange(e.target.value);
              }}
              input={
                <Input
                  classes={{
                    underline: sidebarClasses.underline,
                  }}
                />
              }
              className={sidebarClasses.accountSelect}
              variant="standard"
            >
              {credentialStore.ownerAccounts.map((acc) => (
                <MenuItem key={acc.id} value={acc.id} className={sidebarClasses.accountSelectMenuItem}>
                  {acc.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <PageMenuItems />
      <div className={classes.grow} />
      <div className={classes.drawerHeader}>{!credentialStore.isUserAdmin && <DashboardLogo />}</div>
    </Drawer>
  );
});
