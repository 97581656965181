import React, { useEffect } from 'react';
import { OverlayView } from 'react-google-maps';
import { Card, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ConnectorMarkerData } from './DashboardPage';
import { ReactComponent as ViziMapMarker } from '../../images/vizi_map_marker.svg';
import { useStyles } from '../../styles/styles';
import { greenColor, redColor, yellowColor } from '../../components/main/react/App';
import { ConnectorIcon } from '../../components/other/Connectors';
import { locationDetailsTabParameters } from '../../utils/routing';

export const MapConnectorMarker = ({ data }: { data: ConnectorMarkerData }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    const closeExpanded = () => setExpanded(false);
    if (expanded) {
      document.addEventListener('click', closeExpanded);
    }
    return () => {
      document.removeEventListener('click', closeExpanded);
    };
  }, [expanded]);

  const handleExpandedClick = (e: any, goToDetailsPage: boolean) => {
    e.stopPropagation();
    setExpanded(!expanded);

    if (goToDetailsPage) {
      history.push(locationDetailsTabParameters(data.locationid));
    }
  };

  const getPinColor = () => {
    if (data.connectors?.some((connector) => connector.status === 'error')) {
      return redColor;
    }
    if (data.connectors?.some((connector) => connector.status === 'maintenance')) {
      return yellowColor;
    }
    return greenColor;
  };

  const markerContainerStyle: React.CSSProperties = {
    position: 'absolute',
    transform: 'translate(-50%, -100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    filter: 'drop-shadow(1px 2px 4px grey)',
  };

  const cardContainerStyle: React.CSSProperties = {
    position: 'absolute',
    transform: 'translate(-50%, -100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
  };

  const textStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -70%)',
    textAlign: 'center',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  };

  const cardStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -70%)',
    textAlign: 'center',
    fontWeight: 'bold',
  };

  return (
    <OverlayView position={{ lat: data.lat, lng: data.lng }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <div onClick={(e) => handleExpandedClick(e, false)}>
        {!expanded && (
          <div style={markerContainerStyle}>
            <ViziMapMarker fill={getPinColor()} />
            <div style={textStyle}>
              {data.totalCount} | {data.inUseCount}
            </div>
          </div>
        )}
        {expanded && (
          <div style={cardContainerStyle}>
            <div style={cardStyle}>
              <Card
                style={{
                  padding: '16px',
                  boxShadow: 'none',
                  filter: 'drop-shadow(1px 2px 4px grey)',
                  width: '100%',
                  borderRadius: 20,
                }}
              >
                <Typography
                  onClick={(e) => handleExpandedClick(e, true)}
                  style={{
                    color: greenColor,
                    fontSize: 14,
                    fontWeight: 400,
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                    cursor: 'pointer',
                  }}
                >
                  {data.locationName}
                </Typography>
                <Typography style={{ whiteSpace: 'nowrap', fontSize: 12, textAlign: 'left' }}>
                  {data.locationAddress}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
                  <div>
                    <Typography style={{ whiteSpace: 'nowrap', marginRight: '16px' }}>
                      {data.totalCount} | {data.inUseCount} | {data.errorCount}
                    </Typography>
                  </div>
                  {data.connectors?.map((connector) => (
                    <div key={connector.id} style={{ marginLeft: '4px' }}>
                      <ConnectorIcon type={connector.type} status={connector.status} />
                    </div>
                  ))}
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    </OverlayView>
  );
};
