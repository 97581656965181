import { useEffect, useState } from 'react';
import { makeAutoObservable, toJS } from 'mobx';

type Request = () => Promise<any> | any;

export class ProviderStore {
  private mobxData: any;
  private mobxCount?: number;

  private _request;
  private _onFulfilled;

  private _loading = false;

  constructor(request: Request, onFulfilled: (data: any, count?: number) => void | undefined, autoRequest = true) {
    makeAutoObservable(this, {}, { autoBind: true });

    this._request = request;
    this._onFulfilled = onFulfilled;

    if (autoRequest) {
      this.reloadResource();
    }
  }

  setRequest(request: Request) {
    this._request = request;
  }

  setOnFulfilled(onFulfilled: (data: any, count?: number) => void | undefined) {
    this._onFulfilled = onFulfilled;
  }

  reloadResource() {
    try {
      this._loading = true;
      this._request().then((resp: any) => {
        this.onProviderSuccess(resp);
        this._loading = false;
      });
    } catch (e) {
      console.log('request error', e);
      this._loading = false;
    }
  }

  reloadResourceWithCallback(callback) {
    this._request().then((resp) => {
      this.onProviderSuccess(resp);
      callback();
    });
  }

  setRequestAndReload(request: Request) {
    this.setRequest(request);
    this.reloadResource();
  }

  onProviderSuccess(resp) {
    const data = resp?.data?.data;
    const count = resp?.data?.count;

    this.mobxData = data;
    this.mobxCount = count;

    if (this._onFulfilled) {
      this._onFulfilled(data, count);
    }
  }

  get data() {
    return toJS(this.mobxData); // Need to convert if mobxData is array
  }

  get count() {
    return toJS(this.mobxCount);
  }

  get loading() {
    return toJS(this._loading);
  }
}

export const useProvider = (
  request: Request,
  onFulfilled: (data: any, count?: number) => void | undefined = undefined,
  autoRequest = true,
  refreshInterval: number | undefined = undefined,
) => {
  const provider = useState(() => new ProviderStore(request, onFulfilled, autoRequest))[0];

  useEffect(() => {
    if (refreshInterval) {
      const interval = setInterval(() => provider.reloadResource(), refreshInterval);
      return () => {
        clearInterval(interval);
      };
    } else return undefined;
  }, []);

  return provider;
};
