import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TextField } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFormStyles } from '../../styles/useFormStyles';

export const FormDatePicker = observer(({ processedValues, name, updateInputValue, label }: any) => {
  const formClasses = useFormStyles();

  useEffect(() => {
    if (!processedValues[name]) {
      const newDate = new Date();
      if (label === 'From') {
        newDate.setMonth(newDate.getMonth() - 1);
        newDate.setUTCHours(0, 0, 0, 0);
      } else {
        newDate.setUTCHours(23, 59, 59, 999);
      }
      updateInputValue(newDate, name);
      updateInputValue(undefined, 'presetLabel');
    }
  }, []);

  return (
    <div className={formClasses.formDrawerInput} style={{ display: 'flex' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          inputFormat="dd.MM.yyyy"
          DialogProps={{ disableEnforceFocus: true }}
          onChange={(value) => {
            const newDate = value;
            if (value !== null) {
              if (label === 'From') {
                newDate.setUTCHours(0, 0, 0, 0);
              } else {
                newDate.setUTCHours(23, 59, 59, 999);
              }

              updateInputValue(value, name);
              updateInputValue(undefined, 'presetLabel');
            }
          }}
          label={label}
          value={processedValues[name]}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
});
