import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { GridRowParams } from '@material-ui/data-grid';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import {
  ANALYTICS_DETAILS_TABS,
  ANALYTICS_TABS,
  analyticsDetailsParameters,
  analyticsTabParameters,
  strToUrl,
  urlToStr,
} from '../../utils/routing';
import { TitleNumberCard } from '../Dashboard/TitleNumberCard';
import { gridColumn } from '../../utils/gridUtils';
import { Api } from '../../api';
import TextLink from '../../components/other/TextLink';
import dashboardStore from '../../state/dashboardStore';
import { timeRangeStore } from '../../state/timeRangeStore';
import { DateRangeOpener } from './DateRangeOpener';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import {
  useAccountFilter,
  getCommonParams,
  setNamesForAccounts,
  setNamesForUsers,
} from './useAnalyticsParams';

const getAnalyticsColumns = (activeTab, history, sortableFields) => [
  gridColumn('name', {
    flex: 5,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      if (params.row.accountId) {
        return (
          <TextLink
            to={analyticsDetailsParameters(
              ANALYTICS_TABS.BY_ACCOUNT,
              params.row.accountId,
              ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS,
            )}
            label={params.row.name}
          />
        );
      }
      if (params.row.userId) {
        return (
          <TextLink
            to={analyticsDetailsParameters(
              ANALYTICS_TABS.BY_USER,
              params.row.userId,
              ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS,
            )}
            label={params.row.name}
          />
        );
      }
      if (params.row.chargePointId) {
        return (
          <TextLink
            to={analyticsDetailsParameters(
              ANALYTICS_TABS.BY_CHARGER,
              params.row.chargePointId,
              ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS,
            )}
            label={params.row.name}
          />
        );
      }
      if (params.row.locationId) {
        return (
          <TextLink
            to={analyticsDetailsParameters(
              ANALYTICS_TABS.BY_LOCATION,
              params.row.locationId,
              ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS,
            )}
            label={params.row.name}
          />
        );
      }
      if (params.row.tagId) {
        return (
          <TextLink
            to={analyticsDetailsParameters(
              ANALYTICS_TABS.BY_TAG,
              params.row.tagId,
              ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS,
            )}
            label={params.row.name}
          />
        );
      }

      return <div>{params.row.name}</div>;
    },
  }),
  gridColumn('sessionCount', {
    flex: 2,
    sortableFields,
    headerName: 'No of sessions',
  }),
  gridColumn('totalKWh', {
    flex: 2,
    sortableFields,
    headerName: 'Total kWh',
  }),
  gridColumn('userCount', {
    flex: 2,
    sortableFields,
    headerName: 'No of users',
  }),
  gridColumn('totalTime', {
    flex: 2,
    sortableFields,
  }),
];

const spacing = 3;

export const AnalyticsPage = observer(() => {
  const history = useHistory();
  const analyticsProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);

  const [rows, setRows] = useState([]);

  const { tab } = useParams<{ tab: string }>();

  const [sessionsCount, setSessionsCount] = useState<number | undefined>();
  const [totalKWh, setTotalKWh] = useState<number | undefined>();
  const [usersCount, setUsersCount] = useState<number | undefined>();
  const [totalTime, setTotalTime] = useState<number | undefined>();

  // Use the custom hook for account filtering
  const accountFilter = useAccountFilter();

  const loadCardValues = () => {
    const params = getCommonParams(accountFilter);
    Api.charge.getStatsSummary(params).then((res) => {
      const data = res.data.data;
      setSessionsCount(data.sessionCount);
      setTotalKWh(data.totalKWh);
      setUsersCount(data.userCount);
      setTotalTime(data.totalTime);
    });
  };

  const clearRows = () => {
    setRows([]);
  };

  const setRowsFromResponse = (resData: any) => {
    const newRows = resData.map((row: any, idx: number) => ({
      id: idx,
      name: row.userId || row.accountId ? '' : row.name,
      sessionCount: row.sessionCount,
      totalKWh: row.totalKWh,
      userCount: row.userCount,
      totalTime: row.totalTime,
      accountId: row.accountId,
      locationId: row.locationId,
      chargePointId: row.chargePointId,
      userId: row.userId,
      tagId: row.tagId,
    }));

    setRows(newRows);

    if (resData && resData?.length) {
      if (resData[0].accountId) {
        const accountIds = resData.map((row: any) => row.accountId);
        setNamesForAccounts(accountIds, newRows, setRows);
      }
      if (resData[0].userId) {
        const userIds = resData.map((row: any) => row.userId);
        setNamesForUsers(userIds, newRows, setRows);
      }
    }
  };

  useEffect(() => {
    loadCardValues();
    const params = getCommonParams(accountFilter);
    clearRows();
    switch (tab) {
      case strToUrl(ANALYTICS_TABS.BY_LOCATION):
        analyticsProvider.setRequest((offset, sortBy, sortOrder) =>
          Api.charge.getLocationStats({ skip: offset, take: 20, sortBy, sortOrder, ...params }),
        );
        break;
      case strToUrl(ANALYTICS_TABS.BY_CHARGER):
        analyticsProvider.setRequest((offset, sortBy, sortOrder) =>
          Api.charge.getChargerStats({ skip: offset, take: 20, sortBy, sortOrder, ...params }),
        );
        break;
      case strToUrl(ANALYTICS_TABS.BY_ACCOUNT):
        analyticsProvider.setRequest((offset, sortBy, sortOrder) =>
          Api.charge.getAccountStats({ skip: offset, take: 20, sortBy, sortOrder, ...params }),
        );
        break;
      case strToUrl(ANALYTICS_TABS.BY_USER):
        analyticsProvider.setRequest((offset, sortBy, sortOrder) =>
          Api.charge.getUserStats({ skip: offset, take: 20, sortBy, sortOrder, ...params }),
        );
        break;
      case strToUrl(ANALYTICS_TABS.BY_TAG):
        analyticsProvider.setRequest((offset, sortBy, sortOrder) =>
          Api.charge.getTagStats({ skip: offset, take: 20, sortBy, sortOrder, ...params }),
        );
        break;
      default:
        console.log('No valid tab selected');
        break;
    }

    analyticsProvider.setOnFulfilled((data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
      setRowsFromResponse(data);
    });
    analyticsProvider.reloadResource();
  }, [tab, timeRangeStore.label, timeRangeStore.start, timeRangeStore.end]);

  useEffect(() => {
    dashboardStore.appBarLabel = 'Analytics';
  }, []);

  if (!tab) {
    return <Redirect to={analyticsTabParameters(ANALYTICS_TABS.BY_LOCATION)} />;
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <DateRangeOpener />
      </div>
      <Grid container spacing={spacing} style={{ marginBottom: 24 }}>
        <Grid item xs={6}>
          <Grid container spacing={spacing}>
            <Grid item xs={6}>
              <TitleNumberCard title="No. of sessions" number={sessionsCount} defaultText="0" />
            </Grid>
            <Grid item xs={6}>
              <TitleNumberCard title="Total kwh" number={totalKWh} defaultText="0" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={spacing}>
            <Grid item xs={6}>
              <TitleNumberCard title="No. of users" number={usersCount} defaultText="0" />
            </Grid>
            <Grid item xs={6}>
              <TitleNumberCard title="Total time" number={totalTime} defaultText="0" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ViziTabs
        tabs={Object.values(ANALYTICS_TABS).filter(
          (x) => x !== ANALYTICS_TABS.BY_ACCOUNT || !accountFilter,
        )}
        basePath={analyticsTabParameters()}
      >
        <ViziTab
          label={ANALYTICS_TABS.BY_LOCATION}
          linkTo={analyticsTabParameters(ANALYTICS_TABS.BY_LOCATION)}
        />
        <ViziTab
          label={ANALYTICS_TABS.BY_CHARGER}
          linkTo={analyticsTabParameters(ANALYTICS_TABS.BY_CHARGER)}
        />
        {!accountFilter && (
          <ViziTab
            label={ANALYTICS_TABS.BY_ACCOUNT}
            linkTo={analyticsTabParameters(ANALYTICS_TABS.BY_ACCOUNT)}
          />
        )}
        <ViziTab
          label={ANALYTICS_TABS.BY_USER}
          linkTo={analyticsTabParameters(ANALYTICS_TABS.BY_USER)}
        />
        <ViziTab
          label={ANALYTICS_TABS.BY_TAG}
          linkTo={analyticsTabParameters(ANALYTICS_TABS.BY_TAG)}
        />
      </ViziTabs>
      <VMPaginatedGrid
        rows={rows}
        columns={getAnalyticsColumns(tab, history, sortableFields)}
        label={urlToStr(tab)}
        count={analyticsProvider.count}
        onPageOffsetChange={analyticsProvider.onPageChange}
        onSortTypeChange={analyticsProvider.setSort}
      />
    </div>
  );
});
