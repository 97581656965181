import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { Api } from '../../api';
import { getAllChargersColumns } from './AssignedChargers';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';

const WithIssuesChargers = observer(() => {
  const withIssuesChargersProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);

  useEffect(() => {
    withIssuesChargersProvider.reloadResource();
  }, [withIssuesChargersProvider]);

  const filterCallback = (param?: string, val?: string) => {
    const urlParams: any = {};
    if (param && val) {
      urlParams[param] = val;
    }
    const requestFunction = (offset, sortBy, sortOrder) =>
      Api.charge.listChargersWithIssues({ ...urlParams, skip: offset, take: 20, sortBy, sortOrder });

    withIssuesChargersProvider.setRequest(requestFunction);
    withIssuesChargersProvider.reloadResource();
  };

  useEffect(() => {
    withIssuesChargersProvider.setRequest((offset, sortBy, sortOrder) =>
      Api.charge.listChargersWithIssues({ skip: offset, take: 20, sortBy, sortOrder }),
    );

    withIssuesChargersProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
    });

    withIssuesChargersProvider.reloadResource();
  }, []);
  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        rows={withIssuesChargersProvider.data}
        columns={getAllChargersColumns(withIssuesChargersProvider, sortableFields)}
        onPageOffsetChange={withIssuesChargersProvider.onPageChange}
        onSortTypeChange={withIssuesChargersProvider.setSort}
        label="With Issues Chargepoints"
        filterParamOptions={[
          { label: 'OCPP ID', value: 'ocppId' },
          { label: 'Location', value: 'location' },
        ]}
        filterCallback={filterCallback}
      />
    </div>
  );
});

export default WithIssuesChargers;
