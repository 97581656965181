import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import moment from 'moment';
import VMDataGrid from '../../components/main/VMDataGrid';
import dashboardStore from '../../state/dashboardStore';
import { TitleNumberCard } from '../Dashboard/TitleNumberCard';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import {
  ANALYTICS_DETAILS_TABS,
  ANALYTICS_TABS,
  analyticsDetailsParameters,
  route,
  strToUrl,
} from '../../utils/routing';
import { Api } from '../../api';
import { DateRangeOpener } from './DateRangeOpener';
import { timeRangeStore } from '../../state/timeRangeStore';
import { PAGES } from '../../components/main/MainComponent';
import { gridColumn } from '../../utils/gridUtils';
import { formatToSloDate } from '../Sessions/SessionsPage';
import AnalyticsDetailsChart from './AnalyticsDetailsChart';
import { injectDynamicStyles } from '../../styles/analyticsStyles';
import {
  useAccountFilter,
  getCommonParams,
} from './useAnalyticsParams';

const spacing = 3;

export const AnalyticsDetails = observer(() => {
  const { tab } = useParams<{ tab: string }>();
  const { id } = useParams<{ id: string }>();
  const { subtab } = useParams<{ subtab: string }>();

  const [sessionsCount, setSessionsCount] = useState<number | undefined>();
  const [totalKWh, setTotalKWh] = useState<number | undefined>();
  const [usersCount, setUsersCount] = useState<number | undefined>();
  const [totalTime, setTotalTime] = useState<number | undefined>();

  const [mappedData, setMappedData] = useState<any[]>([]);

  const [rows, setRows] = useState<any[]>([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [dateColumnWidth, setDateColumnWidth] = useState(150);
  const [units, setUnits] = useState('');
  const accountFilter = useAccountFilter();

  useEffect(() => {
    setEditingRowId(-1);
  }, []);

  useEffect(() => {
    const updateDateColumnWidth = () => {
      const dateCell = document.querySelector(
        '.MuiDataGrid-cell[data-field="date"][data-rowindex="0"]',
      );

      if (dateCell) {
        const width = dateCell.getBoundingClientRect().width;
        setDateColumnWidth(width);
      } else {
        console.log('Date cell not found');
      }
    };
    updateDateColumnWidth();
    window.addEventListener('resize', updateDateColumnWidth);
    return () => {
      window.removeEventListener('resize', updateDateColumnWidth);
    };
  }, [editingRowId]);

  const getRowIndex = () => rows.findIndex((row) => row.id === editingRowId);

  useEffect(() => {
    if (editingRowId !== null) {
      injectDynamicStyles(getRowIndex(), dateColumnWidth);
    }
  }, [editingRowId, dateColumnWidth]);

  const handleRowClick = (params) => {
    console.log('On Row Click', params);
    setEditingRowId(params.id);
  };

  const getRows = () => {
    const newRows = [];
    mappedData.forEach((dayItem) => {
      const newRow = {
        id: dayItem.date,
        date: dayItem.date,
      };
      dayItem.hourValues?.forEach((hourValue) => {
        console.log(hourValue);
        let cellValue = '';
        switch (subtab) {
          case strToUrl(ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS):
            cellValue = hourValue.sessionCount;
            setUnits('');
            break;
          case strToUrl(ANALYTICS_DETAILS_TABS.TOTAL_KWH):
            cellValue = hourValue.totalKWh;
            setUnits('kWH');
            break;
          case strToUrl(ANALYTICS_DETAILS_TABS.NO_OF_USERS):
            cellValue = hourValue.userCount;
            setUnits('');
            break;
          case strToUrl(ANALYTICS_DETAILS_TABS.TOTAL_TIME):
            cellValue = hourValue.totalTime;
            setUnits('min');
            break;
          default:
            break;
        }

        newRow[`hour_${hourValue.hour}_unrounded`] = cellValue;

        // if cellValue is number round it
        if (typeof cellValue === 'number') {
          const rounded = Math.round(cellValue);
          cellValue = rounded.toString();
        }

        newRow[`hour_${hourValue.hour}`] = cellValue;
      });
      newRows.push(newRow);
    });
    return newRows;
  };

  const getItemColumns = () => {
    const columns = [
      gridColumn('date', {
        flex: 4,
        minWidth: 100,
        renderCell: (params: GridRowParams) => {
          const { date } = params.row;
          const formattedDate = formatToSloDate(date, false);
          const day = moment(date).format('ddd');
          return (
            <div
              style={{ marginLeft: 10, whiteSpace: 'pre-wrap' }}
            >
              {`${formattedDate}  ${day}`}
            </div>
          );
        },
      }),
      {
        field: 'custom',
        headerName: ' ',
        flex: 0.1,
        minWidth: 10,
        renderCell: (params) => {
          if (editingRowId === params.id) {
            return (
              <div className="custom-cell">
                <AnalyticsDetailsChart
                  rowData={params.row}
                  unit={units}
                  onClose={(e) => { e.stopPropagation(); setEditingRowId(-1); }}
                />
              </div>
            );
          }
          return null;
        },
      },
    ];

    for (let i = 0; i <= 23; i++) {
      columns.push(
        gridColumn(`hour_${i}`, {
          flex: 1,
          minWidth: 24,
          headerName: `${i}`,
          hide: false,
          renderCell: (params: GridRowParams) => {
            if (editingRowId === params.id) {
              return <div />;
            }

            const values = Object.keys(params.row).filter((key) => key.startsWith('hour_')).map((key) => params.row[key]);
            const maxValue = Math.max(...values, 0);
            const getColorIndex = (value) => {
              if (maxValue === 0) return 0;
              return Math.min(9, Math.floor((value / maxValue) * 9));
            };

            const unroundedValue = params.row[`hour_${i}_unrounded`] ?? 0;
            let hoverValue = unroundedValue;
            let lookupValue = Math.max(0, Math.min(params.row[`hour_${i}`] ?? 0, 9));

            if (subtab === 'total-kwh') {
              lookupValue = Math.max(0, Math.floor(unroundedValue * 10) / 10);
            } else if (subtab === 'total-time') {
              lookupValue = Math.max(0, unroundedValue ?? 0);
              const hours = Math.floor(lookupValue / 60);
              const remainingMinutes = lookupValue % 60;
              hoverValue = `${hours}h ${remainingMinutes}m`;
            }

            lookupValue = Number.isNaN(lookupValue) ? 0 : lookupValue;

            const colorLookup = {
              0: '#FFFFFF',
              1: '#E3FFA9',
              2: '#FFF1A9',
              3: '#FFCDA9',
              4: '#FFBBA9',
              5: '#FF8585',
              6: '#FF6161',
              7: '#FF4242',
              8: '#FF0000',
              9: '#FF0000',
            };
            let color = colorLookup[lookupValue];
            if (subtab === 'total-time' || subtab === 'total-kwh') {
              color = colorLookup[getColorIndex(lookupValue)];
            }
            return (
              <Tooltip
                title={<Typography variant="body2">{hoverValue ?? lookupValue}</Typography>}
                enterDelay={100}
                enterNextDelay={100}
              >
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: color,
                  }}
                  // onClick={() => handleRowClick(params)}
                >
                  <span>{lookupValue}</span>
                </div>
              </Tooltip>
            );
          },
        }),
      );
    }
    return columns;
  };

  const loadCardValues = () => {
    const params = getCommonParams(accountFilter);

    if (tab === strToUrl(ANALYTICS_TABS.BY_LOCATION)) {
      params.locationId = id;
    }
    if (tab === strToUrl(ANALYTICS_TABS.BY_CHARGER)) {
      params.chargePointId = id;
    }
    if (tab === strToUrl(ANALYTICS_TABS.BY_ACCOUNT)) {
      params.accountId = id;
    }
    if (tab === strToUrl(ANALYTICS_TABS.BY_USER)) {
      params.userId = id;
    }
    if (tab === strToUrl(ANALYTICS_TABS.BY_TAG)) {
      params.tagId = id;
    }

    Api.charge.getStatsSummary(params).then((res) => {
      const data = res.data.data;
      setSessionsCount(data.sessionCount);
      setTotalKWh(data.totalKWh);
      setUsersCount(data.userCount);
      setTotalTime(data.totalTime);
    });
  };

  const setAppbarLabelFromName = () => {
    switch (tab) {
      case strToUrl(ANALYTICS_TABS.BY_LOCATION):
        Api.charge.getLocation(id).then((res) => {
          const name = res.data.data.name;
          dashboardStore.appBarLabel = `Analytics - ${name}`;
        });
        break;
      case strToUrl(ANALYTICS_TABS.BY_CHARGER):
        Api.charge.getCharger(id).then((res) => {
          const ocppId = res.data.data.ocppId;
          dashboardStore.appBarLabel = `Analytics - ${ocppId}`;
        });
        break;
      case strToUrl(ANALYTICS_TABS.BY_ACCOUNT):
        Api.business.getAccount(id).then((res) => {
          const name = res.data.data.name;
          dashboardStore.appBarLabel = `Analytics - ${name}`;
        });
        break;
      case strToUrl(ANALYTICS_TABS.BY_USER):
        Api.user.getUser(id).then((res) => {
          const name = res.data.data.name;
          const familyName = res.data.data.familyName;
          const fullName = `${name} ${familyName}`;
          dashboardStore.appBarLabel = `Analytics - ${fullName}`;
        });
        break;
      case strToUrl(ANALYTICS_TABS.BY_TAG):
        dashboardStore.appBarLabel = `Analytics - ${id}`;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setAppbarLabelFromName();
  }, [id, tab]);

  interface HourValue {
    hour: number;
    sessionCount: number;
    totalKWh: number;
    userCount: number;
    totalTime: number;
  }

  interface MappedDayItem {
    date?: string;
    hourValues?: Map<number, HourValue>;
  }

  const setMappedDataFromResponse = (res) => {
    const resObj = res.data.data;
    const resDays = resObj.items;

    const newMappedData = [];
    resDays?.forEach((day, idx) => {
      const mappedDayItem: MappedDayItem = {};
      mappedDayItem.date = day.date;
      mappedDayItem.hourValues = new Map<number, HourValue>();

      day.items?.forEach((item) => {
        const hour = item.hour;
        mappedDayItem.hourValues?.set(hour, {
          hour,
          sessionCount: item.sessionCount,
          totalKWh: item.totalKWh,
          userCount: item.userCount,
          totalTime: item.totalTime,
        });
      });

      newMappedData.push(mappedDayItem);
    });

    setMappedData(newMappedData);
  };

  const loadStatItems = () => {
    const params = getCommonParams(accountFilter);

    switch (tab) {
      case strToUrl(ANALYTICS_TABS.BY_LOCATION):
        Api.charge.getLocationStatsItems(id, params).then((res) => {
          setMappedDataFromResponse(res);
        });
        break;
      case strToUrl(ANALYTICS_TABS.BY_CHARGER):
        Api.charge.getChargerStatsItems(id, params).then((res) => {
          setMappedDataFromResponse(res);
        });
        break;
      case strToUrl(ANALYTICS_TABS.BY_ACCOUNT):
        Api.charge.getAccountStatsItems(id, params).then((res) => {
          setMappedDataFromResponse(res);
        });
        break;
      case strToUrl(ANALYTICS_TABS.BY_USER):
        Api.charge.getUserStatsItems(id, params).then((res) => {
          setMappedDataFromResponse(res);
        });
        break;
      case strToUrl(ANALYTICS_TABS.BY_TAG):
        Api.charge.getTagStatsItems(id, params).then((res) => {
          setMappedDataFromResponse(res);
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadCardValues();
    loadStatItems();
  }, [timeRangeStore.label, timeRangeStore.start, timeRangeStore.end]);

  useEffect(() => {
    setRows(getRows());
  }, [mappedData, subtab]);

  if (!subtab) {
    return <Redirect to={analyticsDetailsParameters(tab, id, ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS)} />;
  }

  return (
    <div className="expanding_table" style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <DateRangeOpener />
      </div>
      <Grid container spacing={spacing} style={{ marginBottom: 24 }}>
        <Grid item xs={6}>
          <Grid container spacing={spacing}>
            <Grid item xs={6}>
              <TitleNumberCard title="No. of sessions" number={sessionsCount} defaultText="0" />
            </Grid>
            <Grid item xs={6}>
              <TitleNumberCard title="Total kwh" number={totalKWh} defaultText="0" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={spacing}>
            <Grid item xs={6}>
              <TitleNumberCard title="No. of users" number={usersCount} defaultText="0" />
            </Grid>
            <Grid item xs={6}>
              <TitleNumberCard title="Total time" number={totalTime} defaultText="0" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ViziTabs
        tabs={Object.values(ANALYTICS_DETAILS_TABS)}
        basePath={strToUrl(`${route(PAGES.Analytics)}/${tab}/${id}`)}
      >
        <ViziTab
          label={ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS}
          linkTo={analyticsDetailsParameters(tab, id, ANALYTICS_DETAILS_TABS.NO_OF_SESSIONS)}
        />
        <ViziTab
          label={ANALYTICS_DETAILS_TABS.TOTAL_KWH}
          linkTo={analyticsDetailsParameters(tab, id, ANALYTICS_DETAILS_TABS.TOTAL_KWH)}
        />
        <ViziTab
          label={ANALYTICS_DETAILS_TABS.NO_OF_USERS}
          linkTo={analyticsDetailsParameters(tab, id, ANALYTICS_DETAILS_TABS.NO_OF_USERS)}
        />
        <ViziTab
          label={ANALYTICS_DETAILS_TABS.TOTAL_TIME}
          linkTo={analyticsDetailsParameters(tab, id, ANALYTICS_DETAILS_TABS.TOTAL_TIME)}
        />
      </ViziTabs>

      <VMDataGrid
        rows={rows}
        columns={getItemColumns()}
        label="Comparison"
        pageSize={60}
        removeCellPadding
        onRowClick={handleRowClick}
      />
    </div>
  );
});
