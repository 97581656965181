import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GridRowParams } from '@material-ui/data-grid';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Tooltip, Typography } from '@material-ui/core';
import { toJS } from 'mobx';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FormInputTypes } from '../../utils/constants';
import { route, LOCATION_DETAILS_TABS, locationDetailsTabParameters } from '../../utils/routing';
import { Charger, CHARGER_TYPE } from '../../types/Charger';
import { drawerStore } from '../../state/drawerStore';
import TextLink from '../../components/other/TextLink';
import { ConnectorsList } from '../../components/other/Connectors';
import { gridColumn } from '../../utils/gridUtils';
import { ChargerStatusBadge } from '../../components/other/CellBadges';
import { Api } from '../../api';
import ActionsList from '../../components/other/ActionsList';
import { copyTextToClipboard } from '../../utils/utils';
import { greenTextColor } from '../../components/main/react/App';
import { PAGES } from '../../components/main/MainComponent';
import credentialStore from '../../state/credentialStore';
import { getUserAutocompleteInput } from '../../components/forms/UserEmailAutocomplete';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { useProvider } from '../../state/providerStore';

const publicChargerCondition = (values) => values.type === CHARGER_TYPE.PUBLIC;
const domicilChargerCondition = (values) => values.type === CHARGER_TYPE.DOMICIL;

const chargerSelectOption = (charger: Charger) => ({
  optionName: charger.id,
  topRow: `${charger.manufacturer}, ${charger.model}`,
  bottomRow: `${charger.serial}`,
});

const chargerAssignRows = ['type', 'locationId', 'userId', 'identifier'];

/* const getChargerAssignInput = (unassignedProvider) =>
  ({
    name: 'Charger',
    type: FormInputTypes.multilineSelect,
    options:
    // @ts-ignore
        unassignedProvider.data !== undefined
          ? unassignedProvider.data.map((charger) => chargerSelectOption(charger))
          : [],
  }); */

export const locationSelectOption = (location) => ({
  optionName: location.id,
  topRow: `${location.name}`,
  bottomRow: `${location.address}, ${location.city}`,
});

const assignChargerTypeInput = [
  { name: 'type', type: FormInputTypes.select, options: [CHARGER_TYPE.PUBLIC, CHARGER_TYPE.DOMICIL] },
];

const getPublicChargerInputs = (locationsProvider) => [
  {
    name: 'locationId',
    label: 'Location',
    type: FormInputTypes.multilineSelect,
    options:
      // @ts-ignore
      locationsProvider.data !== undefined
        ? locationsProvider.data.map((location) => locationSelectOption(location))
        : [],
    condition: () => publicChargerCondition(drawerStore.values),
  },
];

const onUserAddInputChange = (isAmin, usersProvider, inputValue, locationsProvider) => {
  const urlParams: any = { email: inputValue };

  usersProvider.setOnFulfilled((data) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    drawerStore.setInputs(getAssignChargerCombinedInputs(isAmin, locationsProvider, usersProvider));
    drawerStore.setValues({ ...drawerStore.values });
  });

  usersProvider.setRequest(() => Api.user.listUsers(urlParams));
  usersProvider.reloadResource();
};

const getUserInput = (isAdmin, usersProvider, locationsProvider) => {
  if (isAdmin) {
    return getUserAutocompleteInput(usersProvider.data.users, (inputValue) =>
      onUserAddInputChange(isAdmin, usersProvider, inputValue, locationsProvider),
    );
  } else {
    return { name: 'userId', type: FormInputTypes.textField };
  }
};

const getDomicilChargerInputs = (isAdmin, usersProvider, locationsProvider) => [
  {
    ...getUserInput(isAdmin, usersProvider, locationsProvider),
    condition: () => domicilChargerCondition(drawerStore.values),
  },
];

const assignChargerIdentifierInput = [{ name: 'identifier', type: FormInputTypes.textField }];

export function getAssignChargerCombinedInputs(isAdmin, locationsProvider, usersProvider) {
  return [
    ...assignChargerTypeInput,
    ...getPublicChargerInputs(locationsProvider),
    ...getDomicilChargerInputs(isAdmin, usersProvider, locationsProvider),
    ...assignChargerIdentifierInput,
  ];
}

const ConfirmationDialog = ({ text, dialogOpen, setDialogOpen, confirmCallback }) => (
  <Dialog open={dialogOpen}>
    <DialogContent>
      <Typography>{text}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
      <Button
        onClick={() => {
          setDialogOpen(false);
          confirmCallback();
        }}
        autoFocus
        color="primary"
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

const UnassignedChargers = observer(() => {
  const unassignedProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);
  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      unassignedProvider.setRequest((offset, sortBy, sortOrder) =>
        Api.charge.listUnassignedChargers({ skip: offset, sortBy, sortOrder }),
      );
    } else {
      unassignedProvider.setRequest((offset, sortBy, sortOrder) =>
        Api.charge.listUnassignedChargersForAccount(credentialStore.selectedAccount.id, {
          skip: offset,
          sortBy,
          sortOrder,
        }),
      );
    }
    unassignedProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
    });
    unassignedProvider.reloadResource();
  }, [credentialStore.selectedAccount]);

  const filterCallback = (param?: string, val?: string) => {
    const urlParams: any = {};
    if (param && val) {
      urlParams[param] = val;
    }
    unassignedProvider.setRequest((offset) =>
      Api.charge.listUnassignedChargers({
        ...urlParams,
        skip: offset,
        take: 20,
        sortBy: unassignedProvider.sortBy,
        sortOrder: unassignedProvider.sortOrder,
      }),
    );
    unassignedProvider.reloadResource();
  };

  const locationsProvider = useProvider(Api.charge.listLocations);
  const usersProvider = useProvider(Api.user.listUsers);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogChargerId, setDialogChargerId] = useState(null);

  const [duplicatedOcppIds, setDuplicatedOcppIds] = useState([]);

  const setDuplicateOcppIds = async () => {
    if (unassignedProvider.data !== undefined) {
      let newDuplicated = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const charger of unassignedProvider.data) {
        // eslint-disable-next-line no-await-in-loop
        const resp = await Api.charge.isDuplicatedOcppId(charger.ocppId);
        const isDuplicated = resp?.data?.data;
        if (isDuplicated === true) {
          newDuplicated = [...newDuplicated, charger.ocppId];
        }
      }

      setDuplicatedOcppIds(newDuplicated);
    }
  };

  useEffect(() => {
    setDuplicateOcppIds().then();
  }, [unassignedProvider.data]);

  const confirmAssignCharger = (chargerId, values) => {
    if (values.userId) {
      Api.charge
        .assignChargerToUser(chargerId, values.userId, values.identifier)
        .then(unassignedProvider.reloadResource);
    } else if (values.locationId) {
      Api.charge
        .assignChargerToLocation(chargerId, values.locationId, values.identifier)
        .then(unassignedProvider.reloadResource);
    }
  };

  const confirmDeleteCharger = (chargerId) => {
    Api.charge.deleteCharger(chargerId).then(unassignedProvider.reloadResource);
  };

  const onClickDeleteCharger = (charger: Charger) => {
    setDialogChargerId(charger.id);
    setDialogOpen(true);
  };

  const onClickAssignCharger = (charger: Charger) => {
    drawerStore.openDrawer({
      label: 'Assign charge point',
      inputs: getAssignChargerCombinedInputs(credentialStore.isUserAdmin, locationsProvider, usersProvider),
      clickCallback: () => confirmAssignCharger(charger.id, toJS(drawerStore.values)),
    });
  };

  const unassignedChargerActions = (params: GridRowParams) => (
    <ActionsList
      actions={[
        {
          label: 'Assign',
          callback: () => {
            onClickAssignCharger(params.row as Charger);
          },
        },
        {
          label: 'Remove',
          callback: () => {
            onClickDeleteCharger(params.row as Charger);
          },
        },
      ]}
    />
  );

  const unassignedChargersColumns = [
    gridColumn('ocppId', {
      flex: 3,
      headerName: 'OCPP ID',
      sortableFields,
      renderCell: (params: GridRowParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextLink to={`${route(PAGES.Chargers)}/details/${params.row.id}`} label={params.row.ocppId} />
          <IconButton
            onClick={() => {
              copyTextToClipboard(params.row.ocppId);
            }}
          >
            <ContentCopyIcon style={{ marginLeft: '0.3em', color: greenTextColor, fontSize: 18 }} />
          </IconButton>
          {duplicatedOcppIds.includes(params.row.ocppId) && (
            <Tooltip title="Duplicated OcppId" placement="top">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0 }}>
                <WarningAmberIcon style={{ color: 'red', marginLeft: '0.5em' }} />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    }),
    gridColumn('manufacturer', {
      flex: 2,
      headerName: 'manufacturer/model',
      sortableFields,
      valueGetter: (params: GridRowParams) => `${params.row.manufacturer} / ${params.row.model}`,
    }),
    gridColumn('address', {
      flex: 3,
      headerName: 'Location',
      renderCell: (params: GridRowParams) => (
        <TextLink
          to={locationDetailsTabParameters(params.row.locationId, LOCATION_DETAILS_TABS.OVERVIEW)}
          label={params.row.location?.name}
        />
      ),
    }),
    gridColumn('serial', {
      flex: 2,
      sortableFields,
    }),
    gridColumn('charging points', {
      flex: 2,
      headerName: 'Charging points',
      sortableFields,
      renderCell: (params: GridRowParams) => <ConnectorsList connectors={params.row.connectors} />,
    }),
    gridColumn('status', {
      width: 150,
      align: 'center',
      sortableFields,
      renderCell: (params: GridRowParams) => <ChargerStatusBadge status={params.row.status} />,
    }),
    gridColumn('createdAt', { flex: 1, headerName: 'Created at', sortableFields }),
    gridColumn('actions', {
      width: 150,
      renderCell: (params: GridRowParams) => unassignedChargerActions(params),
      align: 'center',
      headerAlign: 'center',
    }),
  ];

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        rows={unassignedProvider.data}
        columns={unassignedChargersColumns}
        label="Unassigned chargepoints"
        count={unassignedProvider.count}
        onPageOffsetChange={unassignedProvider.onPageChange}
        onSortTypeChange={unassignedProvider.setSort}
        filterParamOptions={[
          { label: 'OCPP ID', value: 'ocppId' },
          { label: 'Location', value: 'location' },
        ]}
        filterCallback={filterCallback}
      />

      <ConfirmationDialog
        text="Are you sure you want to delete charger"
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        confirmCallback={() => confirmDeleteCharger(dialogChargerId)}
      />
    </div>
  );
});

export default UnassignedChargers;
