import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { ConnectorMarkerData } from '../../pages/Dashboard/DashboardPage';
import { MapConnectorMarker } from '../../pages/Dashboard/MapConnectorMarker';

const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

export interface GoogleMapsWrapperProps {
  pos: { lat: number; lng: number };
  zoom?: number;
  dragCallback?: (lat, lng) => void;
  showLocationMarker?: boolean;
  connectorMarkers?: ConnectorMarkerData[];
}

const GoogleMapsWrapper = ({
  pos,
  zoom,
  dragCallback,
  showLocationMarker,
  connectorMarkers,
}: GoogleMapsWrapperProps) => {
  const onDragEnd = (e) => {
    const lat = e.latLng.lat().toFixed(6);
    const lng = e.latLng.lng().toFixed(6);
    dragCallback(lat, lng);
  };

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const GoogleMapsContainer = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={zoom}
        defaultCenter={pos}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        {showLocationMarker && <Marker position={pos} draggable onDragEnd={onDragEnd} />}
        {connectorMarkers?.map((data, index) => (
          <MapConnectorMarker key={`${data.lat}_${data.lng}_${index}`} data={data} />
        ))}
      </GoogleMap>
    )),
  );

  return (
    <GoogleMapsContainer
      googleMapURL={googleMapURL}
      loadingElement={<div style={{ height: '100%', width: '100%' }} />}
      containerElement={
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
        />
      }
      mapElement={<div style={{ height: '100%', width: '100%', borderRadius: 16 }} />}
    />
  );
};

export default GoogleMapsWrapper;
