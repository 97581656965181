import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { GridRowParams } from '@material-ui/data-grid';
import { ListItem, Typography } from '@material-ui/core';
import VMDataGrid from '../../components/main/VMDataGrid';

import { gridColumn } from '../../utils/gridUtils';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';
import TextLink from '../../components/other/TextLink';
import {
  COMPANY_DETAILS_TABS,
  companyDetailsRoute,
  companyPageRoute,
  walletDetailsParameters,
  walletDetailsRoute,
} from '../../utils/routing';
import { WalletDetails } from '../Chargers/Wallets/WalletDetails';
import ActionsMenu from '../../components/other/ActionsMenu';
import { Api } from '../../api';
import { useProvider } from '../../state/providerStore';
import { listOwnerWalletsForAccount } from '../../api/billingApi';

const walletAddInputs = [
  { name: 'name', type: FormInputTypes.textField },
  { name: 'ownerName', type: FormInputTypes.textField },
  { name: 'description', type: FormInputTypes.textField },
  { name: 'searchAddress', type: FormInputTypes.googleAddressAutocomplete },
  { name: 'address', type: FormInputTypes.textField },
  { name: 'zip', type: FormInputTypes.textField },
  { name: 'city', type: FormInputTypes.textField },
  { name: 'country', type: FormInputTypes.textField },
  {
    name: 'ownerType',
    type: FormInputTypes.select,
    options: ['company', 'individual'],
  },
  { name: 'VAT', type: FormInputTypes.textField },
];

const WalletActions = ({ walletsProvider, wallet }) => (
  <ActionsMenu>
    <ListItem
      button
      onClick={() => {
        Api.billing.deleteWallet(wallet.id).then(walletsProvider.reloadResource);
      }}
    >
      <Typography style={{ width: '100%' }}>Delete</Typography>
    </ListItem>
  </ActionsMenu>
);

const CompanyDetailsWallets = observer(({ companyid }: { companyid: string }) => {
  const walletsProvider = useProvider(() => listOwnerWalletsForAccount(companyid));

  const confirmWalletAdd = (values) => {
    values.accountId = companyid;

    Api.billing.createWallet(values).then(walletsProvider.reloadResource);
  };

  const onClickWalletAdd = () => {
    drawerStore.openDrawer({
      label: 'Add wallet',
      inputs: walletAddInputs,
      clickCallback: () => confirmWalletAdd(toJS(drawerStore.values)),
    });
  };

  const walletColumns = [
    gridColumn('name', {
      flex: 2,
      renderCell: (params: GridRowParams) => {
        const walletId = params.row.id;

        return <TextLink to={walletDetailsParameters(walletId)} label={params.row.name} />;
      },
    }),
    gridColumn('ownerName', { flex: 2 }),
    gridColumn('address', { flex: 2 }),
    gridColumn('zip', { flex: 1 }),
    gridColumn('city', { flex: 1 }),
    gridColumn('country', { flex: 1 }),
    gridColumn('ownerType', { flex: 1 }),
    gridColumn('VAT', { flex: 1 }),
    gridColumn('actions', {
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <WalletActions walletsProvider={walletsProvider} wallet={params.row} />,
    }),
  ];

  console.log('companyid', companyid);

  return (
    <div style={{ width: '100%' }}>
      <VMDataGrid onClickAdd={onClickWalletAdd} label="Wallets" rows={walletsProvider.data} columns={walletColumns} />
    </div>
  );
});

export default observer(({ companyid }: { companyid: string }) => (
  <Switch>
    <Route path={walletDetailsRoute()}>
      <WalletDetails />
    </Route>
    <Route path={companyDetailsRoute(COMPANY_DETAILS_TABS.WALLETS)}>
      <CompanyDetailsWallets companyid={companyid} />
    </Route>
    <Route path={companyPageRoute(COMPANY_DETAILS_TABS.WALLETS)}>
      <CompanyDetailsWallets companyid={companyid} />
    </Route>
  </Switch>
));
