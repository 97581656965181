import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip } from '@material-ui/core';

export function MultiAutocomplete({ label, initialTags, onTagsChange }) {
  const [tags, setTags] = useState(initialTags || []);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    onTagsChange(tags.join(' '));
  }, [tags]);

  const handleTagsChange = (event, value, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      setTags(value);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      const newTag = inputValue.trim();
      if (newTag) {
        const newTags = [...tags, newTag];
        setTags(newTags);
        setInputValue('');
      }
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={tags}
      onInputChange={handleInputChange}
      onChange={handleTagsChange}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} placeholder="Add Tags" onKeyDown={handleKeyDown} />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => <Chip label={option} {...getTagProps({ index })} color="primary" />)
      }
    />
  );
}
