import { PixelCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1) {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const pixelRatio = window.devicePixelRatio;

  const cropWidth = crop.width * scaleX;
  const cropHeight = crop.height * scaleY;

  const previewWidth = 128;
  const previewHeight = 128;

  canvas.width = previewWidth * pixelRatio;
  canvas.height = previewHeight * pixelRatio;

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropAspectRatio = cropWidth / cropHeight;
  const previewAspectRatio = previewWidth / previewHeight;

  let scaleRatio;
  if (cropAspectRatio > previewAspectRatio) {
    scaleRatio = previewHeight / cropHeight;
  } else {
    scaleRatio = previewWidth / cropWidth;
  }

  const offsetX = (previewWidth - cropWidth * scaleRatio) / 2;
  const offsetY = (previewHeight - cropHeight * scaleRatio) / 2;

  ctx.save();

  ctx.translate(offsetX, offsetY);
  ctx.scale(scaleRatio * scale, scaleRatio * scale);
  ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

  ctx.restore();
}
