import { useState, useEffect } from 'react';
import credentialStore from '../../state/credentialStore';
import { timeRangeStore } from '../../state/timeRangeStore';
import { Api } from '../../api';
import { arrayToCommaSeparatedString } from '../../api/apiUtils';

// Custom hook for account filtering
export const useAccountFilter = () => {
  const [accountFilter, setAccountFilter] = useState(credentialStore.selectedAccount);

  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      setAccountFilter(null);
    } else {
      setAccountFilter(credentialStore.selectedAccount);
    }
  }, []);

  return accountFilter;
};

type Params = {
  accountId?: any,
  fromDate?: string,
  toDate?: string,
  [key: string]: any,
};

export const getCommonParams = (accountFilter): Params => {
  const fromDate = timeRangeStore.start?.toISOString();
  const toDate = timeRangeStore.end?.toISOString();

  let params: Params = { ...(accountFilter && { accountId: accountFilter.id }) };

  if (fromDate && toDate) {
    params = {
      fromDate,
      toDate,
      ...params,
    };
  }
  return params;
};

// Function to set names for accounts
export const setNamesForAccounts = async (accountIds, accounts, setRows) => {
  const idsParam = arrayToCommaSeparatedString(accountIds);

  const res = await Api.business.listAccounts({ ids: idsParam });
  const accountsData = res.data?.data;

  const updatedRows = accounts.map((row) => {
    const account = accountsData?.find((resRow) => resRow.id === row.accountId);
    if (account) {
      return { ...row, name: account.name };
    }
    return row;
  });

  setRows(updatedRows);
};

// Function to set names for users
export const setNamesForUsers = async (userIds, users, setRows) => {
  const userPromises = userIds.map((userId) => Api.user.getUser(userId));
  const responses = await Promise.all(userPromises);

  const updatedUsers = users.map((user) => {
    const response = responses.find((res) => res.data?.data?.id === user.userId);
    const userName = `${response?.data.data?.name} ${response?.data.data?.familyName}`;

    if (userName) {
      return { ...user, name: userName };
    }

    return user;
  });

  setRows(updatedUsers);
};
