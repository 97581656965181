import React, { ReactNode } from 'react';
import { CardContent, Divider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

const StyledCardContent = observer(({ children, showDivider = true }: {children?: ReactNode, showDivider?: boolean}) => (
  <CardContent>
    { showDivider && <Divider />}
    {children}
  </CardContent>
));

export default StyledCardContent;
