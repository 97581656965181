import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { BILLING_TABS, billingTabRoute, route } from '../../utils/routing';
import { PAGES } from '../../components/main/MainComponent';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import { useFormStyles } from '../../styles/useFormStyles';
import { PaymentsPage } from './PaymentsPage';
import { OpenedBalancesPage } from './OpenedBalancesPage';
import { InvoicesPage } from './InvoicesPage';

export const BillingContainer = observer(() => {
  const formClasses = useFormStyles();

  return (
    <div style={{ width: '100%' }}>
      <div className={formClasses.root}>
        <div className={formClasses.verticalRoot}>
          <ViziTabs tabs={Object.values(BILLING_TABS)} basePath={route(PAGES.Billing)}>
            <ViziTab label={BILLING_TABS.PAYMENTS} linkTo={billingTabRoute(BILLING_TABS.PAYMENTS)} />
            <ViziTab label={BILLING_TABS.OPENED_BALANCES} linkTo={billingTabRoute(BILLING_TABS.OPENED_BALANCES)} />
            <ViziTab label={BILLING_TABS.INVOICES} linkTo={billingTabRoute(BILLING_TABS.INVOICES)} />
          </ViziTabs>
          <Switch>
            <Route path={billingTabRoute(BILLING_TABS.PAYMENTS)}>
              <PaymentsPage />
            </Route>
            <Route path={billingTabRoute(BILLING_TABS.OPENED_BALANCES)}>
              <OpenedBalancesPage />
            </Route>
            <Route path={billingTabRoute(BILLING_TABS.INVOICES)}>
              <InvoicesPage />
            </Route>
            <Route exact path={route(PAGES.Billing)}>
              <Redirect to={billingTabRoute(BILLING_TABS.PAYMENTS)} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
});
