import * as billing from './billingApi';
import * as business from './businessApi';
import * as catalog from './catalogApi';
import * as charge from './chargeApi';
import * as user from './userApi';
import * as docs from './docsApi';

export * as utils from './apiUtils';

export const Api = {
  billing,
  business,
  catalog,
  charge,
  user,
  docs,
};
