import { CONNECTOR_TYPE } from './ConnectorType';

export enum CONNECTOR_STATUS {
  AVAILABLE = 'available',
  MAINTENANCE = 'maintenance',
  OFFLINE = 'offline',
  IN_USE = 'in_use',
  ERROR = 'error',
  READY_TO_CHARGE = 'ready_to_charge'
}

export interface Connector {
  canStart: boolean;
  chargePointID: string;
  id: string;
  name: string;
  occpID: string;
  status: CONNECTOR_STATUS;
  type: CONNECTOR_TYPE;
  vendor: string;
}
