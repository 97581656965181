import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useFormStyles } from '../../styles/useFormStyles';

export interface AccountSelectOption {
  accountName: string;
  id: string;
}

export const accountSelectOption = (account): AccountSelectOption => ({
  accountName: account.name,
  id: account.id,
});

export const AccountNameAutocomplete = observer(({ divKey, options, updateInputValue, inputChangeCallback }: any) => {
  const formClasses = useFormStyles();

  return (
    <div key={divKey} className={formClasses.formDrawerInput}>
      <Autocomplete
        noOptionsText=""
        fullWidth
        disablePortal
        options={options}
        getOptionLabel={(option: AccountSelectOption) => option?.accountName || ''}
        onChange={(e, newVal) => {
          const newOption = newVal as AccountSelectOption;
          if (newOption) {
            updateInputValue(newOption.id, 'accountId');
          }
        }}
        onClose={(e) => {
          if (inputChangeCallback) {
            inputChangeCallback(undefined);
          }
        }}
        onInputChange={(e, _value, reason) => {
          if (inputChangeCallback) {
            inputChangeCallback(_value);
          }
        }}
        renderOption={(option: AccountSelectOption) => (
          <div key={option.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Typography>{option.accountName}</Typography>
          </div>
        )}
        renderInput={(params) => <TextField {...params} label="Account Name" />}
      />
    </div>
  );
});
