import React from 'react';
import { ReactComponent as PublicCharger } from '../../images/Chargers/PublicCharger.svg';
import { ReactComponent as DomicilCharger } from '../../images/Chargers/DomicilCharger.svg';
import { CHARGER_TYPE } from '../../types/Charger';

const PublicChargerIcon = ({ color }: {color: string}) => <PublicCharger style={{ stroke: color }} />;
const DomicilChargerIcon = ({ color }: {color: string}) => <DomicilCharger style={{ stroke: color }} />;

export const ChargerIcon = ({ type }: {type: CHARGER_TYPE}) => {
  if (type === CHARGER_TYPE.PUBLIC) {
    return <PublicChargerIcon color="black" />;
  } else {
    return <DomicilChargerIcon color="black" />;
  }
};
