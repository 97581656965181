/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { GridRowParams } from '@material-ui/data-grid';
import { Button, ListItem, Typography } from '@material-ui/core';
import VMDataGrid from '../../components/main/VMDataGrid';
import { Api } from '../../api';
import { useProvider } from '../../state/providerStore';
import { gridColumn } from '../../utils/gridUtils';
import TextLink from '../../components/other/TextLink';
import { BILLING_TABS, billingTabRoute, userDetailsTabParameters } from '../../utils/routing';
import { formatToSloDate } from '../Sessions/SessionsPage';
import ActionsMenu from '../../components/other/ActionsMenu';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';

const onClickShowInvoiceDetails = (invoice) => {
  drawerStore.openDrawer({
    label: 'Invoice details',
    inputs: [{ name: 'details', type: FormInputTypes.jsonView, value: invoice }],
    clickCallback: undefined,
    buttonLabel: 'Close',
  });

  drawerStore.setValues(invoice);
};

const cartInputs = [
  { name: 'id', type: FormInputTypes.disabledTextField },
  { name: 'reference', type: FormInputTypes.disabledTextField },
  { name: 'status', type: FormInputTypes.disabledTextField },
  { name: 'createdAt', type: FormInputTypes.disabledTextField },
  { name: 'billingToken', type: FormInputTypes.pugCart },
];

export const onClickShowCart = async (cartId: string) => {
  drawerStore.openDrawer({
    label: 'Cart details',
    inputs: [{ name: 'loader', type: FormInputTypes.loader }],
    clickCallback: undefined,
    buttonLabel: 'Close',
  });

  Api.billing.getCart(cartId).then((data) => {
    const cartData = data.data.data;
    drawerStore.setValues(cartData);
    drawerStore.setInputs(cartInputs);
  });
};

const openMarkPaidDrawer = (invoicesProvider: any) => {
  drawerStore.openDrawer({
    label: 'Mark invoice as paid',
    inputs: [{ name: 'invoiceId', type: FormInputTypes.textFieldWithPrefix, prefix: 'SI12 ' }],
    clickCallback: () => {
      Api.billing.markInvoicePaidByReference(drawerStore.values.invoiceId_with_prefix).then(() => {
        invoicesProvider.reloadResource();
      });
    },
    buttonLabel: 'Mark paid',
  });
};

const onClickMarkPaid = async (invoiceId: string, invoicesProvider: any) => {
  Api.billing.markInvoicePaid(invoiceId).then(() => {
    invoicesProvider.reloadResource();
  });
};

const invoiceActions = (params: GridRowParams, invoicesProvider: any) => {
  const invoice = params.row;
  const receipt = params.row?.purchase?.receipt;
  const cartId = params.row?.cartId;
  const status = params.row?.status;

  return (
    <ActionsMenu>
      <ListItem button onClick={() => onClickShowInvoiceDetails(invoice)}>
        <Typography style={{ width: '100%' }}>Details</Typography>
      </ListItem>
      {receipt && (
        <ListItem button onClick={() => window.open(receipt, '_blank')}>
          <Typography style={{ width: '100%' }}>Receipt</Typography>
        </ListItem>
      )}
      {cartId && (
        <ListItem button onClick={() => onClickShowCart(cartId)}>
          <Typography style={{ width: '100%' }}>Cart</Typography>
        </ListItem>
      )}
      {status !== 'paid' && (
        <ListItem button onClick={() => onClickMarkPaid(invoice?.id, invoicesProvider)}>
          <Typography style={{ width: '100%' }}>Mark paid</Typography>
        </ListItem>
      )}
    </ActionsMenu>
  );
};

const getInvoicesColumns = (invoicesProvider, names) => [
  gridColumn('user', {
    flex: 1,
    renderCell: (params: GridRowParams) => {
      const { row } = params;
      return (
        <TextLink
          label={names.get(row.userId || row.accountId)}
          to={userDetailsTabParameters(row.userId || row.accountId)}
        />
      );
    },
  }),
  gridColumn('settlementMode', { flex: 1 }),
  gridColumn('amount', {
    flex: 1,
    renderCell: (params: GridRowParams) => {
      const { row } = params;
      const formattedBalance = row.amount?.formatted;
      return <TextLink label={formattedBalance} to={`${billingTabRoute(BILLING_TABS.INVOICES)}/${row.walletId}`} />;
    },
  }),
  gridColumn('reference', {
    flex: 2,
    renderCell: (params: GridRowParams) => <div>{params.row.purchase?.processorId}</div>,
  }),
  gridColumn('contractNo', {
    flex: 2,
    renderCell: (params: GridRowParams) => <div>{params.row.contractNo}</div>,
  }),
  gridColumn('periodFrom', {
    flex: 2,
    renderCell: (params: GridRowParams) => {
      const date = new Date(params.row.periodFrom);

      if (!date) {
        return <div />;
      }

      const sloDate = formatToSloDate(date);

      return <div>{sloDate}</div>;
    },
  }),
  gridColumn('periodTo', {
    flex: 2,
    renderCell: (params: GridRowParams) => {
      const date = new Date(params.row.periodTo);

      if (!date) {
        return <div />;
      }

      const sloDate = formatToSloDate(date);

      return <div>{sloDate}</div>;
    },
  }),
  gridColumn('status', {
    flex: 2,
    renderCell: (params: GridRowParams) => <div>{params.row.status}</div>,
  }),
  gridColumn('actions', {
    width: 100,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => invoiceActions(params, invoicesProvider),
  }),
];

export const InvoicesPage = observer(() => {
  const invoicesProvider = useProvider(Api.billing.getInvoices);
  const [names, setNames] = React.useState(new Map<string, string>());

  useEffect(() => {
    invoicesProvider.data?.forEach((invoice) => {
      if (invoice.userId) {
        const id = invoice.userId;
        console.log('user id', id);
        Api.user.getUser(id).then((data) => {
          const userData = data.data.data;
          const newMap = new Map(names);
          newMap.set(id, `${userData.name} ${userData.familyName}`);
          setNames(newMap);
        });
      } else if (invoice.accountId) {
        const id = invoice.accountId;
        Api.business.getAccount(id).then((data) => {
          const accountData = data.data;
          const newMap = new Map(names);
          newMap.set(id, accountData.name);
          setNames(newMap);
        });
      }
    });
  }, [invoicesProvider.data]);

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Button variant="text" color="primary" onClick={() => openMarkPaidDrawer(invoicesProvider)}>
          Mark invoice as paid
        </Button>
      </div>
      <VMDataGrid label="Invoices" rows={invoicesProvider.data} columns={getInvoicesColumns(invoicesProvider, names)} />
    </div>
  );
});
