import React, { useEffect, useState } from 'react';
import { ListItem, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { makeAutoObservable, toJS } from 'mobx';
import { Route, Switch } from 'react-router-dom';
import { GridRowParams } from '@material-ui/data-grid';
import ActionsMenu from '../../components/other/ActionsMenu';
import { FormInputTypes } from '../../utils/constants';
import { drawerStore } from '../../state/drawerStore';
import VMDataGrid from '../../components/main/VMDataGrid';
import { UserStatusBadge } from '../../components/other/CellBadges';
import UserDetails from './UserDetails';
import TextLink from '../../components/other/TextLink';
import dashboardStore from '../../state/dashboardStore';
import { gridColumn } from '../../utils/gridUtils';
import { toastStore } from '../../state/toastStore';
import { Api } from '../../api';
import { route, USER_DETAILS_TABS, userDetailsTabParameters, userDetailsTabRoute } from '../../utils/routing';
import { ADMIN_PAGES, PAGES } from '../../components/main/MainComponent';

const userRows = ['name', 'familyName', 'email', 'role', 'password'];

const userAddInputs = [
  { name: 'name', label: 'firstName', type: FormInputTypes.textField },
  { name: 'familyName', label: 'lastName', type: FormInputTypes.textField },
  { name: 'email', type: FormInputTypes.textField },
  {
    name: 'role',
    type: FormInputTypes.select,
    options: ['User', 'Admin'],
  },
  { name: 'password', type: FormInputTypes.password },
  { name: 'confirmPassword', type: FormInputTypes.password },
];

const filterParamOptions = [
  { label: 'First name', value: 'name' },
  { label: 'Last name', value: 'familyName' },
  { label: 'Email', value: 'email' },
];

const getUrlParams = (
  param?: string,
  filterValue?: string,
  paginationToken?: string,
  sortBy?: string,
  orderBy?: string,
) => {
  const urlParams: any = {};
  if (param && filterValue) {
    urlParams[param] = filterValue;
  }

  if (paginationToken) {
    urlParams.paginationToken = paginationToken;
  }

  if (sortBy) {
    urlParams.sortBy = sortBy;
  }

  if (orderBy) {
    urlParams.orderBy = orderBy;
  }
  return urlParams;
};

class UsersStore {
  loading = false;
  private _users: any[] = [];
  userCount = 0;
  paginationToken?: string = undefined;
  sortBy?: string = 'name';
  orderBy?: string = 'asc';
  filterParam?: string = undefined;
  filterValue?: string = undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get users() {
    return toJS(this._users); // Need to convert if mobxData is array
  }

  set users(users) {
    this._users = users;
  }

  setUsersCount(count: number) {
    this.userCount = count;
  }

  setSort(sortBy: string, orderBy: string) {
    this.sortBy = sortBy;
    this.orderBy = orderBy;
  }

  setFilter(param: string, value: string) {
    this.filterParam = param;
    this.filterValue = value;
  }
}

const UsersPage = observer(() => {
  const [usersStore] = useState(() => new UsersStore());
  const sortableFields = ['name', 'email'];

  const loadUsers = (
    param = undefined,
    filterValue = undefined,
    clearData = false,
    sortBy = undefined,
    orderBy = undefined,
  ) => {
    if (usersStore.loading) {
      return;
    }

    if (!usersStore.paginationToken) {
      if (usersStore.users.length > 0) {
        if (!clearData) {
          return;
        }
      }
    }

    if (clearData) {
      usersStore.users = [];
    }

    const urlParams = getUrlParams(param, filterValue, usersStore.paginationToken, sortBy, orderBy);
    usersStore.loading = true;
    urlParams.paginationToken = usersStore.paginationToken;
    Api.user.listUsers(urlParams).then((resp) => {
      const newUsers = [...usersStore.users, ...resp?.data?.data?.users];
      usersStore.users = newUsers;
      console.log('resp: ', resp);
      usersStore.setUsersCount(resp?.data?.data?.count ?? usersStore.users.length);
      usersStore.paginationToken = resp?.data?.data?.paginationToken;
      usersStore.loading = false;
    });
  };

  useEffect(() => {
    if (!usersStore.loading && (!usersStore.users || usersStore.users.length === 0)) {
      loadUsers('', '', false);
    }
  }, []);

  useEffect(() => {
    loadUsers(usersStore.filterParam, usersStore.filterValue, true, usersStore.sortBy, usersStore.orderBy);
  }, [usersStore.sortBy, usersStore.orderBy]);

  const filterCallback = (param: string, val: string) => {
    usersStore.setFilter(param, val);
    usersStore.paginationToken = undefined;
    loadUsers(param, val, true);
  };

  const confirmAddUser = (values) => {
    values = _.pick(values, userRows);
    if (values.role === 'Admin') {
      values.admin = true;
    }

    values.role = undefined;

    Api.user.createUser(values).then(() => {
      loadUsers();
    });
  };

  const onClickUserAdd = () => {
    drawerStore.openDrawer({
      label: 'Add user',
      inputs: userAddInputs,
      clickCallback: () => confirmAddUser(toJS(drawerStore.values)),
    });
  };

  const userActions = (params) => (
    <ActionsMenu>
      <ListItem button onClick={() => toastStore.showNotImplemented()}>
        <Typography style={{ width: '100%' }}>Deactivate</Typography>
      </ListItem>
      <ListItem button onClick={() => toastStore.showNotImplemented()}>
        <Typography style={{ width: '100%' }}>Send email</Typography>
      </ListItem>
    </ActionsMenu>
  );

  const userColumns = [
    gridColumn('name', {
      flex: 3,
      sortableFields,
      renderCell: (params: GridRowParams) => (
        <TextLink
          label={`${params.row.name} ${params.row.familyName}`}
          to={userDetailsTabParameters(params.row.id, USER_DETAILS_TABS.OVERVIEW)}
        />
      ),
    }),
    gridColumn('email', { flex: 4, sortableFields }),
    gridColumn('role', { flex: 2 }),
    gridColumn('status', { flex: 2, renderCell: (params) => <UserStatusBadge user={params.row} /> }),
    gridColumn('actions', {
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => userActions(params),
    }),
  ];

  const onBottomScroll = async () => {
    if (!usersStore.loading) {
      loadUsers();
    }
  };

  return (
    <VMDataGrid
      onBottomScroll={onBottomScroll}
      onClickAdd={onClickUserAdd}
      rows={usersStore.users}
      columns={userColumns}
      pageSize={60}
      filterParamOptions={filterParamOptions}
      filterCallback={filterCallback}
      count={usersStore.userCount}
      onSortTypeChange={usersStore.setSort}
    />
  );
});

export default () => (
  <Switch>
    <Route path={userDetailsTabRoute()} component={UserDetails} />
    <Route path={route(PAGES.Users)} component={UsersPage} />
  </Switch>
);
