import React, { FunctionComponent } from 'react';
import { Divider, Typography } from '@material-ui/core';
import CircleRoundedIcon from '@material-ui/icons/CircleRounded';
import { useCardStyles } from './TitleSubtitleRow';

export interface ImageRowProps {
  label: string;
  actions?: any;
}

const ImageTextRow: FunctionComponent<ImageRowProps> = ({ label, actions }) => {
  const cardClasses = useCardStyles();

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={cardClasses.imageLabelContainer}>
          <CircleRoundedIcon style={{ marginRight: 24, color: '#aaa' }} />
          <Typography className={cardClasses.title}>{label}</Typography>
        </div>
        <div>{actions}</div>
      </div>
      <Divider />
    </div>
  );
};

export default ImageTextRow;
