import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CssBaseline, MuiThemeProvider, createTheme } from '@material-ui/core';
import LoginPage from '../../../pages/Auth/LoginPage';
import MainToast from '../MainToast';
import { MainComponent } from '../MainComponent';

export const defaultBackgroundColor = '#F9F9F3';
export const darkBackgroundColor = '#24262B';
export const greenTextColor = '#00BD89';
export const greenColor = '#00BD89';
export const lightGreenColor = '#00E8A8';
export const lightRedColor = '#E36D6D';
export const redColor = '#E02416';
export const blueColor = '#00DAE8';
export const yellowColor = '#CAE048';
export const greyColor = '#6E7495';
export const lightGreyColor = '#bababa';

const theme = createTheme({
  palette: {
    primary: {
      main: greenColor,
    },
    secondary: {
      main: '#E2E2CD',
    },
    background: {
      default: defaultBackgroundColor,
    },
    /* text: {
      primary: '#00BD89',
      secondary: '#252733',
    }, */
  },
  shape: {
    borderRadius: 4,
  },
});

const App = observer(() => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route component={MainComponent} />
        </Switch>

        <MainToast />
      </div>
    </Router>
  </MuiThemeProvider>
));

export default App;
