import { DataGrid } from '@material-ui/data-grid';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import VMDataGridHeader, { FilterParamOption } from './VMDataGridHeader';
import { standardDataGridProps } from '../../utils/constants';
import dashboardStore from '../../state/dashboardStore';
import { useStyles } from '../../styles/styles';

export interface VMPaginatedGridProps {
  label?: string;
  count?: number;
  onClickAdd?: () => void;
  rows: any[];
  columns: any;
  onBottomScroll?: () => void;
  pageSize?: number;
  filterParamOptions?: FilterParamOption[];
  filterCallback?: (param: string, val: string) => void;
  removeCellPadding?: boolean;
  onPageOffsetChange: (offset: number) => void;
  onSortTypeChange?: (sortField: string, sortOrder: 'ASC' | 'DESC' | null) => void;
  defaultSortModel?: any;
}

export const VMPaginatedGrid = observer(
  ({
    label,
    count,
    onClickAdd,
    rows,
    columns,
    onBottomScroll,
    pageSize,
    filterParamOptions,
    filterCallback,
    removeCellPadding,
    onPageOffsetChange,
    onSortTypeChange,
    defaultSortModel = {},
  }: VMPaginatedGridProps) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [sortModel, setSortModel] = useState([defaultSortModel]);

    const onScroll = () => {
      const winHeight = window.innerHeight;
      const srcHeight = document.documentElement.scrollHeight;
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const toBottom = srcHeight - winHeight - scrollTop;

      if (toBottom <= 100) {
        if (onBottomScroll) {
          onBottomScroll();
        }
      }
    };

    useEffect(() => {
      window.addEventListener('scroll', onScroll);

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    });

    const classes = useStyles();

    let definedLabel = label;
    if (label === undefined) {
      definedLabel = `All ${dashboardStore.selectedMenuItem}`;
    }

    const gridClasses = [classes.gridRoot];
    if (pageSize) {
      gridClasses.push(classes.noGridPagination);
    }
    if (removeCellPadding) {
      gridClasses.push(classes.noCellPadding);
    }

    const onDataGridPageChange = (page: number) => {
      setCurrentPage(page);
      onPageOffsetChange(page * 20);
    };

    const setFilter = (param: string, val: string) => {
      if (filterCallback) {
        setCurrentPage(0);
        filterCallback(param, val);
      }
    };

    const fetchSortedData = (sortField, sortOrder) => {
      const order = sortOrder === 'asc' ? 'ASC' : 'DESC';
      onSortTypeChange(sortField, order);
    };

    return (
      <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 20 }}>
        {rows && (
          <div className={clsx(gridClasses)} key="rows">
            <VMDataGridHeader
              onClickAdd={onClickAdd}
              label={`${definedLabel} (${count ?? rows.length})`}
              filterParamOptions={filterParamOptions}
              filterCallback={setFilter}
            />
            <DataGrid
              {...standardDataGridProps}
              columns={columns}
              rows={rows}
              className={classes.dataGrid}
              pageSize={pageSize || 20}
              onPageChange={(page: number) => onDataGridPageChange(page)}
              paginationMode="server"
              rowCount={count}
              page={currentPage}
              sortingMode="server"
              onSortModelChange={(_sortModel) => {
                if (_sortModel.length > 0) {
                  const { field, sort } = _sortModel[0];
                  fetchSortedData(field, sort);
                }
                setSortModel(_sortModel);
              }}
              sortModel={sortModel}
            />
          </div>
        )}
      </div>
    );
  },
);
