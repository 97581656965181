import { CardHeader, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import { useStyles } from '../../styles/styles';

export enum HeaderButtonIcon {
  edit,
  add,
  settings,
}

const HeaderButton = ({ icon }) => {
  switch (icon) {
    case HeaderButtonIcon.edit:
      return <EditIcon />;
    case HeaderButtonIcon.add:
      return <AddIcon />;
    case HeaderButtonIcon.settings:
      return <SettingsIcon />;
    default:
      return <div />;
  }
};

type StyledCardHeaderProps = {
  title: string;
  onClick?: any;
  headerButtonIcon?: HeaderButtonIcon;
};

const StyledCardHeader = ({ title, onClick, headerButtonIcon = HeaderButtonIcon.edit }: StyledCardHeaderProps) => {
  const classes = useStyles();

  return (
    <CardHeader
      title={title}
      className={classes.cardHeader}
      action={
        onClick !== undefined && (
          <IconButton onClick={onClick}>
            <HeaderButton icon={headerButtonIcon} />
          </IconButton>
        )
      }
    />
  );
};

export default StyledCardHeader;
