import { makeStyles } from '@material-ui/core/styles';
import { greyColor } from '../components/main/react/App';

export const useCardStyles = makeStyles((theme) => ({
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
  },
  articleCardImage: {
    height: 128,
    width: 'auto',
  },
  cardRowLeftItem: {
    flex: 1,
    color: greyColor,
    fontWeight: 200,
    textAlign: 'right',
    paddingRight: theme.spacing(4),
  },
  cardRowRightItem: {
    flex: 3,
  },
  cardRowActionItem: {
    flex: 1,
  },
}), { index: 1 });
