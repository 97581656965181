import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import StyledCard from '../../components/cards/StyledCard';
import StyledCardHeader, { HeaderButtonIcon } from '../../components/cards/StyledCardHeader';
import StyledCardContent from '../../components/cards/StyledCardContent';
import { Api } from '../../api';
import { TitleSubtitleRow } from '../../components/cards/CardRows/TitleSubtitleRow';
import ActionsList from '../../components/other/ActionsList';
import { useStyles } from '../../styles/styles';
import NameValueRow from '../../components/cards/CardRows/NameValueRow';
import { FormInputTypes } from '../../utils/constants';
import { drawerStore } from '../../state/drawerStore';
import { paymentsColumns } from '../Payments/PaymentsPage';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { useProvider } from '../../state/providerStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { generateMultiSelectOptions, paymentMethodSelectOption } from './SharedUtils';

const monthlyIntervalCondition = (values) => values.settlementInterval === 'monthly';
const paymentMethodCondition = (values) => values.settlementMode === 'payment_method';

const settlementIntervalInput = [
  { name: 'settlementInterval', type: FormInputTypes.select, options: ['instant', 'monthly'] },
];

const getMonthlyIntervalInputs = () => [
  {
    name: 'minBalance',
    type: FormInputTypes.number,
    condition: () => monthlyIntervalCondition(drawerStore.values),
  },
  {
    name: 'settlementMode',
    type: FormInputTypes.select,
    options: ['payment_method', 'invoice'],
    condition: () => monthlyIntervalCondition(drawerStore.values),
  },
];

const getSettlementSourceInputs = (paymentMethodsProvider) => [
  {
    name: 'settlementSource',
    type: FormInputTypes.multilineSelect,
    options: generateMultiSelectOptions(paymentMethodsProvider, paymentMethodSelectOption),
    condition: () => monthlyIntervalCondition(drawerStore.values) && paymentMethodCondition(drawerStore.values),
  },
];

const getRestOfBillingPlanInputs = () => [
  {
    name: 'contractNo',
    type: FormInputTypes.textField,
  },
  { name: 'ownerName', label: 'Full name', type: FormInputTypes.textField },
  { name: 'address', type: FormInputTypes.googleAddressAutocomplete },
  { name: 'zip', type: FormInputTypes.textField },
  { name: 'city', type: FormInputTypes.textField },
  { name: 'country', type: FormInputTypes.textField },
  { name: 'VAT', type: FormInputTypes.number },
];

export function getBillingPlanInputs(paymentMethodsProvider) {
  return [
    ...settlementIntervalInput,
    ...getMonthlyIntervalInputs(),
    ...getSettlementSourceInputs(paymentMethodsProvider),
    ...getRestOfBillingPlanInputs(),
  ];
}

export const UserDetailsBilling = observer(() => {
  const { userid } = useParams<{ userid: string }>();

  const classes = useStyles();

  const paymentsProvider = usePaginatedProvider((offset) =>
    Api.billing.listPaymentsForUser(userid, { skip: offset, limit: 20 }),
  );
  const billingPlanProvider = useProvider(() => Api.billing.getBillingPlanForUser(userid));
  const paymentMethodsProvider = useProvider(() => Api.billing.getPaymentMethodsForUser(userid));
  const [source, setSource] = React.useState(undefined);

  const confirmEditBillingPlan = () => {
    Api.billing.updateBillingPlanForUser(userid, drawerStore.values).then(() => {
      billingPlanProvider.reloadResource();
    });
  };

  const onClickEditBillingPlan = () => {
    const newValues = { ...billingPlanProvider.data };
    if (newValues.minBalance) {
      newValues.minBalance = newValues.minBalance.value;
    }

    drawerStore.openDrawer({
      label: 'Billing Plan',
      inputs: getBillingPlanInputs(paymentMethodsProvider),
      clickCallback: confirmEditBillingPlan,
      buttonLabel: 'Save',
    });
    drawerStore.setValues(newValues);
  };

  const onClickEditPaymentMethod = (paymentMethod: any) => {
    drawerStore.openDrawer({
      label: 'Update Payment Method',
      inputs: [
        { name: 'id', type: FormInputTypes.disabledTextField },
        { name: 'fullName', type: FormInputTypes.textField },
        { name: 'address', type: FormInputTypes.googleAddressAutocomplete },
        { name: 'zip', type: FormInputTypes.textField },
        { name: 'city', type: FormInputTypes.textField },
        { name: 'country', type: FormInputTypes.textField },
        { name: 'VAT', type: FormInputTypes.number },
      ],
      clickCallback: () => Api.billing.updatePaymentMethod(paymentMethod.id, drawerStore.values),
      buttonLabel: 'Save',
    });
    drawerStore.setValues(paymentMethod);
  };

  const getPaymentMethodActions = (paymentMethod: any) => {
    if (paymentMethod.processor === 'vizi') {
      return undefined;
    } else return [{ label: 'Edit', callback: () => onClickEditPaymentMethod(paymentMethod) }];
  };

  useEffect(() => {
    if (paymentMethodsProvider.data) {
      const settlementSource = billingPlanProvider.data?.settlementSource;
      if (settlementSource) {
        const purchaseMethod = paymentMethodsProvider.data?.find((method) => method.id === settlementSource);
        if (purchaseMethod?.walletId) {
          Api.billing.getWallet(purchaseMethod?.walletId).then((res) => {
            setSource(res.data.data);
          });
        }
      }
    }
  }, [billingPlanProvider.data, paymentMethodsProvider.data]);

  const isMonthly = billingPlanProvider.data?.settlementInterval === 'monthly';
  const isPaymentMethod = billingPlanProvider.data?.settlementMode === 'payment_method';

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.cardRows}>
        <div className={classes.leftCardRow}>
          <StyledCard>
            <StyledCardHeader
              title="Billing Plan"
              onClick={onClickEditBillingPlan}
              headerButtonIcon={HeaderButtonIcon.edit}
            />
            <StyledCardContent>
              <NameValueRow name="INTERVAL" value={billingPlanProvider.data?.settlementInterval} />
              {isMonthly && billingPlanProvider.data?.minBalance && (
                <NameValueRow name="Max credit" value={billingPlanProvider.data?.minBalance?.formatted} />
              )}
              {isMonthly && <NameValueRow name="Settlement" value={billingPlanProvider.data?.settlementMode} />}
              {isMonthly && isPaymentMethod && <NameValueRow name="Source" value={source?.name} />}
              {isMonthly && isPaymentMethod && <NameValueRow name="Balance" value={source?.balance?.formatted} />}
            </StyledCardContent>
          </StyledCard>
        </div>
        <div className={classes.rightCardRow}>
          <StyledCard>
            <StyledCardHeader title="Payment methods" />
            <StyledCardContent>
              {paymentMethodsProvider.data?.map((method: any) => (
                <div key={method.id}>
                  <TitleSubtitleRow
                    title={method.fullName}
                    subtitle={method.name}
                    actions={<ActionsList actions={getPaymentMethodActions(method)} />}
                  />
                </div>
              ))}
            </StyledCardContent>
          </StyledCard>
        </div>
      </div>

      <VMPaginatedGrid
        label="Payments"
        rows={paymentsProvider.data}
        columns={paymentsColumns(paymentsProvider)}
        count={paymentsProvider.count}
        onPageOffsetChange={paymentsProvider.onPageChange}
      />
    </div>
  );
});

export default UserDetailsBilling;
