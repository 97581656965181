import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton, ListItem, Tab, Tabs, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from '../styles/styles';
import { strToUrl } from './routing';
import ActionsMenu from '../components/other/ActionsMenu';

interface ViziTabsProps {
  basePath: string;
  tabs: string[];
  children: ReactNode[];
  onClickAdd?: () => void;
}

export const ViziTabs = ({ basePath, tabs, children, onClickAdd }: ViziTabsProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabIndexChange = (event: any, newValue: any) => {
    setTabIndex(newValue);
  };

  const isUUID = (str) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
  };

  const onTabLoad = (tab: string) => {
    let idx = -1;
    if (isUUID(tab)) {
      idx = children.findIndex((child: any) => child.key === tab);
    } else {
      idx = tabs.findIndex((el) => strToUrl(el) === tab);
    }
    if (idx >= 0) {
      setTabIndex(idx);
    }
  };

  useEffect(() => {
    const basePathWords = basePath.substring(1).split('/');
    const pathWords = pathname.substring(1).split('/');
    const tabWordIndex = pathWords.length > basePathWords.length ? pathWords.length - 1 : basePathWords.length - 1;

    const tabWord = pathWords[tabWordIndex];
    onTabLoad(tabWord);
  }, [pathname]);

  return (
    <Tabs
      className={classes.tabs}
      value={tabIndex}
      onChange={handleTabIndexChange}
      centered
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
    >
      {children}
      {onClickAdd && (
        <IconButton onClick={onClickAdd}>
          <AddIcon style={{ color: '#525252' }} />
        </IconButton>
      )}
      {!onClickAdd && <div style={{ marginRight: '2em', marginTop: '2em' }} />}
    </Tabs>
  );
};

interface ViziTabProps {
  label: string;
  linkTo: string;
  onRemove?: () => void;
  isParentTab?: boolean;
}

// TODO: The idx is always -1 here as it is not passed as value prop to the underlying MaterialUI Tab component which causes errors in console when using ViziTabs.
export const ViziTab = ({ label, linkTo, onRemove, isParentTab = false }: ViziTabProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ position: 'relative', display: 'inline-flex', flexGrow: 1, alignItems: 'center' }}
    >
      <Tab
        className={classes.tab}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
            {onRemove && hovered && !isParentTab && (
              <div style={{ marginLeft: '0.5em' }}>
                <ActionsMenu style={{ iconButton: { padding: 0 } }}>
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRemove();
                    }}
                  >
                    <Typography style={{ width: '100%' }}>Remove</Typography>
                  </ListItem>
                </ActionsMenu>
              </div>
            )}
          </div>
        }
        onClick={() => history.push(linkTo)}
      />
    </div>
  );
};
