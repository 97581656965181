import React from 'react';
import { observer } from 'mobx-react-lite';
import { MenuItem, TextField } from '@material-ui/core';
import { useFormStyles } from '../../styles/useFormStyles';

export const YearMonthPicker = observer(({ processedValues, name, pickerType, updateInputValue }: any) => {
  const formClasses = useFormStyles();

  const getYearOptions = () => {
    const yearOptions = [];
    for (let i = 0; i < 3; i++) {
      const date = new Date(Date.now());
      date.setFullYear(date.getFullYear() - i);

      yearOptions.push({ label: date.getFullYear(), value: date.getFullYear() });
    }

    return yearOptions;
  };

  const getMonthOptions = () => {
    const monthOptions = [];
    for (let i = 0; i < 12; i++) {
      const date = new Date(Date.now());
      date.setMonth(date.getMonth() - i);
      const monthName = date.toLocaleString('default', { month: 'long' });

      monthOptions.push({ label: monthName, value: i + 1 });
    }

    return monthOptions;
  };

  const yearValueName = `${name}_year`;
  const monthValueName = `${name}_month`;

  return (
    <div className={formClasses.formDrawerInput} style={{ display: 'flex' }}>
      <TextField
        label={pickerType === 'from' ? 'From year' : 'To year'}
        value={processedValues[yearValueName] || ''}
        fullWidth
        style={{ marginRight: '1em' }}
        select
      >
        {getYearOptions().map((option: any, index) => (
          <MenuItem
            key={index}
            selected={processedValues[yearValueName] === option.value}
            onClick={() => updateInputValue(option.value, yearValueName)}
            value={option.value}
          >
            {option.label}
          </MenuItem>))}
      </TextField>
      <TextField
        label={pickerType === 'from' ? 'From month' : 'To month'}
        value={processedValues[monthValueName] || ''}
        fullWidth
        style={{ marginRight: '1em' }}
        select
      >
        {getMonthOptions().map((option: any, index) => (
          <MenuItem
            key={index}
            selected={processedValues[monthValueName] === option.value}
            onClick={() => updateInputValue(option.value, monthValueName)}
            value={option.value}
          >
            {option.label}
          </MenuItem>))}
      </TextField>
    </div>
  );
});
