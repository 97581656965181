import { makeStyles } from '@material-ui/core/styles';
import { Button, Drawer, IconButton, ListItem, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { configure } from 'mobx';
import { observer } from 'mobx-react-lite';
import DrawerContent from './DrawerContent';
import { drawerStore } from '../../state/drawerStore';

configure({
  enforceActions: 'never',
});

const useSideFormStyles = makeStyles((theme) => ({
  drawerContainer: {
    width: 'fit-content',
    minWidth: theme.spacing(60),
    maxWidth: theme.spacing(100),
    height: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}), { index: 1 });

const DrawerContainer = observer(() => {
  const sideFormClasses = useSideFormStyles();

  const onClickConfirm = () => {
    drawerStore.save();
  };

  return (
    <div style={{ position: 'fixed' }}>
      <Drawer anchor="right" open={drawerStore.open} onClose={drawerStore.reset}>
        <div className={sideFormClasses.drawerContainer}>
          <div>
            <ListItem style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 0, marginLeft: 0 }}>
              <div>
                <Typography variant="h5" style={{ whiteSpace: 'nowrap', paddingLeft: 0, marginLeft: 0 }}>
                  {drawerStore.label}
                </Typography>
              </div>
              <div>
                <IconButton onClick={drawerStore.reset}>
                  <CloseIcon />
                </IconButton>
              </div>
            </ListItem>

            <DrawerContent />
          </div>
          <div>
            <ListItem
              style={{ paddingTop: 32, display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              {drawerStore.showButton && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClickConfirm}
                  style={{ width: '90%', borderRadius: 32 }}
                >
                  {drawerStore.buttonLabel}
                </Button>
              )}
            </ListItem>
          </div>
        </div>
      </Drawer>
    </div>
  );
});

export default DrawerContainer;
