import React from 'react';
import { ResponsiveContainer, AreaChart, XAxis, YAxis, CartesianGrid, Area, Tooltip } from 'recharts';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface RowData {
  date: string;
  [key: string]: any;
}

interface AnalyticsDetailsChartProps {
  rowData: RowData;
  unit: string;
  onClose: (e: any) => void;
}

const AnalyticsDetailsChart: React.FC<AnalyticsDetailsChartProps> = ({ rowData, unit, onClose }) => {
  const data = Array.from({ length: 24 }, (_, hour) => ({
    name: hour.toString(),
    value: parseInt(rowData[`hour_${hour}`], 10) || 0,
  }));

  const minValue = Math.min(...data.map((d) => d.value));
  const maxValue = Math.max(...data.map((d) => d.value));

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <IconButton
        style={{
          position: 'absolute',
          top: 5,
          right: 20,
          zIndex: 10,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      {/* Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data} margin={{ top: 10, bottom: 10 }}>
          <XAxis
            dataKey="name"
            interval="preserveStartEnd"
            padding={{ left: 25, right: 45 }}
          />
          <YAxis
            type="number"
            domain={[minValue, maxValue]}
            tickFormatter={(value) => `${value} ${unit}`}
          />
          <CartesianGrid strokeDasharray="1 1" />

          <Tooltip
            formatter={(value) => [`${value} ${unit}`]}
            labelFormatter={() => ''}
            cursor={{ strokeDasharray: '3 3' }}
            contentStyle={{ padding: '4px 8px' }}
            itemStyle={{ lineHeight: '1' }}
          />

          <Area
            type="monotone"
            dataKey="value"
            stroke="#3354f5"
            strokeWidth={2}
            fillOpacity={0.25}
            yAxisId={0}
            dot={{ r: 5 }}
            activeDot={{ strokeWidth: 2, r: 8, stroke: '#3354f5', fill: 'white' }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AnalyticsDetailsChart;
