import { IconButton, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useContentHeaderStyles from '../../styles/useContentHeaderStyles';
import dashboardStore from '../../state/dashboardStore';

export interface FilterParamOption {
  label: string;
  value: string;
}

export interface VMDataGridHeaderProps {
  onClickAdd?: () => void;
  label?: string;
  filterParamOptions?: FilterParamOption[];
  filterCallback?: (param: string, val: string) => void;
  onDateChangeCallBack?: (newDate: string) => void;
  date?: string;
}

const VMDataGridHeader = observer(
  ({ onClickAdd, label, filterParamOptions, filterCallback, onDateChangeCallBack, date }: VMDataGridHeaderProps) => {
    const classes = useContentHeaderStyles();

    let headerLabel = label;
    if (headerLabel === undefined) headerLabel = `All${dashboardStore.selectedMenuItem}`;

    const [filterParam, setFilterParam] = React.useState<string | undefined>(undefined);
    const [filterValue, setFilterValue] = React.useState<string | undefined>(undefined);

    useEffect(() => {
      if (!filterParam && filterParamOptions && filterParamOptions.length > 0) {
        setFilterParam(filterParamOptions[0].value);
      }
    }, [filterParamOptions]);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onDateChangeCallBack) {
        onDateChangeCallBack(event.target.value);
      }
    };

    const handlePrevDay = () => {
      if (date) {
        const prevDate = new Date(date);
        prevDate.setDate(prevDate.getDate() - 1);
        onDateChangeCallBack(prevDate.toISOString().split('T')[0]);
      }
    };

    const handleNextDay = () => {
      if (date) {
        const nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate() + 1);
        onDateChangeCallBack(nextDate.toISOString().split('T')[0]);
      }
    };

    return (
      <div className={classes.contentHeader}>
        <Typography style={{ paddingLeft: 10, paddingTop: 10, color: '#252733' }}>{headerLabel}</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {filterParamOptions && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
              <Typography>Search by</Typography>
              <TextField
                value={filterParam || ''}
                select
                style={{ minWidth: '10em', marginLeft: '0.5em', marginRight: '0.5em' }}
              >
                {filterParamOptions.map((option: FilterParamOption, index) => (
                  <MenuItem
                    value={option.value}
                    key={`option_${index}`}
                    selected={filterParam === option.value}
                    onClick={() => {
                      setFilterParam(option.value);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                value={filterValue || ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={(e) => {
                  setFilterValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    filterCallback(filterParam, filterValue);
                  }
                }}
              />
            </div>
          )}
          {onClickAdd && (
            <IconButton onClick={onClickAdd}>
              <AddIcon style={{ color: '#525252' }} />
            </IconButton>
          )}
          {!onClickAdd && <div style={{ marginRight: '2em', marginTop: '2em' }} />}

          {date && (
            <div className={classes.dateButton}>
              <IconButton onClick={handlePrevDay} aria-label="Previous Day">
                <ArrowBackIcon />
              </IconButton>
              <TextField
                type="date"
                value={date || ''}
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                style={{ marginLeft: '1em', marginRight: '1em' }}
              />
              <IconButton onClick={handleNextDay} aria-label="Next Day">
                <ArrowForwardIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default VMDataGridHeader;
