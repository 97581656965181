import { baseUrls, createApi } from './apiUtils';

const userApi = {
  v1: createApi({ baseURL: `${baseUrls.user}/v1` }),
  v2: createApi({ baseURL: `${baseUrls.user}/v2` }),
  adminV1: createApi({ baseURL: `${baseUrls.user}/admin/v1` }),
  adminV2: createApi({ baseURL: `${baseUrls.user}/admin/v2` }),
};

const USER_URLS = {
  USERS: 'users',
  USER: (userId: string) => `users/${userId}`,
};

export const login = (data) =>
  userApi.v1.post('login', data);

export const refreshToken = (data) =>
  userApi.v1.post('login/refresh', data);

export const listUsers = (params?: any) =>
  userApi.adminV1.get(USER_URLS.USERS, { params });

export const createUser = (userData) =>
  userApi.adminV1.post(USER_URLS.USERS, userData);

export const getUser = (userId: string) =>
  userApi.adminV1.get(USER_URLS.USER(userId));

export const updateUser = (userId: string, userData) =>
  userApi.adminV1.patch(USER_URLS.USER(userId), userData);
