import { Button, Switch, Typography } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import TextLink from '../../other/TextLink';
import { useCardStyles } from '../../../styles/useCardStyles';

interface NameValueRowProps {
  name: string;
  invertValue?: boolean;
  value: any;
  linkTo?: string;
  actionLabel?: string; // Label for optional text button on the right side of row
  actionFunction?: () => void;
  externalLink?: boolean;
  style?: React.CSSProperties;
}

const NameValueRow = ({
  name,
  invertValue,
  value,
  linkTo,
  actionLabel,
  actionFunction,
  externalLink = false,
  style,
}: NameValueRowProps) => {
  const cardClasses = useCardStyles();

  const RowName = () => (
    <Typography className={cardClasses.cardRowLeftItem} style={{ textTransform: 'uppercase', ...style }}>
      {name ? _.startCase(name) : ''}
    </Typography>
  );

  const RowValue = () => {
    if (value === true || value === false) {
      let checked = value;
      if (invertValue) {
        checked = !checked;
      }
      return (
        <div className={cardClasses.cardRowRightItem}>
          <Switch checked={checked} name="enabled" color="primary" />
        </div>
      );
    } else if (linkTo !== undefined) {
      return (
        <div className={cardClasses.cardRowRightItem}>
          <TextLink to={linkTo} label={value} externalLink={externalLink} />
        </div>
      );
    } else {
      return <Typography className={cardClasses.cardRowRightItem}>{value ?? ''}</Typography>;
    }
  };

  const RowAction = () => (
    <div className={cardClasses.cardRowActionItem} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant="text" onClick={actionFunction} color="primary" style={{ whiteSpace: 'nowrap' }}>
        {actionLabel}
      </Button>
    </div>
  );

  return (
    <div className={cardClasses.cardRow}>
      <RowName />
      <div
        className={cardClasses.cardRowRightItem}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <RowValue />
        {actionLabel && <RowAction />}
      </div>
    </div>
  );
};

export default NameValueRow;
