import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useStyles } from '../../styles/styles';
import StyledCardHeader, { HeaderButtonIcon } from '../../components/cards/StyledCardHeader';
import StyledCard from '../../components/cards/StyledCard';
import StyledCardContent from '../../components/cards/StyledCardContent';
import ActionsList from '../../components/other/ActionsList';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';
import { TitleSubtitleRow } from '../../components/cards/CardRows/TitleSubtitleRow';
import { NameValueRowList } from '../../components/cards/CardRows/NameValueRowList';
import NameValueRow from '../../components/cards/CardRows/NameValueRow';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import dashboardStore from '../../state/dashboardStore';
import { formatToSloDate } from '../Sessions/SessionsPage';
import { userSelectOption } from '../../components/forms/UserEmailAutocomplete';
import ProductsPage from './ProductsPage';

const detailsRows = ['name', 'description', 'createdAt', 'isActive'];

const CatalogDetailsOverview = observer(({ fetchPriceLists } : {fetchPriceLists?: ()=>void;}) => {
  const { catalogid, childid } = useParams<{ catalogid: string; childid: string }>();

  const effectiveId = childid || catalogid;

  const timeRestrictionProvider = useProvider(() => Api.catalog.getTimeRestriction(effectiveId));
  const userRestrictionProvider = useProvider(() => Api.catalog.getUserRestriction(effectiveId));
  const catalogProvider = useProvider(() => Api.catalog.getCatalog(effectiveId));

  const classes = useStyles();
  const history = useHistory();

  const [assignedUsers, setAssignedUsers] = useState<any[]>([]);

  const fetchUserData = async (userId) => {
    const resp = await Api.user.getUser(userId);
    return resp.data.data;
  };

  const fetchAllUsers = async () => {
    const promises = userRestrictionProvider.data?.userIds.map(fetchUserData);
    const ownerUsers = await Promise.all(promises);
    setAssignedUsers(ownerUsers);
  };

  useEffect(() => {
    dashboardStore.appBarLabel = 'Price list details';
  }, [history.location]);

  useEffect(() => {
    if (userRestrictionProvider.data?.userIds !== undefined) {
      fetchAllUsers();
    }
  }, [userRestrictionProvider.data]);

  useEffect(() => {
    dashboardStore.appBarLabel = 'Price list rules';
  }, [history.location]);

  useEffect(() => {
    timeRestrictionProvider.setRequestAndReload(() => Api.catalog.getTimeRestriction(effectiveId));
    userRestrictionProvider.setRequestAndReload(() => Api.catalog.getUserRestriction(effectiveId));
    catalogProvider.setRequestAndReload(() => Api.catalog.getCatalog(effectiveId));
  }, [effectiveId]);

  const editTimeRestrictionsInputs = [
    { name: 'mon', label: 'Monday', type: FormInputTypes.checkbox },
    { name: 'tue', label: 'Tuesday', type: FormInputTypes.checkbox },
    { name: 'wed', label: 'Wednesday', type: FormInputTypes.checkbox },
    { name: 'thr', label: 'Thursday', type: FormInputTypes.checkbox },
    { name: 'fri', label: 'Friday', type: FormInputTypes.checkbox },
    { name: 'sat', label: 'Saturday', type: FormInputTypes.checkbox },
    { name: 'sun', label: 'Sunday', type: FormInputTypes.checkbox },
    { name: 'from', type: FormInputTypes.time },
    { name: 'to', type: FormInputTypes.time },
  ];

  const editCatalogDetailsInputs = [
    { name: 'name', type: FormInputTypes.textField },
    { name: 'description', type: FormInputTypes.textField },
    { name: 'createdAt', type: FormInputTypes.textField },
    { name: 'isActive', type: FormInputTypes.switch },
  ];

  const editConfigurationInputs = [
    { name: 'isFree', type: FormInputTypes.switch },
    { name: 'enabled', label: 'User based pricing', type: FormInputTypes.switch },
    { name: 'enabledTime', label: 'Time restricted', type: FormInputTypes.switch },
  ];

  const confirmEditDetails = (values) => {
    Api.catalog.patchCatalog(effectiveId, values).then(() => {
      fetchPriceLists();
      catalogProvider.reloadResource();
    });
  };

  const onClickEditCatalogDetails = () => {
    drawerStore.openDrawer({
      label: 'Edit details',
      inputs: editCatalogDetailsInputs,
      clickCallback: () => confirmEditDetails(toJS(drawerStore.values)),
    });

    drawerStore.setValues(catalogProvider.data);
  };

  const confirmEditConfiguration = (values) => {
    const updatedTimeRestrictions = {
      ...values.timeRestrictionData,
      enabled: values.enabledTime,
    };
    Api.catalog.patchCatalog(effectiveId, values).then(catalogProvider.reloadResource);
    Api.catalog.setUserRestriction(effectiveId, values.enabled).then(userRestrictionProvider.reloadResource);
    Api.catalog.setTimeRestriction(effectiveId, updatedTimeRestrictions).then(timeRestrictionProvider.reloadResource);
  };

  const onClickEditConfiguration = () => {
    drawerStore.openDrawer({
      label: 'Edit configuration',
      inputs: editConfigurationInputs,
      clickCallback: () => confirmEditConfiguration(toJS(drawerStore.values)),
    });
    drawerStore.setValues({
      ...catalogProvider.data,
      ...userRestrictionProvider.data,
      enabledTime: timeRestrictionProvider.data.enabled,
      timeRestrictionData: { ...timeRestrictionProvider.data },
    });
  };

  const getFilteredUserOptions = (usersProvider, filteredUserIds) => {
    const options = [];
    usersProvider.data?.users?.forEach((user) => {
      if (!filteredUserIds.includes(user.id)) {
        options.push(userSelectOption(user));
      }
    });

    return options;
  };

  const assignUserInputs = (usersProvider) => [
    {
      name: 'userId',
      label: 'User',
      type: FormInputTypes.userEmailAutocomplete,
      inputChangeCallback: (inputValue) => {
        const urlParams: any = { email: inputValue };

        usersProvider.setOnFulfilled((data) => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          drawerStore.setInputs(assignUserInputs(usersProvider));
          drawerStore.setValues({ ...drawerStore.values });
        });

        usersProvider.setRequest(() => Api.user.listUsers(urlParams));
        usersProvider.reloadResource();
      },
      options: getFilteredUserOptions(usersProvider, userRestrictionProvider.data?.userIds),
    },
  ];

  const confirmAssignUser = (values: any) => {
    Api.catalog.addUserRestriction(effectiveId, values.userId).then(userRestrictionProvider.reloadResource);
  };
  const confirmEditTimeRestrictions = (values: any) => {
    Api.catalog.setTimeRestriction(effectiveId, values).then(timeRestrictionProvider.reloadResource);
  };

  const onClickEditTimeRestrictions = () => {
    drawerStore.openDrawer({
      label: 'Edit time restrictions',
      inputs: editTimeRestrictionsInputs,
      clickCallback: () => confirmEditTimeRestrictions(toJS(drawerStore.values)),
    });

    if (timeRestrictionProvider.data) {
      if (!timeRestrictionProvider.data.to) {
        timeRestrictionProvider.data.to = '00:00';
      }
      if (!timeRestrictionProvider.data.from) {
        timeRestrictionProvider.data.from = '00:00';
      }
    }
    drawerStore.setValues(timeRestrictionProvider.data);
  };

  const onClickRemoveUser = (userId: any) => {
    Api.catalog.removeUserRestriction(effectiveId, userId).then(userRestrictionProvider.reloadResource);
  };

  const onClickAssignUser = (usersProvider) => {
    drawerStore.openDrawer({
      label: 'Assign User',
      inputs: assignUserInputs(usersProvider),
      clickCallback: () => confirmAssignUser(toJS(drawerStore.values)),
    });
  };

  const getRestrictedDays = () => {
    if (!timeRestrictionProvider.data) return [];

    const dayNames = ['mon', 'tue', 'wed', 'thr', 'fri', 'sat', 'sun'];
    const days = [];

    dayNames.forEach((day) => {
      if (timeRestrictionProvider.data[day]) {
        if (days.length > 0) {
          days.push(', ');
        }
        days.push(day.toUpperCase());
      }
    });

    return days;
  };

  const usersProvider = useProvider(Api.user.listUsers);
  const isFree = catalogProvider.data?.isFree;
  const isUserBasedPricingEnabled = userRestrictionProvider.data?.enabled;
  const isTimeRestrictionEnabled = timeRestrictionProvider.data?.enabled;
  return (
    <div style={{ width: '100%' }}>
      <div className={classes.cardRows}>
        <div className={classes.leftCardRow}>
          <StyledCard>
            <StyledCardHeader
              title="Details"
              onClick={onClickEditCatalogDetails}
              headerButtonIcon={HeaderButtonIcon.edit}
            />
            <StyledCardContent>
              <NameValueRowList
                rows={detailsRows}
                values={{ ...catalogProvider.data, createdAt: formatToSloDate(catalogProvider.data?.createdAt) }}
              />
            </StyledCardContent>
          </StyledCard>
          {isTimeRestrictionEnabled && (
            <StyledCard>
              <StyledCardHeader
                title="Time Restrictions"
                onClick={onClickEditTimeRestrictions}
                headerButtonIcon={HeaderButtonIcon.edit}
              />
              <StyledCardContent>
                <NameValueRow name="days" value={getRestrictedDays()} />
                <NameValueRow name="from" value={timeRestrictionProvider.data?.from} />
                <NameValueRow name="to" value={timeRestrictionProvider.data?.to} />
              </StyledCardContent>
            </StyledCard>
          )}
        </div>

        <div className={classes.rightCardRow}>
          <StyledCard>
            <StyledCardHeader
              title="Configuration"
              onClick={onClickEditConfiguration}
              headerButtonIcon={HeaderButtonIcon.edit}
            />
            <StyledCardContent>
              <NameValueRow style={{ flex: 2 }} name="isFree" value={isFree} />
            </StyledCardContent>

            <StyledCardContent>
              <NameValueRow style={{ flex: 2 }} name="User based pricing" value={isUserBasedPricingEnabled} />
            </StyledCardContent>

            <StyledCardContent>
              <NameValueRow style={{ flex: 2 }} name="Time Restrictions" value={isTimeRestrictionEnabled} />
            </StyledCardContent>
          </StyledCard>
          {isUserBasedPricingEnabled && (
            <StyledCard>
              <StyledCardHeader
                title="Assigned Users for Pricing"
                onClick={() => onClickAssignUser(usersProvider)}
                headerButtonIcon={HeaderButtonIcon.add}
              />
              <StyledCardContent>
                {assignedUsers?.map((user) => (
                  <TitleSubtitleRow
                    title={`${user.name} ${user.familyName}`}
                    subtitle={user?.email}
                    key={user.id}
                    actions={
                      <ActionsList actions={[{ label: 'Remove', callback: () => onClickRemoveUser(user.id) }]} />
                    }
                  />
                ))}
              </StyledCardContent>
            </StyledCard>
          )}
        </div>
      </div>
      <ProductsPage catalogId={effectiveId} />
    </div>
  );
});

export default CatalogDetailsOverview;
