import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { makeAutoObservable, toJS } from 'mobx';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import QrCodeIcon from '@material-ui/icons/QrCode';
import Radio from '@mui/material/Radio';
import moment from 'moment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CONNECTOR_STATUS } from '../../types/Connector';
import { getCommonParams, useAccountFilter } from '../Analytics/useAnalyticsParams';
import AnalyticsDetailsChart from '../Analytics/AnalyticsDetailsChart';
import { useStyles } from '../../styles/styles';
import useConnectorStatusStyles from '../../styles/connectorStatusStyles';
import { FormInputTypes } from '../../utils/constants';
import { drawerStore } from '../../state/drawerStore';
import { Charger, CHARGER_STATUS, CHARGER_TYPE } from '../../types/Charger';
import { ColumnStatusBadge, ConnectorStatusBadge } from '../../components/other/CellBadges';
import StyledCard from '../../components/cards/StyledCard';
import StyledCardHeader, { HeaderButtonIcon } from '../../components/cards/StyledCardHeader';
import StyledCardContent from '../../components/cards/StyledCardContent';
import VMDataGrid from '../../components/main/VMDataGrid';
import { ConnectorColumnCell, formatToSloDate } from '../Sessions/SessionsPage';
import ActionsList from '../../components/other/ActionsList';
import { toastStore } from '../../state/toastStore';
import { gridColumn } from '../../utils/gridUtils';
import { getAssignChargerCombinedInputs } from './UnassignedChargers';
import {
  assignToAccountInputs,
  getPriceDropdownInput,
  getPriceListDropdownInput,
} from '../Locations/LocationDetailsOverview';
import { NameValueRowList } from '../../components/cards/CardRows/NameValueRowList';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import NameValueRow from '../../components/cards/CardRows/NameValueRow';
import { useCardStyles } from '../../styles/useCardStyles';
import { companyDetailsParameters, priceListDetailsRoute, strToUrl } from '../../utils/routing';
import { copyTextToClipboard } from '../../utils/utils';
import { greenTextColor } from '../../components/main/react/App';
import { TitleSubtitleRow } from '../../components/cards/CardRows/TitleSubtitleRow';
import credentialStore from '../../state/credentialStore';
import { ReactComponent as UserBased } from '../../images/CatalogRules/user_based.svg';
import { ReactComponent as Timebased } from '../../images/CatalogRules/time_based.svg';
import { ReactComponent as Free } from '../../images/CatalogRules/free.svg';
import { ReactComponent as Inherited } from '../../images/CatalogRules/inherited.svg';
import { timeRangeStore } from '../../state/timeRangeStore';

export const UserBasedRuleIcon = () => <UserBased style={{ paddingTop: '0.5px', marginLeft: '15px' }} />;
export const TimeBasedRuleIcon = () => <Timebased style={{ marginLeft: '15px' }} />;
export const FreeRuleIcon = () => <Free style={{ marginLeft: '15px' }} />;
export const InheritedRuleIcon = () => <Inherited />;

class ChargerDetailsStore {
  automaticModeDialogOpen = false;
  rebootDialogOpen = false;
  dialogText = '';
  chargerProvider: any;

  lastHeartbeat: any;
  millisSinceLastHeartbeat: any;
  chargerOffline: any;

  activeConnector: any;
  activeDuration: any;

  openAutomaticModeDialog(text, chargerProvider) {
    this.automaticModeDialogOpen = true;
    this.rebootDialogOpen = false;
    this.dialogText = text;
    this.chargerProvider = chargerProvider;
  }

  openRebootDialog(text, chargerProvider) {
    this.rebootDialogOpen = true;
    this.automaticModeDialogOpen = false;
    this.dialogText = text;
    this.chargerProvider = chargerProvider;
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

const DisplayActiveTimeDuration = observer(({ store }: { store: ChargerDetailsStore }) => <NameValueRow name="duration" value={store.activeDuration} />);

const chargerDetailsStore = new ChargerDetailsStore();

const onConfirmRebootCharger = (chargerProvider, type: 'SOFT' | 'HARD') => {
  Api.charge.resetCharger(chargerProvider.data?.id, type).then(chargerProvider.reloadResource);
};

const onConfirmToggleChargeMode = (chargerProvider, mode) => {
  Api.charge.patchCharger(chargerProvider.data?.id, { mode }).then(chargerProvider.reloadResource);
};

const AutomaticModeDialog = observer(({ chargerProvider }: { chargerProvider: any }) => {
  const closeDialog = () => {
    chargerDetailsStore.automaticModeDialogOpen = false;
  };

  return (
    <Dialog open={chargerDetailsStore.automaticModeDialogOpen} onClose={closeDialog}>
      <DialogContent>
        <Typography>{chargerDetailsStore.dialogText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          onClick={() => {
            closeDialog();
            const newMode = chargerProvider.data?.mode === 'automatic' ? 'manual' : 'automatic';
            onConfirmToggleChargeMode(chargerProvider, newMode);
          }}
          autoFocus
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const RebootDialog = observer(() => {
  const [confirmType, setConfirmType] = useState<'SOFT' | 'HARD'>('SOFT');

  const closeDialog = () => {
    chargerDetailsStore.rebootDialogOpen = false;
  };

  return (
    <Dialog open={chargerDetailsStore.rebootDialogOpen} onClose={closeDialog}>
      <DialogContent>
        <Typography>{chargerDetailsStore.dialogText}</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ marginTop: '1em' }}>Type</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Radio checked={confirmType === 'SOFT'} onChange={() => setConfirmType('SOFT')} />
          <Typography>Soft</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Radio checked={confirmType === 'HARD'} onChange={() => setConfirmType('HARD')} />
          <Typography>Hard</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          onClick={() => {
            closeDialog();
            onConfirmRebootCharger(chargerDetailsStore.chargerProvider, confirmType);
          }}
          autoFocus
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const getDetailsRows = (chargerProvider) => {
  const newMode = chargerProvider.data?.mode === 'automatic' ? 'manual' : 'automatic';
  const actionLabel = `Set ${newMode}`;
  const actionFunction = () => {
    chargerDetailsStore.openAutomaticModeDialog(`Are you sure you want to set mode to ${newMode}?`, chargerProvider);
  };

  return [
    { name: 'id', label: 'identifier' },
    'manufacturer',
    'model',
    'ocppId',
    'serial',
    { name: 'mode', actionLabel, actionFunction },
  ];
};

const getStatusRows = (chargerProvider) => [
  'maxPower',
  { name: 'currentFirmwareVersion', label: 'Firmware' },
  {
    name: 'status',
    actionLabel: 'reboot',
    actionFunction: () => {
      chargerDetailsStore.openRebootDialog('Are you sure you want to reboot this charger?', chargerProvider);
    },
  },
  'createdAt',
  'isVisible',
];

/* const pricingRows = [{ name: 'catalogId', label: 'priceList' }]; */
const powerSettingsRows = ['voltageFactor', 'currentFactor', 'powerFactor', 'consumptionFactor'];
/* const activityRows = [{ name: 'lastHeartbeat', label: 'lastSignal' }, 'power', 'duration', 'consumption']; */
const totalsRows = ['avgPower', 'consumption', 'time'];
const ChargerConfigurationRows = ['manufacturer', 'model'];

const editDetailsInputs = [
  { name: 'id', label: 'identifier', type: FormInputTypes.disabledTextField },
  { name: 'manufacturer', type: FormInputTypes.textField },
  { name: 'model', type: FormInputTypes.textField },
  { name: 'ocppId', type: FormInputTypes.textField },
  { name: 'serial', type: FormInputTypes.textField },
];

const editStatusInputs = [
  { name: 'maxPower', type: FormInputTypes.textField },
  { name: 'currentFirmwareVersion', label: 'firmware', type: FormInputTypes.textField },
  { name: 'createdAt', type: FormInputTypes.textField },
  { name: 'isVisible', type: FormInputTypes.switch },
];

const editPowerSettingsInputs = [
  { name: 'voltageFactor', type: FormInputTypes.number },
  { name: 'currentFactor', type: FormInputTypes.number },
  { name: 'powerFactor', type: FormInputTypes.number },
  { name: 'consumptionFactor', type: FormInputTypes.number },
];

const domicilTypeConstantInput = [
  { name: 'type', value: CHARGER_TYPE.DOMICIL, type: FormInputTypes.disabledTextField },
];

const getMilisecondDiffToNow = (startedAtISO?: string): number | undefined => {
  if (!startedAtISO) {
    return undefined;
  }

  const startedAtTime = new Date(startedAtISO).getTime();
  return Date.now() - startedAtTime;
};

const getTimeDifferenceString = (startedAtISO?: string): string => {
  const diff = getMilisecondDiffToNow(startedAtISO);
  if (!diff) {
    return '';
  }

  const duration = moment.duration(diff);
  return `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
};

const ChargerDetailsOverview = observer(() => {
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const connectorStatusStyles = useConnectorStatusStyles();

  const { chargerid } = useParams<{ chargerid: string }>();
  const { id } = useParams<{ id: string }>();

  const [rows, setRows] = useState<any[]>([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [units, setUnits] = useState('');
  const [mappedData, setMappedData] = useState<any[]>([]);
  const accountFilter = useAccountFilter();
  const today = new Date().toISOString().split('T')[0];
  const [date, setDate] = useState<string>(today);

  const _5minsInMilliseconds = 1000 * 60 * 5;

  const chargerProviderCallback = (data) => {
    chargerDetailsStore.lastHeartbeat = data?.lastHeartbeat;

    if (chargerDetailsStore.lastHeartbeat) {
      chargerDetailsStore.millisSinceLastHeartbeat = getMilisecondDiffToNow(chargerDetailsStore.lastHeartbeat);
    }

    chargerDetailsStore.chargerOffline =
      !chargerDetailsStore.millisSinceLastHeartbeat ||
      chargerDetailsStore.millisSinceLastHeartbeat > _5minsInMilliseconds;
  };

  const chargerActivityCallback = (data) => {
    if (data?.chargeConnectors) {
      chargerDetailsStore.activeConnector = data?.chargeConnectors[0];
    }
  };

  const chargerProvider = useProvider(() => Api.charge.getCharger(chargerid), chargerProviderCallback);
  const chargerActivityProvider = useProvider(
    () => Api.charge.getChargerActivity(chargerid),
    chargerActivityCallback,
    true,
    60000,
  );
  const nameAccountsProvider = useProvider(Api.business.listAccounts, undefined, false);
  const locationsProvider = useProvider(undefined, undefined, false);
  const usersProvider = useProvider(Api.user.listUsers, undefined, false);
  const catalogsProvider = useProvider(Api.catalog.listCatalogs, undefined, false);
  const chargerCatalogsProvider = useProvider(() => Api.charge.listCatalogsForCharger(chargerid));
  const chargerAccountsProvider = useProvider(() => Api.charge.listAccountsForCharger(chargerid), undefined, false);

  const locationId = chargerProvider?.data?.locationId;

  const [catalogs, setCatalogs] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);
  const orderedCatalogs = catalogs
    .map((catalog) => {
      const parentCatalog = catalogs.find((parent) => parent.id === catalog.parentId);
      const parentName = parentCatalog ? parentCatalog.name : null;
      return { ...catalog, priority: catalog.priority || 0, parentName };
    })
    .sort((a, b) => b.priority - a.priority)
    .map((catalog, index) => ({ ...catalog, order: catalogs.length - index }));
  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      usersProvider.reloadResource();
      catalogsProvider.reloadResource();
      nameAccountsProvider.reloadResource();
      chargerAccountsProvider.reloadResource();

      locationsProvider.setRequest(() => Api.charge.listLocations());
    } else {
      locationsProvider.setRequest(() => Api.charge.listLocationsForAccount(credentialStore.getAccountId()));
    }

    locationsProvider.reloadResource();
  }, [credentialStore.selectedAccount]);
  const fetchCatalogData = async (catalogId) => {
    const resp = await Api.catalog.getCatalog(catalogId);
    const isInherited = await Api.charge.listCatalogsForCharger(chargerid);
    const isUserRestricted = await Api.catalog.getUserRestriction(catalogId);
    const isTimeRestricted = await Api.catalog.getTimeRestriction(catalogId);
    const isInheritedStatus = !isInherited.data.data.some((item) => item.catalogId === catalogId);

    const catalogData = {
      ...resp.data.data,
      isUserRestricted: isUserRestricted.data.data.enabled,
      isTimeRestricted: isTimeRestricted.data.data.enabled,
      isInherited: isInheritedStatus,
    };

    catalogData.priority =
      (catalogData.isUserRestricted ? 1000 : 0) +
      (catalogData.isTimeRestricted ? 100 : 0) +
      (catalogData.isFree ? 10 : 0);

    return catalogData;
  };

  const fetchAllCatalogs = async () => {
    const promises = chargerCatalogsProvider.data.map((catalog) => fetchCatalogData(catalog.catalogId));
    const locationCatalogs = await Promise.all(promises);
    setCatalogs(locationCatalogs);
  };

  const fetchAccountData = async (accountId) => {
    const resp = await Api.business.getAccount(accountId);
    return resp.data.data;
  };

  const fetchAllAccounts = async () => {
    const promises = chargerAccountsProvider.data.map((account) => fetchAccountData(account.accountId));
    const chargerAccounts = await Promise.all(promises);
    setAccounts(chargerAccounts);
  };

  const fetchLocationCatalogs = async () => {
    if (!locationId) return;
    try {
      const response = await Api.charge.listCatalogsForLocation(locationId);
      const promises = response.data.data.map((catalog) => fetchCatalogData(catalog.catalogId));
      const locationCatalogs = await Promise.all(promises);
      setCatalogs(locationCatalogs);
    } catch (error) {
      console.error('Error fetching location catalogs:', error);
    }
  };

  useEffect(() => {
    if (chargerProvider.data) {
      chargerCatalogsProvider.reloadResource();
    }
  }, [chargerProvider.data]);

  useEffect(() => {
    if (chargerCatalogsProvider.data === undefined) {
      return;
    }

    if (chargerCatalogsProvider.data.length > 0) {
      fetchAllCatalogs();
    } else if (locationId) {
      fetchLocationCatalogs();
    }
  }, [chargerCatalogsProvider.data, locationId]);

  useEffect(() => {
    if (chargerAccountsProvider.data !== undefined) {
      fetchAllAccounts();
    }
  }, [chargerAccountsProvider.data]);

  const confirmAddCatalogToCharger = (values) => {
    Api.catalog.getCatalog(values.catalogId).then((data) => {
      const childrenIds = data?.data?.data?.children.map((child) => child.id);
      Api.charge
        .addCatalogToCharger(chargerid, values.catalogId, childrenIds)
        .then(chargerCatalogsProvider.reloadResource);
    });
  };

  const onClickAddCatalogToCharger = () => {
    drawerStore.openDrawer({
      label: 'Add price list',
      inputs: [
        getPriceListDropdownInput(
          catalogsProvider,
          chargerCatalogsProvider.data.map((el) => el.catalogId),
        ),
      ],
      clickCallback: () => confirmAddCatalogToCharger(toJS(drawerStore.values)),
    });
  };

  const onClickRemoveCatalogFromCharger = (catalogId) => {
    const childrenIds = orderedCatalogs
      .filter((catalog) => catalog.parentId === catalogId)
      .map((catalog) => catalog.id);

    const catalogIds = [...childrenIds, catalogId];

    Api.charge.removeCatalogFromCharger(chargerid, catalogIds).then(chargerCatalogsProvider.reloadResource);
  };

  const confirmAssignToAccount = () => {
    Api.charge
      .addAccountToCharger(chargerid, drawerStore.values.accountId)
      .then(chargerAccountsProvider.reloadResource);
  };

  const onClickAssignedTo = () => {
    drawerStore.openDrawer({
      label: 'Assign to account',
      inputs: assignToAccountInputs(nameAccountsProvider, chargerAccountsProvider),
      clickCallback: confirmAssignToAccount,
    });
  };

  const onClickQRCodeIcon = (connector) => {
    drawerStore.openDrawer({
      label: 'Charge connector',
      inputs: [{ name: 'ocppId', type: FormInputTypes.qrCode }],
      clickCallback: undefined,
      buttonLabel: 'Close',
    });
    drawerStore.setValues(connector);
  };

  const onClickStartConnector = (connector) => {
    Api.charge.startConnector(connector.id).then(chargerProvider.reloadResource);
  };

  const onClickStopConnector = (connector) => {
    Api.charge.stopConnector(connector.id).then(chargerProvider.reloadResource);
  };

  const connectorActions = (params: GridRowParams) => (
    <ActionsList
      actions={[
        {
          label: 'Start charging',
          callback: () => {
            onClickStartConnector(params.row);
          },
        },
        {
          label: 'Stop charging',
          callback: () => {
            onClickStopConnector(params.row);
          },
        },
        {
          label: 'Settings',
          callback: () => {
            toastStore.showNotImplemented();
          },
        },
      ]}
    />
  );

  const connectorColumns = [
    gridColumn('occpID', {
      flex: 4,
      headerName: 'OCCP ID',
      renderCell: (params: GridRowParams) => (
        <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
          <Typography>{params.row.ocppId}</Typography>
          <IconButton
            onClick={() => {
              copyTextToClipboard(params.row.ocppId);
            }}
          >
            <ContentCopyIcon style={{ marginLeft: '0.3em', color: greenTextColor, fontSize: 18 }} />
          </IconButton>
        </div>
      ),
    }),
    gridColumn('identifier', {
      flex: 3,
      headerName: 'Name',
      renderCell: (params: GridRowParams) => (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography style={{ marginRight: 16 }}>{params.row.name}</Typography>
        </div>
      ),
    }),
    gridColumn('connector', {
      flex: 3,
      renderCell: (params: GridRowParams) => <ConnectorColumnCell connector={params.row} showLink={false} />,
    }),
    gridColumn('status', {
      flex: 3,
      renderCell: (params: GridRowParams) => <ConnectorStatusBadge status={params.row.status} />,
    }),
    gridColumn('instantPower', {
      headerName: 'Active power',
      flex: 2,
      renderCell: (params: GridRowParams) => <div>{params?.row?.instantPower || 0}</div>,
    }),
    gridColumn('consumption', {
      flex: 2,
      renderCell: (params: GridRowParams) => <div>{params?.row?.consumption || 0}</div>,
    }),
    gridColumn('time', {
      flex: 2,
      renderCell: (params: GridRowParams) => <div>{params?.row?.time || 0}</div>,
    }),
    gridColumn('qr', {
      flex: 1,
      headerName: 'QR',
      renderCell: (params: GridRowParams) => (
        <IconButton onClick={() => onClickQRCodeIcon(params.row)} style={{ padding: 0 }}>
          <QrCodeIcon color="primary" />
        </IconButton>
      ),
    }),
    gridColumn('actions', {
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerName: ' ',
      renderCell: (params: GridRowParams) => connectorActions(params),
    }),
  ];

  interface HourValue {
    hour: number;
    status: string;
  }

  interface MappedDayItem {
    connectorId?: string;
    hourValues?: Map<number, HourValue>;
    items?: any;
  }

  const convertTimeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const getTimeIntervalStatus = (startTime: string, endTime: string, status: string) => {
    const startMinutes = convertTimeToMinutes(startTime);
    const endMinutes = convertTimeToMinutes(endTime);

    const intervals: { time: number; status: string }[] = [];

    for (let time = startMinutes; time <= endMinutes; time += 15) {
      intervals.push({ time, status });
    }

    return intervals;
  };

  const formatItemTime = (item, index, rowLength) => {
    if (index !== 0) {
      item.startedAt = moment.utc(item.startedAt, 'HH:mm').local().format('HH:mm');
    }
    if (index !== rowLength - 1) {
      item.endedAt = moment.utc(item.endedAt, 'HH:mm').local().format('HH:mm');
    }
  };

  const fillMissingHours = (mappedDayItem, lastHour) => {
    for (let hour = 0; hour <= lastHour; hour++) {
      if (!mappedDayItem.hourValues[hour]) {
        mappedDayItem.hourValues[hour] = [];
      }
    }
  };

  const fillRemainingHours = (mappedDayItem, lastItem, isToday) => {
    const startHour = parseInt(moment(lastItem.startedAt, 'HH:mm').format('HH'), 10) + 1;
    const endHour = isToday ? moment().hour() + 1 : 24;

    for (let hour = startHour; hour < endHour; hour++) {
      if (!mappedDayItem.hourValues[hour]) {
        mappedDayItem.hourValues[hour] = [];
      }
      mappedDayItem.hourValues[hour].push({
        hour,
        ...lastItem,
      });
    }
  };

  const mapRowItemsToHourValues = (row, mappedDayItem) => {
    let lastHour = -1;

    row.items?.forEach((item, index) => {
      const startHour = parseInt(item.startedAt.split(':')[0], 10);
      if (!mappedDayItem.hourValues[startHour]) {
        mappedDayItem.hourValues[startHour] = [];
      }

      mappedDayItem.hourValues[startHour].push({
        hour: startHour,
        ...item,
      });

      lastHour = Math.max(lastHour, startHour);

      if (index !== row.items.length - 1) {
        const nextStartHour = parseInt(row.items[index + 1].startedAt.split(':')[0], 10);
        for (let hour = startHour + 1; hour < nextStartHour; hour++) {
          if (!mappedDayItem.hourValues[hour]) {
            mappedDayItem.hourValues[hour] = [];
            mappedDayItem.hourValues[hour].push({
              hour,
              ...item,
            });
          }
        }
      }
    });

    return lastHour;
  };

  const setMappedDataFromResponse = (res) => {
    const resRows = res.items;

    const newMappedData = [];
    const isToday = moment(res.date).isSame(moment(), 'day');

    resRows?.forEach((row) => {
      const mappedDayItem = {
        connectorId: row.connectorId,
        hourValues: new Map(),
        items: row.items,
      };

      row.items?.forEach((item, index) => formatItemTime(item, index, row.items.length));

      if (isToday && row.items?.length > 0) {
        const lastItem = row.items[row.items.length - 1];
        lastItem.endedAt = moment.utc(lastItem.endedAt, 'HH:mm').local().format('HH:mm');
      }

      const lastHour = mapRowItemsToHourValues(row, mappedDayItem);

      fillMissingHours(mappedDayItem, lastHour);

      if (row.items?.length > 0) {
        const lastItem = row.items[row.items.length - 1];
        fillRemainingHours(mappedDayItem, lastItem, isToday);
      }

      newMappedData.push(mappedDayItem);
    });

    setMappedData(newMappedData);
  };

  const loadStatItems = async () => {
    try {
      const params = getCommonParams(accountFilter);
      const res = await Api.charge.getChargerWithParams(chargerid, params);
      const connectors = res.data?.data?.connectors;
      if (connectors && connectors.length > 0) {
        const resconnector = await Api.charge.getConnectorsStatus(connectors, date);
        const data = resconnector.data?.data;
        if (data) {
          const items = data;
          const count = data.length;
          const formattedResponse = {
            data: {
              date,
              count,
              items,
            },
          };
          setMappedDataFromResponse(formattedResponse.data);
        }
      }
    } catch (error) {
      console.error('Error loading connector statuses:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      chargerDetailsStore.activeDuration = getTimeDifferenceString(chargerDetailsStore.activeConnector?.startedAt);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [chargerDetailsStore.activeConnector?.startedAt]);

  const onClickRemoveAccountFromCharger = (accountId: string) => {
    Api.charge.removeAccountFromCharger(chargerid, accountId).then(chargerAccountsProvider.reloadResource);
  };

  const handleRowClick = (params) => {
    setEditingRowId(params.id);
  };

  const handleDateChange = (newDate: string) => {
    setDate(newDate);
  };

  const getRows = () => {
    const newRows = [];

    mappedData.forEach((rowItem) => {
      const newRow = {
        id: rowItem.connectorId,
        connectorId: rowItem.connectorId,
        items: rowItem.items,
        hourValues: rowItem.hourValues,
      };
      newRows.push(newRow);
    });

    return newRows;
  };

  useEffect(() => {
    loadStatItems();
  }, [date]);

  useEffect(() => {
    setRows(getRows());
  }, [mappedData]);

  const getItemColumns = () => {
    const columns = [
      gridColumn('occp id', {
        flex: 4,
        minWidth: 100,
        renderCell: (params: GridRowParams) => {
          const { connectorId } = params.row;
          return (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {connectorId}
            </div>
          );
        },
      }),
    ];

    for (let i = 0; i <= 23; i++) {
      columns.push(
        gridColumn(`hour_${i}`, {
          flex: 1,
          minWidth: 10,
          headerName: `${i}`,
          textAlign: 'left',
          hide: false,
          renderCell: (params: GridRowParams) => {
            const hourValues = params.row.hourValues[i] || [];
            const totalMinutesInHour = 60;

            if (hourValues.length === 1) {
              const statusValue = hourValues[0]?.status || null;
              const colorLookup = {
                [CONNECTOR_STATUS.MAINTENANCE]: '#d9b114',
                [CONNECTOR_STATUS.OFFLINE]: 'lightgrey',
                [CONNECTOR_STATUS.ERROR]: 'red',
                [CONNECTOR_STATUS.AVAILABLE]: '#3a3a3a',
                [CONNECTOR_STATUS.IN_USE]: 'green',
                [CONNECTOR_STATUS.READY_TO_CHARGE]: '#14b8d9',
                default: 'white',
              };
              const color = colorLookup[statusValue] || colorLookup.default;

              const tooltipContent = (
                <Typography variant="body1">
                  <strong>{statusValue?.toUpperCase()}</strong> <br />
                  {moment.utc(hourValues[0].startedAt, 'HH:mm').format('HH:mm')}-{moment.utc(hourValues[0].endedAt, 'HH:mm').format('HH:mm')}
                </Typography>
              );

              return (
                <Tooltip title={tooltipContent} enterDelay={100} enterNextDelay={100}>
                  <div
                    style={{
                      height: '80%',
                      width: '100%',
                      display: 'flex',
                      padding: '0px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: color,
                    }}
                    onClick={() => handleRowClick(params)}
                  />
                </Tooltip>
              );
            }

            return (
              <div style={{ height: '80%', width: '100%', display: 'flex', padding: '0px' }}>
                {hourValues.slice(0, 5).map((item, index) => {
                  const totalItems = Math.min(hourValues.length, 5);
                  const widthPercentage = totalItems > 0 ? 100 / totalItems : 0;

                  const colorLookup = {
                    [CONNECTOR_STATUS.MAINTENANCE]: '#d9b114',
                    [CONNECTOR_STATUS.OFFLINE]: 'lightgrey',
                    [CONNECTOR_STATUS.ERROR]: 'red',
                    [CONNECTOR_STATUS.AVAILABLE]: '#3a3a3a',
                    [CONNECTOR_STATUS.IN_USE]: 'green',
                    ready_to_charge: '#14b8d9',
                    default: 'white',
                  };

                  const color = colorLookup[item.status] || colorLookup.default;
                  const tooltipContent = (
                    <div>
                      {hourValues.map((fullItem, fullIndex) => (
                        <Typography key={fullIndex} variant="body2" style={{ marginBottom: '4px' }}>
                          <strong>{fullItem.status.toUpperCase()}</strong> <br />
                          {fullItem.startedAt} - {fullItem.endedAt}
                        </Typography>
                      ))}
                    </div>
                  );

                  return (
                    <Tooltip
                      key={index}
                      title={tooltipContent}
                      enterDelay={100}
                      enterNextDelay={100}
                    >
                      <div
                        style={{
                          height: '100%',
                          width: `${widthPercentage}%`,
                          display: 'flex',
                          padding: '0px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: color,
                        }}
                        onClick={() => handleRowClick(params)}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            );
          },
        }),
      );
    }
    return columns;
  };

  const confirmEditDetails = (values) => {
    Api.charge.patchCharger(chargerid, values).then(chargerProvider.reloadResource);
  };

  const confirmEditStatus = (values) => {
    Api.charge.patchCharger(chargerid, values).then(chargerProvider.reloadResource);
  };

  const confirmEditPricing = (values) => {
    Api.charge.patchCharger(chargerid, values).then(chargerProvider.reloadResource);
  };

  const confirmEditPowerSettings = (values) => {
    Api.charge.patchCharger(chargerid, values).then(chargerProvider.reloadResource);
  };

  const confirmAssignCharger = (chargerId, values) => {
    if (values.userId) {
      Api.charge.assignChargerToUser(chargerId, values.userId, values.identifier).then(chargerProvider.reloadResource);
    } else if (values.locationId) {
      Api.charge
        .assignChargerToLocation(chargerId, values.locationId, values.identifier)
        .then(chargerProvider.reloadResource);
    }
  };

  const onClickEditDetails = () => {
    drawerStore.openDrawer({
      label: 'Edit charge point details',
      inputs: editDetailsInputs,
      clickCallback: () => confirmEditDetails(toJS(drawerStore.values)),
    });
    drawerStore.setValues(chargerProvider.data);
  };

  const onClickEditStatus = () => {
    drawerStore.openDrawer({
      label: 'Edit charge point status',
      inputs: editStatusInputs,
      clickCallback: () => confirmEditStatus(toJS(drawerStore.values)),
    });
    drawerStore.setValues(chargerProvider.data);
  };

  const chargerConfig = {
    chargerId: 'CHG-12345',
    model: 'SuperCharge X2',
    location: {
      street: '456 Charger Ave',
      city: 'ElectriCity',
      zip: '98765',
      latitude: 37.7749,
      longitude: -122.4194,
    },
    settings: {
      maxOutputKw: 50,
      minOutputKw: 10,
      supportedModes: ['AC', 'DC', 'FastCharge'],
      cableLengthMeters: 5,
      isPublic: true,
    },
    status: {
      isActive: true,
      lastMaintenanceDate: '2024-08-15',
      firmwareVersion: '1.2.3',
      uptimePercentage: 99.7,
    },
    network: {
      connectivity: '5G',
      ipAddress: '192.168.1.100',
      macAddress: '00:1A:2B:3C:4D:5E',
    },
  };

  const onClickDisplayChargerConfiguration = async () => {
    if (chargerid === undefined) {
      return;
    }

    const chargePointConfigurationId = (
      await Api.charge.getChargePointConfigurationRequest({ chargePointId: chargerid })
    ).data;
    if (
      chargePointConfigurationId &&
      Array.isArray(chargePointConfigurationId.data) &&
      chargePointConfigurationId.data.length > 0
    ) {
      const { configurationId } = chargePointConfigurationId.data[0];
      const newOperationConfig = (await Api.charge.getConfigurationOperationRequest({ id: configurationId })).data;

      const { settings } = newOperationConfig.data[0];
      drawerStore.openDrawer({
        label: 'Charger configuration',
        inputs: [{ name: 'chargeConfig', type: FormInputTypes.jsonView, value: settings }],
      });
      drawerStore.setValues(settings);
    } else {
      drawerStore.openDrawer({
        label: 'Charger configuration',
        inputs: [{ label: '', name: 'chargeConfig', type: FormInputTypes.textView, value: 'N/A' }],
      });
    }
  };

  const onClickEditPricing = () => {
    const priceInputs = [
      getPriceDropdownInput(
        catalogsProvider,
        chargerCatalogsProvider.data?.map((el) => el.id),
      ),
    ];

    drawerStore.openDrawer({
      label: 'Edit charge point pricing',
      inputs: priceInputs,
      clickCallback: () => confirmEditPricing(toJS(drawerStore.values)),
    });
    drawerStore.setValues(chargerProvider.data);
  };

  const onClickEditPowerSettings = () => {
    drawerStore.openDrawer({
      label: 'Edit charge point power settings',
      inputs: editPowerSettingsInputs,
      clickCallback: () => confirmEditPowerSettings(toJS(drawerStore.values)),
    });
    drawerStore.setValues(chargerProvider.data);
  };

  const onClickAssignCharger = () => {
    drawerStore.openDrawer({
      label: 'Assign charge point',
      inputs: getAssignChargerCombinedInputs(credentialStore.isUserAdmin, locationsProvider, usersProvider),
      clickCallback: () => confirmAssignCharger(chargerProvider.data?.id, toJS(drawerStore.values)),
    });
  };

  const sendCommandInputs = [
    {
      id: 'selectedSendCommand',
      label: 'Apply Option',
      name: 'selectedSendCommand',
      type: FormInputTypes.radioButton,
      options: [
        {
          label: 'BOOT NOTIFICATION',
          value: 'BootNotification',
          description: 'Requests a boot notification from the charger. Make sure that session is not running.',
        },
        {
          label: 'HEARTBEAT',
          value: 'Heartbeat',
          description: 'Requests a heartbeat from the charger. Make sure that session is not running.',
        },
        {
          label: 'METER VALUES',
          value: 'MeterValues',
          description: 'Requests an update about the energy consumption meter and current power.',
        },
        {
          label: 'STATUS NOTIFICATION',
          value: 'StatusNotification',
          description: 'Requests a status update from the charger.',
        },
        { label: 'UNLOCK CONNECTOR', value: 'UnlockConnector', description: 'Requests to unlock the connector.' },
      ],
    },
  ];

  const saveSendCommand = () => {
    const drawerStoreValues = toJS(drawerStore.values);
    Api.charge.triggerCommand(chargerProvider.data?.id, drawerStoreValues?.selectedSendCommand);
  };

  const onClickSendCommand = () => {
    drawerStore.openDrawer({
      label: 'Send command',
      inputs: sendCommandInputs,
      clickCallback: saveSendCommand,
    });
    drawerStore.setValues({ selectedSendCommand: 'BootNotification' });
  };

  return (
    <div className={connectorStatusStyles.root} style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
        <Button variant="text" color="primary" onClick={onClickSendCommand}>
          Send Command
        </Button>
        {chargerProvider?.data?.status === CHARGER_STATUS.UNASSIGNED && (
          <Button variant="text" color="primary" onClick={onClickAssignCharger}>
            Assign
          </Button>
        )}
      </div>
      <div className={classes.cardRows}>
        <div className={classes.leftCardRow}>
          <StyledCard>
            <StyledCardHeader title="Details" onClick={onClickEditDetails} headerButtonIcon={HeaderButtonIcon.edit} />
            <StyledCardContent>
              <NameValueRowList rows={getDetailsRows(chargerProvider)} values={chargerProvider.data} />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="Status" onClick={onClickEditStatus} headerButtonIcon={HeaderButtonIcon.edit} />
            <StyledCardContent>
              <NameValueRowList
                rows={getStatusRows(chargerProvider)}
                values={{ ...chargerProvider.data, createdAt: formatToSloDate(chargerProvider.data?.createdAt) }}
              />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader
              title="Price lists"
              onClick={credentialStore.isUserAdmin ? onClickAddCatalogToCharger : undefined}
              headerButtonIcon={HeaderButtonIcon.add}
            />
            <StyledCardContent>
              {orderedCatalogs?.map((catalog) => {
                const parentNameDisplay = catalog.parentId ? ` (${catalog.parentName})` : '';
                return (
                  <TitleSubtitleRow
                    key={catalog.id}
                    title={catalog.name + parentNameDisplay}
                    subtitle={`${catalog?.productsCount} products`}
                    order={catalog.order}
                    actions={
                      !catalog.parentId && (
                        <ActionsList
                          actions={[{ label: 'Remove', callback: () => onClickRemoveCatalogFromCharger(catalog.id) }]}
                        />
                      )
                    }
                    additionalContent={
                      <>
                        {catalog.isInherited && <InheritedRuleIcon />}
                        {catalog.isFree && <FreeRuleIcon />}
                        {catalog.isTimeRestricted && <TimeBasedRuleIcon />}
                        {catalog.isUserRestricted && <UserBasedRuleIcon />}
                      </>
                    }
                    titleLink={priceListDetailsRoute(catalog.id)}
                  />
                );
              })}
            </StyledCardContent>
          </StyledCard>
          <StyledCard>
            <StyledCardHeader
              title="Charger Configuration"
              onClick={onClickDisplayChargerConfiguration}
              headerButtonIcon={HeaderButtonIcon.edit}
            />
            <StyledCardContent>
              <NameValueRowList rows={ChargerConfigurationRows} values={chargerProvider.data} />
            </StyledCardContent>
          </StyledCard>
        </div>

        <div className={classes.rightCardRow}>
          <StyledCard>
            <StyledCardHeader title="Activity" />
            <StyledCardContent>
              <div className={cardClasses.cardRow}>
                <Typography className={cardClasses.cardRowLeftItem} style={{ textTransform: 'uppercase' }}>
                  Last signal
                </Typography>
                <div
                  className={cardClasses.cardRowRightItem}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Typography>{formatToSloDate(chargerDetailsStore.lastHeartbeat)}</Typography>
                  {chargerDetailsStore.chargerOffline && (
                    <ColumnStatusBadge label="OFFLINE" color="#00E8A8" justifyContent="flex-end" />
                  )}
                </div>
              </div>
              <NameValueRow name="power" value={chargerDetailsStore.activeConnector?.power} />
              <DisplayActiveTimeDuration store={chargerDetailsStore} />
              <NameValueRow name="consumption" value={chargerDetailsStore.activeConnector?.consumption} />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader
              title="Power settings"
              onClick={onClickEditPowerSettings}
              headerButtonIcon={HeaderButtonIcon.edit}
            />
            <StyledCardContent>
              <NameValueRowList rows={powerSettingsRows} values={chargerProvider.data} />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="Totals" />
            <StyledCardContent>
              <NameValueRowList rows={totalsRows} values={chargerProvider.data} />
            </StyledCardContent>
          </StyledCard>

          {credentialStore.isUserAdmin && (
            <StyledCard>
              <StyledCardHeader
                title="Assigned to"
                onClick={onClickAssignedTo}
                headerButtonIcon={HeaderButtonIcon.add}
              />
              <StyledCardContent>
                {accounts?.map((account) => (
                  <TitleSubtitleRow
                    key={account.id}
                    title={account.name}
                    subtitle={account.email}
                    actions={
                      <ActionsList
                        actions={[{ label: 'Remove', callback: () => onClickRemoveAccountFromCharger(account.id) }]}
                      />
                    }
                    titleLink={companyDetailsParameters(account.id)}
                  />
                ))}
              </StyledCardContent>
            </StyledCard>
          )}
        </div>
      </div>

      <VMDataGrid rows={chargerProvider?.data?.connectors} columns={connectorColumns} label="Connectors" />
      <br />
      <div className={connectorStatusStyles.root}>
        <VMDataGrid
          rows={rows}
          columns={getItemColumns()}
          label="Connector status"
          pageSize={60}
          onDateChangeCallBack={handleDateChange}
          date={date}
        />
      </div>

      <AutomaticModeDialog chargerProvider={chargerProvider} />
      <RebootDialog />
    </div>
  );
});

export default ChargerDetailsOverview;
