export const injectDynamicStyles = (rowIndex, dateColumnWidth) => {
  const styleId = 'dynamic-styles';
  let styleElement = document.getElementById(styleId);

  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.id = styleId;
    document.head.appendChild(styleElement);
  }

  styleElement.innerHTML = `
    .expanding_table .MuiDataGrid-row {
      cursor: pointer;
    }

    .expanding_table .MuiDataGrid-renderingZone {
      overflow-y: scroll;
      overflow-x: clip;
      margin: 0px;
    }

    .expanding_table .MuiDataGrid-cell[data-rowindex="${rowIndex}"] {
      max-height: 300px !important;
    }

    .expanding_table .MuiDataGrid-row[data-rowindex="${rowIndex}"] {
      height: 300px !important;
      min-height: 300px !important;
    }

    .expanding_table .MuiDataGrid-cell[data-field="custom"][data-rowindex="${rowIndex}"] .custom-cell {
      height: 300px;
      display: flex;
      background-color: rgba(255, 255, 255, 0.9);
      width: calc(100% - ${dateColumnWidth}px);
      position: absolute;
      left: ${dateColumnWidth + 10}px;
      z-index: 10;
      box-sizing: border-box;
    }

    .expanding_table .MuiDataGrid-cell[data-rowindex="${rowIndex}"][data-field^="hour_"] {
      display: none !important;
    }
  `;
};
