import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function NotFoundPage() {
  const history = useHistory();

  return (
    <div>
      <Typography variant="h2" style={{ marginBottom: 50 }}>
        404
      </Typography>
      <Typography paragraph>Route '{history.location.pathname}' not found</Typography>
    </div>
  );
}
