import { GridRowParams } from '@material-ui/data-grid';
import { ListItem, Typography } from '@material-ui/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import TextLink from '../../components/other/TextLink';
import ActionsMenu from '../../components/other/ActionsMenu';
import { Product } from '../../types/Product';
import { PRICING_TABS } from '../../utils/routing';
import { drawerStore } from '../../state/drawerStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { FormInputTypes } from '../../utils/constants';
import { gridColumn } from '../../utils/gridUtils';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { Api } from '../../api';
import { PAGES } from '../../components/main/MainComponent';
import { useProvider } from '../../state/providerStore';

export enum ProductType {
  PRODUCT = 'product',
  ADDON = 'addon',
}
const validUnits = ['kWh', 'mWh', 'W', 'h', 'min', 'unit'];
const validTypes = [ProductType.PRODUCT, ProductType.ADDON];
const productAddInputs = (onClickCopy) => [
  { name: 'copyFrom', type: FormInputTypes.button, inputChangeCallback: onClickCopy, label: 'Copy From' },
  { name: 'name', type: FormInputTypes.textField },
  { name: 'displayName', type: FormInputTypes.textField },
  { name: 'shortName', type: FormInputTypes.textField },
  { name: 'description', type: FormInputTypes.multilineTextField },
  { name: 'shortDescription', type: FormInputTypes.multilineTextField },
  { name: 'SKU', type: FormInputTypes.textField },
  { name: 'currency', type: FormInputTypes.select, options: ['eur', 'usd'] },
  { name: 'price', type: FormInputTypes.number },
  {
    name: 'VAT',
    label: 'VAT',
    type: FormInputTypes.select,
    options: [
      { label: '22%', value: 22 },
      { label: '20%', value: 20 },
      { label: '9.5%', value: 9.5 },
    ],
  },
  { name: 'unit', label: 'Unit', type: FormInputTypes.select, options: validUnits },
  {
    name: 'type',
    label: 'Product type',
    type: FormInputTypes.select,
    options: validTypes,
  },
  { name: 'quantityForPrice', type: FormInputTypes.number },
  { name: 'reservationAmount', type: FormInputTypes.number },
];

const ProductsPage = observer(({ catalogId }: { catalogId: string }) => {
  const catalogProductsProvider = usePaginatedProvider(undefined, undefined, false);
  const allProductsProvider = useProvider(undefined, undefined, false);
  const productProvider = useProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);
  const [productOptions, setProductOptions] = useState(null);
  const [copyFromProductData, setCopyFromProductData] = useState(null);

  useEffect(() => {
    catalogProductsProvider.setRequest(() => Api.catalog.listCatalogProducts(catalogId));

    catalogProductsProvider.setOnFulfilled((data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
    });

    catalogProductsProvider.reloadResource();
  }, [catalogId]);

  const productSelectOption = (product) => ({
    optionName: product.id,
    topRow: `${product.name}`,
    bottomRow: `${product.SKU}`,
  });

  const confirmCopyFrom = (values) => {
    productProvider.setRequestAndReload(() => Api.catalog.getProduct(values.productId));
    productProvider.setOnFulfilled((data, _count) => {
      const mappedData = {
        name: data.name || 'Default Product Name',
        displayName: data.displayName || 'Default Display Name',
        shortName: data.shortName || 'Default Short Name',
        description: data.description || 'Default Description',
        shortDescription: data.shortDescription || 'Default Short Description',
        SKU: data.SKU || '',
        currency: data.price?.currency || 'usd',
        price: data.price?.value || 0,
        VAT: data.tax?.value && [22, 20, 9.5].includes(data.tax.value) ? data.tax.value : 0,
        unit: data.unit && validUnits.includes(data.unit) ? data.unit : 'kWh',
        type: data.type && validTypes.includes(data.type) ? data.type : 'product',
        quantityForPrice: data.quantityForPrice || 1,
        reservationAmount: data.reservationAmount?.value || 1,
      };

      setCopyFromProductData(mappedData);
    });
  };

  const onClickCopy = () => {
    allProductsProvider.setRequest(() => Api.catalog.listProducts());
    allProductsProvider.setOnFulfilled((data, _count) => {
      if (data) {
        setProductOptions(data.map(productSelectOption));
      }
    });
    allProductsProvider.reloadResource();
  };
  useEffect(() => {
    const editCatalogProductsInputs = [
      {
        name: 'productId',
        type: FormInputTypes.multilineSelect,
        options: productOptions,
      },
    ];
    if (productOptions) {
      drawerStore.openDrawer({
        label: 'Add product from',
        inputs: editCatalogProductsInputs,
        buttonLabel: 'Save Copy',
        clickCallback: () => confirmCopyFrom(toJS(drawerStore.values)),
      });
    }
  }, [productOptions]);

  useEffect(() => {
    if (!drawerStore.open) {
      setCopyFromProductData(null);
    }
  }, [drawerStore.open]);

  const confirmAddProduct = async () => {
    const response = await Api.catalog.createProduct(drawerStore.values);
    const productId = response.data.data.id;
    await Api.catalog.createCatalogProduct(catalogId, { productId, order: 0 });
    catalogProductsProvider.reloadResource();
  };

  const onClickAddProduct = () => {
    drawerStore.openDrawer({
      label: 'Add Product',
      inputs: productAddInputs(onClickCopy),
      clickCallback: confirmAddProduct,
    });

    drawerStore.setValues(copyFromProductData);
  };

  useEffect(() => {
    if (copyFromProductData) {
      onClickAddProduct();
    }
  }, [copyFromProductData]);

  const onClickRemoveProduct = async (product: Product) => {
    try {
      await Api.catalog.removeCatalogProduct(catalogId, product.id);
      catalogProductsProvider.reloadResource();
    } catch (error) {
      console.error('Failed to remove product: ', `${error}`);
    }
  };

  const productActions = (params: GridRowParams) => (
    <ActionsMenu>
      <ListItem button onClick={() => onClickRemoveProduct(params.row as Product)}>
        <Typography style={{ width: '100%' }}>Remove</Typography>
      </ListItem>
    </ActionsMenu>
  );

  const productColumns = [
    gridColumn('name', {
      flex: 2,
      sortableFields,
      renderCell: (params: GridRowParams) => (
        <TextLink
          to={`/${PAGES.Pricing.toLowerCase()}/${PRICING_TABS.PRODUCTS.toLowerCase()}/${params.row.id}`}
          label={params.row.name}
        />
      ),
    }),
    gridColumn('SKU', { flex: 2, sortableFields }),
    gridColumn('price', {
      flex: 2,
      sortableFields,
      renderCell: (params: GridRowParams) => (
        <div>
          {params.row.price.value} {params.row.price.currency}
        </div>
      ),
    }),
    gridColumn('unit', { flex: 2, sortableFields }),
    gridColumn('rules', {
      flex: 4,
      renderCell: (params: GridRowParams) => {
        const rules = params.row.rules;
        if (!rules || rules.length === 0) {
          return <div>None</div>;
        }
        return (
          <>
            {rules.map((rule: any, index: number) => (
              <div
                key={index}
                style={{
                  margin: 0,
                  lineHeight: 1.5,
                }}
              >
                {rule.ruleProductName ? `${rule.ruleProductName} (${rule.rule})` : rule.rule}
                {index < rules.length - 1 && ', '}
              </div>
            ))}
          </>
        );
      },
    }),
    gridColumn('actions', {
      width: 150,
      renderCell: (params: GridRowParams) => productActions(params),
      align: 'center',
      headerAlign: 'center',
    }),
  ];

  return (
    <VMPaginatedGrid
      rows={catalogProductsProvider.data}
      columns={productColumns}
      onClickAdd={onClickAddProduct}
      label="Products"
      onPageOffsetChange={catalogProductsProvider.onPageChange}
      onSortTypeChange={catalogProductsProvider.setSort}
    />
  );
});

export default ProductsPage;
