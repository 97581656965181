import { makeStyles } from '@material-ui/core/styles';

const useContentHeaderStyles = makeStyles(
  () => ({
    contentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 20,
      paddingTop: 20,
      borderRadius: 20,
    },
    contentHeaderButtonContainer: {},
    dateButton: {
      cursor: 'pointer',
      marginLeft: '10px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { index: 1 },
);

export default useContentHeaderStyles;
