import { PixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';

async function getCroppedImg(image: HTMLImageElement, pixelCrop: PixelCrop): Promise<Blob> {
  if (!pixelCrop.width || !pixelCrop.height) {
    throw new Error('Invalid crop dimensions. Please select a valid crop area.');
  }

  const previewCanvas = document.createElement('canvas');
  const ctx = previewCanvas.getContext('2d');

  if (!ctx) {
    throw new Error('Canvas context not found');
  }
  canvasPreview(image, previewCanvas, pixelCrop);

  return new Promise((resolve, reject) => {
    previewCanvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      resolve(blob);
    }, 'image/jpeg');
  });
}

export default getCroppedImg;
