import React, { useEffect } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import { useMediaQuery } from '@mui/material';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ChargerDetails from './ChargerDetails';
import { chargerDetailsTabRoute, CHARGERS_TABS, chargersTabRoute, route } from '../../utils/routing';
import UnassignedChargers from './UnassignedChargers';
import WithIssuesChargers from './WithIssuesChargers';
import dashboardStore from '../../state/dashboardStore';
import { AllChargers } from './AllChargers';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import { PAGES } from '../../components/main/MainComponent';
import credentialStore from '../../state/credentialStore';
import UnknownChargers from './UnknownChargers';
import ChargerConfigurationsPage from './ChargerConfigurationsPage';

const ChargersPage = observer(() => {
  const history = useHistory();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    dashboardStore.appBarLabel = 'Charge Points';
  });

  if (!credentialStore.isUserAdmin) {
    if (!credentialStore.canViewUnassignedChargers()) {
      if (history.location.pathname !== route(PAGES.Chargers)) {
        return <Redirect to={route(PAGES.Chargers)} />;
      }
      return <AllChargers />;
    }
  }

  const handleOpenConfigurationPage = () => {
    history.push(chargersTabRoute(CHARGERS_TABS.INITIALISATION_CONFIG));
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
        <ViziTabs tabs={[CHARGERS_TABS.ALL, CHARGERS_TABS.UNASSIGNED, CHARGERS_TABS.WITHISSUES, CHARGERS_TABS.UNKNOWN]} basePath={chargersTabRoute()}>
          <ViziTab label={CHARGERS_TABS.ALL} linkTo={chargersTabRoute(CHARGERS_TABS.ALL)} />
          <ViziTab label={CHARGERS_TABS.UNASSIGNED} linkTo={chargersTabRoute(CHARGERS_TABS.UNASSIGNED)} />
          <ViziTab label={CHARGERS_TABS.WITHISSUES} linkTo={chargersTabRoute(CHARGERS_TABS.WITHISSUES)} />
          <ViziTab label={CHARGERS_TABS.UNKNOWN} linkTo={chargersTabRoute(CHARGERS_TABS.UNKNOWN)} />
        </ViziTabs>

        <Card
          style={{
            width: isSmallScreen ? '62px' : '48px',
            height: isSmallScreen ? '62px' : '48px',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={handleOpenConfigurationPage}
        >
          <IconButton>
            <SettingsIcon />
          </IconButton>
        </Card>
      </div>

      <Switch>
        <Route path={chargersTabRoute(CHARGERS_TABS.ALL)}>
          <AllChargers />
        </Route>
        <Route path={chargersTabRoute(CHARGERS_TABS.UNASSIGNED)}>
          <UnassignedChargers />
        </Route>
        <Route path={chargersTabRoute(CHARGERS_TABS.WITHISSUES)}>
          <WithIssuesChargers />
        </Route>
        <Route path={chargersTabRoute(CHARGERS_TABS.UNKNOWN)}>
          <UnknownChargers />
        </Route>
      </Switch>
    </div>
  );
});

export default observer(() => (
  <Switch>
    <Route path={chargerDetailsTabRoute()} component={ChargerDetails} />
    <Route path={chargersTabRoute(CHARGERS_TABS.INITIALISATION_CONFIG)} component={ChargerConfigurationsPage} />
    <Route path={chargersTabRoute(CHARGERS_TABS.OPERATION_CONFIG)} component={ChargerConfigurationsPage} />
    {(credentialStore.isUserAdmin || credentialStore.canViewUnassignedChargers()) && (
      <Route exact path={route(PAGES.Chargers)}>
        <Redirect to={chargersTabRoute(CHARGERS_TABS.ALL)} />
      </Route>
    )}
    <Route path={route(PAGES.Chargers)} component={ChargersPage} />
  </Switch>
));
