import { useCallback, useEffect, useState } from 'react';
import { CardContent, CardHeader, Divider, IconButton, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@material-ui/icons/Add';
import { observer } from 'mobx-react-lite';
import { makeAutoObservable } from 'mobx';
import { useStyles } from '../../styles/styles';
import StyledCard from './StyledCard';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import ImageCropper from '../../pages/Locations/ImageCropper';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';

export const apiMapping = {
  location: {
    listImages: Api.charge.listImagesForLocation,
    addImageUrl: Api.charge.addImageUrlForLocation,
    removeImageUrl: Api.charge.deleteImage,
  },
  account: {
    listImages: Api.business.getAccountLogoUrl,
    addImageUrl: Api.business.addAccountLogoUrl,
    removeImageUrl: Api.business.removeLogoUrl,
  },
};
interface ImageResponse {
  id: string;
  url: string;
}

class ImageGridStore {
  images: ImageResponse[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  pushImage(image: ImageResponse) {
    this.images = [...this.images, image];
  }

  removeImageById(id: string) {
    this.images = this.images.filter((img) => img.id !== id);
  }
}

const ImageGridCard = observer(({ entityId, type }: { entityId: string; type: keyof typeof apiMapping }) => {
  const classes = useStyles();

  const [imageGridStore] = useState(() => new ImageGridStore());
  const [croppedBlobImage, setCroppedBlobImage] = useState<any>(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const addImage = (img) => {
    imageGridStore.pushImage(img);

    if (type === 'account' && imageGridStore.images.length >= 1) {
      setIsAddButtonDisabled(true);
    }
  };

  const removeImage = async (id: string) => {
    const { removeImageUrl } = apiMapping[type];
    try {
      await removeImageUrl(id);
      imageGridStore.removeImageById(id);

      if (imageGridStore.images.length === 0) {
        setIsAddButtonDisabled(false);
      }
    } catch (error) {
      console.error('Error removing image:', error);
    }
  };

  const onReceiveImagesFromApi = (data) => {
    data.forEach((image) => {
      if (image.url) {
        addImage(image);
      }
    });
  };

  const onSaveCroppedImage = async (croppedBlob) => {
    try {
      const formData = new FormData();
      formData.append('file', croppedBlob);

      const uploadedImageResponse = await Api.docs.createImageForLocation(formData);
      const imageUrl = uploadedImageResponse.data.data;

      const { addImageUrl } = apiMapping[type];
      const response = await addImageUrl(entityId, imageUrl);
      addImage(response.data.data);
    } catch (error) {
      console.error('Error cropping image: ', error);
    }
  };

  useProvider(() => {
    const { listImages } = apiMapping[type];
    return listImages(entityId);
  }, onReceiveImagesFromApi);

  useEffect(() => {
    drawerStore.setCallback(() => {
      onSaveCroppedImage(croppedBlobImage);
    });
  }, [croppedBlobImage]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const fileDataUrl = reader.result as string;
        drawerStore.openDrawer({
          label: 'Crop Image',
          inputs: [
            {
              type: FormInputTypes.component,
              value: <ImageCropper selectedImage={fileDataUrl} setCroppedImage={setCroppedBlobImage} />,
            },
          ],
          buttonLabel: 'Save',
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    disabled: isAddButtonDisabled,
  });

  return (
    <StyledCard>
      <CardHeader
        title="Images"
        className={classes.cardHeader}
        action={
          <div key="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <IconButton disabled={isAddButtonDisabled}>
              <AddIcon />
            </IconButton>
          </div>
        }
      />

      <CardContent>
        <Divider />
        <div style={{ width: '100%' }}>
          {imageGridStore.images.map((image, index) => (
            <div style={{ position: 'relative', display: 'inline-block' }} key={`${image}_${index}`}>
              <img src={image.url} alt={image.url} style={{ height: 140 }} />
              <div
                style={{
                  margin: '0.2em',
                  paddingLeft: '0.4em',
                  paddingRight: '0.4em',
                  paddingTop: '0.2em',
                  paddingBottom: '0.2em',
                  position: 'absolute',
                  zIndex: 1,
                  right: 0,
                  top: 0,
                  color: '#fff',
                  opacity: 1,
                  background: 'black',
                  borderRadius: '0.3em',
                  cursor: 'pointer',
                }}
                onClick={() => removeImage(image.id)}
              >
                <Typography style={{ margin: 0, padding: 0 }}>x</Typography>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </StyledCard>
  );
});

export default ImageGridCard;
