/* eslint-disable camelcase */
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import {
  sessionsColumn_chargerTypeIcon,
  sessionsColumn_location,
  sessionsColumn_StartedAt,
  sessionsColumn_UserId,
  sessionsColumns_actions,
  sessionsColumns_avgPower,
  sessionsColumns_Connector,
  sessionsColumns_Duration,
  sessionsColumns_price,
  sessionsColumns_Status,
  sessionsColumns_unitsConsumed,
} from '../Sessions/SessionsPage';
import { Api } from '../../api';

const ChargerDetailsSessions = observer(() => {
  const { chargerid } = useParams<{ chargerid: string }>();

  const chargerSessionsProvider = usePaginatedProvider(
    (offset) => Api.charge.listSessionsForCharger(chargerid, { skip: offset, take: 20 }),
    undefined,
  );
  const history = useHistory();

  const chargerSessionColumns = [
    sessionsColumn_chargerTypeIcon(),
    sessionsColumn_StartedAt(),
    sessionsColumn_UserId(),
    sessionsColumn_location(),
    sessionsColumns_Connector(),
    sessionsColumns_Status(),
    sessionsColumns_Duration(),
    sessionsColumns_avgPower(),
    sessionsColumns_unitsConsumed(),
    sessionsColumns_price(),
    sessionsColumns_actions(chargerSessionsProvider, history),
  ];

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        label="Sessions"
        rows={chargerSessionsProvider.data}
        columns={chargerSessionColumns}
        count={chargerSessionsProvider.count}
        onPageOffsetChange={chargerSessionsProvider.onPageChange}
      />
    </div>
  );
});

export default ChargerDetailsSessions;
