import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, IconButton, ListItem, Typography } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toJS } from 'mobx';
import { GridRowParams } from '@material-ui/data-grid';

import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import TextLink from '../../components/other/TextLink';
import {
  CHARGER_DETAILS_TABS,
  chargerDetailsTabParameters,
} from '../../utils/routing';
import ActionsMenu from '../../components/other/ActionsMenu';
import { drawerStore } from '../../state/drawerStore';
import { Charger, CHARGER_STATUS, CHARGER_TYPE } from '../../types/Charger';
import { ChargerIcon } from '../../components/other/ChargerIcon';
import { gridColumn } from '../../utils/gridUtils';
import { ChargerStatusBadge } from '../../components/other/CellBadges';
import { greenTextColor } from '../../components/main/react/App';
import { copyTextToClipboard } from '../../utils/utils';
import { Api } from '../../api';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import credentialStore from '../../state/credentialStore';
import { FormInputTypes } from '../../utils/constants';

const initalisationConfig = {
  provider: '',
  minPower: 0,
  maxPower: 0,
  minCurrent: 0,
  maxCurrent: 0,
  connectors: [
    {
      type: '',
      cableLength: 0,
      connectorId: '1',
      ocppExtension: '01',
    },
    {
      type: '',
      cableLength: 0,
      connectorId: '2',
      ocppExtension: '02',
    },
  ],
};

const syncConfiguration = (unknownChargersProvider: any) => {
  Api.charge.syncUnknownChargePoint().then(unknownChargersProvider.reloadResource);
};

const onClickRemoveUnknownCharger = (charger: Charger, unknownChargersProvider: any) => {
  Api.charge.deleteUnknownCharger(charger.id).then(unknownChargersProvider.reloadResource);
};

const onClickConfirmAdd = (unknownChargersProvider: any) => {
  const configuration = toJS(drawerStore.values);
  if (credentialStore.isUserAdmin) {
    Api.charge.createInitalisationConfig(configuration)
      .then(syncConfiguration)
      .then(() => {
        unknownChargersProvider.reloadResource();
      });
  }
};

export const onClickDuplicateConfiguration = (charher: Charger, unknownChargersProvider) => {
  const selectedConfiguration = unknownChargersProvider.data.find((item) => item.id === charher.id);

  drawerStore.openDrawer({
    label: 'Duplicate configuration template',
    inputs: [
      { name: 'manufacturer', type: FormInputTypes.disabledTextField },
      { name: 'model', type: FormInputTypes.disabledTextField },
      { name: 'template', type: FormInputTypes.editableJsonView },
    ],
    clickCallback: () => onClickConfirmAdd(unknownChargersProvider),
  });

  const initialValues = {
    manufacturer: selectedConfiguration.manufacturer,
    model: selectedConfiguration.model,
    template: initalisationConfig,
  };
  drawerStore.setValues(initialValues);
};

const assignedChargerActions = (params: GridRowParams, unknownChargersProvider) => (
  <ActionsMenu>
    <ListItem button onClick={() => onClickDuplicateConfiguration(params.row as Charger, unknownChargersProvider)}>
      <Typography style={{ width: '100%' }}>Add configuration</Typography>
    </ListItem>
    <ListItem button onClick={() => onClickRemoveUnknownCharger(params.row as Charger, unknownChargersProvider)}>
      <Typography style={{ width: '100%' }}>Remove</Typography>
    </ListItem>
  </ActionsMenu>
);

export const getAllChargersColumns = (unknownChargersProvider, sortableFields = []) => [
  gridColumn('chargerTypeIcon', {
    width: 60,
    headerName: '',
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const type = params.row.isPublic ? CHARGER_TYPE.PUBLIC : CHARGER_TYPE.DOMICIL;

      return <ChargerIcon type={type} />;
    },
  }),
  gridColumn('ocppId', {
    flex: 2,
    headerName: 'OCPP ID',
    sortableFields,
    renderCell: (params: GridRowParams) => (
      <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
        <p>{params.row.ocppId}</p>
      </div>
    ),
  }),
  gridColumn('manufacturer', {
    flex: 2,
    headerName: 'manufacturer/model',
    sortableFields,
    valueGetter: (params: GridRowParams) => `${params.row.manufacturer} / ${params.row.model}`,
  }),
  gridColumn('totalPower', {
    flex: 1,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const maxPower = params.row.maxPower;
      return <p>{Number(maxPower).toFixed(2)} kW</p>;
    },
  }),
  gridColumn('availablePower', {
    flex: 1,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const availablePower = params.row.availablePower || 0;
      return <p>{Number(availablePower).toFixed(2)} kW</p>;
    },
  }),
  gridColumn('activePower', {
    flex: 1,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const activePower = params.row.activePower || 0;
      return <p>{Number(activePower).toFixed(2)} kW</p>;
    },
  }),
  gridColumn('status', {
    width: 150,
    sortableFields,
    align: 'center',
    renderCell: (params: GridRowParams) => <ChargerStatusBadge status={params.row.status} />,
  }),
  gridColumn('actions', {
    width: 150,
    renderCell: (params: GridRowParams) => assignedChargerActions(params, unknownChargersProvider),
    align: 'center',
    headerAlign: 'center',
  }),
];

const UnknownChargers = observer(() => {
  const unknownChargersProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);

  useEffect(() => {
    unknownChargersProvider.reloadResource();
  }, [unknownChargersProvider]);

  const filterCallback = (param?: string, val?: string) => {
    const urlParams: any = {};
    if (param && val) {
      urlParams[param] = val;
    }
    const requestFunction = (offset, sortBy, sortOrder) =>
      Api.charge.listChargersUnknown({ ...urlParams, skip: offset, take: 20, sortBy, sortOrder });

    unknownChargersProvider.setRequest(requestFunction);
    unknownChargersProvider.reloadResource();
  };

  useEffect(() => {
    unknownChargersProvider.setRequest((offset, sortBy, sortOrder) =>
      Api.charge.listChargersUnknown({ skip: offset, take: 20, sortBy, sortOrder }),
    );

    unknownChargersProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
    });

    unknownChargersProvider.reloadResource();
  }, []);
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => syncConfiguration(unknownChargersProvider)}
          style={{
            color: '#00BD89',
            backgroundColor: 'transparent',
            padding: '0.5em 1em',
            marginBottom: '1.4em',
          }}
        >
          SYNC CONFIGURATIONS
        </Button>
      </div>
      <VMPaginatedGrid
        rows={unknownChargersProvider.data}
        columns={getAllChargersColumns(unknownChargersProvider, sortableFields)}
        onPageOffsetChange={unknownChargersProvider.onPageChange}
        onSortTypeChange={unknownChargersProvider.setSort}
        label="Unknown Chargepoints"
        filterParamOptions={[
          { label: 'OCPP ID', value: 'ocppId' },
          { label: 'Location', value: 'location' },
        ]}
        filterCallback={filterCallback}
      />
    </div>
  );
});

export default UnknownChargers;
