import { baseUrls, createApi } from './apiUtils';

const businessApi = {
  v1: createApi({ baseURL: `${baseUrls.business}/v1` }),
  v2: createApi({ baseURL: `${baseUrls.business}/v2` }),
  adminV1: createApi({ baseURL: `${baseUrls.business}/admin/v1` }),
  adminV2: createApi({ baseURL: `${baseUrls.business}/admin/v2` }),
};

const BUSINESS_URLS = {
  ACCOUNTS: 'accounts',
  ACCOUNT: (accountId: string) => `accounts/${accountId}`,
  USERS: (accountId: string) => `accounts/${accountId}/users`,
  USER: (accountId: string, userId: string) => `accounts/${accountId}/users/${userId}`,
  OWNER_ACCOUNTS: (userId: string) => `users/${userId}/accounts`,
  ACCOUNT_SETTINGS: (accountId: string) => `accounts/${accountId}/settings`,
  ACCOUNT_LOGO: (accountId: string) => `accounts/${accountId}/logo`,
};

export const listAccounts = (params?: any) => businessApi.adminV1.get(BUSINESS_URLS.ACCOUNTS, { params });

export const getAccount = (accountId: string) => businessApi.adminV1.get(BUSINESS_URLS.ACCOUNT(accountId));

export const createAccount = (accountData) => businessApi.adminV1.post(BUSINESS_URLS.ACCOUNTS, accountData);

export const patchAccount = (accountId: string, accountData) =>
  businessApi.adminV1.patch(BUSINESS_URLS.ACCOUNT(accountId), accountData);

export const assignUserToAccount = (accountId: string, userId: string, type: 'user' | 'owner') =>
  businessApi.adminV1.post(BUSINESS_URLS.USERS(accountId), { userId, type });

export const getUsersForAccount = (accountId: string) => businessApi.adminV1.get(BUSINESS_URLS.USERS(accountId));

export const removeUserFromAccount = (accountId: string, userId: string) =>
  businessApi.adminV1.delete(BUSINESS_URLS.USER(accountId, userId));

export const listOwnerAccounts = (userId: string) => businessApi.v1.get(BUSINESS_URLS.OWNER_ACCOUNTS(userId));

export const setAccountSettings = (accountId: string, settings: any) =>
  businessApi.adminV1.post(BUSINESS_URLS.ACCOUNT_SETTINGS(accountId), settings);

export const addAccountLogoUrl = (accountId: string, logo) =>
  businessApi.v1.post(BUSINESS_URLS.ACCOUNT_LOGO(accountId), logo);

export const getAccountLogoUrl = (accountId: string) => businessApi.v1.get(BUSINESS_URLS.ACCOUNT_LOGO(accountId));

export const removeLogoUrl = (accountId: string) =>
  businessApi.v1.patch(BUSINESS_URLS.ACCOUNT_LOGO(accountId), { logo: 'empty' });
