import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grow, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { TOAST_MESSAGE_TYPE, toastStore } from '../../state/toastStore';

const MainToast = observer(() => (
  <Grow in={toastStore.message !== ''}>
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open
    >
      <Alert severity={toastStore.type === TOAST_MESSAGE_TYPE.ERROR ? 'error' : 'info'}>
        {toastStore.message}
      </Alert>
    </Snackbar>
  </Grow>
));

export default MainToast;
