import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GridRowParams } from '@material-ui/data-grid';
import { toJS } from 'mobx';
import { Button, Grid, IconButton, ListItem, Typography } from '@material-ui/core';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { Api } from '../../api';
import ActionsMenu from '../../components/other/ActionsMenu';
import { Charger, Configuration, CHARGER_STATUS, CHARGER_TYPE } from '../../types/Charger';
import { drawerStore } from '../../state/drawerStore';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { gridColumn } from '../../utils/gridUtils';
import credentialStore from '../../state/credentialStore';
import { FormInputTypes } from '../../utils/constants';

const operationConfig = {
  settings: [
    {
      value: 0,
      setting: 'HeartbeatInterval',
    },
    {
      value: 0,
      setting: 'MeterValueSampleInterval',
    },
    {
      value: 0,
      setting: 'WebSocketPingInterval',
    },
    {
      value: 0,
      setting: 'EVDiscardTimeOut',
    },
  ],
};

const getChargerOptions = (chargerProvider) =>
  chargerProvider.data?.map((charger) => {
    const label = `${charger.manufacturer} / ${charger.model}`;
    return {
      topRow: label,
      bottomRow: charger.ocppId,
      optionName: charger.id,
      label,
    };
  });

const getApplyOptions = (chargerProvider, configuration) =>
  chargerProvider.data
    ?.filter((charger) =>
      charger.model === configuration.model && charger.manufacturer === configuration.manufacturer,
    )
    .map((charger) => ({
      topRow: `${charger.ocppId}`,
      bottomRow: charger.id,
      optionName: charger.id,
      label: `${charger.manufacturer} / ${charger.model}`,
    }));

const getAssignedChargersOptions = (chargerProvider, configuration) =>
  chargerProvider.data
    ?.filter((charger) =>
      charger.model === configuration.model &&
      charger.manufacturer === configuration.manufacturer &&
      configuration.assignedChargerIds.includes(charger.id),
    )
    .map((charger) => ({
      topRow: `${charger.ocppId}`,
      bottomRow: charger.id,
      optionName: charger.id,
      label: `${charger.manufacturer} / ${charger.model}`,
      locationName: `${charger.location?.name}`,
    }));

const onClickConfirmAdd = (operationConfigProvider: any) => {
  const configuration = toJS(drawerStore.values);

  const { manufacturerModel, ...rest } = configuration;

  const [manufacturer, model] = manufacturerModel.split('/').map((part) => part.trim());

  const newConfiguration = {
    ...rest,
    manufacturer,
    model,
  };
  if (credentialStore.isUserAdmin) {
    Api.charge.createoperationConfig(newConfiguration)
      .then((response) => {
        operationConfigProvider.reloadResource();
      });
  }
};

const onClickConfirmApply = async (configuration: any, operationConfigProvider, options: any) => {
  const configurationId = configuration.id;

  const applyOption = drawerStore.values.applyOption;

  if (applyOption === 'selected') {
    const createChargePointPromises = drawerStore.values.chargePoints_filterList.map(async (chargePoint) => {
      const chargePointConfig = { configurationId, chargePointId: chargePoint.optionName };

      try {
        await Api.charge.createChargePointConfiguration(chargePointConfig);
      } catch (error) {
        console.error(`Failed to create charge point configuration for ID: ${chargePoint.optionName}`, error);
      }
    });

    await Promise.all(createChargePointPromises);
    operationConfigProvider.reloadResource();
  } else if (applyOption === 'all') {
    const allChargePointConfigurations = options.map((option) => ({
      configurationId,
      chargePointId: option.optionName,
    }));

    const createAllChargePointPromises = allChargePointConfigurations.map(async (chargePointConfig) => {
      try {
        await Api.charge.createChargePointConfiguration(chargePointConfig);
      } catch (error) {
        console.error(`Failed to create charge point configuration for ID: ${chargePointConfig.chargePointId}`, error);
      }
    });

    await Promise.all(createAllChargePointPromises);
    operationConfigProvider.reloadResource();
  }
};

const onReadConfigurationClicked = async (chargerProvider, operationConfigProvider) => {
  const chargePointId = drawerStore.values.chargePoint;

  if (chargePointId === undefined) {
    return;
  }
  drawerStore.openDrawer({
    label: 'Create operation config',
    inputs: [
      { name: 'loading', type: FormInputTypes.loader },
    ],
  });

  const configurationRequest = (await Api.charge.getConfigurationRequest(chargePointId)).data;
  const configuration = (await Api.charge.getConfigurationRequestDetail(chargePointId, configurationRequest.data.taskId)).data;

  const selectedCharger = getChargerOptions(chargerProvider).find(
    (charger) => charger.optionName === chargePointId,
  );

  const [selectedManufacturer, selectedModel] = selectedCharger.label.split('/').map((part) => part.trim());

  if (configuration.data?.response) {
    drawerStore.openDrawer({
      label: 'Create operation config',
      inputs: [
        {
          name: 'chargePoint',
          label: 'Charge Point',
          type: FormInputTypes.multilineSelect,
          options: getChargerOptions(chargerProvider),

        },
        {
          name: 'readConfiguration',
          type: FormInputTypes.textButton,
          label: 'Read Configuration',
          onClick: () => onReadConfigurationClicked(chargerProvider, operationConfigProvider),
        },
        { label: 'Manufacturer / Model', name: 'manufacturerModel', type: FormInputTypes.disabledTextField },
        { name: 'comment', type: FormInputTypes.textField },
        { name: 'settings', type: FormInputTypes.editableJsonView },
      ],
      clickCallback: () => onClickConfirmAdd(operationConfigProvider),
      buttonLabel: 'Create',
    });
    const initialValues = {
      settings: configuration.data.response.configurationKeys,
      manufacturerModel: `${selectedManufacturer} / ${selectedModel}`,
    };
    drawerStore.setValues(initialValues);
  } else {
    drawerStore.openDrawer({
      label: 'Create operation config',
      inputs: [
        {
          name: 'chargePoint',
          label: 'Charge Point',
          type: FormInputTypes.multilineSelect,
          options: getChargerOptions(chargerProvider),

        },
        {
          name: 'readConfiguration',
          type: FormInputTypes.textButton,
          label: 'Read Configuration',
          onClick: () => onReadConfigurationClicked(chargerProvider, operationConfigProvider),
        },
        { label: 'Manufacturer / Model', name: 'manufacturerModel', type: FormInputTypes.disabledTextField },
        { name: 'comment', type: FormInputTypes.textField },
        { name: 'settings', type: FormInputTypes.editableJsonView },
      ],
      clickCallback: () => onClickConfirmAdd(operationConfigProvider),
      buttonLabel: 'Create',
    });
    const initialValues = {
      manufacturerModel: `${selectedManufacturer} / ${selectedModel}`,
    };
    drawerStore.setValues(initialValues);
  }
};

const operationConfigCreateInputs = (chargerProvider, operationConfigProvider) => [
  {
    name: 'chargePoint',
    label: 'Charge Point',
    type: FormInputTypes.multilineSelect,
    options: getChargerOptions(chargerProvider),
  },
  {
    name: 'readConfiguration',
    type: FormInputTypes.textButton,
    label: 'Read Configuration',
    onClick: () => onReadConfigurationClicked(chargerProvider, operationConfigProvider),
  },
];

const onClickCreateoperationConfig = (operationConfigProvider, chargerProvider) => {
  drawerStore.openDrawer({
    label: 'Create operation config',
    inputs: operationConfigCreateInputs(chargerProvider, operationConfigProvider),
  });
};

export const onClickDuplicateConfiguration = (configuration: Configuration, operationConfigProvider) => {
  const selectedConfiguration = operationConfigProvider.data.find((item) => item.id === configuration.id);

  drawerStore.openDrawer({
    label: 'Duplicate operation config',
    inputs: [
      { label: 'Manufacturer / Model', name: 'manufacturerModel', type: FormInputTypes.disabledTextField },
      { name: 'comment', type: FormInputTypes.textField },
      { name: 'settings', type: FormInputTypes.editableJsonView },
    ],
    clickCallback: () => onClickConfirmAdd(operationConfigProvider),
    buttonLabel: 'Create',
  });
  const initialValues = {
    manufacturerModel: `${selectedConfiguration.manufacturer} / ${selectedConfiguration.model}`,
    settings: selectedConfiguration.settings,
  };
  drawerStore.setValues(initialValues);
};

const onClickDeleteConfiguration = (configuration: Configuration, operationConfigProvider: any) => {
  Api.charge.deleteoperationConfig(configuration.id).then(operationConfigProvider.reloadResource);
};

export const onApplyConfiguration = (configuration: Configuration, operationConfigProvider, chargerProvider: any) => {
  drawerStore.openDrawer({
    label: 'Apply operation config',
    inputs: [
      { label: 'revision no.', name: 'revision', type: FormInputTypes.disabledTextField, value: configuration.revision },
      {
        id: 'abc',
        label: '',
        name: 'applyOption',
        type: FormInputTypes.radioButton,
        options: [
          { label: 'All', value: 'all' },
          { label: 'Selected', value: 'selected' },
        ],
      },
      { name: 'chargePoints', type: FormInputTypes.filterListSelect, filter: { label: 'Apply to', name: 'chargePoints' }, options: getApplyOptions(chargerProvider, configuration) },
    ],

    clickCallback: () => onClickConfirmApply(configuration, operationConfigProvider, getApplyOptions(chargerProvider, configuration)),
    buttonLabel: 'Apply configuration',
  });
  drawerStore.setValues({ applyOption: 'all' });
};

const operationConfigActions = (params: GridRowParams, operationConfigProvider, chargerProvider) => (
  <ActionsMenu>
    <ListItem button onClick={() => onClickDuplicateConfiguration(params.row as Configuration, operationConfigProvider)}>
      <Typography style={{ width: '100%' }}>Duplicate</Typography>
    </ListItem>
    <ListItem button onClick={() => onApplyConfiguration(params.row as Configuration, operationConfigProvider, chargerProvider)}>
      <Typography style={{ width: '100%' }}>Apply</Typography>
    </ListItem>
    <ListItem button onClick={() => onClickDeleteConfiguration(params.row as Configuration, operationConfigProvider)}>
      <Typography style={{ width: '100%' }}>Delete</Typography>
    </ListItem>
  </ActionsMenu>
);

const onClickChargersCount = (chargerProvider, configuration) => {
  drawerStore.openDrawer({
    label: 'Assigned charge points',
    inputs: [
      { label: 'revision no.', name: 'revision', type: FormInputTypes.disabledTextField, value: configuration.revision },
      { label: '', name: 'chargePoints', type: FormInputTypes.textLinkView, filter: { label: 'Apply to', name: 'chargePoints' }, options: getAssignedChargersOptions(chargerProvider, configuration) },
    ],
    clickCallback: undefined,
    buttonLabel: 'Close',
  });
  drawerStore.setValues({ revision: configuration.revision, applyOption: 'all' });
};

export const getAllChargersColumns = (operationConfigProvider, sortableFields = [], chargerProvider) => [
  gridColumn('manufacturer', {
    flex: 2,
    headerName: 'Manufacturer',
    sortableFields,
    valueGetter: (params: GridRowParams) => `${params.row.manufacturer}`,
  }),
  gridColumn('model', {
    flex: 2,
    headerName: 'Model',
    sortableFields,
    valueGetter: (params: GridRowParams) => `${params.row.model}`,
  }),
  gridColumn('revision', {
    flex: 2,
    headerName: 'Revision',
    sortableFields,
    valueGetter: (params: GridRowParams) => `${params.row.revision}`,
  }),
  gridColumn('comment', {
    flex: 2,
    headerName: 'Comment',
    sortableFields,
    valueGetter: (params: GridRowParams) => `${params.row.comment}`,
  }),
  gridColumn('chargers', {
    flex: 1,
    headerName: 'Chargers',
    renderCell: (params: GridRowParams) => (
      params.row.assignedChargerIds.length === 0 ? (
        <Typography color="textSecondary">0</Typography>
      ) : (
        <IconButton onClick={() => onClickChargersCount(chargerProvider, params.row)} style={{ padding: 0, justifyContent: 'center', alignItems: 'center' }}>
          <Typography color="primary">{params.row.assignedChargerIds.length}</Typography>
        </IconButton>
      )
    ),
  }),
  gridColumn('createdAt', {
    flex: 2,
    headerName: 'Created At',
    sortableFields,
    valueGetter: (params: GridRowParams) => {
      const date = new Date(params.row.createdAt);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  }),
  gridColumn('', {
    width: 150,
    renderCell: (params: GridRowParams) => operationConfigActions(params, operationConfigProvider, chargerProvider),
    align: 'center',
    headerAlign: 'center',
  }),
];

const operationConfigs = observer(() => {
  const operationConfigProvider = usePaginatedProvider(undefined, undefined, false);
  const chargerProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);

  useEffect(() => {
    const requestFunction = (offset, sortBy, sortOrder) =>
      Api.charge.listoperationConfigs({ skip: offset, take: 20, sortBy, sortOrder });

    operationConfigProvider.setRequest(requestFunction);

    operationConfigProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) setSortableFields(sortable);
    });
    operationConfigProvider.reloadResource();

    const requestFunctionForChargers = (offset, sortBy, sortOrder) =>
      Api.charge.listChargers({ skip: offset, sortBy, sortOrder });

    chargerProvider.setRequest(requestFunctionForChargers);

    chargerProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) setSortableFields(sortable);
    });
    chargerProvider.reloadResource();
  }, [operationConfigProvider, chargerProvider]);

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        rows={operationConfigProvider.data}
        columns={getAllChargersColumns(operationConfigProvider, sortableFields, chargerProvider)}
        onClickAdd={
          credentialStore.isUserAdmin
            ? () => onClickCreateoperationConfig(operationConfigProvider, chargerProvider)
            : undefined
        }
        onPageOffsetChange={operationConfigProvider.onPageChange}
        onSortTypeChange={operationConfigProvider.setSort}
        label="Operation Configurations"
      />
    </div>
  );
});

export default operationConfigs;
