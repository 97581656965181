import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { makeAutoObservable, toJS } from 'mobx';
import _ from 'lodash';
import { useStyles } from '../../styles/styles';
import ImageTextRow from '../../components/cards/CardRows/ImageTextRow';
import LocationImagesGrid from '../../components/cards/ImageGridCard';
import StyledCard from '../../components/cards/StyledCard';
import StyledCardHeader, { HeaderButtonIcon } from '../../components/cards/StyledCardHeader';
import StyledCardContent from '../../components/cards/StyledCardContent';
import { drawerStore } from '../../state/drawerStore';
import GoogleMapsWrapper from '../../components/google/GoogleMapsWrapper';
import { FormInputTypes } from '../../utils/constants';
import { NameValueRowList } from '../../components/cards/CardRows/NameValueRowList';
import { Api } from '../../api';
import { useProvider } from '../../state/providerStore';

const detailsRows = [
  { name: 'isRecommended', label: 'Recommended' },
  'description',
  'website',
  'workingHours',
  { name: 'isPayableParking', label: 'Free parking', invertValue: true },
];
const contactRows = [
  { name: 'contactPhone', label: 'Phone' },
  { name: 'contactEmail', label: 'Email' },
];
const locationRows = ['latitude', 'longitude'];

const editDetailsInputs = [
  { name: 'isRecommended', label: 'Recommended', type: FormInputTypes.switch },
  { name: 'description', type: FormInputTypes.textField },
  { name: 'website', type: FormInputTypes.textField },
  { name: 'workingHours', type: FormInputTypes.textField },
  { name: 'freeParking', type: FormInputTypes.switch },
];

const editContactInputs = [
  { name: 'phone', type: FormInputTypes.textField },
  { name: 'email', type: FormInputTypes.textField },
];

const editLocationInputs = [
  { name: 'latitude', type: FormInputTypes.textField },
  { name: 'longitude', type: FormInputTypes.textField },
];

class LocationDetailsDetailsStore {
  selectedActivities: Record<string, ActivitySelection> = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

// /locations/activities Api returns ActivityObj[] array with all possible activities
interface ActivityObj {
  group: string;
  name: string;
  activities: string[];
}

const getActivityHash = (group, activityName) => `${group}_${activityName}`;

interface ActivitySelection {
  group: string;
  activityName: string;
}

const initSelectedActivitiesObj = (
  locationDetailsDetailsStore: LocationDetailsDetailsStore,
  allActivities: ActivityObj[],
) => {
  locationDetailsDetailsStore.selectedActivities = {};

  allActivities.forEach((obj: ActivityObj) => {
    const { group } = obj;

    obj.activities.forEach((activityName) => {
      const activityHash = getActivityHash(group, activityName);

      locationDetailsDetailsStore.selectedActivities[activityHash] = {
        group,
        activityName,
      };
    });
  });
};

const getActivityInputs = (allActivities: ActivityObj[]) => {
  const inputs = [];

  allActivities?.forEach((obj: ActivityObj) => {
    inputs.push({ name: obj.name, type: FormInputTypes.miniTitle });

    obj.activities?.forEach((activityName) => {
      const activityHash = getActivityHash(obj.group, activityName);

      inputs.push({ name: activityHash, label: activityName, type: FormInputTypes.checkbox });
    });
  });

  return inputs;
};

const LocationDetailsDetails = observer(() => {
  const { locationid } = useParams<{ locationid: string }>();

  const [locationDetailsDetailsStore] = useState(() => new LocationDetailsDetailsStore());

  const locationProvider = useProvider(() => Api.charge.getLocation(locationid));
  const locationActivitiesProvider = useProvider(() => Api.charge.listActivitiesForLocation(locationid));
  const allActivitiesProvider = useProvider(Api.charge.listAllActivities, (data) =>
    initSelectedActivitiesObj(locationDetailsDetailsStore, data),
  );

  const classes = useStyles();

  // TODO find a way to toggle activitiesByGroup on drawer switch onChange callback

  const confirmEditDetails = (values) => {
    values = _.pick(values, detailsRows);
    Api.charge.patchLocation(locationid, values).then(locationProvider.reloadResource);
  };

  const confirmEditContact = (values) => {
    values = _.pick(values, contactRows);
    Api.charge.patchLocation(locationid, values).then(locationProvider.reloadResource);
  };

  const confirmEditLocation = (values) => {
    values = _.pick(values, locationRows);
    Api.charge.patchLocation(locationid, values).then(locationProvider.reloadResource);
  };

  const confirmAddActivity = (values) => {
    const selectedActivitiesArray = [];
    Object.entries(values).forEach(([activityHash, selected]) => {
      if (selected) {
        const activity = locationDetailsDetailsStore.selectedActivities[activityHash];
        selectedActivitiesArray.push({
          group: activity.group,
          name: activity.activityName,
        });
      }
    });

    Api.charge
      .patchActivitiesForLocation(locationid, { activities: selectedActivitiesArray })
      .then(locationProvider.reloadResource);
  };

  const onClickEditDetails = () => {
    drawerStore.openDrawer({
      label: 'Edit details',
      inputs: editDetailsInputs,
      clickCallback: () => confirmEditDetails(toJS(drawerStore.values)),
    });
    drawerStore.setValues(locationProvider.data);
  };

  const onClickEditContact = () => {
    drawerStore.openDrawer({
      label: 'Edit contact',
      inputs: editContactInputs,
      clickCallback: () => confirmEditContact(toJS(drawerStore.values)),
    });

    drawerStore.setValues(locationProvider.data);
  };

  const onClickEditLocation = () => {
    drawerStore.openDrawer({
      label: 'Edit location',
      inputs: editLocationInputs,
      clickCallback: () => confirmEditLocation(toJS(drawerStore.values)),
    });

    drawerStore.setValues(locationProvider.data);
  };

  const onClickAddActivity = () => {
    drawerStore.openDrawer({
      label: 'Update activities',
      inputs: getActivityInputs(allActivitiesProvider.data),
      clickCallback: () => confirmAddActivity(toJS(drawerStore.values)),
    });
  };

  const googleMapsDragCallback = (lat, lng) => {
    const values = { latitude: lat.toString(), longitude: lng.toString() };
    Api.charge.patchLocation(locationid, values).then(locationProvider.reloadResource);
  };

  const pos = { lat: parseFloat(locationProvider.data?.latitude), lng: parseFloat(locationProvider.data?.longitude) };

  return (
    <div className={classes.cardRows}>
      <div className={classes.leftCardRow}>
        <StyledCard>
          <StyledCardHeader title="Details" onClick={onClickEditDetails} headerButtonIcon={HeaderButtonIcon.edit} />
          <StyledCardContent>
            <NameValueRowList rows={detailsRows} values={locationProvider.data} />
          </StyledCardContent>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader title="Contact" onClick={onClickEditContact} headerButtonIcon={HeaderButtonIcon.edit} />
          <StyledCardContent>
            <NameValueRowList rows={contactRows} values={locationProvider.data} />
          </StyledCardContent>
        </StyledCard>

        <LocationImagesGrid entityId={locationid} type="location" />
      </div>

      <div className={classes.rightCardRow}>
        <StyledCard>
          <StyledCardHeader
            title="Precise location"
            onClick={onClickEditLocation}
            headerButtonIcon={HeaderButtonIcon.edit}
          />
          <StyledCardContent>
            <NameValueRowList rows={locationRows} values={locationProvider.data} />
          </StyledCardContent>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 32,
              marginBottom: 32,
              paddingLeft: 32,
              paddingRight: 32,
              height: '20vw',
            }}
          >
            <GoogleMapsWrapper pos={pos} zoom={15} dragCallback={googleMapsDragCallback} showLocationMarker />
          </div>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader title="Activities" onClick={onClickAddActivity} headerButtonIcon={HeaderButtonIcon.add} />
          <StyledCardContent>
            {locationActivitiesProvider.data?.map((activity, idx) => (
              <ImageTextRow key={`${activity}_${idx}`} label={activity.name} />
            ))}
          </StyledCardContent>
        </StyledCard>
      </div>
    </div>
  );
});

export default LocationDetailsDetails;
