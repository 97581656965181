import { makeAutoObservable } from 'mobx';

class DashboardStore {
  selectedMenuItem = '';
  appBarLabel = '';
  paragraphFormImages = [];
  routeChangeCallback?: () => void;
  historySub = undefined;
  loadedCredentials = false;
  madeAnyRequest = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  loadCredentialsCallback = () => {
    if (!this.loadedCredentials) {
      this.loadedCredentials = true;
      if (this.routeChangeCallback) {
        this.routeChangeCallback();
      }
    }
  };
}

const dashboardStore = new DashboardStore();
export default dashboardStore;
