import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { toJS } from 'mobx';
import _ from 'lodash';
import { GridRowParams } from '@material-ui/data-grid';
import { useStyles } from '../../styles/styles';
import StyledCard from '../../components/cards/StyledCard';
import StyledCardHeader, { HeaderButtonIcon } from '../../components/cards/StyledCardHeader';
import StyledCardContent from '../../components/cards/StyledCardContent';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';
import VMDataGrid from '../../components/main/VMDataGrid';
import { gridColumn } from '../../utils/gridUtils';
import TextLink from '../../components/other/TextLink';
import { CHARGER_DETAILS_TABS, chargerDetailsTabParameters } from '../../utils/routing';
import { ConnectorsList } from '../../components/other/Connectors';
import ActionsList from '../../components/other/ActionsList';
import { ChargerStatusBadge } from '../../components/other/CellBadges';
import { TOAST_MESSAGE_TYPE, toastStore } from '../../state/toastStore';
import { NameValueRowList } from '../../components/cards/CardRows/NameValueRowList';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import { TitleSubtitleRow } from '../../components/cards/CardRows/TitleSubtitleRow';
import { formatToSloDate } from '../Sessions/SessionsPage';
import { generateMultiSelectOptions, paymentMethodSelectOption, getPaymentMethodNameById } from './SharedUtils';

const personalInfoRows = [
  { name: 'name', label: 'firstName' },
  { name: 'familyName', label: 'lastName' },
  'email',
  'phone',
];
const usageOverviewRows = ['sessions', 'totalTime', 'totalEnergy', 'avgTime', 'avgEnergy', 'totalSpent'];

const personalInfoInputs = [
  { name: 'id', type: FormInputTypes.disabledTextField },
  { name: 'email', type: FormInputTypes.textField },
  { name: 'firstName', type: FormInputTypes.textField },
  { name: 'lastName', type: FormInputTypes.textField },
  { name: 'phone', type: FormInputTypes.textField },
];

const detailsInputs = [
  { name: 'createdAt', type: FormInputTypes.textField },
  { name: 'enabled', label: 'isActive', type: FormInputTypes.switch },
  { name: 'admin', label: 'isAdmin', type: FormInputTypes.switch },
];

const changePasswordInputs = [
  { name: 'id', type: FormInputTypes.disabledTextField },
  { name: 'password', type: FormInputTypes.password },
  { name: 'confirmPassword', type: FormInputTypes.password },
];

const UserDetailsOverview = observer(() => {
  const { userid } = useParams<{ userid: string }>();
  const classes = useStyles();

  const userProvider = useProvider(() => Api.user.getUser(userid));
  const domicilConnectorsProvider = useProvider(() => Api.charge.listDomicilConnectorsForUser(userid));
  const tagsProvider = useProvider(() => Api.charge.listUsersAuthorizationTags(userid));
  const paymentMethodsProvider = useProvider(() => Api.billing.getPaymentMethodsForUser(userid));

  const confirmChangePassword = (values) => {
    if (values.password !== values.confirmPassword) {
      toastStore.showMessage('Passwords do not match', TOAST_MESSAGE_TYPE.ERROR);
    } else {
      Api.user.updateUser(userid, { password: values.password }).then(userProvider.reloadResource);
    }
  };

  const onClickChangePassword = () => {
    drawerStore.openDrawer({
      label: 'Change password',
      inputs: changePasswordInputs,
      clickCallback: () => confirmChangePassword(toJS(drawerStore.values)),
    });

    drawerStore.setValues(userProvider.data);
  };

  const detailsRows = [
    { name: 'password', value: '**********', actionLabel: 'change', actionFunction: onClickChangePassword },
    { name: 'verified', label: 'isVerified' },
    'createdAt',
    { name: 'enabled', label: 'isActive' },
    { name: 'admin', label: 'isAdmin' },
  ];

  const confirmEditPersonalInfo = (values) => {
    values = _.pick(values, personalInfoRows);

    Api.user.updateUser(userid, values).then(userProvider.reloadResource);
  };

  const confirmEditDetails = (values) => {
    values = _.pick(values, ['verified', 'createdAt', 'enabled', 'admin']);

    Api.user.updateUser(userid, values).then(userProvider.reloadResource);
  };

  const onClickEditPersonalInfo = () => {
    drawerStore.openDrawer({
      label: 'Update user',
      inputs: personalInfoInputs,
      clickCallback: () => confirmEditPersonalInfo(toJS(drawerStore.values)),
    });

    drawerStore.setValues(userProvider.data);
  };

  const onClickEditDetails = () => {
    drawerStore.openDrawer({
      label: 'Update details',
      inputs: detailsInputs,
      clickCallback: () => confirmEditDetails(toJS(drawerStore.values)),
    });

    drawerStore.setValues(userProvider.data);
  };

  const onClickAddTag = () => {
    drawerStore.openDrawer({
      label: 'Add tag',
      inputs: [
        { name: 'name', type: FormInputTypes.textField },
        {
          name: 'type',
          type: FormInputTypes.select,
          options: ['rfid', 'vin'],
        },
        { name: 'tag', label: 'Tag Code', type: FormInputTypes.textField },
        {
          name: 'paymentMethodId',
          label: 'Payment Method',
          type: FormInputTypes.multilineSelect,
          options: generateMultiSelectOptions(paymentMethodsProvider, paymentMethodSelectOption),
        },
      ],
      clickCallback: () => {
        Api.charge.createAuthorizationTag(userid, drawerStore.values).then(tagsProvider.reloadResource);
      },
    });
  };

  const onClickRemoveTag = (tagId: string) => {
    Api.charge.deleteAuthorizationTag(tagId).then(tagsProvider.reloadResource);
  };

  const userChargerActions = (params: GridRowParams) => (
    <ActionsList
      actions={[
        {
          label: 'Details',
          callback: () => {
            console.log('TODO');
          },
        },
        {
          label: 'Remove',
          callback: () => {
            console.log('TODO');
          },
        },
      ]}
    />
  );

  const userChargersColumns = [
    gridColumn('ocppId', {
      flex: 2,
      headerName: 'OCPP ID',
      renderCell: (params: GridRowParams) => (
        <TextLink
          to={chargerDetailsTabParameters(params.row.id, CHARGER_DETAILS_TABS.OVERVIEW)}
          label={params.row.ocppId}
        />
      ),
    }),
    gridColumn('manufacturer/model', {
      flex: 2,
      valueGetter: (params: GridRowParams) => `${params.row.manufacturer} / ${params.row.model}`,
    }),
    gridColumn('connectors', {
      flex: 2,
      renderCell: (params: GridRowParams) => <ConnectorsList connectors={params.row.connectors} />,
    }),
    gridColumn('mode', {
      flex: 1,
    }),
    gridColumn('activePower', {
      flex: 1,
      renderCell: (params: GridRowParams) => {
        const activePower = params.row.activePower || 0;
        return <p>{Number(activePower).toFixed(2)} kW</p>;
      },
    }),
    gridColumn('status', {
      width: 150,
      align: 'center',
      renderCell: (params: GridRowParams) => <ChargerStatusBadge status={params.row.status} />,
    }),
    gridColumn('actions', {
      width: 150,
      renderCell: (params: GridRowParams) => userChargerActions(params),
      align: 'center',
      headerAlign: 'center',
    }),
  ];

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.cardRows}>
        <div className={classes.leftCardRow}>
          <StyledCard>
            <StyledCardHeader
              title="Personal Info"
              onClick={onClickEditPersonalInfo}
              headerButtonIcon={HeaderButtonIcon.edit}
            />
            <StyledCardContent>
              <NameValueRowList rows={personalInfoRows} values={userProvider.data} />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="Details" onClick={onClickEditDetails} headerButtonIcon={HeaderButtonIcon.edit} />
            <StyledCardContent>
              <NameValueRowList
                rows={detailsRows}
                values={{ ...userProvider.data, createdAt: formatToSloDate(userProvider.data?.createdAt) }}
              />
            </StyledCardContent>
          </StyledCard>
        </div>
        <div className={classes.rightCardRow}>
          <StyledCard>
            <StyledCardHeader title="Usage overview" />
            <StyledCardContent>
              <NameValueRowList rows={usageOverviewRows} values={userProvider.data} />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="ID Tags" onClick={onClickAddTag} headerButtonIcon={HeaderButtonIcon.add} />
            <StyledCardContent>
              {tagsProvider.data?.map((tag) => {
                const paymentMethodName = getPaymentMethodNameById(paymentMethodsProvider, tag.paymentMethodId); // Get payment method name by ID
                return (
                  <TitleSubtitleRow
                    title={`${tag.name}`}
                    subtitle={`${tag.tag}`}
                    subtitle1={`${paymentMethodName}`}
                    key={tag.id}
                    actions={<ActionsList actions={[{ label: 'Remove', callback: () => onClickRemoveTag(tag.id) }]} />}
                  />
                );
              })}
            </StyledCardContent>
          </StyledCard>
        </div>
      </div>

      <VMDataGrid rows={domicilConnectorsProvider.data} columns={userChargersColumns} label="Charge points" />
    </div>
  );
});

export default UserDetailsOverview;
