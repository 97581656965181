/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import {
  SESSIONS_REFRESH_INTERVAL,
  sessionsColumn_chargerTypeIcon,
  sessionsColumn_StartedAt,
  sessionsColumn_UserId,
  sessionsColumns_actions,
  sessionsColumns_avgPower,
  sessionsColumns_Connector,
  sessionsColumns_Duration,
  sessionsColumns_price,
  sessionsColumns_Status,
  sessionsColumns_unitsConsumed,
} from '../Sessions/SessionsPage';
import { Api } from '../../api';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';

const LocationDetailsSessions = observer(() => {
  const { locationid } = useParams<{ locationid: string }>();

  const locationSessionsProvider = usePaginatedProvider(
    (offset) => Api.charge.listSessionsForLocation(locationid, { skip: offset, limit: 20 }),
    undefined,
  );

  useEffect(() => {
    locationSessionsProvider.setRefreshInterval(SESSIONS_REFRESH_INTERVAL);
  }, []);

  const history = useHistory();

  const locaitonSessionColumns = [
    sessionsColumn_chargerTypeIcon(),
    sessionsColumn_StartedAt(),
    sessionsColumn_UserId(),
    sessionsColumns_Connector(),
    sessionsColumns_Status(),
    sessionsColumns_Duration(),
    sessionsColumns_avgPower(),
    sessionsColumns_unitsConsumed(),
    sessionsColumns_price(),
    sessionsColumns_actions(locationSessionsProvider, history),
  ];
  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        label="Sessions"
        rows={locationSessionsProvider.data}
        columns={locaitonSessionColumns}
        count={locationSessionsProvider.count}
        onPageOffsetChange={locationSessionsProvider.onPageChange}
      />
    </div>
  );
});

export default LocationDetailsSessions;
