import { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Fade, Grid } from '@material-ui/core';
import { makeAutoObservable } from 'mobx';
import { TitleNumberCard } from './TitleNumberCard';
import { DashboardDetailsCard } from './DashboardDetailsCard';
import { Connector, CONNECTOR_STATUS } from '../../types/Connector';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import credentialStore from '../../state/credentialStore';
import { route, SESSIONS_TABS, sessionTabRoute } from '../../utils/routing';
import { PAGES } from '../../components/main/MainComponent';
import StyledCard from '../../components/cards/StyledCard';
import GoogleMapsWrapper from '../../components/google/GoogleMapsWrapper';

const spacing = 3;

const HalfGrid = observer(({ children }: { children: ReactNode }) => (
  <Grid item xs={6}>
    <Grid container spacing={spacing}>
      {children}
    </Grid>
  </Grid>
));

class DashboardViewStore {
  usersCount = 0;
  locationsCount = 0;
  chargersCount = 0;
  connectorsCount = 0;

  sessionsCount = 0;
  activeSessionsCount = 0;

  publicChargersCount = 0;
  domicilChargersCount = 0;
  unassignedChargersCount = 0;
  issuesChargersCount = 0;

  availableConnectorsCount = 0;
  inUseConnectorsCount = 0;
  inErrorConnectorsCount = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

export interface ConnectorMarkerData {
  lat: number;
  lng: number;
  hasError: boolean;
  totalCount: number;
  inUseCount: number;
  errorCount: number;
  locationName?: string;
  locationAddress?: string;
  locationid?: string;
  connectors?: Connector[];
}

const DashboardPage = observer(() => {
  const [dashboardViewStore] = useState(() => new DashboardViewStore());
  const [connectorMarkers, setConnectorMarkers] = useState<ConnectorMarkerData[]>([]);

  const mapLocationsProvider = useProvider(undefined, undefined, false);

  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      mapLocationsProvider.setRequest(() => Api.charge.adminMapLocations());
    } else {
      mapLocationsProvider.setRequest(() => Api.charge.accountMapLocations(credentialStore.getAccountId()));
    }

    mapLocationsProvider.reloadResource();
  }, [credentialStore.selectedAccount]);

  useEffect(() => {
    const markers: ConnectorMarkerData[] = [];
    mapLocationsProvider.data?.forEach((location) => {
      const { connectors } = location;
      const hasError = connectors.some((connector) => connector.status === CONNECTOR_STATUS.ERROR);
      const totalCount = connectors.length;
      const inUseCount = connectors.filter((connector) => connector.status === CONNECTOR_STATUS.IN_USE).length;
      const errorCount = connectors.filter((connector) => connector.status === CONNECTOR_STATUS.ERROR).length;

      markers.push({
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
        hasError,
        totalCount,
        inUseCount,
        errorCount,
        locationName: location.name,
        locationAddress: location.address,
        locationid: location.id,
        connectors,
      });
    });

    setConnectorMarkers(markers);
  }, [mapLocationsProvider.data]);

  const setChargersAndConnectorsCount = (data, count) => {
    dashboardViewStore.chargersCount = count;
    Array.prototype.forEach.call(data, (charger) => {
      let hasIssues = false;

      if (charger.connectors) {
        dashboardViewStore.connectorsCount += charger.connectors.length;

        charger.connectors.forEach((connector) => {
          if (connector.status === CONNECTOR_STATUS.AVAILABLE) {
            dashboardViewStore.availableConnectorsCount += 1;
          } else if (connector.status === CONNECTOR_STATUS.IN_USE) {
            dashboardViewStore.inUseConnectorsCount += 1;
          } else {
            dashboardViewStore.inErrorConnectorsCount += 1;
            hasIssues = true;
          }
        });
      }

      if (charger.isVisible) {
        dashboardViewStore.publicChargersCount += 1;
      } else {
        dashboardViewStore.domicilChargersCount += 1;
      }

      if (!charger.locationId && !charger.userId) {
        dashboardViewStore.unassignedChargersCount += 1;
      }

      if (hasIssues) {
        dashboardViewStore.issuesChargersCount += 1;
      }
    });
  };

  const sessionsCallback = (data, count) => {
    dashboardViewStore.sessionsCount = count;
  };

  const activeSessionsCallback = (data, count) => {
    dashboardViewStore.activeSessionsCount = count;
  };

  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      Api.user.listUsers().then((resp) => {
        dashboardViewStore.usersCount = resp?.data?.data?.count;
      });

      Api.charge.listLocations().then((resp) => {
        dashboardViewStore.locationsCount = resp?.data?.data?.length;
      });

      Api.charge.listChargers().then((resp) => {
        setChargersAndConnectorsCount(resp?.data?.data, resp?.data?.count);
      });

      Api.charge.listSessions().then((resp) => {
        sessionsCallback(resp?.data?.data, resp?.data?.count);
      });

      Api.charge.listActiveSessions().then((resp) => {
        activeSessionsCallback(resp?.data?.data, resp?.data?.count);
      });
    } else {
      Api.charge.listLocationsForAccount(credentialStore.getAccountId()).then((resp) => {
        dashboardViewStore.locationsCount = resp?.data?.data?.length;
      });

      Api.charge.listChargersForAccount(credentialStore.getAccountId()).then((resp) => {
        setChargersAndConnectorsCount(resp?.data?.data, resp?.data?.count);
      });

      Api.charge.listSessionsForAccount(credentialStore.getAccountId()).then((resp) => {
        sessionsCallback(resp?.data?.data, resp?.data?.count);
      });

      Api.charge.listSessionsForAccount(credentialStore.getAccountId(), { isActive: true }).then((resp) => {
        activeSessionsCallback(resp?.data?.data, resp?.data?.count);
      });
    }
  }, []);

  const getDefaultPos = () => {
    if (connectorMarkers.length > 0) {
      const marker = connectorMarkers[0];
      return { lat: marker.lat, lng: marker.lng };
    }

    return { lat: 46.0541709, lng: 14.5045177 };
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={spacing}>
        <HalfGrid>
          <Grid item xs={6}>
            <TitleNumberCard
              title="Locations"
              number={dashboardViewStore.locationsCount}
              link={route(PAGES.Locations)}
            />
          </Grid>
          <Grid item xs={6}>
            {credentialStore.isUserAdmin && (
              <TitleNumberCard title="Users" number={dashboardViewStore.usersCount} link={route(PAGES.Users)} />
            )}
          </Grid>
          <Grid item xs={12}>
            <DashboardDetailsCard
              title="Charge Points"
              number={dashboardViewStore.chargersCount}
              rows={[
                { label: 'Public', number: dashboardViewStore.publicChargersCount },
                { label: 'Domicil', number: dashboardViewStore.domicilChargersCount },
                { label: 'With issues', link: `${route(PAGES.Chargers)}/with-issues`, number: dashboardViewStore.issuesChargersCount, isError: true },
              ]}
              link={route(PAGES.Chargers)}
            />
          </Grid>
        </HalfGrid>
        <HalfGrid>
          <Grid item xs={6}>
            <TitleNumberCard
              title="Sessions"
              number={dashboardViewStore.sessionsCount}
              link={sessionTabRoute(SESSIONS_TABS.HISTORY)}
            />
          </Grid>
          <Grid item xs={6}>
            <TitleNumberCard
              title="Current active sessions"
              number={dashboardViewStore.activeSessionsCount}
              link={sessionTabRoute(SESSIONS_TABS.ACTIVE)}
            />
          </Grid>
          <Grid item xs={12}>
            <DashboardDetailsCard
              title="Charge Connectors"
              number={dashboardViewStore.connectorsCount || 0}
              rows={[
                { label: 'Available', number: dashboardViewStore.availableConnectorsCount },
                { label: 'In use', number: dashboardViewStore.inUseConnectorsCount },
                { label: 'Issues', number: dashboardViewStore.inErrorConnectorsCount, isError: true },
              ]}
            />
          </Grid>
        </HalfGrid>
      </Grid>
      <Fade in timeout={1000}>
        <div style={{ width: '100%', marginTop: '2em' }}>
          <StyledCard>
            <div style={{ width: '100%', height: '50vh', borderRadius: 16 }}>
              <GoogleMapsWrapper pos={getDefaultPos()} zoom={13} connectorMarkers={connectorMarkers} />
            </div>
          </StyledCard>
        </div>
      </Fade>
    </div>
  );
});

export default DashboardPage;
