// noinspection JSDeprecatedSymbols
import { TitleSubtitleRow } from '../TitleSubtitleRow';
import ActionsList from '../../../other/ActionsList';
import { drawerStore } from '../../../../state/drawerStore';
import { FormInputTypes } from '../../../../utils/constants';

export const RuleRow = ({ rule, actions }: { rule: any; actions: any[] }) => {
  if (rule.rule === 'free_units') {
    return (
      <TitleSubtitleRow
        title="Free units"
        subtitle={`Quantity: ${rule.quantity}`}
        key={rule.id}
        actions={<ActionsList actions={actions} />}
      />
    );
  } else {
    let subtitle = rule.rule;
    if (rule.addonType) {
      subtitle += ', ' + rule.addonType;
    }
    if (rule.group) {
      subtitle += ', ' + rule.group;
    }

    const onClickTitle = () => {
      const inputs = [{ name: ' ', type: FormInputTypes.jsonView, value: rule }];
      drawerStore.openDrawer({
        label: 'Rule',
        inputs,
        buttonLabel: 'Close',
        clickCallback: undefined,
      });
    };

    return (
      <TitleSubtitleRow
        title={rule.product?.name}
        onClick={onClickTitle}
        subtitle={subtitle}
        key={rule.id}
        actions={<ActionsList actions={actions} />}
      />
    );
  }
};
