import React, { useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import { ListItem, Typography } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import ActionsMenu from '../../components/other/ActionsMenu';
import LocationDetails from './LocationDetails';
import TextLink from '../../components/other/TextLink';
import { ChargingLocation } from '../../types/ChargingLocation';
import { LOCATION_DETAILS_TABS, locationDetailsTabParameters } from '../../utils/routing';
import { FormInputTypes } from '../../utils/constants';
import { drawerStore } from '../../state/drawerStore';
import { gridColumn } from '../../utils/gridUtils';
import { Api } from '../../api';
import { PAGES } from '../../components/main/MainComponent';
import credentialStore from '../../state/credentialStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';

const locationCreateInputs = [
  { name: 'name', type: FormInputTypes.textField },
  { name: 'tags', type: FormInputTypes.multiAutocomplete },
  { name: 'searchAddress', type: FormInputTypes.googleAddressAutocomplete },
  { name: 'address', type: FormInputTypes.textField },
  { name: 'city', type: FormInputTypes.textField },
  { name: 'zip', type: FormInputTypes.textField },
  { name: 'country', type: FormInputTypes.textField },
  { name: 'longitude', type: FormInputTypes.disabledTextField },
  { name: 'latitude', type: FormInputTypes.disabledTextField },
];

const locationEditInputs = [
  { name: 'id', type: FormInputTypes.disabledTextField },
  ...locationCreateInputs,
  { name: 'status', type: FormInputTypes.switch },
];

const LocationsPage = observer(() => {
  const locationsProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);
  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      locationsProvider.setRequest((offset, sortBy, sortOrder) =>
        Api.charge.listLocations({}, { skip: offset, take: 20, sortBy, sortOrder }),
      );
    } else {
      locationsProvider.setRequest((offset, sortBy, sortOrder) =>
        Api.charge.listLocationsForAccount(credentialStore.selectedAccount.id, {
          skip: offset,
          take: 20,
          sortBy,
          sortOrder,
        }),
      );
    }
    locationsProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
    });
    locationsProvider.setSort('name', 'ASC');
  }, [credentialStore.selectedAccount]);

  const onClickConfirmAdd = () => {
    const location = toJS(drawerStore.values) as Location;

    if (credentialStore.isUserAdmin) {
      Api.charge.createLocation(location).then(locationsProvider.reloadResource);
    } else {
      Api.charge
        .ownerCreateLocation(location, credentialStore.selectedAccount.id)
        .then(locationsProvider.reloadResource);
    }
  };

  const onClickConfirmEdit = () => {
    const location = drawerStore.values as ChargingLocation;

    Api.charge.patchLocation(location.id, location).then(locationsProvider.reloadResource);
  };

  const onClickCreateLocation = () => {
    drawerStore.openDrawer({
      label: 'Create location',
      inputs: locationCreateInputs,
      clickCallback: onClickConfirmAdd,
    });
  };

  const onClickEditLocation = (location: any) => {
    drawerStore.openDrawer({
      label: 'Edit location',
      inputs: locationEditInputs,
      clickCallback: onClickConfirmEdit,
    });

    drawerStore.setValues(location);
  };

  const onClickRemoveLocation = (location: any) => {
    Api.charge.deleteLocation(location.id).then(locationsProvider.reloadResource);
  };

  const chargerLocationActions = (params: GridRowParams) => (
    <ActionsMenu>
      <ListItem button onClick={() => onClickEditLocation(params.row)}>
        <Typography style={{ width: '100%' }}>Edit</Typography>
      </ListItem>
      <ListItem button onClick={() => onClickRemoveLocation(params.row)}>
        <Typography style={{ width: '100%' }}>Remove</Typography>
      </ListItem>
    </ActionsMenu>
  );

  const chargeLocationsColumns = [
    gridColumn('name', {
      flex: 2,
      sortableFields,
      renderCell: (params: GridRowParams) => (
        <TextLink
          to={locationDetailsTabParameters(params.row.id, LOCATION_DETAILS_TABS.OVERVIEW)}
          label={params.row.name}
        />
      ),
    }),
    gridColumn('address', { flex: 3, sortableFields }),
    gridColumn('tags', { flex: 2, sortableFields }),
    gridColumn('connectors', {
      flex: 2,
      width: 160,
      sortableFields,
      renderCell: (params: GridRowParams) => <div>{`${params.row.connectors?.length}`}</div>,
    }),
    gridColumn('availiability', {
      flex: 2,
      width: 160,
      sortableFields,
      renderCell: (params: GridRowParams) => {
        let numAvailableConnectors = 0;
        params.row.connectors?.forEach((connector) => {
          if (connector.status === 'available') {
            numAvailableConnectors += 1;
          }
        });

        return <div>{`${numAvailableConnectors} / ${params.row.connectors?.length}`}</div>;
      },
    }),
  ];

  if (credentialStore.canManageLocations()) {
    chargeLocationsColumns.push(
      gridColumn('actions', {
        width: 150,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRowParams) => chargerLocationActions(params),
      }),
    );
  }

  const filterCallback = (param?: string, val?: string) => {
    const urlParams: any = {};
    if (param && val) {
      urlParams[param] = val;
    }
    locationsProvider.setRequest((offset, sortBy, sortOrder) =>
      Api.charge.listLocations({ ...urlParams, skip: offset, take: 20, sortBy, sortOrder }),
    );
    locationsProvider.reloadResource();
  };

  return (
    <VMPaginatedGrid
      label="Locations"
      count={locationsProvider.count}
      rows={locationsProvider.data}
      columns={chargeLocationsColumns}
      onClickAdd={credentialStore.canManageLocations() ? onClickCreateLocation : undefined}
      filterParamOptions={[
        { label: 'Name', value: 'name' },
        { label: 'Tags', value: 'tags' },
      ]}
      filterCallback={filterCallback}
      onPageOffsetChange={locationsProvider.onPageChange}
      onSortTypeChange={locationsProvider.setSort}
      defaultSortModel={{ field: 'name', sort: 'asc' }}
    />
  );
});

export default () => (
  <Switch>
    <Route path={`/${PAGES.Locations}/:locationid`} component={LocationDetails} />
    <Route path={`/${PAGES.Locations}`} component={LocationsPage} />
  </Switch>
);
