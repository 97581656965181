import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useFormStyles } from '../../styles/useFormStyles';
import { FormInputTypes } from '../../utils/constants';

export interface UserSelectOption {
  fullName: string;
  email: string;
  id: string;
}

export const userSelectOption = (user): UserSelectOption => {
  const fullName = `${user.name} ${user.familyName}`;

  return {
    fullName,
    email: user.email,
    id: user.id,
  };
};

export const getUserAutocompleteInput = (users?: any[], inputChangeCallback?: any) => ({
  name: 'userId',
  label: 'User',
  inputChangeCallback,
  type: FormInputTypes.userEmailAutocomplete,
  // @ts-ignore
  options: users?.map((user) => userSelectOption(user)) || [],
});

export const UserEmailAutocomplete = observer(({ divKey, options, updateInputValue, inputChangeCallback }: any) => {
  const formClasses = useFormStyles();

  return (
    <div key={divKey} className={formClasses.formDrawerInput}>
      <Autocomplete
        noOptionsText=""
        fullWidth
        disablePortal
        options={options}
        getOptionLabel={(option: UserSelectOption) => option?.email || ''}
        onChange={(e, newVal) => {
          const newOption = newVal as UserSelectOption;
          if (newOption) {
            updateInputValue(newOption.id, 'userId');
          }
        }}
        onClose={(e) => {
          if (inputChangeCallback) {
            inputChangeCallback(undefined);
          }
        }}
        onInputChange={(e, _value, reason) => {
          if (inputChangeCallback) {
            inputChangeCallback(_value);
          }
        }}
        renderOption={(option: UserSelectOption) => (
          <div key={option.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Typography>{option.email}</Typography>
            <Typography style={{ color: '#9a9a9a', fontSize: 14, fontWeight: 300 }}>{option.fullName}</Typography>
          </div>
        )}
        renderInput={(params) => <TextField {...params} label="User Email" />}
      />
    </div>
  );
});
