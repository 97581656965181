import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GridRowParams } from '@material-ui/data-grid';
import { toJS } from 'mobx';
import { IconButton, ListItem, Typography } from '@material-ui/core';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { Api } from '../../api';
import ActionsMenu from '../../components/other/ActionsMenu';
import { drawerStore } from '../../state/drawerStore';
import { Charger, Configuration, CHARGER_STATUS, CHARGER_TYPE } from '../../types/Charger';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { gridColumn } from '../../utils/gridUtils';
import credentialStore from '../../state/credentialStore';
import { FormInputTypes } from '../../utils/constants';

const initalisationConfig = {
  provider: '',
  minPower: 0,
  maxPower: 0,
  minCurrent: 0,
  maxCurrent: 0,
  connectors: [
    {
      type: '',
      cableLength: 0,
      connectorId: '1',
      ocppExtension: '01',
    },
    {
      type: '',
      cableLength: 0,
      connectorId: '2',
      ocppExtension: '02',
    },
  ],
};

const initalisationConfigCreateInputs = [
  { name: 'manufacturer', type: FormInputTypes.textField },
  { name: 'model', type: FormInputTypes.textField },
  { name: 'template', type: FormInputTypes.editableJsonView },
];

const onClickConfirmAdd = (initalisationConfigProvider: any) => {
  const configuration = toJS(drawerStore.values);
  if (credentialStore.isUserAdmin) {
    Api.charge.createInitalisationConfig(configuration).then(initalisationConfigProvider.reloadResource);
  }
};

const onClickConfirmEdit = (initalisationConfigProvider: any) => {
  const configuration = toJS(drawerStore.values);
  if (credentialStore.isUserAdmin) {
    Api.charge.editInitalisationConfig(configuration).then(initalisationConfigProvider.reloadResource);
  }
};

const onClickCreateinitalisationConfig = (initalisationConfigProvider) => {
  drawerStore.openDrawer({
    label: 'Create configuration template',
    inputs: initalisationConfigCreateInputs,
    clickCallback: () => onClickConfirmAdd(initalisationConfigProvider),
  });
  drawerStore.setValues({ template: initalisationConfig });
};

export const onClickEditConfiguration = (configuration: Configuration, initalisationConfigProvider) => {
  const selectedConfiguration = initalisationConfigProvider.data.find((item) => item.id === configuration.id);

  drawerStore.openDrawer({
    label: 'Edit configuration template',
    inputs: [
      { name: 'manufacturer', type: FormInputTypes.disabledTextField },
      { name: 'model', type: FormInputTypes.disabledTextField },
      { name: 'template', type: FormInputTypes.editableJsonView },
    ],
    clickCallback: () => onClickConfirmEdit(initalisationConfigProvider),
  });

  const initialValues = {
    manufacturer: selectedConfiguration.manufacturer,
    model: selectedConfiguration.model,
    template: selectedConfiguration.template,
  };
  drawerStore.setValues(initialValues);
};

export const onClickDuplicateConfiguration = (configuration: Configuration, initalisationConfigProvider) => {
  const selectedConfiguration = initalisationConfigProvider.data.find((item) => item.id === configuration.id);

  drawerStore.openDrawer({
    label: 'Duplicate configuration template',
    inputs: [
      { name: 'manufacturer', type: FormInputTypes.textField },
      { name: 'model', type: FormInputTypes.textField },
      { name: 'template', type: FormInputTypes.editableJsonView },
    ],
    clickCallback: () => onClickConfirmAdd(initalisationConfigProvider),
  });

  const initialValues = {
    manufacturer: selectedConfiguration.manufacturer,
    model: selectedConfiguration.model,
    template: selectedConfiguration.template,
  };
  drawerStore.setValues(initialValues);
};

const onClickDeleteConfiguration = (configuration: Configuration, initalisationConfigProvider: any) => {
  Api.charge.deleteInitalisationConfig(configuration.id).then(initalisationConfigProvider.reloadResource);
};

const initalisationConfigActions = (params: GridRowParams, initalisationConfigProvider) => (
  <ActionsMenu>
    <ListItem button onClick={() => onClickDuplicateConfiguration(params.row as Configuration, initalisationConfigProvider)}>
      <Typography style={{ width: '100%' }}>Duplicate</Typography>
    </ListItem>
    <ListItem button onClick={() => onClickEditConfiguration(params.row as Configuration, initalisationConfigProvider)}>
      <Typography style={{ width: '100%' }}>Edit</Typography>
    </ListItem>
    <ListItem button onClick={() => onClickDeleteConfiguration(params.row as Configuration, initalisationConfigProvider)}>
      <Typography style={{ width: '100%' }}>Delete</Typography>
    </ListItem>
  </ActionsMenu>
);

export const getAllChargersColumns = (initalisationConfigProvider, sortableFields = []) => [
  gridColumn('manufacturer', {
    flex: 2,
    headerName: 'Manufacturer',
    sortableFields,
    valueGetter: (params: GridRowParams) => `${params.row.manufacturer}`,
  }),
  gridColumn('model', {
    flex: 2,
    headerName: 'Model',
    sortableFields,
    valueGetter: (params: GridRowParams) => `${params.row.model}`,
  }),
  gridColumn('createdAt', {
    flex: 2,
    headerName: 'Created At',
    sortableFields,
    valueGetter: (params: GridRowParams) => {
      const date = new Date(params.row.createdAt);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  }),
  gridColumn('', {
    width: 150,
    renderCell: (params: GridRowParams) => initalisationConfigActions(params, initalisationConfigProvider),
    align: 'center',
    headerAlign: 'center',
  }),
];

const initalisationConfigs = observer(() => {
  const initalisationConfigProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);

  useEffect(() => {
    const requestFunction = (offset, sortBy, sortOrder) =>
      Api.charge.listInitalisationConfigs({ skip: offset, take: 20, sortBy, sortOrder });

    initalisationConfigProvider.setRequest(requestFunction);

    initalisationConfigProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) setSortableFields(sortable);
    });

    initalisationConfigProvider.reloadResource();
  }, [initalisationConfigProvider]);

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        rows={initalisationConfigProvider.data}
        columns={getAllChargersColumns(initalisationConfigProvider, sortableFields)}
        onClickAdd={
          credentialStore.isUserAdmin
            ? () => onClickCreateinitalisationConfig(initalisationConfigProvider)
            : undefined
        }
        onPageOffsetChange={initalisationConfigProvider.onPageChange}
        onSortTypeChange={initalisationConfigProvider.setSort}
        label="Initialisation configurations"
      />
    </div>
  );
});

export default initalisationConfigs;
