import { useEffect } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useFormStyles } from '../../styles/useFormStyles';
import { FormInputTypes } from '../../utils/constants';
import TextLink from '../../components/other/TextLink';
import { route, COMPANY_DETAILS_TABS, companyDetailsParameters, companyDetailsRoute } from '../../utils/routing';
import { useStyles } from '../../styles/styles';
import { gridColumn } from '../../utils/gridUtils';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import CompanyDetailsOverview from './CompanyDetailsOverview';
import CompanyDetailsWallets from './CompanyDetailsWallets';
import dashboardStore from '../../state/dashboardStore';
import { PAGES } from '../../components/main/MainComponent';

const detailsEditInputs = [
  { name: 'Name' },
  { name: 'Address' },
  { name: 'Zip code' },
  { name: 'City' },
  { name: 'Country' },
];
const contactsEditInputs = [
  { name: 'Type', type: FormInputTypes.switch },
  { name: 'Owner' },
  { name: 'Company' },
  { name: 'Responsible person' },
  { name: 'Phone' },
  { name: 'Email' },
];

const CompanyDetails = observer(() => {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const { companyid } = useParams<{ companyid: string }>();

  const history = useHistory();

  useEffect(() => {
    dashboardStore.appBarLabel = 'Company details';
  }, [history.location]);

  const chargersColumns = [
    gridColumn('name', {
      flex: 1,
      renderCell: (params: GridRowParams) => (
        <TextLink to={`/${PAGES.Chargers}/${params.row.id}`} label={params.row.name} />
      ),
    }),
    gridColumn('address', {
      flex: 1,
      headerName: 'Location',
    }),
    gridColumn('points', { flex: 1 }),
    gridColumn('model', { flex: 1 }),
  ];

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <div className={formClasses.root}>
          <div className={formClasses.verticalRoot}>
            <ViziTabs basePath={companyDetailsParameters(companyid)} tabs={Object.values(COMPANY_DETAILS_TABS)}>
              <ViziTab
                label={COMPANY_DETAILS_TABS.OVERVIEW}
                linkTo={companyDetailsParameters(companyid, COMPANY_DETAILS_TABS.OVERVIEW)}
              />
              <ViziTab
                label={COMPANY_DETAILS_TABS.WALLETS}
                linkTo={companyDetailsParameters(companyid, COMPANY_DETAILS_TABS.WALLETS)}
              />
            </ViziTabs>

            <Switch>
              <Route path={companyDetailsRoute(COMPANY_DETAILS_TABS.OVERVIEW)}>
                <CompanyDetailsOverview companyid={companyid} />
              </Route>
              <Route path={companyDetailsRoute(COMPANY_DETAILS_TABS.WALLETS)}>
                <CompanyDetailsWallets companyid={companyid} />
              </Route>
              <Route path={route(PAGES.Companies)}>
                <Redirect to={companyDetailsParameters(companyid, COMPANY_DETAILS_TABS.OVERVIEW)} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CompanyDetails;
