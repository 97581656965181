import React from 'react';
import { observer } from 'mobx-react-lite';
import NameValueRow from './NameValueRow';

export const NameValueRowList = observer(
  ({ rows, values, hideEmpty }: { rows: any; values: any; hideEmpty?: boolean }) => (
    <div style={{ width: '100%' }}>
      {rows &&
        rows.map((row) => {
          let { name } = row;
          if (name === undefined && typeof row === 'string') {
            name = row;
          }

          const { label, invertValue } = row;
          let definedlabel = label;
          if (label === undefined) {
            definedlabel = name;
          }

          let definedValues = values;
          if (values === undefined) {
            definedValues = {};
          }

          let value = definedValues[name];
          if (row.value) {
            value = row.value;
          }

          if (!value && hideEmpty) {
            return <div key={name} />;
          }

          return (
            <NameValueRow
              name={definedlabel}
              invertValue={invertValue}
              value={value}
              key={name}
              actionLabel={row.actionLabel}
              actionFunction={row.actionFunction}
            />
          );
        })}
    </div>
  ),
);
