import { makeAutoObservable, toJS } from 'mobx';

export const getInitialFormValues = (inputs: any) => {
  const values: any = {};

  inputs?.forEach((input: any) => {
    values[input.name] = '';
  });

  return values;
};

interface OpenDrawerProps {
  label: string;
  inputs: any[];
  clickCallback?: () => void;
  buttonLabel?: string;
  showButton?: boolean;
}

export class DrawerStore {
  private _open = false;
  private _label = '';
  private _inputs: any[] = [];
  private _values: any = {};
  private _buttonLabel?: string;
  private _showButton = true;

  private saveCallback?: () => void;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset() {
    this._open = false;
    this._values = {};
    this._inputs = [];
    this._label = '';
    this.saveCallback = undefined;
  }

  openDrawer({ label, inputs, clickCallback, buttonLabel, showButton = true }: OpenDrawerProps) {
    this._label = label;
    this._inputs = inputs;
    this.saveCallback = clickCallback;
    this._buttonLabel = buttonLabel;
    this._showButton = showButton;

    if (!buttonLabel) {
      this._buttonLabel = 'Save';
    }

    this._open = true;
  }

  setValues(values: any) {
    this._values = toJS(values);
  }

  setInputs(inputs: any) {
    this._inputs = toJS(inputs);
  }

  setLabel(label: string) {
    this._label = label;
  }

  setCallback(callback: () => void) {
    this.saveCallback = callback;
  }

  setButtonLabel(buttonLabel: string) {
    this._buttonLabel = buttonLabel;
  }

  get open() {
    return toJS(this._open);
  }

  get inputs() {
    return toJS(this._inputs);
  }

  get label() {
    return toJS(this._label);
  }

  get buttonLabel() {
    return toJS(this._buttonLabel);
  }

  get values() {
    return toJS(this._values); // Need to convert if mobxData is array
  }

  get showButton() {
    return toJS(this._showButton);
  }

  save() {
    if (this.saveCallback) this.saveCallback();
    this.reset();
  }
}

export const drawerStore = new DrawerStore();
