import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton, TextField, Typography } from '@material-ui/core';
import QRCode from 'qrcode.react';
import * as htmlToImage from 'html-to-image';
import { Download } from '@material-ui/icons';
import { useFormStyles } from '../../styles/useFormStyles';
import { TOAST_MESSAGE_TYPE, toastStore } from '../../state/toastStore';

export const FormTextToQRCodeVisualizer = observer(({ processedValues, name, handleTextInputChange }: any) => {
  const formClasses = useFormStyles();
  const nodeRef = useRef(null);

  const downloadImage = () => {
    htmlToImage
      .toPng(nodeRef.current)
      .then((dataUrl: any) => {
        const link = document.createElement('a');
        link.download = `${processedValues[name]} QRCode.png`;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error: any) => {
        toastStore.showMessage(`Error downloading QR code: ${error}`, TOAST_MESSAGE_TYPE.ERROR);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '50vh',
      }}
    >
      <div
        ref={nodeRef}
        style={{ background: 'white', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {processedValues[name] && (
            <div style={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
              <QRCode value={processedValues[name]} size={200} />
            </div>
          )}
          {processedValues[name] && (
            <Typography style={{ marginTop: '2em', marginBottom: '2em', textAlign: 'center', fontSize: 20 }}>
              {processedValues[name]}
            </Typography>
          )}
          {!processedValues[name] && (
            <Typography style={{ color: 'gray', marginTop: '2em', marginBottom: '2em', textAlign: 'center' }}>
              Type in QR Code value...
            </Typography>
          )}
        </div>
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}
        className={formClasses.formDrawerInput}
      >
        <TextField
          label={processedValues[name] ? '' : 'QR Code value'}
          fullWidth
          value={processedValues[name] || ''}
          onChange={(e) => handleTextInputChange(e, name)}
          disabled={name === 'ocppId'}
        />
        <div className={formClasses.formDrawerInput} style={{ marginTop: '1em' }}>
          <IconButton color="primary" onClick={downloadImage} disabled={!processedValues[name]}>
            <Download style={{ fontSize: 40 }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
});
