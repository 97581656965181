import { makeStyles } from '@material-ui/core/styles';

const useConnectorStatusStyles = makeStyles({
  root: {
    '& .MuiDataGrid-cell': {
      padding: '0 !important', // Removes padding from grid cells
    },
    '& .MuiDataGrid-columnHeader': {
      paddingLeft: '0 !important', // Removes left padding from column headers
    },
  },
});

export default useConnectorStatusStyles;
