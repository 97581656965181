import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Typography } from '@material-ui/core';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { toJS } from 'mobx';
import { drawerStore } from '../../state/drawerStore';
import StyledCard from '../../components/cards/StyledCard';
import StyledCardHeader from '../../components/cards/StyledCardHeader';
import StyledCardContent from '../../components/cards/StyledCardContent';
import { Api } from '../../api';
import { downloadCsvFile, getFileNameFromResponseHeaders } from '../../utils/utils';
import { FormInputTypes } from '../../utils/constants';
import { useProvider } from '../../state/providerStore';
import { TOAST_MESSAGE_TYPE, toastStore } from '../../state/toastStore';
import { ChargeSessionStatus } from '../../types/ChargeSessionStatus';
import { PaymentStatus } from '../../types/PaymentStatus';
import credentialStore from '../../state/credentialStore';
import { arrayToCommaSeparatedString } from '../../api/apiUtils';

const getLocationOptions = (locationsProvider) =>
  locationsProvider.data?.map((location) => ({
    topRow: location.name,
    bottomRow: location.id,
    optionName: location.id,
  }));

const getChargerOptions = (chargersProvider) =>
  chargersProvider.data?.map((charger) => {
    let label = charger.model;
    if (charger.location?.name) {
      label = `${charger.model} - ${charger.location.name}`;
    }

    return {
      topRow: label,
      bottomRow: charger.ocppId,
      optionName: charger.id,
    };
  });

const getStatusOptions = () =>
  Object.keys(ChargeSessionStatus).map((key) => {
    const status = ChargeSessionStatus[key];

    return {
      topRow: key,
      bottomRow: '',
      optionName: status,
    };
  });

const getPaymentStatusOptions = () =>
  Object.keys(PaymentStatus).map((key) => {
    const status = PaymentStatus[key];

    return {
      topRow: key,
      bottomRow: '',
      optionName: status,
    };
  });

const getMonthlyInputs = (locationsProvider, chargersProvider) => [
  { name: 'presets', type: FormInputTypes.datePresets },
  { name: 'fromDate', type: FormInputTypes.datePicker, label: 'From' },
  { name: 'toDate', type: FormInputTypes.datePicker, label: 'To' },
  {
    name: 'locations',
    type: FormInputTypes.filterListSelect,
    options: getLocationOptions(locationsProvider),
    filter: { label: 'Location name', name: 'locationIds' },
  },
  {
    name: 'chargers',
    type: FormInputTypes.filterListSelect,
    options: getChargerOptions(chargersProvider),
    filter: { label: 'OccpId', name: 'occpId' },
  },
];

const getSessionInputs = (locationsProvider, chargersProvider) => [
  { name: 'presets', type: FormInputTypes.datePresets },
  { name: 'fromDate', type: FormInputTypes.datePicker, label: 'From' },
  { name: 'toDate', type: FormInputTypes.datePicker, label: 'To' },
  {
    name: 'locations',
    type: FormInputTypes.filterListSelect,
    options: getLocationOptions(locationsProvider),
    filter: { label: 'Location name', name: 'locationIds' },
  },
  {
    name: 'chargers',
    type: FormInputTypes.filterListSelect,
    options: getChargerOptions(chargersProvider),
    filter: { label: 'OccpId', name: 'occpId' },
  },
  {
    name: 'statuses',
    type: FormInputTypes.filterListSelect,
    options: getStatusOptions(),
    defaultOptions: [{ optionName: 'finished', topRow: 'FINISHED', bottomRow: '' }],
    filter: { label: 'Status', name: 'status' },
  },
];

const getPaymentInputs = (locationsProvider, chargersProvider) => [
  { name: 'presets', type: FormInputTypes.datePresets },
  { name: 'fromDate', type: FormInputTypes.datePicker, label: 'From' },
  { name: 'toDate', type: FormInputTypes.datePicker, label: 'To' },
  {
    name: 'locations',
    type: FormInputTypes.filterListSelect,
    options: getLocationOptions(locationsProvider),
    filter: { label: 'Location name', name: 'locationIds' },
  },
  {
    name: 'chargers',
    type: FormInputTypes.filterListSelect,
    options: getChargerOptions(chargersProvider),
    filter: { label: 'OccpId', name: 'occpId' },
  },
  {
    name: 'statuses',
    type: FormInputTypes.filterListSelect,
    options: getPaymentStatusOptions(),
    defaultOptions: [{ optionName: 'captured', topRow: 'CAPTURED', bottomRow: '' }],
    filter: { label: 'Status', name: 'status' },
  },
];

const locationsEndpoint = (accountId?: string, params?: any) => {
  if (accountId) {
    return Api.charge.listLocationsForAccount(accountId, params);
  }

  return Api.charge.listLocations(params);
};

const chargersEndpoint = (accountId?: string, params?: any) => {
  if (accountId) {
    return Api.charge.listChargersForAccount(accountId, params);
  }

  return Api.charge.listChargers(params);
};

export const ReportsPage = observer(() => {
  const locationsProvider = useProvider(undefined, undefined, false);
  const chargersProvider = useProvider(undefined, undefined, false);

  const [inputGetter, setInputGetter] = useState<(() => void) | undefined>(undefined);

  function reloadInputs() {
    if (inputGetter) {
      drawerStore.setInputs(inputGetter());
    }
  }

  useEffect(() => {
    if (drawerStore.open) {
      const locationName = drawerStore.values?.locations;

      let locationIds = [];
      locationIds = drawerStore.values.locations_filterList?.map((listEl) => listEl.optionName);

      locationsProvider.setRequest(() =>
        locationsEndpoint(credentialStore.selectedAccount?.id, { name: locationName }),
      );
      locationsProvider.reloadResourceWithCallback(reloadInputs);

      const params = { locationIds: arrayToCommaSeparatedString(locationIds) };
      chargersProvider.setRequest(() => chargersEndpoint(credentialStore.selectedAccount?.id, params));
      chargersProvider.reloadResourceWithCallback(reloadInputs);
    }
  }, [drawerStore.open, drawerStore.values, credentialStore.selectedAccount]);

  const onMonthlyResponse = (response) => {
    const fileName = getFileNameFromResponseHeaders(response);
    downloadCsvFile(response.data, fileName);
  };

  const onSessionsResponse = (response) => {
    const fileName = getFileNameFromResponseHeaders(response);
    downloadCsvFile(response.data, fileName);
  };

  const onPaymentsResponse = (response) => {
    const fileName = getFileNameFromResponseHeaders(response);
    downloadCsvFile(response.data, fileName);
  };

  const getSessionReports = (
    fromDate: string,
    toDate: string,
    locationIds: string[],
    chargerIds: string[],
    statuses: string[],
  ) => {
    if (credentialStore.isUserAdmin) {
      return Api.charge.getSessionReports(fromDate, toDate, locationIds, chargerIds, statuses);
    } else {
      return Api.charge.getSessionReportsForAccount(
        credentialStore.getAccountId(),
        fromDate,
        toDate,
        locationIds,
        chargerIds,
        statuses,
      );
    }
  };

  const getPaymentReports = (
    fromDate: string,
    toDate: string,
    locationIds: string[],
    chargerIds: string[],
    statuses: string[],
  ) => {
    if (credentialStore.isUserAdmin) {
      return Api.billing.getPaymentReports(fromDate, toDate, locationIds, chargerIds, statuses);
    } else {
      return Api.billing.getPaymentReportsForAccount(
        credentialStore.getAccountId(),
        fromDate,
        toDate,
        locationIds,
        chargerIds,
        statuses,
      );
    }
  };

  const getMonthlyConsumption = (fromDate: string, toDate: string, locationIds: string[], chargerIds: string[]) => {
    if (credentialStore.isUserAdmin) {
      return Api.charge.getMonthlyConsumption(fromDate, toDate, locationIds, chargerIds);
    } else {
      return Api.charge.getMonthlyConsumptionForAccount(
        credentialStore.getAccountId(),
        fromDate,
        toDate,
        locationIds,
        chargerIds,
      );
    }
  };

  const confirmGenerateMonthly = (values) => {
    if (values.fromDate === undefined || values.toDate === undefined) {
      toastStore.showMessage('Please select a valid period', TOAST_MESSAGE_TYPE.ERROR);
      return;
    }

    const fromDate = values.fromDate.toISOString();
    const toDate = values.toDate.toISOString();

    let locationIds = [];
    locationIds = values.locations_filterList?.map((listEl) => listEl.optionName);
    let chargerIds = [];
    chargerIds = values.chargers_filterList?.map((listEl) => listEl.optionName);

    getMonthlyConsumption(fromDate, toDate, locationIds, chargerIds).then(onMonthlyResponse);
  };

  const confirmGenerateSessions = (values) => {
    if (values.fromDate === undefined || values.toDate === undefined) {
      toastStore.showMessage('Please select a valid period', TOAST_MESSAGE_TYPE.ERROR);
      return;
    }

    const fromDate = values.fromDate.toISOString();
    const toDate = values.toDate.toISOString();

    let locationIds = [];
    locationIds = values.locations_filterList?.map((listEl) => listEl.optionName);
    let chargerIds = [];
    chargerIds = values.chargers_filterList?.map((listEl) => listEl.optionName);

    let statuses = [];
    statuses = values.statuses_filterList?.map((listEl) => listEl.optionName);

    getSessionReports(fromDate, toDate, locationIds, chargerIds, statuses).then(onSessionsResponse);
  };

  const confirmGeneratePayments = (values) => {
    if (values.fromDate === undefined || values.toDate === undefined) {
      toastStore.showMessage('Please select a valid period', TOAST_MESSAGE_TYPE.ERROR);
      return;
    }

    const fromDate = values.fromDate.toISOString();
    const toDate = values.toDate.toISOString();

    let locationIds = [];
    locationIds = values.locations_filterList?.map((listEl) => listEl.optionName);
    let chargerIds = [];
    chargerIds = values.chargers_filterList?.map((listEl) => listEl.optionName);
    let statuses = [];
    statuses = values.statuses_filterList?.map((listEl) => listEl.optionName);

    getPaymentReports(fromDate, toDate, locationIds, chargerIds, statuses).then(onPaymentsResponse);
  };

  const onClickGenerateMonthly = () => {
    setInputGetter(() => () => getMonthlyInputs(locationsProvider, chargersProvider));

    drawerStore.openDrawer({
      label: 'Monthly consumption',
      inputs: getMonthlyInputs(locationsProvider, chargersProvider),
      clickCallback: () => confirmGenerateMonthly(toJS(drawerStore.values)),
      buttonLabel: 'Generate',
    });
  };

  const onClickGenerateSessions = () => {
    setInputGetter(() => () => getSessionInputs(locationsProvider, chargersProvider));

    drawerStore.openDrawer({
      label: 'Session consumption',
      inputs: getSessionInputs(locationsProvider, chargersProvider),
      clickCallback: () => confirmGenerateSessions(toJS(drawerStore.values)),
      buttonLabel: 'Generate',
    });
  };

  const onClickGeneratePayments = () => {
    setInputGetter(() => () => getPaymentInputs(locationsProvider, chargersProvider));

    drawerStore.openDrawer({
      label: 'Payments',
      inputs: getPaymentInputs(locationsProvider, chargersProvider),
      clickCallback: () => confirmGeneratePayments(toJS(drawerStore.values)),
      buttonLabel: 'Generate',
    });
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '2em' }}>
      <div style={{ width: '100%' }}>
        <StyledCard>
          <StyledCardHeader title="Monthly consumption" />
          <StyledCardContent>
            <Typography style={{ paddingTop: '1em' }}>
              Export of monthly consumption report for the selected period.
            </Typography>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '1em' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={onClickGenerateMonthly}
                style={{ borderRadius: '2em', paddingLeft: '3em', paddingRight: '3em' }}
              >
                <FileDownloadOutlinedIcon style={{ marginRight: '0.5em' }} />
                <Typography>Generate</Typography>
              </Button>
            </div>
          </StyledCardContent>
        </StyledCard>
      </div>
      <div style={{ width: '100%' }}>
        <StyledCard>
          <StyledCardHeader title="Sessions" />
          <StyledCardContent>
            <Typography style={{ paddingTop: '1em' }}>Export of session consumption report.</Typography>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '1em' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={onClickGenerateSessions}
                style={{ borderRadius: '2em', paddingLeft: '3em', paddingRight: '3em' }}
              >
                <FileDownloadOutlinedIcon style={{ marginRight: '0.5em' }} />
                <Typography>Generate</Typography>
              </Button>
            </div>
          </StyledCardContent>
        </StyledCard>
      </div>
      {credentialStore.isUserAdmin && (
        <div style={{ width: '100%' }}>
          <StyledCard>
            <StyledCardHeader title="Payments" />
            <StyledCardContent>
              <Typography style={{ paddingTop: '1em' }}>Export of payments report.</Typography>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '1em' }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onClickGeneratePayments}
                  style={{ borderRadius: '2em', paddingLeft: '3em', paddingRight: '3em' }}
                >
                  <FileDownloadOutlinedIcon style={{ marginRight: '0.5em' }} />
                  <Typography>Generate</Typography>
                </Button>
              </div>
            </StyledCardContent>
          </StyledCard>
        </div>
      )}
      {/* Additional cards can be added here */}
    </div>
  );
});
