export enum CHARGER_TYPE {
  PUBLIC = 'public',
  DOMICIL = 'domicil',
}

export enum CHARGER_STATUS {
  ACTIVE = 'active',
  AVAILABLE = 'available',
  UNASSIGNED = 'unassigned',
  ASSIGNED = 'assigned',
  AT_SERVICE = 'at_service',
  ERROR = 'error',
}

export interface Charger {
  id: string;
  ownerAccountId: string;
  serial: string;
  manufacturer: string;
  model: string;
  ocppId: string;
  status: string;
  minPower: number;
  maxPower: number;
  numberOfConnectors: number;
  currentFirmwareVersion: string;
  firmwareUpdateAvailable: boolean;
  isVisible?: string;
  isTaken?: string;
  type?: string;
  points?: string;
  address?: string;
  createdAt?: string;
}

export interface Configuration {
  id: string;
  manufacturer: string;
  model: string;
  createdAt?: string;
  revision?: string;
}
/*
Sample response:

  "id": "035f7191-8737-417a-8423-16b4e1772fc7",
  "ownerAccountId": null,
  "serial": "RE32434234239XR",
  "manufacturer": "RB Charge",
  "model": "MOV-34XX",
  "ocppId": "RB-RB83439-XI",
  "status": "unassigned",
  "minPower": 50,
  "maxPower": 350,
  "numberOfConnectors": 2,
  "currentFirmwareVersion": "v10.9",
  "firmwareUpdateAvailable": false
 */
