/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import { darkBackgroundColor, defaultBackgroundColor } from '../components/main/react/App';

export const drawerWidth = 260;

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      backgroundColor: defaultBackgroundColor,
    },
    gridRoot: {
      '& .MuiDataGrid-root': {
        borderStyle: 'none',
        padding: 20,
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        overflow: 'visible',
        lineHeight: '1.43rem',
        whiteSpace: 'normal',
        paddingLeft: 0,
        marginLeft: 0,
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        paddingLeft: 0,
        marginLeft: 0,
      },
      width: '100%',
      backgroundColor: '#fff',
      borderRadius: 20,
      boxShadow: '0px 2px 2px 2px #E0E0E0',
    },
    noCellPadding: {
      '& .MuiDataGrid-cell': {
        padding: '0 !important',
        textAlign: 'center',
      },
    },
    noGridPagination: {
      '& .MuiTablePagination-caption': {
        display: 'none',
      },
    },
    dataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    styledCardWithMargin: {
      width: '100%',
      marginBottom: 24,
      borderRadius: 20,
      boxShadow: '0px 2px 2px 2px #E0E0E0',
      padding: 8,
    },
    card: {
      width: '100%',
      borderRadius: 20,
      boxShadow: '0px 2px 2px 2px #E0E0E0',
      padding: 8,
    },
    cardHeader: {
      /*    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 8, */
      paddingBottom: 0,
    },
    cardRows: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
    },
    leftCardRow: {
      flex: 1,
      marginRight: 24,
    },
    rightCardRow: {
      flex: 1,
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      background: defaultBackgroundColor,
      boxShadow: 'none',
    },
    toolbarIcon: {
      fontSize: 30,
    },
    menuItem: {
      marginRight: theme.spacing(2),
    },
    menuSubItem: {
      marginLeft: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: darkBackgroundColor,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'space-evenly',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: 'white',
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    deleteButton: {
      backgroundColor: '#d9534f',
      borderColor: '#d43f3a',
      '&:hover': {
        backgroundColor: '#c9302c',
        borderColor: '#ac2925',
      },
    },
    tabs: {
      width: '100%',
      backgroundColor: '#fff',
      marginBottom: theme.spacing(4),
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
      boxShadow: '0px 1px 2px 1px #E0E0E0',
      display: 'flex',
    },
    tab: {
      flexGrow: 1,
      maxWidth: 'none',
      flexBasis: 0,
      flexShrink: 1,
    },
  }),
  { index: 1 },
);
