/* eslint-disable function-paren-newline */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface ActionsMenuProps {
  children: React.ReactNode;
  style?: {
    iconButton?: React.CSSProperties;
  };
}

export default function ActionsMenu({ children, style }: ActionsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} style={style?.iconButton || {}}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        {React.Children.map(children, (child) => (
          <div onClick={handleClose}>{child}</div>
        ))}
      </Menu>
    </div>
  );
}
