import { baseUrls, createApi } from './apiUtils';

const docsApi = {
  v1: createApi({ baseURL: `${baseUrls.docs}/v1` }),
  v2: createApi({ baseURL: `${baseUrls.docs}/v2` }),
  adminV1: createApi({ baseURL: `${baseUrls.docs}/admin/v1` }),
  adminV2: createApi({ baseURL: `${baseUrls.docs}/admin/v2` }),
};

const DOCS_URLS = {
  IMAGES: 'images',
  STATUS: 'status',
};

export const createImageForLocation = (imageData) => docsApi.v1.post(DOCS_URLS.IMAGES, imageData);

export const getStatus = docsApi.v1.get(DOCS_URLS.STATUS);
