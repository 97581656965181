import React from 'react';
import ReactJson from 'react-json-view';

export const FormJsonView = ({ data }) => (
  <ReactJson
    src={data}
    iconStyle="triangle"
    displayDataTypes={false}
    displayObjectSize={false}
    indentWidth={4}
    collapsed={false}
    enableClipboard={false}
    collapseStringsAfterLength={100}
    style={{ padding: '10px', backgroundColor: 'white' }}
  />
);
