export enum ChargeSessionStatus {
    WAITING_PAYMENT = 'waiting_payment',
    PENDING = 'pending',
    STARTED = 'started',
    FINISHED = 'finished',
    PAUSED = 'paused',
    CANCELED = 'canceled',
    ERROR = 'error',
    DELETED = 'deleted',
    FAILED = 'failed',
}
