import { CardContent, Divider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useStyles } from '../../styles/styles';
import StyledCardHeader, { HeaderButtonIcon } from '../../components/cards/StyledCardHeader';
import StyledCard from '../../components/cards/StyledCard';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';
import StyledCardContent from '../../components/cards/StyledCardContent';
import { Product } from '../../types/Product';
import { NameValueRowList } from '../../components/cards/CardRows/NameValueRowList';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import { RuleRow } from '../../components/cards/CardRows/Products/RuleRow';

const mandatoryCondition = (values) => values.type === 'mandatory_addon';
const freeUnitsCondition = (values) => values.type === 'free_units';

const quantityPerTimeCondition = (values) =>
  values.type === 'mandatory_addon' && values.addonType === 'quantity_per_time';

export const productSelectOption = (product) => ({
  optionName: product.id,
  topRow: `${product.name}`,
  bottomRow: `${product.SKU}`,
});

const ProductDetails = observer(() => {
  const classes = useStyles();

  const { productid } = useParams<{ productid: string }>();

  const productProvider = useProvider(() => Api.catalog.getProduct(productid));
  const rulesProvider = useProvider(() => Api.catalog.listProductRules(productid));
  const productsProvider = useProvider(Api.catalog.listProducts);

  const ruleTypeInputs = [{ name: 'type', type: FormInputTypes.select, options: ['mandatory_addon', 'free_units'] }];

  const mapProductsToOptions = (products) => {
    const products2 = [];
    products.forEach((product) => {
      if (product.id !== productid) {
        products2.push(product);
      }
    });

    return products2.map((product) => productSelectOption(product));
  };

  const mandatoryAddonRuleInputs = [
    {
      name: 'productId',
      type: FormInputTypes.multilineSelect,
      options: productsProvider.data !== undefined ? mapProductsToOptions(productsProvider.data) : [],
      condition: mandatoryCondition,
    },
    { name: 'quantity', type: FormInputTypes.number, condition: mandatoryCondition, label: 'Initial Quantity' },
    {
      name: 'addonType',
      type: FormInputTypes.select,
      options: ['one_per_invoice', 'one_per_product', 'quantity_per_time'],
      condition: mandatoryCondition,
    },
    {
      name: 'addonGroup',
      type: FormInputTypes.select,
      options: ['entry_fee', 'parking_fee', 'other'],
      condition: mandatoryCondition,
    },
    { name: 'initialFreeQuantity', type: FormInputTypes.number, condition: quantityPerTimeCondition },
  ];

  const freeUnitsRuleInputs = [{ name: 'quantity', type: FormInputTypes.textField, condition: freeUnitsCondition }];

  const productDetailRows = ['SKU', 'name', 'displayName', 'shortName', 'description', 'shortDescription', 'createdAt'];

  const editProductDetailsInputs = [
    { name: 'SKU', type: FormInputTypes.textField },
    { name: 'name', type: FormInputTypes.textField },
    { name: 'displayName', type: FormInputTypes.textField },
    { name: 'shortName', type: FormInputTypes.textField },
    { name: 'description', type: FormInputTypes.textField },
    { name: 'shortDescription', type: FormInputTypes.textField },
    { name: 'createdAt', type: FormInputTypes.textField },
  ];

  const editProductPriceInputs = [
    { name: 'price', type: FormInputTypes.textField },
    { name: 'unit', type: FormInputTypes.textField },
    { name: 'VAT', type: FormInputTypes.textField },
    { name: 'quantityForPrice', type: FormInputTypes.number },
    { name: 'reservationAmount', type: FormInputTypes.number },
  ];

  const editProductConfigInputs = [
    { name: 'power', type: FormInputTypes.textField },
    { name: 'current', type: FormInputTypes.textField },
  ];

  const confirmAddRule = (rule) => {
    if (rule.type === 'free_units') {
      rule.addonType = 'one_per_product';
    }

    Api.catalog.createProductRule(productid, rule).then(rulesProvider.reloadResource);
  };

  const confirmEditProductDetails = (values: Partial<Product>) => {
    values = _.pick(values, productDetailRows);
    Api.catalog.patchProduct(productid, values).then(productProvider.reloadResource);
  };

  const confirmEditProductPrice = (values: any) => {
    const body = {
      price: values.price,
      unit: values.unit,
      tax: {
        formatted: values.VAT,
      },
      quantityForPrice: values.quantityForPrice,
    };

    Api.catalog.patchProduct(productid, body).then(productProvider.reloadResource);
  };

  const confirmEditProductConfig = (values: Partial<Product>) => {
    Api.catalog.patchProduct(productid, { config: values }).then(productProvider.reloadResource);
  };

  const removeRule = (ruleId) => {
    Api.catalog.deleteRule(ruleId).then(rulesProvider.reloadResource);
  };

  const priceRows = [
    { label: 'price', name: 'formattedPrice' },
    'unit',
    'VAT',
    'reservationAmount',
    'quantityForPrice',
  ];
  const priceValues = {
    price: productProvider.data?.price?.value,
    formattedPrice: productProvider.data?.price?.formatted,
    unit: productProvider.data?.unit,
    VAT: productProvider.data?.tax?.formatted,
    reservationAmount: productProvider.data?.reservationAmount?.formatted,
    quantityForPrice: productProvider.data?.quantityForPrice,
  };

  const configRows = ['power', 'current'];
  const configValues = { ...productProvider.data?.config };

  const onClickAddRule = () => {
    drawerStore.openDrawer({
      label: 'Add rule',
      inputs: [...ruleTypeInputs, ...mandatoryAddonRuleInputs, ...freeUnitsRuleInputs],
      clickCallback: () => confirmAddRule(toJS(drawerStore.values)),
    });
  };

  const onClickEditProductDetails = () => {
    drawerStore.openDrawer({
      label: 'Edit product details',
      inputs: editProductDetailsInputs,
      clickCallback: () => confirmEditProductDetails(toJS(drawerStore.values)),
    });

    drawerStore.setValues(productProvider.data);
  };

  const onClickEditPrice = () => {
    drawerStore.openDrawer({
      label: 'Edit product price',
      inputs: editProductPriceInputs,
      clickCallback: () => confirmEditProductPrice(toJS(drawerStore.values)),
    });

    drawerStore.setValues(priceValues);
  };

  const onClickEditConfig = () => {
    drawerStore.openDrawer({
      label: 'Edit product config',
      inputs: editProductConfigInputs,
      clickCallback: () => confirmEditProductConfig(toJS(drawerStore.values)),
    });

    drawerStore.setValues(configValues);
  };

  return (
    <div className={classes.cardRows}>
      <div className={classes.leftCardRow}>
        <StyledCard>
          <StyledCardHeader
            title="Details"
            onClick={onClickEditProductDetails}
            headerButtonIcon={HeaderButtonIcon.edit}
          />
          <StyledCardContent>
            <NameValueRowList rows={productDetailRows} values={productProvider.data} />
          </StyledCardContent>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader title="Rules" onClick={onClickAddRule} headerButtonIcon={HeaderButtonIcon.add} />
          <CardContent>
            <Divider />
            {rulesProvider.data?.map((rule, idx) => (
              <RuleRow rule={rule} actions={[{ label: 'Remove', callback: () => removeRule(rule.id) }]} key={rule.id} />
            ))}
          </CardContent>
        </StyledCard>
      </div>

      <div className={classes.rightCardRow}>
        <StyledCard>
          <StyledCardHeader title="Price" onClick={onClickEditPrice} headerButtonIcon={HeaderButtonIcon.edit} />
          <StyledCardContent>
            <NameValueRowList rows={priceRows} values={priceValues} />
          </StyledCardContent>
        </StyledCard>

        <StyledCard>
          <StyledCardHeader
            title="Configuration"
            onClick={onClickEditConfig}
            headerButtonIcon={HeaderButtonIcon.edit}
          />
          <StyledCardContent>
            <NameValueRowList rows={configRows} values={configValues} />
          </StyledCardContent>
        </StyledCard>
      </div>
    </div>
  );
});

export default ProductDetails;
