import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { getAllChargersColumns } from './AssignedChargers';
import { Api } from '../../api';
import credentialStore from '../../state/credentialStore';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';

export const AllChargers = observer(() => {
  const allChargersProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      allChargersProvider.setRequest((offset, sortBy, sortOrder) =>
        Api.charge.listChargers({ ...filterParams, skip: offset, take: 20, sortBy, sortOrder }),
      );
    } else {
      allChargersProvider.setRequest((offset, sortBy, sortOrder) =>
        Api.charge.listChargersForAccount(credentialStore.selectedAccount.id, {
          ...filterParams,
          skip: offset,
          take: 20,
          sortBy,
          sortOrder,
        }),
      );
    }

    allChargersProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
    });
    allChargersProvider.reloadResource();
  }, [credentialStore.selectedAccount]);

  const filterCallback = (param?: string, val?: string) => {
    const urlParams: any = {};
    if (param && val) {
      urlParams[param] = val;
      setFilterParams(urlParams);
    }

    allChargersProvider.setRequest((offset) =>
      Api.charge.listChargers({
        ...urlParams,
        skip: offset,
        take: 20,
        sortBy: allChargersProvider.sortBy,
        sortOrder: allChargersProvider.sortOrder,
      }),
    );
    allChargersProvider.reloadResource();
  };

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        rows={allChargersProvider.data}
        columns={getAllChargersColumns(allChargersProvider, sortableFields)}
        label="All chargepoints"
        count={allChargersProvider.count}
        onPageOffsetChange={allChargersProvider.onPageChange}
        onSortTypeChange={allChargersProvider.setSort}
        filterParamOptions={[
          { label: 'OCPP ID', value: 'ocppId' },
          { label: 'Location', value: 'location' },
        ]}
        filterCallback={filterCallback}
      />
    </div>
  );
});
