import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Divider } from '@material-ui/core';
import React from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import { timeRangeStore } from '../../state/timeRangeStore';
import { drawerStore } from '../../state/drawerStore';
import { FormInputTypes } from '../../utils/constants';

export const DateRangeOpener = observer(() => {
  const formattedStart = timeRangeStore.start?.format('DD.MM.YYYY');
  const formattedEnd = timeRangeStore.end?.format('DD.MM.YYYY');

  const dateInputs = [
    { name: 'presets', type: FormInputTypes.datePresets },
    {
      name: 'fromDate',
      type: FormInputTypes.datePicker,
      label: 'From',
    },
    { name: 'toDate', type: FormInputTypes.datePicker, label: 'To' },
  ];

  const onDateRangeClick = () => {
    drawerStore.setValues({
      presetLabel: timeRangeStore.label,
      fromDate: timeRangeStore.start?.toDate(),
      toDate: timeRangeStore.end?.toDate(),
    });
    drawerStore.openDrawer({
      label: 'Select date range',
      inputs: dateInputs,
      clickCallback: () => {
        timeRangeStore.setValues({
          label: drawerStore.values?.presetLabel,
          start: moment(drawerStore.values?.fromDate),
          end: moment(drawerStore.values?.toDate),
        });
      },
      buttonLabel: 'Apply',
    });
  };

  return (
    <div style={{ marginBottom: '1em', cursor: 'pointer', width: '300px' }} onClick={onDateRangeClick}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CalendarTodayIcon />
        <div>{timeRangeStore.label ?? `${formattedStart} - ${formattedEnd}`}</div>
        <ArrowDropDownIcon />
      </div>
      <div style={{ width: '100%', marginTop: 8 }}>
        <Divider variant="fullWidth" orientation="horizontal" style={{ backgroundColor: 'grey' }} />
      </div>
    </div>
  );
});
