import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormStyles } from '../../styles/useFormStyles';

export const FormPugCart = observer(({ data }: any) => {
  const formClasses = useFormStyles();

  return (
    <div className={formClasses.formDrawerInput} style={{ width: '600px', margin: 'auto', marginTop: '2em' }}>
      <table style={{ width: '100%', marginBottom: '20px' }}>
        <thead style={{ backgroundColor: '#20C597', color: 'white' }}>
          <tr>
            <th>No</th>
            <th>Item</th>
            <th>Unit</th>
            <th style={{ textAlign: 'right' }}>PPU</th>
            <th style={{ textAlign: 'right' }}>Qty.</th>
            <th style={{ textAlign: 'right' }}>Rate</th>
            <th style={{ textAlign: 'right' }}>Total (ex. TAX)</th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((item, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{item.title}</td>
              <td>{item.unit}</td>
              <td style={{ textAlign: 'right' }}>{item.unitPrice?.formatted}</td>
              <td style={{ textAlign: 'right' }}>{item.quantity?.toFixed(2)}</td>
              <td style={{ textAlign: 'right' }}>{item.taxRate?.formatted}</td>
              <td style={{ textAlign: 'right' }}>{item.price?.formatted}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: 'right' }}>
        {data?.totalDiscount?.value < 0 && (
          <p>
            <strong>Total discount:</strong> {data.totalDiscount.formatted}
          </p>
        )}
        <p>
          <strong>Total ex. tax:</strong> {data?.totalPrice?.formatted}
        </p>
        {data?.taxRates?.map((rate, index) => (
          <p key={index}>
            <strong>
              TAX {rate.rate?.formatted} (of base {rate.base?.formatted}):
            </strong>{' '}
            {rate.total?.formatted}
          </p>
        ))}
        <p>
          <strong>Total TAX:</strong> {data?.totalTax?.formatted}
        </p>
        <p className="total" style={{ fontWeight: 'bold' }}>
          <strong>Total Price:</strong> {data?.totalPriceWithTax?.formatted}
        </p>
      </div>
    </div>
  );
});
