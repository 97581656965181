/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ListItem, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { makeAutoObservable } from 'mobx';
import TextLink from '../../components/other/TextLink';
import ActionsMenu from '../../components/other/ActionsMenu';
import {
  route,
  CHARGER_DETAILS_TABS,
  chargerDetailsTabParameters,
  LOCATION_DETAILS_TABS,
  locationDetailsTabParameters,
  SESSION_DETAILS_TABS,
  sessionDetailsTabParameters,
  sessionDetailsTabRoute,
  SESSIONS_TABS,
  sessionTabRoute,
  USER_DETAILS_TABS,
  userDetailsTabParameters,
} from '../../utils/routing';
import { ConnectorIconWithLink } from '../../components/other/Connectors';
import { useStyles } from '../../styles/styles';
import SessionDetails from './SessionDetails';
import { CHARGER_TYPE } from '../../types/Charger';
import { ChargerIcon } from '../../components/other/ChargerIcon';
import { SessionStatusBadge } from '../../components/other/CellBadges';
import { gridColumn } from '../../utils/gridUtils';
import { SESSION_STATUS } from '../../types/Session';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import { Api } from '../../api';
import dashboardStore from '../../state/dashboardStore';
import { PAGES } from '../../components/main/MainComponent';
import credentialStore from '../../state/credentialStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';

function getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
  // eslint-disable-next-line eqeqeq
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : '';
}

class SessionsStore {
  gridLabel = '';
  isActiveParam?: string;
  fromDate?: string;
  toDate?: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

const onClickStopSessionFromGrid = (session, sessionsProvider) => {
  Api.charge.stopConnector(session.connector.id).then(sessionsProvider.reloadResource);
};

const sessionActions = (session, sessionsProvider, history) => (
  <ActionsMenu>
    <ListItem
      button
      onClick={() => history.push(sessionDetailsTabParameters(session.id, SESSION_DETAILS_TABS.OVERVIEW))}
    >
      <Typography style={{ width: '100%' }}>Details</Typography>
    </ListItem>
    {(session.status === SESSION_STATUS.STARTED || session.status === SESSION_STATUS.PENDING) && (
      <ListItem button onClick={() => onClickStopSessionFromGrid(session, sessionsProvider)}>
        <Typography style={{ width: '100%' }}>Stop session</Typography>
      </ListItem>
    )}
  </ActionsMenu>
);

export const ConnectorColumnCell = ({ connector, showLink = true }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
      }}
    >
      {connector && <ConnectorIconWithLink connector={connector} showLink={showLink} />}
    </div>
  </div>
);

export const sessionsColumn_chargerTypeIcon = (sortableFields = []) =>
  gridColumn('chargerTypeIcon', {
    width: 60,
    headerName: '',
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const type = params.row.isPublic ? CHARGER_TYPE.PUBLIC : CHARGER_TYPE.DOMICIL;

      return <ChargerIcon type={type} />;
    },
  });

export const formatToSloDate = (date: any, includeHour = true) => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const MM = date.getMonth() + 1;
  const dd = date.getDate();
  const yy = date.getFullYear();
  const hh = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');

  if (includeHour) {
    return `${dd}.${MM}.${yy} ${hh}:${mm}`;
  }

  return `${dd}.${MM}.${yy}`;
};

export const sessionsColumn_StartedAt = (sortableFields = []) =>
  gridColumn('startedAt', {
    flex: 2,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const date = new Date(params.row.startedAt);

      if (!date) {
        return <div />;
      }

      const sloDate = formatToSloDate(date);

      return (
        <TextLink label={sloDate} to={sessionDetailsTabParameters(params.row.id, SESSION_DETAILS_TABS.OVERVIEW)} />
      );
    },
  });

export const sessionsColumn_UserId = (sortableFields = []) =>
  gridColumn('userId', {
    flex: 2,
    headerName: 'User',
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const { userId } = params.row;
      if (!userId) {
        return <div>N/A</div>;
      }

      const shorterId = userId.substring(userId.lastIndexOf('-') + 1);
      return <TextLink to={userDetailsTabParameters(userId, USER_DETAILS_TABS.OVERVIEW)} label={`...${shorterId}`} />;
    },
  });

export const sessionsColumn_tag = gridColumn('user tag', {
  flex: 2,
  renderCell: (params: GridRowParams) => {
    const { authorizationAttempt } = params.row;

    if (!authorizationAttempt?.tag) {
      return <div>N/A</div>;
    }

    const shorterId = authorizationAttempt?.tag.substring(authorizationAttempt?.tag.lastIndexOf('-') + 1);
    return (
      <TextLink
        to={userDetailsTabParameters(authorizationAttempt?.userId, USER_DETAILS_TABS.OVERVIEW)}
        label={`...${shorterId}`}
      />
    );
  },
});

export const sessionsColumn_location = (sortableFields = []) =>
  gridColumn('location', {
    flex: 2,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const { location } = params.row;

      if (!location?.address) {
        const chargePoint = params.row.connector?.chargePoint;
        return (
          <TextLink
            to={chargerDetailsTabParameters(chargePoint?.id, CHARGER_DETAILS_TABS.OVERVIEW)}
            label={chargePoint?.ocppId}
          />
        );
      }

      return (
        <TextLink
          to={locationDetailsTabParameters(location.id, LOCATION_DETAILS_TABS.OVERVIEW)}
          label={location.address}
        />
      );
    },
  });

export const sessionsColumns_Connector = (sortableFields = []) =>
  gridColumn('connector', {
    flex: 2,
    sortableFields,
    renderCell: (params: GridRowParams) => <ConnectorColumnCell connector={params.row.connector} />,
  });

export const sessionsColumns_Status = (sortableFields = []) =>
  gridColumn('status', {
    flex: 1,
    sortableFields,
    renderCell: (params: GridRowParams) => <SessionStatusBadge status={params.row.status} />,
  });

export const sessionsColumns_Duration = (sortableFields = []) =>
  gridColumn('duration', {
    flex: 2,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      if (params.row.duration === undefined) {
        return <div />;
      }

      const seconds = parseInt(params.row.duration, 10) || 0;
      const duration = moment.utc(moment.duration(seconds, 'seconds').as('milliseconds'));
      let formatted = duration.format('mm[m] ss[s]');
      if (duration.hours() >= 1) {
        formatted = duration.format('HH[h] mm[m] ss[s]');
      }

      return <Typography style={{ fontSize: 14 }}>{formatted}</Typography>;
    },
  });

export const sessionsColumns_avgPower = (sortableFields = []) =>
  gridColumn('avgPower', {
    flex: 1,
    headerName: 'Avg Power',
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const avgPower = params.row.avgPower;

      if (params.row.avgPower === undefined) {
        return <div />;
      }

      return <p>{Number(avgPower).toFixed(2)} kW</p>;
    },
  });

export const sessionsColumns_unitsConsumed = (sortableFields = []) =>
  gridColumn('unitsConsumed', {
    flex: 1,
    headerName: 'Consumption',
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const unitsConsumed = params.row.unitsConsumed;

      if (params.row.unitsConsumed === undefined) {
        return <div />;
      }

      return <p>{Number(unitsConsumed).toFixed(2)} kWh</p>;
    },
  });

export const sessionsColumns_price = (sortableFields = []) =>
  gridColumn('price', {
    flex: 1,
    sortableFields,
    renderCell: (params: GridRowParams) => <div>0</div>,
  });

export const sessionsColumns_actions = (sessionsProvider, history) =>
  gridColumn('actions', {
    width: 60,
    align: 'center',
    headerAlign: 'center',
    headerName: ' ',
    renderCell: (params: GridRowParams) => sessionActions(params.row, sessionsProvider, history),
  });

export const SESSIONS_REFRESH_INTERVAL = 5000; // milliseconds

const SessionsPage = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const sessionsProvider = usePaginatedProvider(undefined, undefined, false);
  const [sortableFields, setSortableFields] = useState([]);

  const [sessionStore] = useState(() => new SessionsStore());

  useEffect(() => {
    dashboardStore.appBarLabel = 'Sessions';
  }, [history.location]);

  const sessionColumns = [
    sessionsColumn_chargerTypeIcon(sortableFields),
    sessionsColumn_StartedAt(sortableFields),
    sessionsColumn_UserId(sortableFields),
    sessionsColumn_tag,
    sessionsColumn_location(sortableFields),
    sessionsColumns_Connector(sortableFields),
    sessionsColumns_Status(sortableFields),
    sessionsColumns_Duration(sortableFields),
    sessionsColumns_avgPower(sortableFields),
    sessionsColumns_unitsConsumed(sortableFields),
    sessionsColumns_price(sortableFields),
    sessionsColumns_actions(sessionsProvider, history),
  ];

  const getISOStringFrom24hAgo = () => {
    const oneDay = 24 * 60 * 60 * 1000;
    const yesterday = Date.now() - oneDay;

    return moment(yesterday).toISOString();
  };

  const onTabLoad = (tab: SESSIONS_TABS) => {
    switch (tab) {
      case SESSIONS_TABS.ACTIVE:
        sessionStore.gridLabel = 'Active sessions';
        sessionStore.isActiveParam = 'true';
        sessionStore.fromDate = undefined;
        sessionStore.toDate = undefined;
        break;
      case SESSIONS_TABS.LAST_24H:
        sessionStore.gridLabel = 'Sessions in last 24h';
        sessionStore.isActiveParam = undefined;
        sessionStore.fromDate = getISOStringFrom24hAgo();
        sessionStore.toDate = undefined;
        break;
      case SESSIONS_TABS.HISTORY:
        sessionStore.gridLabel = 'All sessions';
        sessionStore.isActiveParam = undefined;
        sessionStore.fromDate = undefined;
        sessionStore.toDate = getISOStringFrom24hAgo();
        break;
      default:
        break;
    }
    const uriParams: any = {};
    uriParams.isActive = sessionStore.isActiveParam;
    uriParams.fromDate = sessionStore.fromDate;
    uriParams.toDate = sessionStore.toDate;
    uriParams.take = 20;

    if (credentialStore.isUserAdmin) {
      sessionsProvider.setRequest((offset, sortBy, sortOrder) =>
        Api.charge.listSessions({ ...uriParams, skip: offset, sortBy, sortOrder }),
      );
    } else {
      sessionsProvider.setRequest((offset, sortBy, sortOrder) =>
        Api.charge.listSessionsForAccount(credentialStore.getAccountId(), {
          ...uriParams,
          skip: offset,
          sortBy,
          sortOrder,
        }),
      );
    }

    if (tab === SESSIONS_TABS.ACTIVE) {
      sessionsProvider.setRefreshInterval(SESSIONS_REFRESH_INTERVAL);
    }
    sessionsProvider.setOnFulfilled((_data, _count, sortable) => {
      if (sortable) {
        setSortableFields(sortable);
      }
    });

    sessionsProvider.reloadResource();
  };

  useEffect(() => {
    const pathWords = pathname.substring(1).split('/');

    const tabWord = pathWords[1];

    switch (tabWord) {
      case 'active':
        onTabLoad(SESSIONS_TABS.ACTIVE);
        break;
      case 'last-24h':
        onTabLoad(SESSIONS_TABS.LAST_24H);
        break;
      case 'history':
        onTabLoad(SESSIONS_TABS.HISTORY);
        break;
      default:
        break;
    }
  }, [pathname]);

  const filterCallback = (param?: string, val?: string) => {
    const urlParams: any = {};
    if (param && val) {
      urlParams[param] = val;
    }
    sessionsProvider.setRequest((offset) =>
      Api.charge.listSessions({
        ...urlParams,
        skip: offset,
        take: 20,
        sortBy: sessionsProvider.sortBy,
        sortOrder: sessionsProvider.sortOrder,
      }),
    );
    sessionsProvider.reloadResource();
  };

  return (
    <div style={{ width: '100%' }}>
      <ViziTabs tabs={Object.values(SESSIONS_TABS)} basePath={sessionTabRoute()}>
        <ViziTab label={SESSIONS_TABS.ACTIVE} linkTo={sessionTabRoute(SESSIONS_TABS.ACTIVE)} />
        <ViziTab label={SESSIONS_TABS.LAST_24H} linkTo={sessionTabRoute(SESSIONS_TABS.LAST_24H)} />
        <ViziTab label={SESSIONS_TABS.HISTORY} linkTo={sessionTabRoute(SESSIONS_TABS.HISTORY)} />
      </ViziTabs>

      <VMPaginatedGrid
        label={sessionStore.gridLabel}
        count={sessionsProvider.count}
        rows={sessionsProvider.data}
        columns={sessionColumns}
        filterParamOptions={[{ label: 'Tag', value: 'tag' }]}
        filterCallback={filterCallback}
        onPageOffsetChange={sessionsProvider.onPageChange}
        onSortTypeChange={sessionsProvider.setSort}
      />
    </div>
  );
});

export default () => (
  <Switch>
    <Route path={sessionDetailsTabRoute()} component={SessionDetails} />
    <Route exact path={route(PAGES.Sessions)}>
      <Redirect to={sessionTabRoute(SESSIONS_TABS.ACTIVE)} />
    </Route>
    <Route path={route(PAGES.Sessions)} component={SessionsPage} />
  </Switch>
);
