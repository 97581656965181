/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import {
  SESSIONS_REFRESH_INTERVAL,
  sessionsColumn_chargerTypeIcon,
  sessionsColumn_location,
  sessionsColumn_StartedAt,
  sessionsColumns_actions,
  sessionsColumns_avgPower,
  sessionsColumns_Connector,
  sessionsColumns_Duration,
  sessionsColumns_price,
  sessionsColumns_Status,
  sessionsColumns_unitsConsumed,
} from '../Sessions/SessionsPage';
import { Api } from '../../api';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';

const UserDetailsSessions = observer(() => {
  const { userid } = useParams<{ userid: string }>();

  const history = useHistory();

  const userSessionsProvider = usePaginatedProvider(
    (offset: number) => Api.charge.listSessionsForUser(userid, { skip: offset, limit: 20 }),
    undefined,
  );

  useEffect(() => {
    userSessionsProvider.setRefreshInterval(SESSIONS_REFRESH_INTERVAL);
  }, []);

  const userSessionColumns = [
    sessionsColumn_chargerTypeIcon(),
    sessionsColumn_StartedAt(),
    sessionsColumn_location(),
    sessionsColumns_Connector(),
    sessionsColumns_Status(),
    sessionsColumns_Duration(),
    sessionsColumns_avgPower(),
    sessionsColumns_unitsConsumed(),
    sessionsColumns_price(),
    sessionsColumns_actions(userSessionsProvider, history),
  ];

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        label="Sessions"
        rows={userSessionsProvider.data}
        columns={userSessionColumns}
        count={userSessionsProvider.count}
        onPageOffsetChange={userSessionsProvider.onPageChange}
      />
    </div>
  );
});

export default UserDetailsSessions;
