import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import { MenuItem, Typography } from '@material-ui/core';

export interface ActionItem {
  label: string;
  callback: (() => void) | ((data) => void);
}

const ActionsList = ({ actions }: { actions?: Array<ActionItem> }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const onClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onItemClick = (callback) => {
    callback();
    setAnchorEl(null);
  };

  return (
    <div>
      {actions && actions.length > 0 && (
        <IconButton onClick={onClickOpen}>
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={open}
        onClose={closeMenu}
        PaperProps={{ style: { width: '20ch' } }}
      >
        {actions?.map((item: ActionItem, index: any) => (
          <MenuItem onClick={() => onItemClick(item.callback)} key={item.label + '_' + index}>
            <Typography style={{ width: '100%' }}>{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActionsList;
