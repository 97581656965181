import { Card, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useFormStyles } from '../../styles/useFormStyles';
import { useStyles } from '../../styles/styles';

interface TitleNumberCardProps {
  title: string;
  number?: number;
  link?: string;
  defaultText?: string;
}

export const TitleNumberCard = observer(({ title, number, link, defaultText = '' }: TitleNumberCardProps) => {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const history = useHistory();

  const cursor = link !== undefined ? 'pointer' : 'default';
  const onClick = link !== undefined ? () => history.push(link) : undefined;

  return (
    <Card
      className={classes.card}
      style={{ height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', cursor }}
      onClick={onClick}
    >
      <Typography align="center" className={formClasses.greyTitleText}>
        {title}
      </Typography>
      <Typography variant="h6" align="center" className={link !== undefined ? formClasses.greenText : ''}>
        {number || defaultText}
      </Typography>
    </Card>
  );
});
