import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Credentials } from '../types/Credentials';
import dashboardStore from './dashboardStore';
import { Api } from '../api';

interface AccountSettings {
  canViewWallets?: boolean;
  canManageLocations?: boolean;
  canViewUnassignedChargers?: boolean;
  ocppPrefix?: string;
}

class CredentialStore {
  credentials: Credentials;
  inited = false;
  tokenExpired = false;
  tokenRefreshFlag = 0; // Flag for reloading components after token is refreshed
  refreshingToken = false;

  ownerAccounts: any[] = [];
  selectedAccount: any;

  loadingAccounts = false;
  shouldLogOut = false;

  get isUserAdmin() {
    return this.credentials?.user?.isAdmin === true;
  }

  clearCredentials() {
    this.credentials = undefined;
    this.ownerAccounts = [];
    this.selectedAccount = undefined;
  }

  onOwnerAccountsSuccess = (resp) => {
    const data = resp.data.data;
    this.ownerAccounts = data;
    if (!this.selectedAccount) {
      this.selectedAccount = data[0];
    }

    this.loadingAccounts = false;
  };

  getAccountId() {
    return this.selectedAccount?.id;
  }

  getAccountSettings(): AccountSettings | undefined {
    return this.selectedAccount?.settings;
  }

  canViewWallets() {
    return this.isUserAdmin || this.getAccountSettings()?.canViewWallets;
  }

  canManageLocations() {
    return this.isUserAdmin || this.getAccountSettings()?.canManageLocations;
  }

  canViewUnassignedChargers() {
    return this.isUserAdmin || this.getAccountSettings()?.canViewUnassignedChargers;
  }

  getOcppPrefix() {
    return this.getAccountSettings()?.ocppPrefix;
  }

  loadAccounts() {
    try {
      this.loadingAccounts = true;
      Api.business
        .listOwnerAccounts(this.credentials.user.id)
        .then(this.onOwnerAccountsSuccess)
        .catch((error: any) => {
          console.log('error loading accounts: ', error);
          this.loadingAccounts = false;
        });
    } catch (e) {
      this.loadingAccounts = false;
    }
  }

  selectAccountById(id: string) {
    this.selectedAccount = this.ownerAccounts.find((account) => account.id === id);
  }

  logOut() {
    if (this.shouldLogOut) {
      return;
    }

    this.shouldLogOut = true;
    this.clearCredentials();
    if (dashboardStore.routeChangeCallback) {
      dashboardStore.routeChangeCallback();
    }
  }

  onLogOut() {
    this.shouldLogOut = false;
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: 'CredentialStore',
      properties: ['credentials', 'isUserAdmin', 'ownerAccounts', 'selectedAccount'],
      storage: window.localStorage,
    }).then(() => {
      this.inited = true;
      dashboardStore.loadCredentialsCallback();
    });
  }
}

const credentialStore = new CredentialStore();
export default credentialStore;
