import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { greenTextColor } from '../main/react/App';

export interface TimeRangePreset {
  label: string;
  getStart: () => moment.Moment;
  getEnd: () => moment.Moment;
}

const presets: TimeRangePreset[] = [
  { label: 'Today', getStart: () => moment().startOf('day'), getEnd: () => moment().endOf('day') },
  {
    label: 'Yesterday',
    getStart: () => moment().subtract(1, 'days').startOf('day'),
    getEnd: () => moment().subtract(1, 'days').endOf('day'),
  },
  { label: 'This Week', getStart: () => moment().startOf('week'), getEnd: () => moment().endOf('week') },
  {
    label: 'Last Week',
    getStart: () => moment().subtract(1, 'week').startOf('week'),
    getEnd: () => moment().subtract(1, 'week').endOf('week'),
  },
  { label: 'This Month', getStart: () => moment().startOf('month'), getEnd: () => moment().endOf('month') },
  {
    label: 'Last Month',
    getStart: () => moment().subtract(1, 'month').startOf('month'),
    getEnd: () => moment().subtract(1, 'month').endOf('month'),
  },
  { label: 'This Year', getStart: () => moment().startOf('year'), getEnd: () => moment().endOf('year') },
  {
    label: 'Last Year',
    getStart: () => moment().subtract(1, 'year').startOf('year'),
    getEnd: () => moment().subtract(1, 'year').endOf('year'),
  },
];

export const FormTimeRangePresets = observer(({ updateInputValue }: any) => {
  const handleClick = (preset: TimeRangePreset) => {
    updateInputValue(preset.label, 'presetLabel');
    updateInputValue(preset.getStart(), 'fromDate');
    updateInputValue(preset.getEnd(), 'toDate');
  };

  const topRowPresets = presets.slice(0, 4);
  const bottomRowPresets = presets.slice(4);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2em', marginBottom: '2em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
        {topRowPresets.map((preset: TimeRangePreset) => (
          <div
            key={preset.label}
            onClick={() => handleClick(preset)}
            style={{ cursor: 'pointer', color: greenTextColor }}
          >
            {preset.label}
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {bottomRowPresets.map((preset) => (
          <div
            key={preset.label}
            onClick={() => handleClick(preset)}
            style={{ cursor: 'pointer', color: greenTextColor }}
          >
            {preset.label}
          </div>
        ))}
      </div>
    </div>
  );
});
