import { observer } from 'mobx-react-lite';
import { GridRowParams } from '@material-ui/data-grid';
import { IconButton, ListItem, Typography } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VMDataGrid from '../../components/main/VMDataGrid';
import TextLink from '../../components/other/TextLink';
import {
  CHARGER_DETAILS_TABS,
  chargerDetailsTabParameters,
  LOCATION_DETAILS_TABS,
  locationDetailsTabParameters,
} from '../../utils/routing';
import { ConnectorsList } from '../../components/other/Connectors';
import ActionsMenu from '../../components/other/ActionsMenu';
import { Charger, CHARGER_STATUS, CHARGER_TYPE } from '../../types/Charger';
import { ChargerIcon } from '../../components/other/ChargerIcon';
import { gridColumn } from '../../utils/gridUtils';
import { ChargerStatusBadge } from '../../components/other/CellBadges';
import { Api } from '../../api';
import { useProvider } from '../../state/providerStore';
import { greenTextColor } from '../../components/main/react/App';
import { copyTextToClipboard } from '../../utils/utils';

const onClickRemoveAssignedCharger = (charger: Charger, chargersProvider: any) => {
  Api.charge.deleteCharger(charger.id).then(chargersProvider.reloadResource);
};

export const onClickUnassignCharger = (charger: Charger, chargersProvider) => {
  Api.charge.unassignCharger(charger.id).then(chargersProvider.reloadResource);
};

const assignedChargerActions = (params: GridRowParams, chargersProvider) => (
  <ActionsMenu>
    <ListItem button onClick={() => onClickRemoveAssignedCharger(params.row as Charger, chargersProvider)}>
      <Typography style={{ width: '100%' }}>Remove</Typography>
    </ListItem>
    {params.row.status !== CHARGER_STATUS.UNASSIGNED && (
      <ListItem button onClick={() => onClickUnassignCharger(params.row as Charger, chargersProvider)}>
        <Typography style={{ width: '100%' }}>Unassign</Typography>
      </ListItem>
    )}
  </ActionsMenu>
);

export const getAllChargersColumns = (chargersProvider, sortableFields = []) => [
  gridColumn('chargerTypeIcon', {
    width: 60,
    headerName: '',
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const type = params.row.isPublic ? CHARGER_TYPE.PUBLIC : CHARGER_TYPE.DOMICIL;

      return <ChargerIcon type={type} />;
    },
  }),
  gridColumn('ocppId', {
    flex: 2,
    headerName: 'OCPP ID',
    sortableFields,
    renderCell: (params: GridRowParams) => (
      <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
        <TextLink
          to={chargerDetailsTabParameters(params.row.id, CHARGER_DETAILS_TABS.OVERVIEW)}
          label={params.row.ocppId}
        />
        <IconButton
          onClick={() => {
            copyTextToClipboard(params.row.ocppId);
          }}
        >
          <ContentCopyIcon style={{ marginLeft: '0.3em', color: greenTextColor, fontSize: 18 }} />
        </IconButton>
      </div>
    ),
  }),
  gridColumn('manufacturer', {
    flex: 2,
    headerName: 'manufacturer/model',
    sortableFields,
    valueGetter: (params: GridRowParams) => `${params.row.manufacturer} / ${params.row.model}`,
  }),
  gridColumn('address', {
    flex: 3,
    headerName: 'Location',
    sortableFields,
    renderCell: (params: GridRowParams) => (
      <TextLink
        to={locationDetailsTabParameters(params.row.locationId, LOCATION_DETAILS_TABS.OVERVIEW)}
        label={params.row.location?.name}
      />
    ),
  }),
  gridColumn('connectors', {
    flex: 2,
    sortableFields,
    renderCell: (params: GridRowParams) => <ConnectorsList connectors={params.row.connectors} />,
  }),
  gridColumn('totalPower', {
    flex: 1,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const maxPower = params.row.maxPower;
      return <p>{Number(maxPower).toFixed(2)} kW</p>;
    },
  }),
  gridColumn('availablePower', {
    flex: 1,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const availablePower = params.row.availablePower || 0;
      return <p>{Number(availablePower).toFixed(2)} kW</p>;
    },
  }),
  gridColumn('activePower', {
    flex: 1,
    sortableFields,
    renderCell: (params: GridRowParams) => {
      const activePower = params.row.activePower || 0;
      return <p>{Number(activePower).toFixed(2)} kW</p>;
    },
  }),
  gridColumn('status', {
    width: 150,
    sortableFields,
    align: 'center',
    renderCell: (params: GridRowParams) => <ChargerStatusBadge status={params.row.status} />,
  }),
  gridColumn('actions', {
    width: 150,
    renderCell: (params: GridRowParams) => assignedChargerActions(params, chargersProvider),
    align: 'center',
    headerAlign: 'center',
  }),
];

// Currently not shown at all
const AssignedChargers = observer(() => {
  const assignedChargersProvider = useProvider(Api.charge.listAssignedChargers);

  return (
    <div style={{ width: '100%' }}>
      <VMDataGrid
        rows={assignedChargersProvider.data}
        columns={getAllChargersColumns(assignedChargersProvider)}
        label="Assigned chargepoints"
      />
    </div>
  );
});

export default AssignedChargers;
