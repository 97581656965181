import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ChargerDetails from './ChargerDetails';
import { chargerDetailsTabRoute, CHARGERS_TABS, chargersTabRoute, route } from '../../utils/routing';
import dashboardStore from '../../state/dashboardStore';
import { AllChargers } from './AllChargers';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import { PAGES } from '../../components/main/MainComponent';
import credentialStore from '../../state/credentialStore';
import ConfigurationTemplates from './InitalisationConfig';
import OperationConfig from './OperationConfig';

const ChargerConfigurationsPage = observer(() => {
  const history = useHistory();

  useEffect(() => {
    dashboardStore.appBarLabel = 'Charge Point configurations';
  });

  if (!credentialStore.isUserAdmin) {
    if (!credentialStore.canViewUnassignedChargers()) {
      if (history.location.pathname !== route(PAGES.Chargers)) {
        return <Redirect to={route(PAGES.Chargers)} />;
      }
      return <AllChargers />;
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <ViziTabs tabs={[CHARGERS_TABS.INITIALISATION_CONFIG, CHARGERS_TABS.OPERATION_CONFIG]} basePath={chargersTabRoute()}>
        <ViziTab label={CHARGERS_TABS.INITIALISATION_CONFIG} linkTo={chargersTabRoute(CHARGERS_TABS.INITIALISATION_CONFIG)} />
        <ViziTab label={CHARGERS_TABS.OPERATION_CONFIG} linkTo={chargersTabRoute(CHARGERS_TABS.OPERATION_CONFIG)} />
      </ViziTabs>
      <Switch>
        <Route path={chargersTabRoute(CHARGERS_TABS.INITIALISATION_CONFIG)}>
          <ConfigurationTemplates />
        </Route>
        <Route path={chargersTabRoute(CHARGERS_TABS.OPERATION_CONFIG)}>
          <OperationConfig />
        </Route>
      </Switch>
    </div>
  );
});

export default ChargerConfigurationsPage;
