import { ListItem, Tooltip, Typography } from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import { FormInputTypes } from '../../utils/constants';
import { useStyles } from '../../styles/styles';
import TextLink from '../../components/other/TextLink';
import { CONNECTOR_STATUS } from '../../types/Connector';
import { ConnectorsList } from '../../components/other/Connectors';
import { Charger } from '../../types/Charger';
import ActionsMenu from '../../components/other/ActionsMenu';
import {
  catalogDetailsTabParameters,
  CHARGER_DETAILS_TABS,
  chargerDetailsTabParameters,
  companyDetailsParameters,
} from '../../utils/routing';
import StyledCard from '../../components/cards/StyledCard';
import useConnectorStatusStyles from '../../styles/connectorStatusStyles';
import { getCommonParams, useAccountFilter } from '../Analytics/useAnalyticsParams';
import StyledCardHeader, { HeaderButtonIcon } from '../../components/cards/StyledCardHeader';
import StyledCardContent from '../../components/cards/StyledCardContent';
import { drawerStore } from '../../state/drawerStore';
import VMDataGrid from '../../components/main/VMDataGrid';
import NameValueRow from '../../components/cards/CardRows/NameValueRow';
import { gridColumn } from '../../utils/gridUtils';
import { ChargerStatusBadge } from '../../components/other/CellBadges';
import { onClickUnassignCharger } from '../Chargers/AssignedChargers';
import { NameValueRowList } from '../../components/cards/CardRows/NameValueRowList';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import { TitleSubtitleRow } from '../../components/cards/CardRows/TitleSubtitleRow';
import ActionsList from '../../components/other/ActionsList';
import { formatToSloDate } from '../Sessions/SessionsPage';
import credentialStore from '../../state/credentialStore';
import { accountSelectOption } from '../../components/forms/AccountNameAutocomplete';
import { ReactComponent as UserBased } from '../../images/CatalogRules/user_based.svg';
import { ReactComponent as Timebased } from '../../images/CatalogRules/time_based.svg';
import { ReactComponent as Free } from '../../images/CatalogRules/free.svg';

const detailsRows = ['name', 'address', 'zip', 'city', 'state', 'country', 'id'];
const statusRows = ['createdAt', 'isVisible'];
const pricingRows = ['isFree', 'catalogId'];

export const UserBasedRuleIcon = () => <UserBased style={{ paddingTop: '0.5px', marginLeft: '15px' }} />;
export const TimeBasedRuleIcon = () => <Timebased style={{ marginLeft: '15px' }} />;
export const FreeRuleIcon = () => <Free />;

const editDetailsInputs = [
  { name: 'name', type: FormInputTypes.textField },
  { name: 'address', type: FormInputTypes.textField },
  { name: 'zip', type: FormInputTypes.textField },
  { name: 'city', type: FormInputTypes.textField },
  { name: 'state', type: FormInputTypes.textField },
  { name: 'country', type: FormInputTypes.textField },
];

const editStatusInputs = [
  { name: 'createdAt', type: FormInputTypes.textField },
  { name: 'isVisible', type: FormInputTypes.switch },
];

const chargerSelectOption = (charger: Charger) => ({
  optionName: charger.id,
  topRow: `${charger.ocppId}`,
  bottomRow: `${charger.manufacturer}, ${charger.serial}`,
});

const getChargerAssignInputs = (unassignedProvider) => [
  {
    name: 'chargerId',
    label: 'charger',
    type: FormInputTypes.multilineSelect,
    options: unassignedProvider?.data?.map((charger) => chargerSelectOption(charger)) || [],
  },
];

export const catalogSelectOption = (catalog) => ({
  optionName: catalog.id,
  topRow: `${catalog.name}`,
  bottomRow: `${catalog.id}`,
});

export const getFilteredCatalogOptions = (catalogsProvider, filteredCatalogIds) => {
  const options = [];
  catalogsProvider.data?.forEach((catalog) => {
    if (!filteredCatalogIds.includes(catalog.id)) {
      options.push(catalogSelectOption(catalog));
    }
  });

  return options;
};

export const getPriceListDropdownInput = (catalogsProvider, filteredCatalogIds) => ({
  name: 'catalogId',
  label: 'Price list',
  type: FormInputTypes.multilineSelect,
  options: getFilteredCatalogOptions(catalogsProvider, filteredCatalogIds),
});

export const getPriceDropdownInput = (catalogsProvider, filteredCatalogIds) =>
  getPriceListDropdownInput(catalogsProvider, filteredCatalogIds);

const getFilteredAccountOptions = (accountsProvider, filteredAccountIds) => {
  const options = [];
  accountsProvider.data?.forEach((account) => {
    if (!filteredAccountIds.includes(account.id)) {
      options.push(accountSelectOption(account));
    }
  });

  return options;
};

export const assignToAccountInputs = (nameAccountsProvider, assignedAccountsProvider) => [
  {
    name: 'accountId',
    label: 'Account',
    type: FormInputTypes.accountNameAutocomplete,
    inputChangeCallback: (inputValue) => {
      const urlParams: any = { name: inputValue };

      nameAccountsProvider.setOnFulfilled((data) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        drawerStore.setInputs(assignToAccountInputs(nameAccountsProvider, assignedAccountsProvider));
        drawerStore.setValues({ ...drawerStore.values });
      });

      nameAccountsProvider.setRequest(() => Api.business.listAccounts(urlParams));
      nameAccountsProvider.reloadResource();
    },
    options: getFilteredAccountOptions(
      nameAccountsProvider,
      assignedAccountsProvider.data?.map((el) => el.accountId),
    ),
  },
];

const LocationDetailsOverview = observer(() => {
  const { locationid } = useParams<{ locationid: string }>();

  const classes = useStyles();
  const connectorStatusStyles = useConnectorStatusStyles();

  const nameAccountsProvider = useProvider(Api.business.listAccounts, undefined, false);
  const locationProvider = useProvider(() => Api.charge.getLocation(locationid));
  const catalogsProvider = useProvider(Api.catalog.listCatalogs, undefined, false);
  const assignedProvider = useProvider(undefined, undefined, false);
  const unassignedProvider = useProvider(undefined, undefined, false);
  const locationCatalogsProvider = useProvider(() => Api.charge.listCatalogsForLocation(locationid));
  const locationAccountsProvider = useProvider(() => Api.charge.listAccountsForLocation(locationid), undefined, false);

  const [rows, setRows] = useState<any[]>([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [mappedData, setMappedData] = useState<any[]>([]);
  const accountFilter = useAccountFilter();
  const today = new Date().toISOString().split('T')[0];
  const [date, setDate] = useState<string>(today);

  const [catalogs, setCatalogs] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);

  const orderedCatalogs = catalogs
    .map((catalog) => {
      const parentCatalog = catalogs.find((parent) => parent.id === catalog.parentId);
      const parentName = parentCatalog ? parentCatalog.name : null;
      return { ...catalog, priority: catalog.priority || 0, parentName };
    })
    .sort((a, b) => b.priority - a.priority)
    .map((catalog, index) => ({ ...catalog, order: catalogs.length - index }));

  const handleRowClick = (params) => {
    setEditingRowId(params.id);
  };

  const handleDateChange = (newDate: string) => {
    setDate(newDate);
  };

  interface HourValue {
    hour: number;
    status: string;
  }

  interface MappedDayItem {
    connectorId?: string;
    hourValues?: Map<number, HourValue>;
    items?: any;
  }

  const convertTimeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const getTimeIntervalStatus = (startTime: string, endTime: string, status: string) => {
    const startMinutes = convertTimeToMinutes(startTime);
    const endMinutes = convertTimeToMinutes(endTime);

    const intervals: { time: number; status: string }[] = [];

    for (let time = startMinutes; time <= endMinutes; time += 15) {
      intervals.push({ time, status });
    }

    return intervals;
  };

  const formatItemTime = (item, index, rowLength) => {
    if (index !== 0) {
      item.startedAt = moment.utc(item.startedAt, 'HH:mm').local().format('HH:mm');
    }
    if (index !== rowLength - 1) {
      item.endedAt = moment.utc(item.endedAt, 'HH:mm').local().format('HH:mm');
    }
  };

  const fillMissingHours = (mappedDayItem, lastHour) => {
    for (let hour = 0; hour <= lastHour; hour++) {
      if (!mappedDayItem.hourValues[hour]) {
        mappedDayItem.hourValues[hour] = [];
      }
    }
  };

  const fillRemainingHours = (mappedDayItem, lastItem, isToday) => {
    const startHour = parseInt(moment(lastItem.startedAt, 'HH:mm').format('HH'), 10) + 1;
    const endHour = isToday ? moment().hour() + 1 : 24;

    for (let hour = startHour; hour < endHour; hour++) {
      if (!mappedDayItem.hourValues[hour]) {
        mappedDayItem.hourValues[hour] = [];
      }
      mappedDayItem.hourValues[hour].push({
        hour,
        ...lastItem,
      });
    }
  };

  const mapRowItemsToHourValues = (row, mappedDayItem) => {
    let lastHour = -1;

    row.items?.forEach((item, index) => {
      const startHour = parseInt(item.startedAt.split(':')[0], 10);
      if (!mappedDayItem.hourValues[startHour]) {
        mappedDayItem.hourValues[startHour] = [];
      }

      mappedDayItem.hourValues[startHour].push({
        hour: startHour,
        ...item,
      });

      lastHour = Math.max(lastHour, startHour);

      if (index !== row.items.length - 1) {
        const nextStartHour = parseInt(row.items[index + 1].startedAt.split(':')[0], 10);
        for (let hour = startHour + 1; hour < nextStartHour; hour++) {
          if (!mappedDayItem.hourValues[hour]) {
            mappedDayItem.hourValues[hour] = [];
            mappedDayItem.hourValues[hour].push({
              hour,
              ...item,
            });
          }
        }
      }
    });

    return lastHour;
  };

  const setMappedDataFromResponse = (res) => {
    const resRows = res.items;

    const newMappedData = [];
    const isToday = moment(res.date).isSame(moment(), 'day');

    resRows?.forEach((row) => {
      const mappedDayItem = {
        connectorId: row.connectorId,
        hourValues: new Map(),
        items: row.items,
      };

      row.items?.forEach((item, index) => formatItemTime(item, index, row.items.length));

      if (isToday && row.items?.length > 0) {
        const lastItem = row.items[row.items.length - 1];
        lastItem.endedAt = moment.utc(lastItem.endedAt, 'HH:mm').local().format('HH:mm');
      }

      const lastHour = mapRowItemsToHourValues(row, mappedDayItem);

      fillMissingHours(mappedDayItem, lastHour);

      if (row.items?.length > 0) {
        const lastItem = row.items[row.items.length - 1];
        fillRemainingHours(mappedDayItem, lastItem, isToday);
      }

      newMappedData.push(mappedDayItem);
    });

    setMappedData(newMappedData);
  };

  const loadStatItems = async () => {
    try {
      if (assignedProvider.data === undefined) return;
      const allResponses = await Promise.all(
        assignedProvider.data.map(async (chargePoint) => {
          const params = getCommonParams(accountFilter);
          const res = await Api.charge.getChargerWithParams(chargePoint.id, params);
          const connectors = res.data?.data?.connectors;

          if (connectors && connectors.length > 0) {
            const resconnector = await Api.charge.getConnectorsStatus(connectors, date);
            return resconnector.data?.data;
          }

          return null;
        }),
      );

      const allItems = allResponses.filter((response) => response !== null).flat();
      const formattedResponse = {
        data: {
          date,
          count: allItems.length,
          items: allItems,
        },
      };
      setMappedDataFromResponse(formattedResponse.data);
    } catch (error) {
      console.error('Error loading connector statuses:', error);
    }
  };

  const getRows = () => {
    const newRows = [];

    mappedData.forEach((rowItem) => {
      const newRow = {
        id: rowItem.connectorId,
        connectorId: rowItem.connectorId,
        items: rowItem.items,
        hourValues: rowItem.hourValues,
      };
      newRows.push(newRow);
    });

    return newRows;
  };

  useEffect(() => {
    loadStatItems();
  }, [date, assignedProvider.data]);

  useEffect(() => {
    setRows(getRows());
  }, [mappedData]);

  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      nameAccountsProvider.reloadResource();
      catalogsProvider.reloadResource();
      locationAccountsProvider.reloadResource();

      assignedProvider.setRequest(() => Api.charge.listAssignedChargersFromLocation(locationid));
      unassignedProvider.setRequest(Api.charge.listUnassignedChargers);
      unassignedProvider.reloadResource();
    } else {
      assignedProvider.setRequest(() =>
        Api.charge.listChargersForAccount(credentialStore.getAccountId(), { locationId: locationid }),
      );
      unassignedProvider.setRequest(() =>
        Api.charge.listChargersForAccount(credentialStore.getAccountId(), { status: 'unassigned' }),
      );
    }

    assignedProvider.reloadResource();

    if (credentialStore.isUserAdmin || credentialStore.canViewUnassignedChargers()) {
      unassignedProvider.reloadResource();
    }
  }, [credentialStore.selectedAccount]);

  const getItemColumns = () => {
    const columns = [
      gridColumn('occp id', {
        flex: 4,
        minWidth: 100,
        renderCell: (params: GridRowParams) => {
          const { connectorId } = params.row;
          return (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {connectorId}
            </div>
          );
        },
      }),
    ];

    for (let i = 0; i <= 23; i++) {
      columns.push(
        gridColumn(`hour_${i}`, {
          flex: 1,
          minWidth: 10,
          headerName: `${i}`,
          textAlign: 'left',
          hide: false,
          renderCell: (params: GridRowParams) => {
            const hourValues = params.row.hourValues[i] || [];
            const totalMinutesInHour = 60;

            if (hourValues.length === 1) {
              const statusValue = hourValues[0]?.status || null;
              const colorLookup = {
                [CONNECTOR_STATUS.MAINTENANCE]: '#d9b114',
                [CONNECTOR_STATUS.OFFLINE]: 'lightgrey',
                [CONNECTOR_STATUS.ERROR]: 'red',
                [CONNECTOR_STATUS.AVAILABLE]: '#3a3a3a',
                [CONNECTOR_STATUS.IN_USE]: 'green',
                [CONNECTOR_STATUS.READY_TO_CHARGE]: '#14b8d9',
                default: 'white',
              };
              const color = colorLookup[statusValue] || colorLookup.default;

              const tooltipContent = (
                <Typography variant="body1">
                  <strong>{statusValue?.toUpperCase()}</strong> <br />
                  {moment.utc(hourValues[0].startedAt, 'HH:mm').format('HH:mm')}-{moment.utc(hourValues[0].endedAt, 'HH:mm').format('HH:mm')}
                </Typography>
              );

              return (
                <Tooltip title={tooltipContent} enterDelay={100} enterNextDelay={100}>
                  <div
                    style={{
                      height: '80%',
                      width: '100%',
                      display: 'flex',
                      padding: '0px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: color,
                    }}
                    onClick={() => handleRowClick(params)}
                  />
                </Tooltip>
              );
            }

            return (
              <div style={{ height: '80%', width: '100%', display: 'flex', padding: '0px' }}>
                {hourValues.slice(0, 5).map((item, index) => {
                  const totalItems = Math.min(hourValues.length, 5);
                  const widthPercentage = totalItems > 0 ? 100 / totalItems : 0;

                  const colorLookup = {
                    [CONNECTOR_STATUS.MAINTENANCE]: '#d9b114',
                    [CONNECTOR_STATUS.OFFLINE]: 'lightgrey',
                    [CONNECTOR_STATUS.ERROR]: 'red',
                    [CONNECTOR_STATUS.AVAILABLE]: '#3a3a3a',
                    [CONNECTOR_STATUS.IN_USE]: 'green',
                    ready_to_charge: '#14b8d9',
                    default: 'white',
                  };

                  const color = colorLookup[item.status] || colorLookup.default;
                  const tooltipContent = (
                    <div>
                      {hourValues.map((fullItem, fullIndex) => (
                        <Typography key={fullIndex} variant="body2" style={{ marginBottom: '4px' }}>
                          <strong>{fullItem.status.toUpperCase()}</strong> <br />
                          {fullItem.startedAt} - {fullItem.endedAt}
                        </Typography>
                      ))}
                    </div>
                  );

                  return (
                    <Tooltip
                      key={index}
                      title={tooltipContent}
                      enterDelay={100}
                      enterNextDelay={100}
                    >
                      <div
                        style={{
                          height: '100%',
                          width: `${widthPercentage}%`,
                          display: 'flex',
                          padding: '0px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: color,
                        }}
                        onClick={() => handleRowClick(params)}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            );
          },
        }),
      );
    }
    return columns;
  };

  const fetchCatalogData = async (catalogId) => {
    const resp = await Api.catalog.getCatalog(catalogId);
    const isUserRestricted = await Api.catalog.getUserRestriction(catalogId);
    const isTimeRestricted = await Api.catalog.getTimeRestriction(catalogId);

    const catalogData = {
      ...resp.data.data,
      isUserRestricted: isUserRestricted.data.data.enabled,
      isTimeRestricted: isTimeRestricted.data.data.enabled,
    };

    catalogData.priority =
      (catalogData.isUserRestricted ? 1000 : 0) +
      (catalogData.isTimeRestricted ? 100 : 0) +
      (catalogData.isFree ? 10 : 0);

    return catalogData;
  };

  const fetchAllCatalogs = async () => {
    const promises = locationCatalogsProvider.data.map((catalog) => fetchCatalogData(catalog.catalogId));
    const locationCatalogs = await Promise.all(promises);
    setCatalogs(locationCatalogs);
  };

  const fetchAccountData = async (accountId) => {
    const resp = await Api.business.getAccount(accountId);
    return resp.data.data;
  };

  const fetchAllAccounts = async () => {
    const promises = locationAccountsProvider.data.map((account) => fetchAccountData(account.accountId));
    const locationAccounts = await Promise.all(promises);
    setAccounts(locationAccounts);
  };

  useEffect(() => {
    if (credentialStore.isUserAdmin) {
      if (locationProvider.data) {
        locationCatalogsProvider.reloadResource();
      }
    }
  }, [locationProvider.data]);

  useEffect(() => {
    if (locationCatalogsProvider.data !== undefined) {
      fetchAllCatalogs();
    }
  }, [locationCatalogsProvider.data]);

  useEffect(() => {
    if (locationAccountsProvider.data !== undefined) {
      fetchAllAccounts();
    }
  }, [locationAccountsProvider.data]);

  const confirmEditDetails = (values) => {
    values = _.pick(values, detailsRows);
    Api.charge.patchLocation(locationid, values).then(locationProvider.reloadResource);
  };

  const confirmEditStatus = (values) => {
    values = _.pick(values, statusRows);
    Api.charge.patchLocation(locationid, values).then(locationProvider.reloadResource);
  };

  const confirmEditPricing = (values) => {
    values = _.pick(values, pricingRows);
    Api.charge.patchLocation(locationid, values).then(locationProvider.reloadResource);
  };

  const onClickEditDetails = () => {
    drawerStore.openDrawer({
      label: 'Edit details',
      inputs: editDetailsInputs,
      clickCallback: () => confirmEditDetails(toJS(drawerStore.values)),
    });

    drawerStore.setValues(locationProvider.data);
  };

  const onClickEditStatus = () => {
    drawerStore.openDrawer({
      label: 'Edit status',
      inputs: editStatusInputs,
      clickCallback: () => confirmEditStatus(toJS(drawerStore.values)),
    });

    drawerStore.setValues(locationProvider.data);
  };

  const onClickEditPricing = () => {
    const pricingInputs = [{ name: 'isFree', label: 'Is Free', type: FormInputTypes.switch }];

    drawerStore.openDrawer({
      label: 'Edit price list',
      inputs: pricingInputs,
      clickCallback: () => confirmEditPricing(toJS(drawerStore.values)),
    });

    drawerStore.setValues(locationProvider.data);
  };

  const confirmAddCatalogToLocation = (values) => {
    Api.catalog.getCatalog(values.catalogId).then((data) => {
      const catalogIds = [values.catalogId, ...data?.data?.data?.children.map((child) => child.id)];
      Api.charge.addCatalogWithChildrenToLocation(locationid, catalogIds).then(locationCatalogsProvider.reloadResource);
    });
  };

  const onClickAddCatalogToLocation = () => {
    drawerStore.openDrawer({
      label: 'Add price list',
      inputs: [
        getPriceListDropdownInput(
          catalogsProvider,
          locationCatalogsProvider.data.map((el) => el.catalogId),
        ),
      ],
      clickCallback: () => confirmAddCatalogToLocation(toJS(drawerStore.values)),
    });
  };

  const confirmAddCharger = (values) => {
    Api.charge.assignChargerToLocation(values.chargerId, locationid).then(assignedProvider.reloadResource);
  };

  const onClickAssignCharger = () => {
    drawerStore.openDrawer({
      label: 'Add charger',
      inputs: getChargerAssignInputs(unassignedProvider),
      clickCallback: () => confirmAddCharger(toJS(drawerStore.values)),
    });
  };

  const chargerActions = (params: GridRowParams) => (
    <ActionsMenu>
      <ListItem button onClick={() => onClickUnassignCharger(params.row as Charger, assignedProvider)}>
        <Typography style={{ width: '100%' }}>Remove</Typography>
      </ListItem>
    </ActionsMenu>
  );

  const onClickRemoveCatalogFromLocation = (catalogId) => {
    const childrenIds = orderedCatalogs
      .filter((catalog) => catalog.parentId === catalogId)
      .map((catalog) => catalog.id);

    const catalogIds = [...childrenIds, catalogId];
    Api.charge
      .removeCatalogWithChildrenFromLocation(locationid, catalogIds)
      .then(locationCatalogsProvider.reloadResource);
  };

  const confirmAssignToAccount = () => {
    Api.charge
      .addAccountToLocation(locationid, drawerStore.values.accountId)
      .then(locationAccountsProvider.reloadResource);
  };

  const onClickAssignedTo = () => {
    drawerStore.openDrawer({
      label: 'Assign to account',
      inputs: assignToAccountInputs(nameAccountsProvider, locationAccountsProvider),
      clickCallback: confirmAssignToAccount,
    });
  };

  const onClickRemoveAccountFromLocation = (accountId: string) => {
    Api.charge.removeAccountFromLocation(locationid, accountId).then(locationAccountsProvider.reloadResource);
  };

  const chargersColumns = [
    gridColumn('ocppId', {
      flex: 2,
      headerName: 'OCCP ID',
      renderCell: (params: GridRowParams) => (
        <TextLink
          to={chargerDetailsTabParameters(params.row.id, CHARGER_DETAILS_TABS.OVERVIEW)}
          label={params.row.ocppId}
        />
      ),
    }),
    gridColumn('manufacturer/model', {
      flex: 2,
      valueGetter: (params: GridRowParams) => `${params.row.manufacturer} / ${params.row.model}`,
    }),
    gridColumn('connectors', {
      flex: 2,
      headerName: 'Connectors',
      renderCell: (params: GridRowParams) => <ConnectorsList connectors={params.row.connectors} />,
    }),
    gridColumn('totalPower', { headerName: 'Total power', flex: 1 }),
    gridColumn('availablePower', { headerName: 'Available power', flex: 1 }),
    gridColumn('instantPower', { headerName: 'Active power', flex: 1 }),
    gridColumn('status', {
      width: 100,
      align: 'center',
      renderCell: (params: GridRowParams) => <ChargerStatusBadge status={params.row.status} />,
    }),
    gridColumn('actions', {
      width: 150,
      renderCell: (params: GridRowParams) => chargerActions(params),
      align: 'center',
      headerAlign: 'center',
    }),
  ];

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.cardRows}>
        <div className={classes.leftCardRow}>
          <StyledCard>
            <StyledCardHeader title="Details" onClick={onClickEditDetails} headerButtonIcon={HeaderButtonIcon.edit} />
            <StyledCardContent>
              <NameValueRowList rows={detailsRows} values={locationProvider.data} />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="Status" onClick={onClickEditStatus} headerButtonIcon={HeaderButtonIcon.edit} />
            <StyledCardContent>
              <NameValueRowList
                rows={statusRows}
                values={{ ...locationProvider.data, createdAt: formatToSloDate(locationProvider.data?.createdAt) }}
              />
            </StyledCardContent>
          </StyledCard>
        </div>
        <div className={classes.rightCardRow}>
          <StyledCard>
            <StyledCardHeader
              title="Pricing Configuration"
              onClick={credentialStore.isUserAdmin ? onClickEditPricing : undefined}
              headerButtonIcon={HeaderButtonIcon.edit}
            />
            <StyledCardContent>
              <NameValueRow name="isFree" value={locationProvider.data?.isFree} />
            </StyledCardContent>
          </StyledCard>

          {!locationProvider.data?.isFree && (
            <StyledCard>
              <StyledCardHeader
                title="Price lists"
                onClick={credentialStore.isUserAdmin ? onClickAddCatalogToLocation : undefined}
                headerButtonIcon={HeaderButtonIcon.add}
              />
              <StyledCardContent>
                {orderedCatalogs?.map((catalog) => {
                  const parentNameDisplay = catalog.parentId ? ` (${catalog.parentName})` : '';
                  const titleLink = catalog.parentId
                    ? catalogDetailsTabParameters(catalog.parentId, catalog.id)
                    : catalogDetailsTabParameters(catalog.id);
                  return (
                    <TitleSubtitleRow
                      key={catalog.id}
                      title={catalog.name + parentNameDisplay}
                      subtitle={`${catalog?.productsCount} products`}
                      order={catalog.order}
                      actions={
                        !catalog.parentId && (
                          <ActionsList
                            actions={[
                              { label: 'Remove', callback: () => onClickRemoveCatalogFromLocation(catalog.id) },
                            ]}
                          />
                        )
                      }
                      titleLink={credentialStore.isUserAdmin ? titleLink : undefined}
                      additionalContent={
                        <>
                          {catalog.isFree && <FreeRuleIcon />}
                          {catalog.isTimeRestricted && <TimeBasedRuleIcon />}
                          {catalog.isUserRestricted && <UserBasedRuleIcon />}
                        </>
                      }
                    />
                  );
                })}
              </StyledCardContent>
            </StyledCard>
          )}

          {credentialStore.isUserAdmin && (
            <StyledCard>
              <StyledCardHeader
                title="Assigned to"
                onClick={onClickAssignedTo}
                headerButtonIcon={HeaderButtonIcon.add}
              />
              <StyledCardContent>
                {accounts?.map((account) => (
                  <TitleSubtitleRow
                    key={account.id}
                    title={account.name}
                    subtitle={account.email}
                    actions={
                      <ActionsList
                        actions={[{ label: 'Remove', callback: () => onClickRemoveAccountFromLocation(account.id) }]}
                      />
                    }
                    titleLink={companyDetailsParameters(account.id)}
                  />
                ))}
              </StyledCardContent>
            </StyledCard>
          )}
        </div>
      </div>

      <VMDataGrid
        rows={assignedProvider.data}
        columns={chargersColumns}
        label="Charge points"
        onClickAdd={credentialStore.isUserAdmin ? onClickAssignCharger : undefined}
      />
      <br />

      <div className={connectorStatusStyles.root}>
        <VMDataGrid
          rows={rows}
          columns={getItemColumns()}
          label="Connector status"
          pageSize={60}
          onDateChangeCallBack={handleDateChange}
          date={date}
        />
      </div>
    </div>
  );
});

export default LocationDetailsOverview;
