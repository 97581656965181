import { Card } from '@material-ui/core';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStyles } from '../../styles/styles';

const StyledCard = observer(({ children }) => {
  const classes = useStyles();

  return <Card className={classes.styledCardWithMargin}>{children}</Card>;
});

export default StyledCard;
