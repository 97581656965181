import { CONNECTOR_STATUS } from '../types/Connector';
import { arrayToCommaSeparatedString, baseUrls, createApi } from './apiUtils';

const chargeApi = {
  v1: createApi({ baseURL: `${baseUrls.charging}/admin/v1` }),
  v2: createApi({ baseURL: `${baseUrls.charging}/admin/v2` }),
  appV1: createApi({ baseURL: `${baseUrls.charging}/app/v1` }),
};

const CHARGE_URLS = {
  LOCATIONS: 'locations',
  ALL_ADMIN_LOCATIONS: 'locations/all',
  ALL_ACCOUNT_LOCATIONS: (accountId: string) => `accounts/${accountId}/locations/all`,
  ACCOUNT_LOCATIONS: (accountId: string) => `accounts/${accountId}/locations`,
  LOCATION: (locationId) => `locations/${locationId}`,
  CHARGE_POINTS: 'charge-points',
  UNKNOWN_CHARGE_POINTS: 'unknown-charge-points',
  SYNC_UNKNOWN_CHARGE_POINTS: 'unknown-charge-points/sync-unknown',
  ACCOUNT_CHARGE_POINTS: (accountId: string) => `accounts/${accountId}/charge-points`,
  CHARGE_POINT: (chargerId) => `charge-points/${chargerId}`,
  UNKNOWN_CHARGE_POINT: (unknownChargerId) => `unknown-charge-points/${unknownChargerId}`,
  CHARGE_POINT_ACTIVITY: (chargerId) => `charge-points/${chargerId}/activity`,
  SESSIONS: 'charge-sessions',
  LOCATION_SESSIONS: (locationId: string) => `locations/${locationId}/charge-sessions`,
  CHARGER_SESSIONS: (chargerId: string) => `charge-points/${chargerId}/charge-sessions`,
  ACCOUNT_SESSIONS: (accountId: string) => `accounts/${accountId}/charge-sessions`,
  SESSION: (sessionId: string) => `charge-sessions/${sessionId}`,
  SESSION_METER_VALUES: (sessionId: string) => `charge-sessions/${sessionId}/meter-values`,
  EVENTS: (sessionId: string) => `charge-sessions/${sessionId}/events`,
  CHARGE_CONNECTORS: 'charge-connectors',
  CHARGE_CONNECTOR: (connectorId: string) => `charge-connectors/${connectorId}`,
  CHARGE_CONNECTOR_STATUS: 'charge-connectors/status',
  ALL_ACTIVITIES: 'locations/activities',
  USER_SESSIONS: (userId: string) => `users/${userId}/sessions`,
  DOMICIL_CONNECTORS: (userId: string) => `users/${userId}/domicil-connectors`,
  LOCATION_ACTIVITIES: (locationId: string) => `locations/${locationId}/activities`,
  LOCATION_IMAGES: (locationId: string) => `locations/${locationId}/images`,
  LOCATION_IMAGE_URLS: (locationId: string) => `locations/${locationId}/image-urls`,
  IMAGE: (imageId: string) => `images/${imageId}`,
  REPORTS: 'reports',
  INITALISATION_CONFIGS: 'configuration/initalisation-config',
  INITALISATION_CONFIG_EDIT: 'configuration/initalisation-config-edit',
  OPERATION_CONFIGS: 'configuration/operation-configs',
  CHARGE_POINT_CONFIGURATION: 'configuration/charge-point-configuration',
  CONFIGURATION_REQUEST: (chargePointId: string) => `charge-points/${chargePointId}/request-configuration`,
  CONFIGURATION_DETAIL_REQUEST: (taskId: string) => `charge-points/${taskId}/get-configuration`,
  INITALISATION_CONFIG: (InitalisationConfigId: string) =>
    `configuration/initalisation-config/${InitalisationConfigId}`,
  OPERATION_CONFIG: (operationConfigId: string) => `configuration/operation-configs/${operationConfigId}`,
  ACCOUNT_REPORTS: (accountId: string) => `accounts/${accountId}/reports`,
  IS_DUPLICATED_OCPP_ID: (ocppId: string) => `charge-points/${ocppId}/is-duplicated-ocpp-id`,
  LOCATION_CATALOGS: (locationId: string) => `locations/${locationId}/catalogs`,
  LOCATION_CATALOGS_REMOVE_ALL: (locationId: string) => `locations/${locationId}/catalogs/delete-all`,
  LOCATION_CATALOG: (locationId: string, catalogId: string) => `locations/${locationId}/catalogs/${catalogId}`,
  CHARGE_POINT_CATALOGS: (chargerId: string) => `charge-points/${chargerId}/catalogs`,
  CHARGE_POINT_CATALOG: (chargerId: string, catalogId: string) => `charge-points/${chargerId}/catalogs/${catalogId}`,
  CHARGE_POINT_CATALOG_REMOVE_ALL: (chargerId: string) => `charge-points/${chargerId}/catalogs/delete-all`,
  AUTHORIZATION_TAGS: 'authorization-tags',
  USER_AUTHORIZATION_TAGS: (userId: string) => `users/${userId}/authorization-tags`,
  AUTHORIZATION_TAG: (tagId: string) => `authorization-tags/${tagId}`,
  AUTHORIZATION_ATTEMPTS: (locationId: string) => `locations/${locationId}/authorizations`,
  LOCATION_ACCOUNTS: (locationId: string) => `locations/${locationId}/accounts`,
  LOCATION_ACCOUNT: (locationId: string, accountId: string) => `locations/${locationId}/accounts/${accountId}`,
  CHARGER_ACCOUNTS: (chargerId: string) => `charge-points/${chargerId}/accounts`,
  CHARGER_ACCOUNT: (chargerId: string, accountId: string) => `charge-points/${chargerId}/accounts/${accountId}`,
  STATS_SUMMARY: 'stats/summary',
  STATS_LOCATIONS: 'stats/locations',
  STATS_CHARGE_POINTS: 'stats/charge-points',
  STATS_USERS: 'stats/users',
  STATS_ACCOUNTS: 'stats/accounts',
  STATS_TAGS: 'stats/tags',
  STAT_LOCATIONS_ITEMS: (locationId: string) => `stats/locations/${locationId}/items`,
  STAT_CHARGE_POINTS_ITEMS: (chargerId: string) => `stats/charge-points/${chargerId}/items`,
  STAT_USERS_ITEMS: (userId: string) => `stats/users/${userId}/items`,
  STAT_ACCOUNTS_ITEMS: (accountId: string) => `stats/accounts/${accountId}/items`,
  STAT_TAGS_ITEMS: (tagId: string) => `stats/tags/${tagId}/items`,
  TRIGGER_COMMAND: (chargerId: string) => `charge-points/${chargerId}/trigger-command`,
  CATALOGS: (catalogId: string) => `catalogs/${catalogId}`,
};

export interface LocationFilters {
  name?: string;
  tags?: string[];
}

export const listLocations = (locationFilters?: LocationFilters, params?: any) => {
  const defaultParams: any = { sortBy: 'createdAt' };

  const filteredParams = { ...defaultParams };
  if (locationFilters?.name) {
    filteredParams.name = locationFilters.name;
  }
  if (locationFilters?.tags) {
    filteredParams.tags = locationFilters.tags;
  }

  const finalParams = { ...filteredParams, ...params };

  return chargeApi.v1.get(CHARGE_URLS.LOCATIONS, { params: finalParams });
};

export const listAppLocations = () => {
  const params: any = { isVisible: true };
  return chargeApi.appV1.get(CHARGE_URLS.LOCATIONS, { params });
};

export const listLocationsForAccount = (accountId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.ACCOUNT_LOCATIONS(accountId), { params });

export const adminMapLocations = () => chargeApi.v1.get(CHARGE_URLS.ALL_ADMIN_LOCATIONS);

export const accountMapLocations = (accountId: string) =>
  chargeApi.v1.get(CHARGE_URLS.ALL_ACCOUNT_LOCATIONS(accountId));

export const createLocation = (location) => chargeApi.v1.post(CHARGE_URLS.LOCATIONS, location);

export const deleteLocation = (locationId: string) => chargeApi.v1.delete(CHARGE_URLS.LOCATION(locationId));

export const deleteSoftCatalogEntries = (catalogId: string) => chargeApi.v1.delete(CHARGE_URLS.CATALOGS(catalogId));

export const ownerCreateLocation = (location, accountId) =>
  chargeApi.v1.post(CHARGE_URLS.ACCOUNT_LOCATIONS(accountId), location);

export const patchLocation = (locationId: string, locationData) =>
  chargeApi.v1.patch(CHARGE_URLS.LOCATION(locationId), locationData);

export const getLocation = (locationId: string) => chargeApi.v1.get(CHARGE_URLS.LOCATION(locationId));

export const listChargers = (params?: any) => {
  const defaultParams: any = { skip: 0 };
  const filteredParams = { ...defaultParams, ...params };

  return chargeApi.v1.get(CHARGE_URLS.CHARGE_POINTS, { params: filteredParams });
};

export const listChargersForAccount = (accountId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.ACCOUNT_CHARGE_POINTS(accountId), { params });

export const listAssignedChargers = () =>
  chargeApi.v1.get(CHARGE_URLS.CHARGE_POINTS, { params: { status: 'assigned' } });

export const listAssignedChargersFromLocation = (locationId: string) =>
  chargeApi.v1.get(CHARGE_URLS.CHARGE_POINTS, { params: { status: 'assigned', locationIds: locationId } });

export const listUnassignedChargers = (params?: any) => {
  const defaultParams: any = { status: 'unassigned', skip: 0, take: 20 };
  const filteredParams = { ...defaultParams, ...params };

  return chargeApi.v1.get(CHARGE_URLS.CHARGE_POINTS, { params: filteredParams });
};

export const listChargersWithIssues = (params?: any) => {
  const defaultParams: any = { connectorStatus: CONNECTOR_STATUS.MAINTENANCE, skip: 0, take: 20 };
  const filteredParams = { ...defaultParams, ...params };

  return chargeApi.v1.get(CHARGE_URLS.CHARGE_POINTS, { params: filteredParams });
};

export const createoperationConfig = (config) => chargeApi.v1.post(CHARGE_URLS.OPERATION_CONFIGS, config);

export const createInitalisationConfig = (configuration) =>
  chargeApi.v1.post(CHARGE_URLS.INITALISATION_CONFIGS, configuration);

export const editInitalisationConfig = (configuration) =>
  chargeApi.v1.post(CHARGE_URLS.INITALISATION_CONFIG_EDIT, configuration);

export const createChargePointConfiguration = (configuration) =>
  chargeApi.v1.post(CHARGE_URLS.CHARGE_POINT_CONFIGURATION, configuration);

export const listInitalisationConfigs = (params?: any) => {
  const defaultParams: any = { skip: 0, take: 20 };
  const filteredParams = { ...defaultParams, ...params };

  return chargeApi.v1.get(CHARGE_URLS.INITALISATION_CONFIGS, { params: filteredParams });
};

export const listoperationConfigs = (params?: any) => {
  const defaultParams: any = { skip: 0, take: 20 };
  const filteredParams = { ...defaultParams, ...params };

  return chargeApi.v1.get(CHARGE_URLS.OPERATION_CONFIGS, { params: filteredParams });
};

export const getConfigurationRequest = (chargePointId: string) =>
  chargeApi.v1.post(CHARGE_URLS.CONFIGURATION_REQUEST(chargePointId));

export const getConfigurationRequestDetail = (chargePointId: string, taskId: string) =>
  chargeApi.v1.post(`${CHARGE_URLS.CONFIGURATION_DETAIL_REQUEST(chargePointId)}`, { taskId });

export const getChargePointConfigurationRequest = (params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.CHARGE_POINT_CONFIGURATION, { params });

export const getConfigurationOperationRequest = (params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.OPERATION_CONFIGS, { params });

export const syncUnknownChargePoint = () => chargeApi.v1.post(CHARGE_URLS.SYNC_UNKNOWN_CHARGE_POINTS);

export const listChargersUnknown = (params?: any) => {
  const defaultParams: any = { skip: 0, take: 20 };
  const filteredParams = { ...defaultParams, ...params };

  return chargeApi.v1.get(CHARGE_URLS.UNKNOWN_CHARGE_POINTS, { params: filteredParams });
};

export const listUnassignedChargersForAccount = (accountId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.ACCOUNT_CHARGE_POINTS(accountId), { params: { status: 'unassigned', ...params } });

export const getCharger = (chargerId: string) => chargeApi.v1.get(CHARGE_URLS.CHARGE_POINT(chargerId));

export const getChargerWithParams = (chargerId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.CHARGE_POINT(chargerId), { params });

export const isDuplicatedOcppId = (ocppId: string) => chargeApi.v1.get(CHARGE_URLS.IS_DUPLICATED_OCPP_ID(ocppId));

export const getChargerActivity = (chargerId: string) => chargeApi.v1.get(CHARGE_URLS.CHARGE_POINT_ACTIVITY(chargerId));

export const patchCharger = (chargerId: string, chargerData) =>
  chargeApi.v1.patch(CHARGE_URLS.CHARGE_POINT(chargerId), chargerData);

export const assignChargerToLocation = (chargerId: string, locationId: string, name?: string) =>
  chargeApi.v1.post(`${CHARGE_URLS.CHARGE_POINT(chargerId)}/assign`, { type: 'public', locationId, name });

export const assignChargerToUser = (chargerId: string, userId: string, name?: string) =>
  chargeApi.v1.post(`${CHARGE_URLS.CHARGE_POINT(chargerId)}/assign`, { type: 'domicil', userId, name });

export const deleteCharger = (chargerId: string) => chargeApi.v1.delete(`${CHARGE_URLS.CHARGE_POINT(chargerId)}`);

export const deleteUnknownCharger = (unknownChargerId: string) =>
  chargeApi.v1.delete(`${CHARGE_URLS.UNKNOWN_CHARGE_POINT(unknownChargerId)}`);

export const deleteInitalisationConfig = (InitalisationConfigId: string) =>
  chargeApi.v1.delete(CHARGE_URLS.INITALISATION_CONFIG(InitalisationConfigId));

export const deleteoperationConfig = (operationConfigId: string) =>
  chargeApi.v1.delete(CHARGE_URLS.OPERATION_CONFIG(operationConfigId));

export const unassignCharger = (chargerId: string) =>
  chargeApi.v1.post(`${CHARGE_URLS.CHARGE_POINT(chargerId)}/unassign`);

export const resetCharger = (chargerId: string, type: 'SOFT' | 'HARD') =>
  chargeApi.v1.post(`${CHARGE_URLS.CHARGE_POINT(chargerId)}/reset`, { type });

export const listSessions = (params?: any) => chargeApi.v1.get(CHARGE_URLS.SESSIONS, { params });

export const listActiveSessions = () => chargeApi.v1.get(CHARGE_URLS.SESSIONS, { params: { isActive: true } });

export const listSessionsForCharger = (chargerId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.CHARGER_SESSIONS(chargerId), { params });

export const listSessionsForLocation = (locationId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.LOCATION_SESSIONS(locationId), { params });

export const listSessionsForAccount = (accountId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.ACCOUNT_SESSIONS(accountId), { params });

export const listSessionsForUser = (userId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.USER_SESSIONS(userId), { params });

export const getMeterValuesForSession = (sessionId: string) =>
  chargeApi.v1.get(CHARGE_URLS.SESSION_METER_VALUES(sessionId));

export const listEventsForSession = (sessionId: string) => chargeApi.v1.get(CHARGE_URLS.EVENTS(sessionId));

export const listSessionsForBillingTokens = (billingTokens: string[]) =>
  chargeApi.v1.get(CHARGE_URLS.SESSIONS, { params: { billingTokens: arrayToCommaSeparatedString(billingTokens) } });

export const getSession = (sessionId: string) => chargeApi.v1.get(CHARGE_URLS.SESSION(sessionId));

export const startConnector = (connectorId: string) =>
  chargeApi.v1.post(`${CHARGE_URLS.CHARGE_CONNECTOR(connectorId)}/start`);

export const stopConnector = (connectorId: string) =>
  chargeApi.v1.post(`${CHARGE_URLS.CHARGE_CONNECTOR(connectorId)}/stop`);

export const getConnectorsStatus = (connectors: any[], date: string) => {
  const fromDate = `${date}T00:00:00.000Z`;
  const toDate = `${date}T23:59:59.999Z`;

  const connectorIds = connectors.map((connector) => connector.id);

  const params = {
    connectors: JSON.stringify(connectorIds),
    fromDate,
    toDate,
  };

  return chargeApi.v1.get(CHARGE_URLS.CHARGE_CONNECTOR_STATUS, { params });
};

export const listAllActivities = () => chargeApi.v1.get(CHARGE_URLS.ALL_ACTIVITIES);

export const listDomicilConnectorsForUser = (userId: string) =>
  chargeApi.v1.get(CHARGE_URLS.DOMICIL_CONNECTORS(userId));

export const listActivitiesForLocation = (locationId: string) =>
  chargeApi.v1.get(CHARGE_URLS.LOCATION_ACTIVITIES(locationId));

export const patchActivitiesForLocation = (locationId: string, data) =>
  chargeApi.v1.patch(CHARGE_URLS.LOCATION_ACTIVITIES(locationId), data);

export const listImagesForLocation = (locationId: string) => chargeApi.v1.get(CHARGE_URLS.LOCATION_IMAGES(locationId));

export const createImageForLocation = (locationId: string, imageData) =>
  chargeApi.v1.post(CHARGE_URLS.LOCATION_IMAGES(locationId), imageData);

export const addImageUrlForLocation = (locationId: string, urlData) =>
  chargeApi.v1.post(CHARGE_URLS.LOCATION_IMAGE_URLS(locationId), urlData);

export const deleteImage = (imageId: string) => chargeApi.v1.delete(CHARGE_URLS.IMAGE(imageId));

export const getMonthlyConsumption = (fromDate: string, toDate: string, locationIds: string[], chargerIds: string[]) =>
  chargeApi.v1.get(`${CHARGE_URLS.REPORTS}/monthly-consumption`, {
    params: {
      fromDate,
      toDate,
      locationIds: arrayToCommaSeparatedString(locationIds),
      chargePointIds: arrayToCommaSeparatedString(chargerIds),
    },
  });

export const getMonthlyConsumptionForAccount = (
  accountId: string,
  fromDate: string,
  toDate: string,
  locations: string[],
  chargerIds: string[],
) =>
  chargeApi.v1.get(`${CHARGE_URLS.ACCOUNT_REPORTS(accountId)}/monthly-consumption`, {
    params: {
      fromDate,
      toDate,
      accountId,
      locations: arrayToCommaSeparatedString(locations),
      chargePointIds: arrayToCommaSeparatedString(chargerIds),
    },
  });

export const getSessionReports = (
  fromDate: string,
  toDate: string,
  locationIds: string[],
  chargerIds: string[],
  statuses: string[],
) =>
  chargeApi.v1.get(`${CHARGE_URLS.REPORTS}/sessions`, {
    params: {
      fromDate,
      toDate,
      locationIds: arrayToCommaSeparatedString(locationIds),
      chargePointIds: arrayToCommaSeparatedString(chargerIds),
      statuses: arrayToCommaSeparatedString(statuses),
    },
  });

export const getSessionReportsForAccount = (
  accountId: string,
  fromDate: string,
  toDate: string,
  locations: string[],
  chargerIds: string[],
  statuses: string[],
) =>
  chargeApi.v1.get(`${CHARGE_URLS.ACCOUNT_REPORTS(accountId)}/sessions`, {
    params: {
      fromDate,
      toDate,
      accountId,
      locations: arrayToCommaSeparatedString(locations),
      chargePointIds: arrayToCommaSeparatedString(chargerIds),
      statuses: arrayToCommaSeparatedString(statuses),
    },
  });

export const listCatalogsForLocation = (locationId: string) =>
  chargeApi.v1.get(CHARGE_URLS.LOCATION_CATALOGS(locationId));

export const addCatalogToLocation = (locationId: string, catalogId: string) =>
  chargeApi.v1.post(CHARGE_URLS.LOCATION_CATALOG(locationId, catalogId));

export const addCatalogWithChildrenToLocation = (locationId: string, catalogIds?: any) =>
  chargeApi.v1.post(CHARGE_URLS.LOCATION_CATALOGS(locationId), { catalogIds });

export const removeCatalogFromLocation = (locationId: string, catalogId: string) =>
  chargeApi.v1.delete(CHARGE_URLS.LOCATION_CATALOG(locationId, catalogId));

export const removeCatalogWithChildrenFromLocation = (locationId: string, catalogIds?: any) =>
  chargeApi.v1.post(CHARGE_URLS.LOCATION_CATALOGS_REMOVE_ALL(locationId), { catalogIds });

export const listCatalogsForCharger = (chargerId: string) =>
  chargeApi.v1.get(CHARGE_URLS.CHARGE_POINT_CATALOGS(chargerId));

export const addCatalogToCharger = (chargerId: string, catalogId: string, childrenCatalogIds?: any) =>
  chargeApi.v1.post(CHARGE_URLS.CHARGE_POINT_CATALOG(chargerId, catalogId), { childrenIds: childrenCatalogIds });

export const removeCatalogFromCharger = (chargerId: string, catalogIds?: any) =>
  chargeApi.v1.post(CHARGE_URLS.CHARGE_POINT_CATALOG_REMOVE_ALL(chargerId), { catalogIds });

export const createAuthorizationTag = (userId: string, data: any) =>
  chargeApi.v1.post(CHARGE_URLS.AUTHORIZATION_TAGS, { userId, ...data });

export const listUsersAuthorizationTags = (userId: string) =>
  chargeApi.v1.get(CHARGE_URLS.USER_AUTHORIZATION_TAGS(userId));

export const deleteAuthorizationTag = (tagId: string) => chargeApi.v1.delete(CHARGE_URLS.AUTHORIZATION_TAG(tagId));

export const listAuthorizationAttemptsForLocation = (locationId: string) =>
  chargeApi.v1.get(CHARGE_URLS.AUTHORIZATION_ATTEMPTS(locationId));

export const listAccountsForLocation = (locationId: string) =>
  chargeApi.v1.get(CHARGE_URLS.LOCATION_ACCOUNTS(locationId));

export const addAccountToLocation = (locationId: string, accountId: string) =>
  chargeApi.v1.post(CHARGE_URLS.LOCATION_ACCOUNT(locationId, accountId));

export const removeAccountFromLocation = (locationId: string, accountId: string) =>
  chargeApi.v1.delete(CHARGE_URLS.LOCATION_ACCOUNT(locationId, accountId));

export const listAccountsForCharger = (chargerId: string) => chargeApi.v1.get(CHARGE_URLS.CHARGER_ACCOUNTS(chargerId));

export const addAccountToCharger = (chargerId: string, accountId: string) =>
  chargeApi.v1.post(CHARGE_URLS.CHARGER_ACCOUNT(chargerId, accountId));

export const removeAccountFromCharger = (chargerId: string, accountId: string) =>
  chargeApi.v1.delete(CHARGE_URLS.CHARGER_ACCOUNT(chargerId, accountId));

export const getStatsSummary = (params?: any) => chargeApi.v1.get(CHARGE_URLS.STATS_SUMMARY, { params });

export const getLocationStats = (params?: any) => chargeApi.v1.get(CHARGE_URLS.STATS_LOCATIONS, { params });

export const getChargerStats = (params?: any) => chargeApi.v1.get(CHARGE_URLS.STATS_CHARGE_POINTS, { params });

export const getUserStats = (params?: any) => chargeApi.v1.get(CHARGE_URLS.STATS_USERS, { params });

export const getTagStats = (params?: any) => chargeApi.v1.get(CHARGE_URLS.STATS_TAGS, { params });

export const getAccountStats = (params?: any) => chargeApi.v1.get(CHARGE_URLS.STATS_ACCOUNTS, { params });

export const getLocationStatsItems = (locationId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.STAT_LOCATIONS_ITEMS(locationId), { params });

export const getChargerStatsItems = (chargerId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.STAT_CHARGE_POINTS_ITEMS(chargerId), { params });

export const getUserStatsItems = (userId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.STAT_USERS_ITEMS(userId), { params });

export const getAccountStatsItems = (accountId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.STAT_ACCOUNTS_ITEMS(accountId), { params });

export const getTagStatsItems = (tagId: string, params?: any) =>
  chargeApi.v1.get(CHARGE_URLS.STAT_TAGS_ITEMS(tagId), { params });

export const triggerCommand = (chargerId: string, command: string) =>
  chargeApi.v1.post(CHARGE_URLS.TRIGGER_COMMAND(chargerId), { command });
