/* eslint-disable camelcase */
import { useState } from 'react';
import { makeAutoObservable, toJS } from 'mobx';
import { GridRowParams } from '@material-ui/data-grid';
import { ListItem, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { formatToSloDate, sessionsColumn_location } from '../Sessions/SessionsPage';
import { drawerStore } from '../../state/drawerStore';
import ActionsMenu from '../../components/other/ActionsMenu';
import { FormInputTypes } from '../../utils/constants';
import { Api } from '../../api';
import { gridColumn } from '../../utils/gridUtils';
import TextLink from '../../components/other/TextLink';
import { userDetailsTabParameters } from '../../utils/routing';
import { PaymentStatusBadge } from '../../components/other/CellBadges';
import { onClickShowCart } from './InvoicesPage';
import { usePaginatedProvider } from '../../state/paginatedProviderStore';
import { VMPaginatedGrid } from '../../components/main/VMPaginatedGrid';

const onClickShowPaymentDetails = (payment) => {
  const date = new Date(payment?.createdAt);
  let sloDate: any;
  if (date) {
    sloDate = formatToSloDate(date);
  }

  const parsedValues = {
    ...payment,
    price: payment?.chargeAmount?.formatted,
    date: sloDate,
    ...payment?.purchaseMethod,
  };

  drawerStore.openDrawer({
    label: 'Payment details',
    inputs: [{ name: 'details', type: FormInputTypes.jsonView, value: parsedValues }],
    clickCallback: undefined,
    buttonLabel: 'Close',
  });

  drawerStore.setValues(parsedValues);
};

class PaymentsStore {
  payments: any = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  addLocationToPayment(billingToken, location) {
    const payment = this.payments.find((el) => el.billingToken === billingToken);
    if (payment) {
      payment.location = location;
    }

    this.payments.slice();
  }
}

const paymentActions = (params: GridRowParams, paymentsProvider) => {
  const payment = params.row;
  const { receipt } = params.row;
  const { billingToken } = params.row;
  const cartId = params.row?.cartId;

  return (
    <ActionsMenu>
      <ListItem button onClick={() => onClickShowPaymentDetails(payment)}>
        <Typography style={{ width: '100%' }}>Details</Typography>
      </ListItem>
      {receipt && (
        <ListItem button onClick={() => window.open(receipt, '_blank')}>
          <Typography style={{ width: '100%' }}>Receipt</Typography>
        </ListItem>
      )}
      {params.row.status !== 'captured' && (
        <ListItem
          button
          onClick={() => {
            Api.billing.cancelPayment(billingToken).then(() => {
              paymentsProvider.reloadResource();
            });
          }}
        >
          <Typography style={{ width: '100%' }}>Cancel</Typography>
        </ListItem>
      )}
      {cartId && (
        <ListItem button onClick={() => onClickShowCart(cartId)}>
          <Typography style={{ width: '100%' }}>Cart</Typography>
        </ListItem>
      )}
    </ActionsMenu>
  );
};

export const paymentsColumns = (paymentsProvider) => [
  gridColumn('customer', {
    flex: 1,
    sortableFields: paymentsProvider.sortable,
    renderCell: (params: GridRowParams) => {
      const { purchaseMethod } = params.row;
      const fullName = purchaseMethod?.fullName;
      const userId = purchaseMethod?.userId;

      return <TextLink to={userDetailsTabParameters(userId)} label={fullName} />;
    },
  }),
  gridColumn('method', {
    flex: 1,
    sortableFields: paymentsProvider.sortable,
    renderCell: (params: GridRowParams) => {
      const name = params.row.purchaseMethod?.name;
      return <div>{name}</div>;
    },
  }),
  gridColumn('reference', { flex: 2, sortableFields: paymentsProvider.sortable }),
  gridColumn('price', {
    flex: 1,
    sortableFields: paymentsProvider.sortable,
    renderCell: (params: GridRowParams) => <div>{params.row.chargeAmount?.formatted}</div>,
  }),
  gridColumn('createdAt', {
    flex: 2,
    headerName: 'Date',
    sortableFields: paymentsProvider.sortable,
    renderCell: (params: GridRowParams) => {
      const date = new Date(params.row.createdAt);

      if (!date) {
        return <div />;
      }

      const sloDate = formatToSloDate(date);

      return <div>{sloDate}</div>;
    },
  }),
  sessionsColumn_location(paymentsProvider.sortable),
  gridColumn('status', {
    flex: 1,
    sortableFields: paymentsProvider.sortable,
    renderCell: (params) => <PaymentStatusBadge status={params.row.status} />,
  }),
  gridColumn('actions', {
    width: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => paymentActions(params, paymentsProvider),
  }),
];

export const PaymentsPage = observer(() => {
  const [paymentsStore] = useState(() => new PaymentsStore());
  const [sortableFields, setSortableFields] = useState([]);

  const mapLocations = (sessionsData) => {
    sessionsData?.forEach((session) => {
      if (session?.location && session?.billingToken) {
        paymentsStore.addLocationToPayment(session.billingToken, session.location);
      }
    });
  };

  const requestChargeSessions = (paymentsData) => {
    const billingTokens = [];
    paymentsData?.forEach((payment, idx) => {
      if (payment.billingToken) {
        billingTokens.push(payment.billingToken);
      }
    });

    // divide billingTokens into chunks of 20
    const chunkSize = 20;
    const chunkedBillingTokens = [];
    for (let i = 0; i < billingTokens.length; i += chunkSize) {
      chunkedBillingTokens.push(billingTokens.slice(i, i + chunkSize));
    }

    chunkedBillingTokens.forEach((chunk) => {
      Api.charge.listSessionsForBillingTokens(chunk).then((data) => {
        const sessionsData = data?.data?.data;
        if (sessionsData) {
          mapLocations(sessionsData);
        }
      });
    });
  };

  const onPaymentsResponse = (paymentsData, _count, sortable) => {
    paymentsStore.payments = [];
    paymentsData?.forEach((payment) => {
      paymentsStore.payments.push(payment);
    });
    paymentsStore.payments.slice(); // Mark observed to force update
    if (sortable) {
      setSortableFields(sortable);
    }
    requestChargeSessions(paymentsData);
  };

  const paymentsProvider = usePaginatedProvider(
    (offset, sortBy, sortOrder) => Api.billing.listPayments({ skip: offset, take: 20, sortBy, sortOrder }),
    onPaymentsResponse,
  );

  return (
    <div style={{ width: '100%' }}>
      <VMPaginatedGrid
        label="Payments"
        count={paymentsProvider.count}
        rows={toJS(paymentsStore.payments)}
        columns={paymentsColumns(paymentsProvider)}
        onPageOffsetChange={paymentsProvider.onPageChange}
        onSortTypeChange={paymentsProvider.setSort}
      />
    </div>
  );
});
