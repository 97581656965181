import { Tooltip, Typography } from '@material-ui/core';
import _ from 'lodash';
import { ReactComponent as Type1Connector } from '../../images/Connectors/type1_connector.svg';
import { ReactComponent as Type2Connector } from '../../images/Connectors/type2_connector.svg';
import { ReactComponent as CCSConnector } from '../../images/Connectors/CCS.svg';
import { ReactComponent as CHAdeMOConnector } from '../../images/Connectors/CHAdeMO_connector.svg';
import { CONNECTOR_TYPE } from '../../types/ConnectorType';
import { greenColor } from '../main/react/App';
import { CHARGER_DETAILS_TABS, chargerDetailsTabParameters } from '../../utils/routing';
import TextLink from './TextLink';
import { CONNECTOR_STATUS } from '../../types/Connector';

export const Type1 = ({ color }: { color: string }) => <Type1Connector style={{ stroke: color }} />;
export const Type2 = ({ color }: { color: string }) => <Type2Connector style={{ stroke: color }} />;
export const CCS = ({ color }: { color: string }) => <CCSConnector style={{ stroke: color }} />;
export const CHAdeMO = ({ color }: { color: string }) => <CHAdeMOConnector style={{ stroke: color }} />;

export const connectorStatusToColor = (status: CONNECTOR_STATUS): string => {
  switch (status) {
    case CONNECTOR_STATUS.MAINTENANCE:
      return '#d9b114';
    case CONNECTOR_STATUS.OFFLINE:
      return 'lightgrey';
    case CONNECTOR_STATUS.ERROR:
      return 'red';
    case CONNECTOR_STATUS.AVAILABLE:
      return '#3a3a3a';
    case CONNECTOR_STATUS.IN_USE:
      return greenColor;
    default:
      return 'white';
  }
};

interface ConnectorIconProps {
  type: CONNECTOR_TYPE;
  status: CONNECTOR_STATUS;
}

const IconByType = ({ type, status }: ConnectorIconProps) => {
  const color = connectorStatusToColor(status);

  switch (type) {
    case CONNECTOR_TYPE.Type1:
      return <Type1 color={color} />;
    case CONNECTOR_TYPE.Type2:
      return <Type2 color={color} />;
    case CONNECTOR_TYPE.CCS:
      return <CCS color={color} />;
    case CONNECTOR_TYPE.CHAdeMO:
      return <CHAdeMO color={color} />;
    default:
      return <p>not found</p>;
  }
};

export const ConnectorIcon = ({ type, status }: ConnectorIconProps) => {
  let toolTipTitle = '' + type;
  if (status) {
    toolTipTitle = `${_.startCase(status)} - ${type}`;
  }

  return (
    <Tooltip title={toolTipTitle} placement="top">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0 }}>
        <IconByType type={type} status={status} />
      </div>
    </Tooltip>
  );
};

export const ConnectorIconWithLink = ({ connector, showLink = true }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <ConnectorIcon type={connector.type} status={connector.status} />
    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginLeft: 4 }}>
      {showLink && (
        <TextLink
          label={connector.name}
          to={chargerDetailsTabParameters(connector?.chargePoint?.id, CHARGER_DETAILS_TABS.OVERVIEW)}
        />
      )}
      {!showLink && <Typography>{connector.name}</Typography>}
    </div>
  </div>
);
export const ConnectorsList = ({ connectors }: { connectors: any }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {connectors?.map((connector) => {
      const connectorType: CONNECTOR_TYPE = CONNECTOR_TYPE[connector.type];
      if (connectorType) {
        return <ConnectorIcon key={connector.id} type={connectorType} status={connector.status} />;
      }

      return <div key={connector.id} />;
    })}
  </div>
);
