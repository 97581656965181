export enum FormInputTypes {
  textField,
  number,
  multilineTextField,
  textView,
  textLinkView,
  titleAndValue,
  disabledTextField,
  password,
  select,
  customRenderSelect,
  yearMonthPicker,
  datePicker,
  multilineSelect,
  userEmailAutocomplete,
  accountNameAutocomplete,
  image,
  switch,
  googleAddressAutocomplete,
  checkbox,
  miniTitle,
  component,
  filterListSelect,
  time,
  jsonView,
  editableJsonView,
  qrCode,
  multiAutocomplete,
  loader,
  pugCart,
  textFieldWithPrefix,
  datePresets,
  button,
  textButton,
  radioButton,
}

export enum languageAbbreviations {
  English = 'en',
  Slovenščina = 'si',
}

export const standardDataGridProps: any = {
  disableColumnReorder: true,
  disableColumnSelector: true,
  disableSelectionOnClick: true,
  disableColumnMenu: true,
  autoHeight: true,
  pageSize: 20,
} as const;
