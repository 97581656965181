import { observer } from 'mobx-react-lite';
import { Card, Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useFormStyles } from '../../styles/useFormStyles';
import { useStyles } from '../../styles/styles';

interface DashboardDetailsRowProps {
  label: string;
  number: number;
  isError?: boolean;
  link?: string;
}

const DetailsCardRow = ({ label, number, isError = false, link }: DashboardDetailsRowProps) => {
  const formClasses = useFormStyles();
  const history = useHistory();

  const handleClick = (e) => {
    e.stopPropagation();
    if (link) {
      history.push(link);
    }
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', cursor: link ? 'pointer' : 'default' }}
      onClick={handleClick}
    >
      <div style={{ flex: 1 }} />
      <Typography style={{ flex: 4 }} className={formClasses.greyTitleText}>
        {label}
      </Typography>
      <div style={{ flex: 1 }} />
      <Typography
        style={{ flex: 4 }}
        align="right"
        className={clsx(isError && formClasses.redText, link !== undefined && !isError && formClasses.greenText)}
      >
        {number}
      </Typography>
      <div style={{ flex: 1 }} />
    </div>
  );
};

interface DashboardDetailsCardProps {
  title: string;
  number: number;
  rows: DashboardDetailsRowProps[];
  link?: string;
}

export const DashboardDetailsCard = observer(({ title, number, rows, link }: DashboardDetailsCardProps) => {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const history = useHistory();

  const cursor = link !== undefined ? 'pointer' : 'default';
  const onClick = link !== undefined ? () => history.push(link) : undefined;

  return (
    <Card className={classes.card} style={{ height: 180, cursor }} onClick={onClick}>
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 14 }}
        >
          <Typography align="center" variant="h6" className={formClasses.greyTitleText}>
            {title}
          </Typography>
          <Typography
            style={{ marginTop: '1em', fontSize: 24 }}
            align="center"
            className={link !== undefined ? formClasses.greenText : ''}
          >
            {number}
          </Typography>
          <div style={{ flex: 1 }} />
        </div>
        <Divider orientation="vertical" flexItem />
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
          {rows.map((row: DashboardDetailsRowProps, idx) => (
            <DetailsCardRow label={row.label} number={row.number} isError={row.isError} key={idx} link={row?.link || link} />
          ))}
        </div>
      </div>
    </Card>
  );
});
