export enum ENABLED_STATUS {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export interface PriceList {
  id: string;
  name: string;
  products: string;
  createdAt: string;
  status: ENABLED_STATUS;
  children?: [];
}
