import { observer } from 'mobx-react-lite';
import { GridRowParams } from '@material-ui/data-grid';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from '@material-ui/core';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import VMDataGrid from '../../components/main/VMDataGrid';
import { FormInputTypes } from '../../utils/constants';
import { gridColumn } from '../../utils/gridUtils';
import { useProvider } from '../../state/providerStore';
import { listEventsForSession } from '../../api/chargeApi';
import { drawerStore } from '../../state/drawerStore';
import { copyTextToClipboard } from '../../utils/utils';
import { TOAST_MESSAGE_TYPE, toastStore } from '../../state/toastStore';
import { formatToSloDate } from './SessionsPage';

const SessionDetailsEvents = observer(() => {
  const { sessionid } = useParams<{ sessionid: string }>();

  const eventsProvider = useProvider(() => listEventsForSession(sessionid));

  const onClickShowMetaData = (session) => {
    drawerStore.openDrawer({
      label: 'Session metadata',
      inputs: [{ type: FormInputTypes.jsonView, value: session?.metadata }],
      buttonLabel: 'Close',
      showButton: false,
    });
  };

  const onClickCopyMetadata = (session) => {
    if (copyTextToClipboard(JSON.stringify(session?.metadata))) {
      toastStore.showMessage('Metadata text copied to clipboard');
    } else {
      toastStore.showMessage('Copying failed', TOAST_MESSAGE_TYPE.ERROR);
    }
  };

  const eventColumns = [
    gridColumn('createdAt', {
      flex: 1,
      renderCell: (params: GridRowParams) => {
        const { createdAt } = params.row;
        return <div>{formatToSloDate(createdAt)}</div>;
      },
    }),
    gridColumn('event', {
      flex: 1,
      renderCell: (params: GridRowParams) => {
        const { name } = params.row;
        /* return <TextLink to={`/${DASHBOARD_PAGES.Locations}/${location.id}`} label={location.address} />; */
        return <div>{name}</div>;
      },
    }),
    gridColumn('activePower', {
      flex: 1,
      headerName: 'Power',
      renderCell: (params: GridRowParams) => <div>{params.row?.activePower} kW</div>,
    }),
    gridColumn('metadata', {
      flex: 3,
      renderCell: (params: GridRowParams) => {
        const metaStr = JSON.stringify(params.row?.metadata);
        return <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{metaStr}...</div>;
      },
    }),
    gridColumn('metadataIcons', {
      headerName: '',
      width: 120,
      renderCell: (params: GridRowParams) => {
        let metaStr = JSON.stringify(params.row?.metadata);
        metaStr = metaStr?.substring(0, 30);
        return (
          <div style={{ display: 'flex' }}>
            <IconButton onClick={() => onClickCopyMetadata(params.row)}>
              <ContentCopyIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => onClickShowMetaData(params.row)}>
              <VisibilityIcon color="primary" />
            </IconButton>
          </div>
        );
      },
    }),
  ];

  return (
    <div style={{ width: '100%' }}>
      <VMDataGrid rows={eventsProvider.data} columns={eventColumns} label="All events" />
    </div>
  );
});

export default SessionDetailsEvents;
