/* eslint-disable camelcase */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { GridRowParams } from '@material-ui/data-grid';
import VMDataGrid from '../../components/main/VMDataGrid';
import { gridColumn } from '../../utils/gridUtils';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import TextLink from '../../components/other/TextLink';
import { userDetailsTabParameters, walletDetailsParameters } from '../../utils/routing';
import { formatToSloDate } from '../Sessions/SessionsPage';

const openedBalancesColumns = [
  gridColumn('user', {
    headerName: 'User',
    flex: 1,
    renderCell: (params: GridRowParams) => {
      const { row } = params;
      return <TextLink label={row.ownerName} to={userDetailsTabParameters(row.userId || row.accountId)} />;
    },
  }),
  gridColumn('settlementMode', { flex: 1 }),
  gridColumn('lastTransaction', {
    flex: 2,
    renderCell: (params: GridRowParams) => {
      const date = new Date(params.row.lastEntry?.createdAt);

      if (!date) {
        return <div />;
      }

      const sloDate = formatToSloDate(date);

      return <div>{sloDate}</div>;
    },
  }),
  gridColumn('lastInvoice', {
    flex: 2,
    renderCell: (params: GridRowParams) => {
      const date = new Date(params.row.lastInvoice?.createdAt);

      if (Number.isNaN(date.getTime())) {
        return <div />;
      }

      const sloDate = formatToSloDate(date);

      return <div>{sloDate || ''}</div>;
    },
  }),
  gridColumn('balance', {
    flex: 1,
    renderCell: (params: GridRowParams) => {
      const { row } = params;
      const formattedBalance = row.lastEntry?.balance?.formatted;
      const walletId = row.lastInvoice?.walletId;
      return <TextLink label={formattedBalance} to={walletDetailsParameters(walletId)} />;
    },
  }),
  gridColumn('actions', {
    width: 100,
    align: 'center',
    headerAlign: 'center',
    renderCell: () => <div>...</div>,
  }),
];

export const OpenedBalancesPage = observer(() => {
  const deptProvider = useProvider(Api.billing.getDebt);

  return (
    <div style={{ width: '100%' }}>
      <VMDataGrid label="Opened Balances" rows={deptProvider.data} columns={openedBalancesColumns} />
    </div>
  );
});
