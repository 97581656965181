import { makeStyles } from '@material-ui/core/styles';
import { greenTextColor, greyColor, redColor } from '../components/main/react/App';

export const useFormStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },
    verticalRoot: {
      display: 'flex',
      width: '100%',
      flexGrow: 1,
      marginTop: 50,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    formControl: {
      width: '100%',
      color: 'red',
    },
    redText: {
      color: redColor,
    },
    bigCard: {
      width: 700,
      height: 600,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    buttonsContainer: {
      width: '80%',
      paddingTop: 50,
      display: 'flex',
      justifyContent: 'space-between',
    },
    singleButtonContainer: {
      width: '80%',
      paddingTop: 50,
      paddingBottom: 50,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    labelContainer: {
      paddingTop: 20,
    },
    switchRootContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'start',
      flexDirection: 'row',
    },
    switchItemContainer: {
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: 10,
      marginBottom: 10,
    },
    dropzone: {
      width: '100%',
      height: 64,
      backgroundColor: '#DFE0EB',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paragraphCard: {
      width: '100%',
      marginTop: 20,
    },
    greyTitleText: {
      color: greyColor,
      fontWeight: 200,
      fontSize: 14,
      textTransform: 'uppercase',
    },
    greenText: {
      color: greenTextColor,
    },
    formDrawerInput: {
      marginTop: 8,
    },
    noClear: {
      '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
      },
    },
  }),
  { index: 1 },
);
