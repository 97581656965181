import { Link } from 'react-router-dom';
import React from 'react';
import { greenTextColor } from '../main/react/App';

const TextLink = ({ label, to, externalLink = false }: {label: string, to: string, externalLink?: boolean}) => (
  <div>
    {!externalLink && (
      <Link to={to} style={{ textDecoration: 'none', color: greenTextColor }}>
        {label}
      </Link>
    )}
    {externalLink && (
      <Link to={{ pathname: to }} target="_blank" style={{ textDecoration: 'none', color: greenTextColor }}>
        {label}
      </Link>
    )}
  </div>
);

export default TextLink;
