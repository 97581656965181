import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box } from '@material-ui/core';
import { parse, format } from 'date-fns';
import { toJS } from 'mobx';
import { useStyles } from '../../styles/styles';
import StyledCard from '../../components/cards/StyledCard';
import StyledCardHeader from '../../components/cards/StyledCardHeader';
import StyledCardContent from '../../components/cards/StyledCardContent';
import NameValueRow from '../../components/cards/CardRows/NameValueRow';
import { CHARGER_DETAILS_TABS, chargerDetailsTabParameters } from '../../utils/routing';
import { NameValueRowList } from '../../components/cards/CardRows/NameValueRowList';
import { useProvider } from '../../state/providerStore';
import { Api } from '../../api';
import { formatToSloDate } from './SessionsPage';

const detailsRows = ['id', 'charging', 'startedAt', 'duration', 'avgPower', 'maxPower', 'unitsConsumed', 'batteryLevel'];
const pricingRows = ['price', 'receipt'];
const userRows = ['name', 'email', 'phone'];
const chargerRows = ['charger', 'model', 'type', 'location', 'connector', 'activePower'];

const SessionDetailsOverview = observer(() => {
  const classes = useStyles();

  const { sessionid } = useParams<{ sessionid: string }>();

  const userProvider = useProvider(() => Api.user.getUser(''), undefined, false);
  const meterValuesProvider = useProvider(() => Api.charge.getMeterValuesForSession(sessionid));
  const paymentDetailsProvider = useProvider(() => Api.billing.getPaymentDetails(''), undefined, false);

  const onSessionProviderSuccess = (data) => {
    if (data.userId) {
      userProvider.setRequest(() => Api.user.getUser(data.userId));
      userProvider.reloadResource();
    }

    if (data?.billingToken) {
      paymentDetailsProvider.setRequest(() => Api.billing.getPaymentDetails(data.billingToken));
      paymentDetailsProvider.reloadResource();
    }
  };

  const sessionProvider = useProvider(() => Api.charge.getSession(sessionid), onSessionProviderSuccess);

  const connector = sessionProvider.data?.connector;
  const charger = connector?.chargePoint;

  function parseSloDate(dateString) {
    return parse(dateString, 'dd.MM.yyyy HH:mm', new Date());
  }

  const getBatteryLevel = () => {
    if (sessionProvider.data?.initialBatteryLevel && sessionProvider.data?.latestBatteryLevel) {
      return `${sessionProvider.data.initialBatteryLevel}% - ${sessionProvider.data.latestBatteryLevel}%`;
    } else {
      return 'N/A';
    }
  };

  const formattedDate = formatToSloDate(sessionProvider.data?.startedAt);
  const startTime = parseSloDate(formattedDate);

  const durationInMinutes = sessionProvider.data?.duration / 60 || 0;
  const numberOfPoints = meterValuesProvider?.data?.length || 1;

  const interval = (durationInMinutes * 60 * 1000) / (numberOfPoints - 1);

  const graphValues = meterValuesProvider?.data?.map((meterValue, index) => {
    const date = new Date(startTime.getTime() + index * interval);
    const formattedTime = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;

    return {
      name: `${index}`,
      time: formattedTime,
      value: meterValue.value / 1000,
    };
  });

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.cardRows}>
        <div className={classes.leftCardRow}>
          <StyledCard>
            <StyledCardHeader title="Details" />
            <StyledCardContent>
              <NameValueRowList
                rows={detailsRows}
                values={{ ...sessionProvider.data, batteryLevel: getBatteryLevel(), startedAt: formatToSloDate(sessionProvider.data?.startedAt) }}
              />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="Pricing" />
            <StyledCardContent>
              {/* <PricingRow catalogsProvider={catalogsProvider} catalogId={sessionProvider.data?.catalogId} /> */}
              <NameValueRow name="price" value={paymentDetailsProvider.data?.chargeAmount?.formatted} />
              <NameValueRow
                name="receipt"
                value={paymentDetailsProvider.data?.reference}
                linkTo={paymentDetailsProvider.data?.receipt}
                externalLink
              />
            </StyledCardContent>
          </StyledCard>
        </div>
        <div className={classes.rightCardRow}>
          <StyledCard>
            <StyledCardHeader title="User" />
            <StyledCardContent>
              <NameValueRowList rows={userRows} values={userProvider?.data} />
            </StyledCardContent>
          </StyledCard>

          <StyledCard>
            <StyledCardHeader title="Charger" />
            <StyledCardContent>
              <NameValueRow
                name="charger"
                value={charger?.ocppId}
                linkTo={chargerDetailsTabParameters(charger?.id, CHARGER_DETAILS_TABS.OVERVIEW)}
              />
              <NameValueRow name="model" value={charger?.model} />
              <NameValueRow name="type" value={charger?.type} />
              <NameValueRow name="location" value={charger?.locationId} />
              <NameValueRow
                name="connector"
                value={connector?.name}
                linkTo={chargerDetailsTabParameters(charger?.id, CHARGER_DETAILS_TABS.OVERVIEW)}
              />
              <NameValueRow name="activePower" value={charger?.activePower} />
            </StyledCardContent>
          </StyledCard>
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <StyledCard>
          <StyledCardHeader title="Chart" />
          <StyledCardContent>
            <Box mt={2}>
              <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={graphValues} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                  <XAxis dataKey="time" />
                  <YAxis tickFormatter={(value) => `${value.toLocaleString()} kW`} />
                  <CartesianGrid strokeDasharray="1 1" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#3354f5"
                    strokeWidth={2}
                    fillOpacity={0.25}
                    yAxisId={0}
                    activeDot={{ strokeWidth: 2, r: 8, stroke: '#3354f5', fill: 'white' }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </StyledCardContent>
        </StyledCard>
      </div>
    </div>
  );
});

export default SessionDetailsOverview;
