export const paymentMethodSelectOption = (paymentMethod) => ({
  optionName: paymentMethod.id,
  topRow: `${paymentMethod.name}`,
  bottomRow: `${paymentMethod.fullName}`,
});

export const generateMultiSelectOptions = (provider, mapFunction) =>
  (provider.data !== undefined ? provider.data.map(mapFunction) : []);

export const getPaymentMethodNameById = (paymentMethodsProvider, id) => {
  const paymentMethod = paymentMethodsProvider.data?.find((method) => method.id === id);
  return paymentMethod ? paymentMethod.name : 'N/A';
};
