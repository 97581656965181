import React from 'react';
import { Typography } from '@material-ui/core';
import { blueColor, lightGreenColor, lightGreyColor, lightRedColor, redColor, yellowColor } from '../main/react/App';
import { ENABLED_STATUS } from '../../types/PriceList';
import { CONNECTOR_STATUS } from '../../types/Connector';
import { SESSION_STATUS } from '../../types/Session';
import { CHARGER_STATUS } from '../../types/Charger';
import { PAYMENT_STATUS } from '../../types/Payment';
import { connectorStatusToColor } from './Connectors';

export const ColumnStatusBadge = ({ label, color, textColor = '#000', justifyContent = 'flex-start' }) => (
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent, width: '100%' }}>
    <div
      style={{
        backgroundColor: color,
        borderRadius: 24,
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 2,
        paddingBottom: 2,
        marginRight: 0,
      }}
    >
      <Typography align="left" style={{ fontSize: 14, color: textColor }}>
        {label}
      </Typography>
    </div>
  </div>
);

export const ConnectorStatusBadge = ({ status }: { status: CONNECTOR_STATUS }) => {
  const color = connectorStatusToColor(status);
  if (status === CONNECTOR_STATUS.AVAILABLE) {
    return <ColumnStatusBadge label={status.toUpperCase()} color={color} textColor="#FFF" />;
  }

  return <ColumnStatusBadge label={status.toUpperCase()} color={color} />;
};

export const EnabledStatusBadge = ({ status }: { status: ENABLED_STATUS }) => (
  <ColumnStatusBadge label={status} color={status === ENABLED_STATUS.ENABLED ? '#00E8A8' : '#DFE0EB'} />
);

export const ChargerStatusBadge = ({ status }: { status: string }) => {
  const statusEnum: CHARGER_STATUS = status as CHARGER_STATUS;
  const label = status.toUpperCase();
  let color = '';

  switch (statusEnum) {
    case CHARGER_STATUS.ACTIVE:
      color = lightGreenColor;
      break;
    case CHARGER_STATUS.AVAILABLE:
      color = lightGreenColor;
      break;
    case CHARGER_STATUS.ASSIGNED:
      color = blueColor;
      break;
    case CHARGER_STATUS.AT_SERVICE:
      color = lightRedColor;
      break;
    case CHARGER_STATUS.UNASSIGNED:
      color = lightGreyColor;
      break;
    default:
      color = '#fff';
  }

  return <ColumnStatusBadge label={label} color={color} />;
};

export const SessionStatusBadge = ({ status }: { status: string }) => {
  const statusEnum: SESSION_STATUS = status as SESSION_STATUS;
  const label = status?.toUpperCase();
  let color = '';

  switch (statusEnum) {
    case SESSION_STATUS.STARTED:
      color = lightGreenColor;
      break;
    case SESSION_STATUS.PENDING:
      color = blueColor;
      break;
    case SESSION_STATUS.ERROR:
      color = redColor;
      break;
    case SESSION_STATUS.FAILED:
      color = lightRedColor;
      break;
    case SESSION_STATUS.FINISHED:
      color = lightGreyColor;
      break;
    default:
      color = '#fff';
  }

  return <ColumnStatusBadge label={label} color={color} />;
};

export const UserStatusBadge = ({ user }) => {
  let status = 'disabled';
  let color = redColor;
  if (user.enabled) {
    if (user.verified) {
      status = 'verified';
      color = blueColor;
    } else {
      status = 'unverified';
      color = yellowColor;
    }
  }

  return <ColumnStatusBadge label={status.toUpperCase()} color={color} />;
};

export const PaymentStatusBadge = ({ status }: { status: string }) => {
  const statusEnum: PAYMENT_STATUS = status as PAYMENT_STATUS;
  const label = status?.toUpperCase();
  let color = '';

  switch (statusEnum) {
    case PAYMENT_STATUS.RESERVED:
      color = blueColor;
      break;
    case PAYMENT_STATUS.CAPTURED:
      color = lightGreenColor;
      break;
    case PAYMENT_STATUS.FAILED:
      color = redColor;
      break;
    case PAYMENT_STATUS.REFUNDED:
      color = lightRedColor;
      break;
    default:
      color = '#fff';
  }

  return <ColumnStatusBadge label={label} color={color} />;
};
