export enum SESSION_STATUS {
    PENDING = 'pending',
    STARTED = 'started',
    FINISHED = 'finished',
    PAUSED = 'paused',
    CANCELED = 'canceled',
    ERROR = 'error',
    DELETED = 'deleted',
    FAILED = 'failed',
}
