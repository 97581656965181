import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useFormStyles } from '../../styles/useFormStyles';
import { route, USER_DETAILS_TABS, userDetailsTabParameters, userDetailsTabRoute } from '../../utils/routing';
import UserDetailsOverview from './UserDetailsOverview';
import UserDetailsSessions from './UserDetailsSessions';
import { ViziTab, ViziTabs } from '../../utils/ViziTabs';
import { UserDetailsBilling } from './UserDetailsBilling';
import dashboardStore from '../../state/dashboardStore';
import { PAGES } from '../../components/main/MainComponent';

const UserDetails = observer(() => {
  const formClasses = useFormStyles();
  const { userid } = useParams<{ userid: string }>();
  const history = useHistory();

  useEffect(() => {
    dashboardStore.appBarLabel = 'User details';
  }, [history.location]);

  return (
    <div style={{ width: '100%' }}>
      <div className={formClasses.root}>
        <div className={formClasses.verticalRoot}>
          <ViziTabs tabs={Object.values(USER_DETAILS_TABS)} basePath={userDetailsTabParameters(userid)}>
            <ViziTab
              label={USER_DETAILS_TABS.OVERVIEW}
              linkTo={userDetailsTabParameters(userid, USER_DETAILS_TABS.OVERVIEW)}
            />
            <ViziTab
              label={USER_DETAILS_TABS.SESSIONS}
              linkTo={userDetailsTabParameters(userid, USER_DETAILS_TABS.SESSIONS)}
            />
            <ViziTab
              label={USER_DETAILS_TABS.BILLING}
              linkTo={userDetailsTabParameters(userid, USER_DETAILS_TABS.BILLING)}
            />
          </ViziTabs>
          <Switch>
            <Route path={userDetailsTabRoute(USER_DETAILS_TABS.OVERVIEW)}>
              <UserDetailsOverview />
            </Route>
            <Route path={userDetailsTabRoute(USER_DETAILS_TABS.SESSIONS)}>
              <UserDetailsSessions />
            </Route>
            <Route path={userDetailsTabRoute(USER_DETAILS_TABS.BILLING)}>
              <UserDetailsBilling />
            </Route>
            <Route exact path={route(PAGES.Users) + '/' + userid}>
              <Redirect to={userDetailsTabParameters(userid, USER_DETAILS_TABS.OVERVIEW)} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
});

export default UserDetails;
