import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import VMDataGridHeader, { FilterParamOption } from './VMDataGridHeader';
import { standardDataGridProps } from '../../utils/constants';
import dashboardStore from '../../state/dashboardStore';
import { useStyles } from '../../styles/styles';

export interface VMDataGridProps {
  label?: string;
  count?: number;
  onClickAdd?: () => void;
  rows: any[];
  columns: any;
  onBottomScroll?: () => void;
  pageSize?: number;
  filterParamOptions?: FilterParamOption[];
  filterCallback?: (param: string, val: string) => void;
  onSortTypeChange?: (sortField: string, sortOrder: 'asc' | 'desc' | null) => void;
  removeCellPadding?: boolean;
  onRowClick?: (params: any) => void;
  date?: string;
  onDateChangeCallBack?: (date: string) => void;
}

const VMDataGrid = observer(
  ({
    label,
    count,
    onClickAdd,
    rows,
    columns,
    onBottomScroll,
    pageSize,
    filterParamOptions,
    filterCallback,
    onSortTypeChange,
    removeCellPadding,
    onRowClick,
    date,
    onDateChangeCallBack,
  }: VMDataGridProps) => {
    const onScroll = () => {
      const winHeight = window.innerHeight;
      const srcHeight = document.documentElement.scrollHeight;
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const toBottom = srcHeight - winHeight - scrollTop;

      if (toBottom <= 100) {
        if (onBottomScroll) {
          onBottomScroll();
        }
      }
    };

    useEffect(() => {
      window.addEventListener('scroll', onScroll);

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    });

    const fetchSortedData = (sortField, sortOrder) => {
      onSortTypeChange(sortField, sortOrder);
    };
    const classes = useStyles();

    let definedLabel = label;
    if (label === undefined) {
      definedLabel = `All ${dashboardStore.selectedMenuItem}`;
    }

    const gridClasses = [classes.gridRoot];
    if (pageSize) {
      gridClasses.push(classes.noGridPagination);
    }
    if (removeCellPadding) {
      gridClasses.push(classes.noCellPadding);
    }

    return (
      <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 20 }}>
        {rows && (
          <div>
            <div className={clsx(gridClasses)} key="rows">
              <VMDataGridHeader
                onClickAdd={onClickAdd}
                label={`${definedLabel} (${count ?? rows.length})`}
                filterParamOptions={filterParamOptions}
                filterCallback={filterCallback}
                date={date}
                onDateChangeCallBack={onDateChangeCallBack}
              />
              <DataGrid
                {...standardDataGridProps}
                columns={columns}
                rows={rows}
                className={classes.dataGrid}
                pageSize={pageSize || 20}
                sortingMode="server"
                onSortModelChange={(sortModel) => {
                  if (sortModel.length > 0) {
                    const { field, sort } = sortModel[0];
                    fetchSortedData(field, sort);
                  }
                }}
                onRowClick={onRowClick}
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default VMDataGrid;
